<template>
  <div class="reminders-component screen">
    <SheetVehicleFeatureHeader title="Reminders" :hasVehiclePicker="false" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'reminder',
        title: 'Reminders',
        subTitle: '',
        tooltipScreenName: 'reminders',
      }"
      @close="beforeClose()"
    />
    <SmoothReflow>
      <Alert />
      <div class="top">
        <div class="subheading">VEHICLE LICENCE RENEWAL</div>
        <div class="buttons">
          <button class="btn btn-outline" @click="showModal('new-reminder', { type: 'Vehicle' })">CREATE</button>
        </div>
      </div>
      <div class="panel">
        <template v-if="vehicleLicenceReminders.length || loading">
          <PanelSpinner v-if="loading" />
          <div class="item container" v-for="item in vehicleLicenceReminders" :key="item.Id" v-else>
            <div class="row align-items-center text-center text-md-left">
              <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-start my-3 my-md-0">
                <img src="~@/assets/images/avatar.svg" class="mr-2" width="40" height="40" alt />
                <span class="name">{{ item.Name }}</span>
              </div>
              <div class="col-md-4 mb-2 mb-md-0 d-flex d-md-block justify-content-evenly">
                <div class="date">
                  <img src="~@/assets/images/calendar.svg" width="20" height="20" alt />
                  <span>{{ getDate(item.DateSend) }}</span>
                </div>
                <div class="time">
                  <img src="~@/assets/images/clock.svg" width="20" height="20" alt />
                  <span>{{ getTime(item.DateSend) }}</span>
                </div>
              </div>
              <div class="actions col-md-3 mb-3 mb-md-0">
                <img src="~@/assets/images/edit.svg" class="button mr-md-1" width="25" height="25" alt @click="edit(item)" />
                <svg width="25" height="25" viewBox="0 0 16 16" class="delete-icon button" fill="currentColor" @click="deleteItem('Vehicle', item.Id)">
                  <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                  <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                </svg>
              </div>
            </div>
          </div>
        </template>
        <ErrorBox message="No reminders set. Create a reminder." v-else />
      </div>

      <div class="top mt-4">
        <div class="subheading">DRIVER'S LICENCE RENEWAL</div>
        <div class="buttons">
          <button class="btn btn-outline" @click="showModal('new-reminder', { type: 'Driver' })">CREATE</button>
        </div>
      </div>
      <div class="panel">
        <template v-if="driversLicenceReminders.length || loading">
          <PanelSpinner v-if="loading" />
          <div class="item container" v-for="item in driversLicenceReminders" :key="item.Id" v-else>
            <div class="row align-items-center text-center text-md-left">
              <div class="col-md-5 d-flex align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0">
                <img src="~@/assets/images/avatar.svg" class="mr-2" width="40" height="40" alt />
                <div class="name">
                  <div>{{ item.DriverName }}</div>
                  <div>{{ item.DriverSurname }}</div>
                </div>
              </div>
              <div class="col-md-4 mb-3 mb-md-0 d-flex d-md-block justify-content-evenly">
                <div class="date">
                  <img src="~@/assets/images/calendar.svg" width="20" height="20" alt />
                  <span>{{ getDate(item.DateSend) }}</span>
                </div>
                <div class="time">
                  <img src="~@/assets/images/clock.svg" width="20" height="20" alt />
                  <span>{{ getTime(item.DateSend) }}</span>
                </div>
              </div>
              <div class="actions col-md-3 mb-3 mb-md-0">
                <img src="~@/assets/images/edit.svg" class="button mr-1" width="25" height="25" alt @click="edit(item)" />
                <svg width="25" height="25" viewBox="0 0 16 16" class="delete-icon button" fill="currentColor" @click="deleteItem('Driver', item.Id)">
                  <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                  <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                </svg>
              </div>
            </div>
          </div>
        </template>
        <ErrorBox message="No reminders set. Create a reminder." v-else />
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.reminders-component {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .panel {
    background: $brand-color-4;
    padding: 20px;

    .item {
      background: #fff;
      position: relative;
      padding: 10px;
      margin-bottom: 15px;
      box-shadow: $drop-shadow-light;

      .name {
        font-weight: 600;
      }

      .date {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        img {
          vertical-align: top;
          margin-right: 8px;
        }
      }

      .time {
        display: flex;
        align-items: center;

        img {
          vertical-align: top;
          margin-right: 8px;
        }
      }

      .actions {
        display: flex;
        justify-content: space-evenly;

        .delete-icon {
          fill: $brand-color-1;
        }

        .button {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: $md-1) {
  .reminders-component {
    .panel {
      .item {
        .actions {
          justify-content: space-between;
          position: absolute;
          top: 5px;
          left: 0;
          padding-right: 10px;
        }
      }
    }
  }
}
</style>

<script>
import { alertService, assetService, modalService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";

export default {
  name: "Reminders",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
  },
  data() {
    return {
      loading: true,
      vehicleLicenceReminders: [],
      driversLicenceReminders: [],
    };
  },
  created() {
    const startTime = Date.now();
    assetService
      .getVehicleReminders()
      .then((data) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            data.forEach((reminder) => {
              switch (reminder.EditorMetadata) {
                case "Driver":
                  this.driversLicenceReminders.push(reminder);
                  break;
                case "Vehicle":
                  this.vehicleLicenceReminders.push(reminder);
                  break;
              }
            });
            this.loading = false;
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    getDate(dateTime) {
      return dateTime.split("T")[0];
    },
    getTime(dateTime) {
      const timeSplit = dateTime.split("T")[1].split(":");
      return timeSplit[0] + ":" + timeSplit[1];
    },
    edit(reminder) {
      this.showModal("new-reminder", { reminder: reminder });
    },
    deleteItem(type, id) {
      modalService.showDeleteDialog("Are you sure you want to delete this reminder?", () => {
        assetService.deleteVehicleReminder(id).then((result) => {
          if (result) {
            if (type == "Vehicle") {
              this.vehicleLicenceReminders = this.vehicleLicenceReminders.filter((e) => e.Id !== id);
            } else if (type == "Driver") {
              this.driversLicenceReminders = this.driversLicenceReminders.filter((e) => e.Id !== id);
            }
          } else {
            alertService.showErrorAlert();
          }
        });
      });
    },
    showModal(screenName, parameters) {
      modalService.showModal(this, screenName, parameters);
    },
  },
};
</script>