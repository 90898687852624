function initialState() {
  return {
    type: null,
    message: null,
  };
}

const state = initialState();

const actions = {
  success({ commit }, message) {
    commit("success", message);
  },
  error({ commit }, message) {
    commit("error", message);
  },
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  success(state, message) {
    state.type = "alert-success";
    state.message = message;
  },
  error(state, message) {
    state.type = "alert-danger";
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
};
