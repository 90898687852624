<template>
  <div class="new-alert-component screen">
    <ModalHeader
      :config="{
        type: 'back',
        screenName: backScreenName,
        screenType: openedFromGeoZoneScreen ? 'drawer' : 'modal',
        navigateBack: navigateBack,
        title: '',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <div class="heading">
      <img :src="alertTypeIconURL" width="32" height="32" alt />
      <span>{{ isModifyingExistingAlert ? "MODIFY" : "NEW" }} {{ alertTypeName }} ALERT</span>
    </div>
    <SmoothReflow>
      <Alert />
      <div class="description">{{ alertTypeDescription }}</div>
      <form ref="form" @submit.prevent>
        <div class="container p-0">
          <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column pr-md-0">
              <div class="panel">
                <div class="form-group">
                  <label class="subheading" for="alertType">ALERT TYPE</label>
                  <v-select
                    class="form-control"
                    id="alertType"
                    v-model="form.type"
                    :options="[
                      { label: 'Acceleration Alert', code: 'Acceleration' },
                      { label: 'Braking Alert', code: 'Braking' },
                      { label: 'Cornering Alert', code: 'Cornering' },
                      { label: 'Geo Zone Alert', code: 'Geo-Fencing' },
                      { label: 'Impacts Alert', code: 'Impacts' },
                      { label: 'Lane-change Alert', code: 'Lane-Change' },
                      { label: 'Speeding Alert', code: 'Speeding' },
                      { label: 'Time Rule Alert', code: 'Time Rule' },
                    ]"
                    :reduce="(item) => item.code"
                    :disabled="isModifyingExistingAlert"
                    required
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!form.type" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                </div>
                <div class="form-group mb-0">
                  <label class="subheading" for="alertName">ALERT NAME</label>
                  <input type="text" class="form-control" id="alertName" v-model="form.name" required />
                </div>
              </div>
              <div class="panel" v-if="isGeoZoneAlert">
                <div class="form-group">
                  <label for="geoZoneAlertType">ALERT TRIGGER</label>
                  <v-select
                    class="form-control"
                    id="geoZoneAlertType"
                    v-model="form.geoZoneRule.type"
                    :options="[
                      { label: 'Enter Geo Zone', code: 'EnterGeoZone' },
                      { label: 'Exit Geo Zone', code: 'ExitGeoZone' },
                      { label: 'Ignition Off', code: 'IgnitionOff' },
                      { label: 'Ignition On', code: 'IgnitionOn' },
                    ]"
                    :reduce="(item) => item.code"
                    required
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!form.geoZoneRule.type" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                </div>
                <div class="form-group">
                  <label for="geoZoneAlertValue">ZONE LOCATION</label>
                  <v-select
                    class="form-control"
                    :class="{ loading: !geoZones.length }"
                    id="geoZoneAlertValue"
                    v-model="form.geoZoneRule.value"
                    :options="geoZones"
                    :reduce="(item) => item.Id"
                    label="Name"
                    required
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!form.geoZoneRule.value" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                  <div class="btn btn-link mt-2" @click="createZone()">Create New Zone</div>
                </div>
              </div>
              <div class="panel speeding-rules" v-if="isSpeedingAlert">
                <div class="subheading">SPEED ALERT RULES</div>
                <div class="container">
                  <div class="row">
                    <div class="col-6 custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="limitType"
                        id="roadSpeedThreshold"
                        value="OverSpeeding"
                        v-model="form.speedingRule.type"
                      />
                      <label class="custom-control-label" for="roadSpeedThreshold">Use Road Speed</label>
                    </div>
                    <div class="col-6 custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="limitType" id="speedLimit" value="Speed" v-model="form.speedingRule.type" />
                      <label class="custom-control-label" for="speedLimit">Set Speed Limit</label>
                    </div>
                  </div>
                </div>
                <div class="rule-description">
                  <span v-if="isRoadSpeedRule">Set the threshold above road speed</span>
                  <span v-else>Enter fixed speed limit</span>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    pattern="\d*"
                    maxlength="3"
                    class="form-control"
                    id="limitValue"
                    aria-describedby="kmh"
                    v-model="form.speedingRule.value"
                    required
                  />
                  <div class="kph" for="limitValue">
                    <span>km/h</span>
                  </div>
                </div>
              </div>
              <div class="panel flex-grow-1" :class="{ invalid: submitted && !form.units.length }">
                <div class="subheading">SELECT UNIT</div>
                <div class="container">
                  <div class="row">
                    <div class="col-12 custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" id="allUnits" v-model="allUnits" @click="toggleAllUnits" />
                      <label class="custom-control-label" for="allUnits">All Units</label>
                    </div>
                    <div v-for="(asset, index) in getMyAssets()" :key="asset.SafeAndSoundVehicleId" id="units" class="col-6 custom-control custom-checkbox">
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        :id="'unit' + index"
                        :value="asset.SafeAndSoundVehicleId"
                        :checked="isUnitSelected(asset.SafeAndSoundVehicleId)"
                        @click="toggleUnit(asset.SafeAndSoundVehicleId)"
                        :disabled="allUnits"
                      />
                      <label class="custom-control-label" :for="'unit' + index">{{ asset.RegistrationNumber }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column">
              <div class="panel" :class="{ invalid: submitted && !isValidTimeOrDay }">
                <div class="subheading">TIMES</div>
                <div class="container mb-3">
                  <div class="row mb-2">
                    <div class="custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" id="allDay" v-model="allDay" @click="toggleAllDay" />
                      <label class="custom-control-label" for="allDay">All Day</label>
                    </div>
                  </div>
                  <div class="row" v-show="!allDay">
                    <div class="col-12 col-md-6 p-0">
                      <TimePicker class="mb-2 mb-md-0" label="START TIME" v-model="form.startTime" />
                    </div>
                    <div class="col-12 col-md-6 p-0">
                      <TimePicker label="END TIME" v-model="form.endTime" />
                    </div>
                  </div>
                </div>
                <div class="subheading">DAYS</div>
                <div class="container">
                  <div class="row">
                    <div class="col-12 custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" id="everyDay" v-model="everyDay" @click="toggleEveryDay" />
                      <label class="custom-control-label" for="everyDay">Every Day</label>
                    </div>
                    <div
                      v-for="(day, index) in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                      :key="day"
                      id="days"
                      class="col-6 col-md-4 custom-control custom-checkbox"
                    >
                      <input
                        class="custom-control-input"
                        type="checkbox"
                        :id="'day' + index"
                        :value="day"
                        :checked="isDaySelected(day)"
                        @click="toggleDay(day)"
                        :disabled="everyDay"
                      />
                      <label class="custom-control-label" :for="'day' + index">{{ day }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel notifications flex-grow-1">
                <div class="subheading">NOTIFICATIONS</div>
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" id="pushNotifications" v-model="form.pushNotifications" />
                  <label class="custom-control-label" for="pushNotifications">Notify Me</label>
                </div>
                <template v-for="(e, i) in numNotifications">
                  <div class="form-group row mb-3" :key="i" v-show="numNotifications >= i">
                    <label :for="'email' + i" class="col-sm-2 col-form-label font-weight-normal">EMAIL</label>
                    <div class="col-10 col-sm-8">
                      <input type="email" class="form-control" :id="'email' + i" v-model="form.emails[i]" />
                    </div>
                    <div class="col-2 col-sm-2 p-0">
                      <div class="add-button" @click="addNotification" v-show="numNotifications == i + 1">
                        <svg width="20" height="20" viewBox="0 0 16 16" class="plus-icon" fill="currentColor">
                          <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                          <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="buttons">
        <StatusIndicator class="mr-3" :success="submitSuccess" :error="submitError" />
        <button class="btn btn-outline mr-3" :disabled="processing" @click="goBack()">CANCEL</button>
        <button class="btn btn-outline" :disabled="processing" @click="submit()">
          <span v-show="!processing">SAVE</span>
          <Spinner v-show="processing" />
        </button>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/variables.scss";

.new-alert-component {
  .v-select {
    .vs__dropdown-toggle {
      .vs__selected-options {
        .vs__selected {
          color: $brand-color-1;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.new-alert-component {
  > .heading {
    display: flex;
    align-items: center;
    color: $brand-color-2;
    text-transform: uppercase;
    margin-bottom: 12px;

    img {
      margin-right: 8px;
    }
  }

  .description {
    margin-bottom: 15px;
  }

  .panel {
    background: $brand-color-4;
    padding: 15px 20px 20px 20px;
    margin-bottom: 15px;

    &.notifications {
      padding-bottom: 10px;
    }

    .subheading {
      color: $brand-color-1;
      margin-bottom: 10px;
    }

    .form-group {
      margin-bottom: 10px;

      .form-control {
        background-color: #fff;
        color: $brand-color-1;
        border: 1px solid $brand-color-2;

        &:disabled {
          opacity: 0.5;
        }
      }

      label {
        font-weight: bold;

        &.col-form-label {
          font-style: normal;
          color: $brand-color-2;
        }
      }

      .btn-link {
        font-size: 1em;
        color: $brand-color-1;
        padding: 0;
      }

      .add-button {
        display: inline-block;
        line-height: 30px;

        &:hover {
          cursor: pointer;
        }

        .plus-icon {
          fill: $brand-color-1;
        }
      }
    }

    .custom-checkbox {
      .custom-control-label {
        font-style: normal;
        color: $brand-color-2;
      }
    }

    &.speeding-rules {
      .rule-description {
        font-size: 0.75rem;
        margin: 5px 0 8px 0;
      }

      .form-group {
        position: relative;
        max-width: 200px;

        .form-control {
          padding-right: 65px;
        }

        .kph {
          display: flex;
          align-items: center;
          position: absolute;
          top: 0;
          right: 15px;
          height: 100%;
          font-weight: bold;
        }
      }

      .custom-radio {
        .custom-control-label {
          line-height: 1.25;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { alertService, assetService, modalService } from "@/services";
import TimePicker from "@/components/TimePicker";
import StatusIndicator from "@/components/StatusIndicator";

export default {
  name: "NewAlert",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    TimePicker,
    StatusIndicator,
  },
  props: ["alert", "openedFromGeoZoneScreen", "type"],
  data() {
    return {
      navigateBack: false,
      processing: false,
      submitted: false,
      submitSuccess: false,
      submitError: false,
      allUnits: false,
      allDay: false,
      everyDay: false,
      numNotifications: 1,
      geoZones: [],
      form: {},
      model: {},
    };
  },
  computed: {
    ...mapState(["map"]),
    ...mapGetters("asset", ["getActiveVehicleOwnerId", "getMyAssets", "getSubscriberEmailAddress"]),
    backScreenName() {
      if (this.openedFromGeoZoneScreen) {
        return "geo-zones";
      } else {
        return this.isModifyingExistingAlert ? "manage-alerts" : "new-alert-entry";
      }
    },
    alertTypeName() {
      let name = "";
      switch (this.form.type) {
        case "Acceleration":
          name = "Acceleration";
          break;
        case "Braking":
          name = "Braking";
          break;
        case "Cornering":
          name = "Cornering";
          break;
        case "Geo-Fencing":
          name = "Geo Zone";
          break;
        case "Impacts":
          name = "Impact";
          break;
        case "Lane-Change":
          name = "Lane-change";
          break;
        case "Speeding":
          name = "Speeding";
          break;
        case "Time Rule":
          name = "Time Rule";
          break;
      }
      return name;
    },
    alertTypeDescription() {
      let description = "";
      switch (this.form.type) {
        case "Acceleration":
          description = "Alerts of a possible unsafe or aggressive driving behaviour event when the vehicle accelerates too quickly.";
          break;
        case "Braking":
          description = "Alerts of a possible unsafe or aggressive driving behaviour event when the vehicle does not brake evenly and slowly.";
          break;
        case "Cornering":
          description = "Alerts of a possible unsafe or aggressive driving behaviour event when the vehicle goes around a corner too quickly.";
          break;
        case "Geo-Fencing":
          description = "Alerts you if the vehicle either starts/ends a trip in, or enters/exits a user-configured zone.";
          break;
        case "Impacts":
          description =
            "Alerts you when the vehicle encounters an impact, either by being in an accident or when the vehicle hits an obstacle like a pothole at speed.";
          break;
        case "Lane-Change":
          description = "Alerts of a possible unsafe or aggressive driving behaviour event when the driver changes lanes sharply.";
          break;
        case "Speeding":
          description = "Over-speeding determined either by a fixed speed limit or by comparing the vehicle speed to the road speed.";
          break;
        case "Time Rule":
          description = "Alerts you if the vehicle ignition has been switched on in a specific time period.";
          break;
      }
      return description;
    },
    alertTypeIconURL() {
      let url = "";
      switch (this.form.type) {
        case "Acceleration":
          url = require("@/assets/images/trips/harsh-acceleration.png");
          break;
        case "Braking":
          url = require("@/assets/images/trips/harsh-braking.png");
          break;
        case "Cornering":
          url = require("@/assets/images/trips/harsh-cornering.png");
          break;
        case "Geo-Fencing":
          url = require("@/assets/images/trips/geo-zone.png");
          break;
        case "Impacts":
          url = require("@/assets/images/trips/impacts.png");
          break;
        case "Lane-Change":
          url = require("@/assets/images/trips/lane-changing.png");
          break;
        case "Speeding":
          url = require("@/assets/images/trips/over-speeding.png");
          break;
        case "Time Rule":
          url = require("@/assets/images/trips/time-rule.png");
          break;
      }
      return url;
    },
    isModifyingExistingAlert() {
      return this.alert !== undefined;
    },
    isGeoZoneAlert() {
      return this.form.type == "Geo-Fencing";
    },
    isSpeedingAlert() {
      return this.form.type == "Speeding";
    },
    isRoadSpeedRule() {
      return this.form.speedingRule.type === "OverSpeeding";
    },
    isValidTimeOrDay() {
      const { startTime, endTime, days } = this.form;
      return startTime && endTime && startTime < endTime && days.length;
    },
    isValid() {
      return this.form.units.length && this.isValidTimeOrDay;
    },
  },
  created() {
    this.initForm();
    if (this.isModifyingExistingAlert) {
      this.model.Id = this.alert.Id;
      this.form.name = this.alert.Name;

      let vehicleIdExists = false,
        timeExists = false,
        dayOfWeekExists = false;

      this.alert.Rules.forEach((rule) => {
        switch (rule.Property) {
          case "EventId":
            if (rule.Value === "0") {
              this.form.geoZoneRule.type = "IgnitionOff";
            } else if (rule.Value === "1") {
              this.form.geoZoneRule.type = "IgnitionOn";
            }
            break;
          case "EnterGeoZoneId":
            this.form.geoZoneRule.type = "EnterGeoZone";
            this.form.geoZoneRule.value = parseInt(rule.Value);
            break;
          case "ExitGeoZoneId":
            this.form.geoZoneRule.type = "ExitGeoZone";
            this.form.geoZoneRule.value = parseInt(rule.Value);
            break;
          case "GeoZoneId":
            this.form.geoZoneRule.value = parseInt(rule.Value);
            break;
          case "Speed":
          case "OverSpeeding":
            this.form.speedingRule.type = rule.Property;
            this.form.speedingRule.value = rule.Value;
            break;
          case "VehicleId":
            vehicleIdExists = true;
            this.form.units = rule.Value.split(",").map((item) => item.trim());
            break;
          case "Time":
            timeExists = true;
            if (rule.Value == "00:00, 23:59") {
              this.toggleAllDay();
            } else {
              const times = rule.Value.split(",").map((item) => item.trim());
              this.form.startTime = times[0];
              this.form.endTime = times[1];
            }
            break;
          case "DayOfWeek":
            dayOfWeekExists = true;
            this.form.days = rule.Value.split(",").map((item) => item.trim());
            this.everyDay = this.form.days.length == 7;
            break;
        }
      });

      if (!vehicleIdExists) {
        this.toggleAllUnits();
      }

      if (!timeExists) {
        this.toggleAllDay();
      }

      if (!dayOfWeekExists) {
        this.toggleEveryDay();
      }

      this.form.emails = [];

      this.alert.Channels.forEach((channel) => {
        switch (channel.NotificationType) {
          case "Email":
            this.form.emails.push(channel.Address);
            this.numNotifications = this.form.emails.length;
            break;
          case "PushNotification":
            this.form.pushNotifications = true;
            break;
        }
      });
    }
  },
  methods: {
    ...mapActions("map", ["openGeoZoneEditorForAlert"]),
    ...mapMutations("map", ["updateAlertBeingCreated"]),
    beforeClose() {
      this.$emit("close");
    },
    goBack() {
      if (this.$isMobile) {
        this.beforeClose();
      } else {
        this.navigateBack = true;
      }
    },
    initForm() {
      this.allUnits = false;
      this.allDay = false;
      this.everyDay = false;
      this.numNotifications = 1;
      if (this.map.alertBeingCreated) {
        this.form = this.map.alertBeingCreated;
        this.updateAlertBeingCreated(null);
      } else {
        this.form = {
          type: this.isModifyingExistingAlert ? this.alert.EditorMetadata : this.type,
          name: "",
          geoZoneRule: {
            type: "EnterGeoZone",
            value: "",
          },
          speedingRule: {
            type: "OverSpeeding",
            value: "0",
          },
          units: [],
          startTime: "",
          endTime: "",
          days: [],
          pushNotifications: false,
          emails: [this.getSubscriberEmailAddress()],
        };
      }

      if (this.isGeoZoneAlert) {
        const startTime = Date.now();
        assetService
          .getGeoZones()
          .then((data) => {
            const timeElapsed = Date.now() - startTime;
            setTimeout(
              () => {
                this.geoZones = data;
              },
              timeElapsed < 500 ? 500 - timeElapsed : 0
            );
          })
          .catch((error) => {
            alertService.showErrorAlert("Error retrieving geo zones from server.");
          });
      }
    },
    createZone() {
      this.openGeoZoneEditorForAlert(this.form);
      this.beforeClose();
    },
    isUnitSelected(vehicleId) {
      if (this.form.units) {
        return this.form.units.includes(String(vehicleId));
      }
      return false;
    },
    toggleUnit(value) {
      const index = this.form.units.indexOf(value);
      if (index === -1) {
        this.form.units.push(value);
      } else {
        this.form.units.splice(index, 1);
      }
    },
    toggleAllUnits() {
      this.$nextTick(() => {
        this.allUnits = !this.allUnits;
        const elements = document.querySelectorAll("#units input[type=checkbox]");
        if (this.allUnits) {
          this.form.units = [];
          for (let i = 0, element; (element = elements[i++]); ) {
            element.checked = true;
            this.form.units.push(element.value);
          }
        } else {
          this.form.units = [];
          for (let i = 0, element; (element = elements[i++]); ) {
            element.checked = false;
          }
        }
      });
    },
    isDaySelected(day) {
      if (this.form.days) {
        return this.form.days.includes(day);
      }
      return false;
    },
    toggleAllDay() {
      this.allDay = !this.allDay;
      if (this.allDay) {
        this.form.startTime = "00:00";
        this.form.endTime = "23:59";
      }
    },
    toggleDay(value) {
      const index = this.form.days.indexOf(value);
      if (index === -1) {
        this.form.days.push(value);
      } else {
        this.form.days.splice(index, 1);
      }
    },
    toggleEveryDay() {
      this.$nextTick(() => {
        this.everyDay = !this.everyDay;
        const elements = document.querySelectorAll("#days input[type=checkbox]");
        if (this.everyDay) {
          this.form.days = [];
          for (let i = 0, element; (element = elements[i++]); ) {
            element.checked = true;
            this.form.days.push(element.value);
          }
        } else {
          this.form.days = [];
          for (let i = 0, element; (element = elements[i++]); ) {
            element.checked = false;
          }
        }
      });
    },
    addNotification() {
      if (this.numNotifications < 5) {
        this.numNotifications++;
      }
    },
    submit() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.submitted = true;
      if (this.isValid) {
        this.processing = true;

        const { type, name, geoZoneRule, speedingRule, units, startTime, endTime, days, pushNotifications, emails } = this.form;

        this.model.Id = this.model.Id || 0;
        this.model.Active = true;
        this.model.VehicleOwnerId = this.getActiveVehicleOwnerId();
        this.model.EditorMetadata = type;
        this.model.Name = name;
        this.model.MarkTripAsBusiness = false;
        this.model.Rules = [];

        let eventId = "";
        switch (type) {
          case "Acceleration":
            eventId = "59";
            break;
          case "Braking":
            eventId = "58";
            break;
          case "Cornering":
            eventId = "56";
            break;
          case "Geo-Fencing":
            switch (geoZoneRule.type) {
              case "EnterGeoZone":
                this.model.Rules.push({
                  Property: "EnterGeoZoneId",
                  Operator: "=",
                  Value: geoZoneRule.value,
                });
                break;
              case "ExitGeoZone":
                this.model.Rules.push({
                  Property: "ExitGeoZoneId",
                  Operator: "=",
                  Value: geoZoneRule.value,
                });
                break;
              case "IgnitionOff":
                this.model.Rules.push({
                  Property: "EventId",
                  Operator: "=",
                  Value: "0",
                });
                this.model.Rules.push({
                  Property: "GeoZoneId",
                  Operator: "=",
                  Value: geoZoneRule.value,
                });
                break;
              case "IgnitionOn":
                this.model.Rules.push({
                  Property: "EventId",
                  Operator: "=",
                  Value: "1",
                });
                this.model.Rules.push({
                  Property: "GeoZoneId",
                  Operator: "=",
                  Value: geoZoneRule.value,
                });
                break;
            }
            break;
          case "Impacts":
            eventId = "103,104";
            break;
          case "Lane-Change":
            eventId = "129";
            break;
          case "Speeding":
            this.model.Rules.push({
              Property: speedingRule.type,
              Operator: ">",
              Value: speedingRule.value,
            });
            break;
          case "Time Rule":
            eventId = "";
            break;
        }

        if (eventId) {
          this.model.Rules.push({
            Property: "EventId",
            Operator: eventId.includes(",") ? "in" : "=",
            Value: eventId,
          });
        }

        if (!this.allUnits) {
          this.model.Rules.push({
            Property: "VehicleId",
            Operator: "in",
            Value: units.join(", "),
          });
        }

        this.model.Rules.push({
          Property: "Time",
          Operator: "inrange",
          Value: startTime + ", " + endTime,
        });

        this.model.Rules.push({
          Property: "DayOfWeek",
          Operator: "in",
          Value: days.join(", "),
        });

        this.model.Channels = [];

        if (this.form.pushNotifications) {
          this.model.Channels.push({
            Address: "apns:" + this.model.VehicleOwnerId,
            NotificationType: "PushNotification",
          });
          this.model.Channels.push({
            Address: "gcm:" + this.model.VehicleOwnerId,
            NotificationType: "PushNotification",
          });
        }

        emails.forEach((email) => {
          if (email.trim()) {
            this.model.Channels.push({
              Address: email.trim(),
              NotificationType: "Email",
            });
          }
        });

        assetService
          .updateVehicleAlert(this.model)
          .then((result) => {
            this.processing = false;
            this.submitted = false;
            if (result) {
              if (!this.isModifyingExistingAlert) {
                this.initForm();
              }
              this.submitSuccess = true;
              setTimeout(() => {
                this.submitSuccess = false;
              }, 3000);

              if (this.$isMobile) {
                modalService.showSuccessDialog("Alert created successfully.");
              } else {
                alertService.showSuccessAlert();
              }
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.processing = false;
            this.submitted = false;
            this.submitError = true;
            setTimeout(() => {
              this.submitError = false;
            }, 3000);
            if (error.userMessage) {
              alertService.showErrorAlert(error);
            }
          });
      }
    },
  },
};
</script>
