<template>
  <div class="feedback-component screen">
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'Contact Netstar',
        subTitle: 'Feedback',
      }"
      @close="beforeClose()"
    />
    <Alert />
    <div class="panel">
      <form ref="form" @submit.prevent>
        <div class="form-group">
          <label for="assistanceType">Assistance Type:</label>
          <v-select class="custom-select form-control" id="assistanceType" v-model="model.assistanceType" :options="['Report a bug', 'Feedback']" required />
        </div>
        <div class="form-group">
          <label for="message">Message:</label>
          <textarea class="form-control" id="message" rows="5" v-model="model.message" required />
        </div>
      </form>
    </div>
    <div class="buttons">
      <StatusIndicator class="mr-3" :success="submitSuccess" :error="submitError" />
      <button class="btn btn-outline" @click="submit()">
        <span v-show="!processing">SUBMIT</span>
        <Spinner v-show="processing" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.feedback-component {
  .panel {
    background: $brand-color-4;
    padding: 10px 15px;

    .form-group {
      margin-bottom: 10px;

      .form-control {
        background-color: #fff;
        text-transform: none;
        border: 1px solid $brand-color-2;
      }

      textarea {
        padding: 10px 15px;
      }

      label {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .buttons {
    margin-top: 15px;
  }
}
</style>

<script>
import { adminService, alertService, modalService } from "@/services";
import StatusIndicator from "@/components/StatusIndicator";

export default {
  name: "Feedback",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    StatusIndicator,
  },
  data() {
    return {
      processing: false,
      submitSuccess: false,
      submitError: false,
      model: {},
    };
  },
  computed: {
    isValid() {
      return this.model && this.model.assistanceType && this.model.message;
    },
  },
  created() {
    this.initModelState();
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    initModelState() {
      this.model = {
        assistanceType: "",
        message: "",
        request: "",
        version: navigator.userAgent,
      };
    },
    submit() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      const { model } = this;

      if (this.isValid) {
        this.processing = true;
        adminService
          .sendFeedback(model)
          .then((result) => {
            this.processing = false;
            if (result) {
              this.initModelState();
              this.submitSuccess = true;
              setTimeout(() => {
                this.submitSuccess = false;
              }, 3000);

              if (this.$isMobile) {
                modalService.showSuccessDialog("Feedback submitted successfully.");
              } else {
                alertService.showSuccessAlert();
              }
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.saving = false;
            this.submitError = true;
            setTimeout(() => {
              this.submitError = false;
            }, 3000);
            if (error.userMessage) {
              alertService.showErrorAlert(error);
            }
          });
      } else {
        alertService.showErrorAlert("Please fill in all fields.");
      }
    },
  },
};
</script>