<template>
  <div class="geo-zones-component screen">
    <SheetVehicleFeatureHeader title="Geo Zones" :hasVehiclePicker="false" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'geo-zone',
        title: 'Geo Zones',
        subTitle: '',
        tooltipScreenName: 'geo-zones',
        isLeftDrawer: true,
      }"
      @close="beforeClose()"
    />
    <Alert />
    <div class="panel">
      <SmoothReflow>
        <div>
          <div class="buttons justify-content-end mb-4" v-if="!loading">
            <button class="btn btn-outline mr-3" @click="createAlert">CREATE ALERT</button>
            <button class="btn btn-outline" @click="createZone">CREATE ZONE</button>
          </div>
          <template v-if="geoZones.length || loading">
            <PanelSpinner v-if="loading" />
            <div class="intro" v-if="geoZones.length">Use the toggle switch below to view your active Geo Zones on the map.</div>
            <div class="item container" v-for="(item, i) in geoZones" :key="item.Id">
              <div class="row align-items-center text-left">
                <div class="col-6 col-lg-8">
                  <span class="name">{{ item.Name }}</span>
                </div>
                <!-- <div class="col-6 col-lg-3">
                  <span class="shape">{{ JSON.parse(item.EditorMetadata).Shape }}</span>
                </div> -->
                <div class="col-6 col-lg-4 d-flex justify-content-around">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'alertActive' + i"
                      v-model="item.showOnMap"
                      :disabled="processing"
                      @click="toggleDisplay(item.Id)"
                    />
                    <label class="custom-control-label" :for="'alertActive' + i" />
                  </div>
                  <img src="~@/assets/images/edit.svg" class="button" width="25" height="25" alt @click="edit(item)" />
                  <div class="d-flex align-items-center">
                    <Spinner v-if="processing && deletedGeoZoneId === item.Id" />
                    <svg v-else width="25" height="25" viewBox="0 0 16 16" class="delete-icon button" fill="currentColor" @click="deleteGeoZone(item.Id)">
                      <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                      <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="message-box" v-else>
            <div class="icon">
              <img src="~@/assets/images/globe.svg" width="22" height="22" />
            </div>
            You have not created any zones yet.
          </div>
        </div>
      </SmoothReflow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.geo-zones-component {
  .panel {
    background: $brand-color-4;
    padding: 1.5rem;

    .intro {
      margin-bottom: 20px;
    }

    .item {
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      box-shadow: $drop-shadow-light;

      &:last-of-type {
        margin-bottom: 0;
      }

      .name {
        font-weight: 600;
      }

      .shape {
        text-transform: capitalize;
      }

      .delete-icon {
        fill: $brand-color-1;
      }

      .button {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .message-box {
      background: #fff;
      position: relative;
      font-weight: 600;
      color: $brand-color-1;
      text-align: center;
      padding: 25px;
      margin-left: 0.5rem;
      border: 2px solid $brand-color-2;
      box-shadow: $drop-shadow-light;

      .icon {
        background: #fff;
        position: absolute;
        top: 50%;
        left: -17px;
        padding: 5px;
        transform: translateY(-50%);
        border-radius: 50%;
        border: 2px solid $brand-color-2;
      }
    }
  }
}
</style>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { alertService, assetService, mapService, modalService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import styles from "@/assets/scss/_variables.scss";

export default {
  name: "GeoZones",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
  },
  data() {
    return {
      loading: true,
      processing: false,
      editing: false,
      geoZones: [],
      gmShapes: [],
      gmShapeOptions: {},
      deletedGeoZoneId: null,
    };
  },
  computed: {
    ...mapState(["map"]),
    ...mapState("navigation", ["showLeftDrawer"]),
    ...mapGetters("asset", ["getActiveSafeAndSoundVehicleId"]),
  },
  created() {
    const startTime = Date.now();
    assetService
      .getGeoZones()
      .then((data) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.geoZones = data.map((item) => {
              item.showOnMap = false;
              return item;
            });
            this.loading = false;
            this.displayGeoZones();
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  mounted() {
    if (this.map.gmapRef) {
      this.gmShapeOptions = {
        fillColor: styles.brandColor2,
        fillOpacity: 0.2,
        strokeColor: styles.brandColor1,
        strokeOpacity: 1.0,
        strokeWeight: 4,
        editable: false,
        draggable: false,
        geodesic: true,
        map: this.map.gmapRef.$mapObject,
      };
    }
  },
  beforeDestroy() {
    if (this.editing) {
      this.resetGeoZoneDisplay();
    } else {
      this.resetMap();
    }
  },
  methods: {
    ...mapActions("map", ["openGeoZoneEditor", "setMapBounds"]),
    ...mapMutations("map", ["updateShowMarkers"]),
    beforeClose() {
      this.$emit("close");
    },
    resetMap() {
      this.resetGeoZoneDisplay();
      this.updateShowMarkers(true);
      this.setMapBounds();
    },
    createAlert() {
      modalService.showModal(this, "new-alert", {
        openedFromGeoZoneScreen: true,
        type: "Geo-Fencing",
      });
    },
    createZone() {
      this.editing = true;
      this.openGeoZoneEditor();
      this.beforeClose();
    },
    edit(geoZone) {
      this.editing = true;
      this.openGeoZoneEditor(geoZone);
      this.beforeClose();
    },
    toggleDisplay(geoZoneId) {
      this.geoZones.forEach((geoZone) => {
        if (geoZone.Id === geoZoneId) {
          geoZone.showOnMap = !geoZone.showOnMap;
        }
      });
      this.displayGeoZones();
    },
    deleteGeoZone(geoZoneId) {
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
          .getVehicleAlerts(vehicleId)
          .then((data) => {
            const alertsLinkedToGeoZones = data.filter(item =>
                item.EditorMetadata === "Geo-Fencing" &&
                item.Rules.length > 1 &&
                item.Rules[0].Value === geoZoneId.toString()
            );

            if (alertsLinkedToGeoZones.length) {
              modalService.showDeleteDialog("The selected Geo Zone is currently linked to an Alert. If you proceed with deleting the Geo Zone, the linked Alert will also be deleted.", () => {
                this.processing = true;
                this.deletedGeoZoneId = geoZoneId;
                const alertIds = alertsLinkedToGeoZones.map(item => item.Id);

                assetService
                    .deleteGeoZoneAndLinkedAlerts(alertIds, geoZoneId)
                    .then((result) => {
                      this.processing = false;
                      if (result) {
                        this.geoZones = this.geoZones.filter((item) => item.Id !== geoZoneId);
                        this.displayGeoZones();
                      } else {
                        alertService.showErrorAlert();
                      }
                      this.deletedGeoZoneId = null;
                    })
                    .catch((error) => {
                      this.processing = false;
                      this.deletedGeoZoneId = null;
                      alertService.showErrorAlert();
                    });
              });
            } else {
              modalService.showDeleteDialog("Are you sure you want to delete this geo zone?", () => {
                this.processing = true;
                this.deletedGeoZoneId = geoZoneId;
                assetService
                    .deleteGeoZone(geoZoneId)
                    .then((result) => {
                      this.processing = false;
                      if (result) {
                        this.geoZones = this.geoZones.filter((item) => item.Id !== geoZoneId);
                        this.displayGeoZones();
                      } else {
                        alertService.showErrorAlert();
                      }
                      this.deletedGeoZoneId = null;
                    })
                    .catch((error) => {
                      this.processing = false;
                      this.deletedGeoZoneId = null;
                      alertService.showErrorAlert();
                    });
              });
            }
          })
          .catch((error) => {
            this.processing = false;
            this.deletedGeoZoneId = null;
            alertService.showErrorAlert();
          });
    },
    displayGeoZones() {
      const geoZonesToDisplay = this.geoZones.filter((item) => item.showOnMap);
      if (geoZonesToDisplay.length) {
        this.resetGeoZoneDisplay();
        this.updateShowMarkers(false);
        const bounds = new google.maps.LatLngBounds();

        geoZonesToDisplay.forEach((geoZone) => {
          let gmShape = {};
          let gmShapeInfoWindow = "";
          const zoneString = geoZone.Zone;
          const geoData = mapService.getGeoData(zoneString);
          const color = JSON.parse(geoZone.EditorMetadata).Color;

          if (geoData.type === "Circle") {
            geoData.coordinates.forEach((coordinate) => {
              bounds.extend({ lat: coordinate[1], lng: coordinate[0] });
            });

            gmShape = new google.maps.Circle(
              Object.assign(this.gmShapeOptions, {
                fillColor: color,
                strokeColor: color,
                center: geoData.center,
                radius: geoData.radius,
              })
            );

            gmShapeInfoWindow = new google.maps.InfoWindow({
              position: gmShape.getCenter(),
              content: geoZone.Name,
              map: this.map.gmapRef.$mapObject,
            });

            this.saveShapeObject(gmShape, gmShapeInfoWindow);
          } else {
            const shapeBounds = new google.maps.LatLngBounds();
            const coordinates = [];

            if (geoData.type === "MultiPolygon") {
              geoData.coordinates.forEach((polygons) => {
                polygons.forEach((polygon) => {
                  polygon.forEach((coordinate) => {
                    const point = { lat: coordinate[1], lng: coordinate[0] };
                    bounds.extend(point);
                    shapeBounds.extend(point);
                    coordinates.push(point);
                  });
                });
              });
            } else {
              geoData.coordinates.forEach((shapes) => {
                shapes.forEach((coordinate) => {
                  const point = { lat: coordinate[1], lng: coordinate[0] };
                  bounds.extend(point);
                  shapeBounds.extend(point);
                  coordinates.push(point);
                });
              });
            }

            gmShape = new google.maps.Polygon(
              Object.assign(this.gmShapeOptions, {
                fillColor: color,
                strokeColor: color,
                paths: coordinates,
              })
            );

            gmShapeInfoWindow = new google.maps.InfoWindow({
              position: shapeBounds.getCenter(),
              content: geoZone.Name,
              map: this.map.gmapRef.$mapObject,
            });

            this.saveShapeObject(gmShape, gmShapeInfoWindow);
          }

          this.map.gmapRef.fitBounds(bounds);
        });
      } else {
        this.resetMap();
      }
    },
    resetGeoZoneDisplay() {
      if (this.gmShapes) {
        this.gmShapes.forEach((gmShape) => {
          gmShape.shape.setMap(null);
          gmShape.infoWindow.close();
        });
      }
    },
    saveShapeObject(shape, infoWindow) {
      this.gmShapes.push({
        shape: shape,
        infoWindow: infoWindow,
      });
    },
  },
  watch: {
    showLeftDrawer(value) {
      if (!value) {
        this.$destroy();
      }
    },
  },
};
</script>
