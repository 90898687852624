<template>
  <div class="incompatible-browser-component">
    <div class="container">
      <div class="box">
        <h2>Internet Explorer is not supported.</h2>
        <a href="https://browser-update.org/update-browser.html" class="btn btn-success font-weight-normal">Upgrade Browser</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.incompatible-browser-component {
  .container {
    display: flex;
    align-items: center;
    height: 100vh;

    .box {
      background: rgba(255, 255, 255, 0.8);
      padding: 40px;
      box-shadow: $omni-shadow;

      h2 {
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
}
</style>

<script>
export default {
  name: "IncompatibleBrowser",
};
</script>