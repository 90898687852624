<template>
  <div class="logbooks-component screen">
    <SheetVehicleFeatureHeader title="Logbooks" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Logbooks',
        subTitle: '',
        tooltipScreenName: 'logbooks',
      }"
      @close="beforeClose()"
    />
    <Alert />
    <div class="heading">CREATE YOUR LOGBOOK</div>
    <SmoothReflow>
      <div class="panel" :class="{ invalid: submitted && !model.units.length }">
        <div class="subheading">Select Unit</div>
        <div class="container">
          <div class="row">
            <div class="col-12 custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="allUnits" @click="toggleAllUnits" />
              <label class="custom-control-label" for="allUnits">All Units</label>
            </div>
            <div v-for="(asset, index) in getMyAssets()" :key="asset.SafeAndSoundVehicleId" id="units" class="col-6 custom-control custom-checkbox">
              <input
                class="custom-control-input"
                type="checkbox"
                :id="'unit' + index"
                :value="asset.SafeAndSoundVehicleId"
                @click="toggleUnit(asset.SafeAndSoundVehicleId)"
                :disabled="allUnits"
              />
              <label class="custom-control-label" :for="'unit' + index">{{ asset.RegistrationNumber }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="panel date" :class="{ invalid: submitted && (!model.fromDate || !model.toDate) }">
        <div class="subheading">SELECT DATE</div>
        <DatePicker label="FROM" label-width="43px" v-model="model.fromDate" />
        <DatePicker label="TO" label-width="43px" :minDate="model.fromDate" v-model="model.toDate" />
      </div>
      <div class="panel format" :class="{ invalid: submitted && !destination }">
        <div class="subheading">SELECT DESTINATION</div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-6 custom-control custom-radio">
              <input class="custom-control-input" type="radio" value="email" id="destEmail" name="destination" v-model="destination" />
              <label class="custom-control-label" for="destEmail">Email</label>
            </div>
            <div class="col-6 custom-control custom-radio">
              <input class="custom-control-input" type="radio" value="download" id="destDownload" name="destination" v-model="destination" />
              <label class="custom-control-label" for="destDownload">Download</label>
            </div>
          </div>
        </div>
      </div>
      <div class="panel format" :class="{ invalid: submitted && !model.format }">
        <div class="subheading">SELECT FORMAT</div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-6 custom-control custom-radio">
              <input class="custom-control-input" type="radio" value="pdf" id="pdf" name="format" v-model="model.format" />
              <label class="custom-control-label" for="pdf">
                <img src="~@/assets/images/pdf.svg" width="50" height="43" alt="PDF" />
              </label>
            </div>
            <div class="col-6 custom-control custom-radio">
              <input class="custom-control-input" type="radio" value="excel" id="xls" name="format" v-model="model.format" />
              <label class="custom-control-label" for="xls">
                <img src="~@/assets/images/xls.svg" width="50" height="43" alt="XLS" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="panel email" :class="{ invalid: submitted && !model.emailAddress }" v-if="isEmail">
        <div class="subheading">SEND EMAIL TO:</div>
        <input type="text" class="form-control" v-model="model.emailAddress" />
      </div>
      <div class="buttons">
        <button class="btn btn-outline" :disabled="processing" @click="submit()">
          <span v-show="!processing">SUBMIT</span>
          <Spinner v-show="processing" />
        </button>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.logbooks-component {
  > .heading {
    color: $brand-color-2;
    margin-bottom: 20px;
  }

  .subheading {
    color: $brand-color-1;
  }

  .panel {
    background: $brand-color-4;
    padding: 15px 25px;
    margin-bottom: 15px;

    .custom-checkbox {
      .custom-control-label {
        color: $brand-color-2;
        font-style: normal;
      }
    }

    .form-control {
      background-color: #fff;
      color: $brand-color-1;
      border-radius: 0;
      border-color: $brand-color-2;
    }

    &.format {
      .custom-control-label {
        &::before,
        &::after {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      img {
        margin: 5px 0;
      }
    }

    &.email {
      .form-control {
        &:active,
        &:focus {
          border: 1px solid $brand-color-2;
        }
      }
    }
  }

  .buttons {
    button {
      min-width: 90px;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { alertService, assetService, downloadService, modalService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import DatePicker from "@/components/DatePicker";
import StatusIndicator from "@/components/StatusIndicator";
import { helper } from "@/helper";

export default {
  name: "Logbooks",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
    DatePicker,
    StatusIndicator,
  },
  data() {
    return {
      processing: false,
      submitted: false,
      allUnits: false,
      destination: "",
      model: {
        units: [],
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        format: "",
        emailAddress: "",
      },
    };
  },
  computed: {
    ...mapGetters("asset", ["getMyAssets", "getVehicleRegistrationNumber", "getSubscriberEmailAddress"]),
    isEmail() {
      return this.destination === "email";
    },
    isValid() {
      const { units, fromDate, toDate, format, emailAddress } = this.model;
      const commonValidation = units.length && fromDate && toDate && format;

      if (this.isEmail) {
        return commonValidation && emailAddress;
      }

      return commonValidation;
    },
  },
  mounted() {
    this.model.emailAddress = this.getSubscriberEmailAddress();
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    toggleUnit(value) {
      const index = this.model.units.indexOf(value);
      if (index === -1) {
        this.model.units.push(value);
      } else {
        this.model.units.splice(index, 1);
      }
    },
    toggleAllUnits() {
      this.allUnits = !this.allUnits;
      const elements = document.querySelectorAll("#units input[type=checkbox]");
      if (this.allUnits) {
        this.model.units = [];
        for (let i = 0, element; (element = elements[i++]); ) {
          element.checked = true;
          this.model.units.push(element.value);
        }
      } else {
        this.model.units = [];
        for (let i = 0, element; (element = elements[i++]); ) {
          element.checked = false;
        }
      }
    },
    submit() {
      if (this.isEmail) {
        this.email();
      } else {
        this.download();
      }
    },
    email() {
      this.submitted = true;
      if (this.isValid) {
        this.processing = true;
        const { units, fromDate, toDate, format, emailAddress } = this.model;

        assetService
          .emailVehicleLogbookReport(units, fromDate + "T00:00:00", toDate + "T23:59:59", format, emailAddress)
          .then((result) => {
            this.processing = false;
            if (result) {
              if (this.$isMobile) {
                modalService.showSuccessDialog("Request sent successfully.");
              } else {
                alertService.showSuccessAlert();
              }
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.processing = false;
            this.showErrorAlert();
          });
      }
    },
    download() {
      this.submitted = true;
      if (this.isValid) {
        this.processing = true;
        const { units, fromDate, toDate, format } = this.model;

        const requests = [];

        units.forEach((vehicleId) => {
          requests.push(downloadService.getVehicleLogbook(vehicleId, fromDate + "T00:00:00", toDate + "T23:59:59", format));
        });

        Promise.all(requests)
          .then((responses) => {
            responses.forEach((data, i) => {
              const registrationNumber = this.getVehicleRegistrationNumber(units[i]);
              const filename = format === "pdf" ? `${registrationNumber}.pdf` : `${registrationNumber}.xls`;
              helper.saveToBrowser(filename, data);
            });

            this.processing = false;

            if (this.$isMobile) {
              modalService.showSuccessDialog("File(s) downloaded successfully.");
            } else {
              alertService.showSuccessAlert();
            }
          })
          .catch((errors) => {
            this.processing = false;
            alertService.showErrorAlert();
          });
      }
    },
  },
};
</script>
