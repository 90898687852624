<template>
  <div class="upgrade-terms-and-conditions-for-nano-customers-component">
    <div class="font-weight-bold">SUBSCRIBER AGREEMENT FOR NETSTAR INDIVIDUAL SUBSCRIBER</div>
    <table>
      <tr>
        <td class="section-title" colspan="2">1. CONTRACT</td>
      </tr>
      <tr>
        <td>1.1.</td>
        <td>
          The Subscriber requires certain Netstar Units and Netstar Services from Netstar, and Netstar has agreed to provide the Netstar Units and Netstar
          Services to the Subscriber.
        </td>
      </tr>
      <tr>
        <td>1.2.</td>
        <td>
          This Contract sets out the terms which will apply to the Netstar Unit and Netstar Services provided by Netstar to the Subscriber, regardless whether
          this Contract has been signed by the Subscriber.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">2. DEFINITIONS AND INTERPRETATION</td>
      </tr>
      <tr>
        <td>2.1.</td>
        <td>
          The words and phrases stated below will be used throughout the Contract. When reading the Contract, the word or phrase must be given the meaning set
          out next to it.
        </td>
      </tr>
      <tr>
        <td>2.1.1.</td>
        <td>
          “Application Schedule” means the form which sets out the identity of the Subscriber, details of the Netstar Service which will be provided by Netstar,
          the identity of the Vehicle for which the Netstar Service will be provided and the fees payable for the Netstar Service;
        </td>
      </tr>
      <tr>
        <td>2.1.2.</td>
        <td>
          “Business Day” means Monday to Friday, excluding weekends, being Saturday and Sunday and excluding a public holiday as defined under the Public
          Holiday Act, 36 of 1994;
        </td>
      </tr>
      <tr>
        <td>2.1.3.</td>
        <td>
          “Contract” means this agreement, the Application Schedule, the Netstar User Manual and related literature and where applicable, any addendum to this
          agreement;
        </td>
      </tr>
      <tr>
        <td>2.1.4.</td>
        <td>“Data Subject” means the individual or juristic person to whom the Personal Information relates;</td>
      </tr>
      <tr>
        <td>2.1.5.</td>
        <td>
          “De-identified Data” means Personal Information that has been anonymised such that the identity of the Subscriber or any other person is not or no
          longer identifiable;
        </td>
      </tr>
      <tr>
        <td>2.1.6.</td>
        <td>“Effective Date” means the date when the Netstar Unit is installed into the Vehicle;</td>
      </tr>
      <tr>
        <td>2.1.7.</td>
        <td>
          “Emergency Contact” means the person/s nominated by the Subscriber to be contacted in the event of an emergency or if Netstar is not able to make
          contact with the Subscriber, whose details are set out under the Application Schedule;
        </td>
      </tr>
      <tr>
        <td>2.1.8.</td>
        <td>“Initial Period” means the period of the Contract, as stated under the Application Schedule;</td>
      </tr>
      <tr>
        <td>2.1.9.</td>
        <td>
          “Netstar” means Netstar (Proprietary) Limited, a South African company bearing registration number 1992/001223/07 with its registered office at Block
          O, Central Park Offices, Midrand, and includes its employees, agents and contractors and contractors and where applicable its business partners;
        </td>
      </tr>
      <tr>
        <td>2.1.10.</td>
        <td>“Netstar Control Centre” means the Netstar centre where signals from the Netstar Unit can be monitored and acted upon by Netstar;</td>
      </tr>
      <tr>
        <td>2.1.11.</td>
        <td>
          “Netstar Fitment Centre” means an independent entity that has been authorised and approved by Netstar to install the Netstar Unit on Netstar’s behalf
          into the Vehicle in terms of the Application Schedule;
        </td>
      </tr>
      <tr>
        <td>2.1.12.</td>
        <td>
          “Netstar Privacy Policy” means the document/s which states the manner in which Netstar collects and uses the Subscriber’s Personal Information, with
          whom Netstar shares it, and the Subscriber’s rights in relation to its Personal Information, which can be viewed at
          https://www.altron.com/privacy-policy/;
        </td>
      </tr>
      <tr>
        <td>2.1.13.</td>
        <td>
          Netstar Service” means the Netstar Unit and various services provided by Netstar in terms of the Contract which includes but is not limited to the
          response, tracking and attempted recovery of any stolen or hijacked Vehicle;
        </td>
      </tr>
      <tr>
        <td>2.1.14.</td>
        <td>
          “Netstar Unit” means the Netstar tracking unit installed in the Vehicle Netstar Unit used to provide the Netstar Service and which is sold or rented
          to the Subscriber under this Contract;
        </td>
      </tr>
      <tr>
        <td>2.1.15.</td>
        <td>
          “Netstar User Manual” means the user guide, training materials and related documents provided to the Subscriber, which states how the Netstar Service
          operates;
        </td>
      </tr>
      <tr>
        <td>2.1.16.</td>
        <td>“Parties” means both the Subscriber and Netstar collectively and “Party” means either the Subscriber or Netstar;</td>
      </tr>
      <tr>
        <td>2.1.17.</td>
        <td>
          "Personal Information" has the meaning set out in section 1 of POPIA and includes information relating to an identifiable, natural or juristic person;
        </td>
      </tr>
      <tr>
        <td>2.1.18.</td>
        <td>"POPIA" means the Protection of Personal Information Act, 4 of 2013;</td>
      </tr>
      <tr>
        <td>2.1.19.</td>
        <td>
          "Processing" or "Process" has the meaning set out in POPIA and includes any operation or activity or any set of operations, whether or not by
          automatic means, concerning Personal Information, including:
        </td>
      </tr>
      <tr>
        <td>2.1.19.1.</td>
        <td>the collection, receipt, recording, organisation, collation, storage, updating or modification, retrieval, alteration, consultation or use;</td>
      </tr>
      <tr>
        <td>2.1.19.2.</td>
        <td>dissemination by means of transmission, distribution or making available in any other form; or</td>
      </tr>
      <tr>
        <td>2.1.19.3.</td>
        <td>merging, linking, as well as restriction, degradation, erasure or destruction of Personal Information,</td>
      </tr>
      <tr>
        <td>2.1.20.</td>
        <td>“Subscriber” means the Party to whom this Contract applies, whose details are more specifically set out in the Application Schedule;</td>
      </tr>
      <tr>
        <td>2.1.21.</td>
        <td>
          “Territory” means the countries in Southern Africa stated in the Netstar User Manual where the Netstar Service is available, which may be amended at
          Netstar’s sole discretion from time to time by written notice to the Subscriber;
        </td>
      </tr>
      <tr>
        <td>2.1.22.</td>
        <td>“VAT” means value added tax as levied from time to time in terms of the Value Added Tax Act, 89 of 1991;</td>
      </tr>
      <tr>
        <td>2.1.23.</td>
        <td>“Vehicle” means the vehicle stated in the Application Schedule or any addendum for which the Netstar Service will be provided;</td>
      </tr>
      <tr>
        <td>2.1.24.</td>
        <td>
          “Voice-logged Contract” means, where applicable, an oral Contract concluded telephonically by the Subscriber with Netstar for the Netstar Service to
          which the terms and conditions of this Contract apply.
        </td>
      </tr>
      <tr>
        <td>2.2.</td>
        <td>
          The provisions of this Contract will take precedence over any conflicting provision found under the Netstar User Manual, Application Schedule or any
          other applicable document which may be provided to the Subscriber from time to time.
        </td>
      </tr>
      <tr>
        <td>2.3.</td>
        <td>Words referring to the single form will include the plural form and words referring to one gender will include the other gender.</td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">3. AGREEMENT TO PROVIDE THE NETSTAR SERVICE</td>
      </tr>
      <tr>
        <td>3.1.</td>
        <td>
          The Subscriber has agreed to contract with Netstar for the Netstar Service and Netstar has agreed to provide the Netstar Service to the Subscriber on
          the terms set out in this Contract.
        </td>
      </tr>
      <tr>
        <td>3.2.</td>
        <td>
          Where the Subscriber has entered into a Voice-logged Contract, the Subscriber agrees that the terms of this Contract will apply in all respects to the
          Netstar Service which the Subscriber telephonically requested and which Netstar agreed to provide to the Subscriber.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">4. DURATION OF THE CONTRACT</td>
      </tr>
      <tr>
        <td>4.1.</td>
        <td>
          The duration of the Contract will be for the Initial Period, commencing on the Effective Date, unless terminated earlier by either of the Parties, as
          permitted in terms of this Contract.
        </td>
      </tr>
      <tr>
        <td>4.2.</td>
        <td>
          On expiry of the Initial Period, and provided that the Subscriber has not confirmed that the Contract will terminate on the expiration of the Initial
          Period, the Contract will continue on an indefinite basis, and either Party will have the right to terminate the Contract on 1 (one) calendar months
          written notice to the other.
        </td>
      </tr>
      <tr>
        <td>4.3.</td>
        <td>
          Where the Netstar Unit is purchased by the Subscriber, there will be no Initial Period applicable and either Party will have the right to terminate
          the Contract at any time upon 20 (twenty) Business Days written notice to the other.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">5. SUBSTITUTE VEHICLES AND ADDITIONAL VEHICLES</td>
      </tr>
      <tr>
        <td>5.1.</td>
        <td>
          The Subscriber may at any time add on a vehicle or substitute another vehicle for the Vehicle in respect of which the Netstar Service is being
          provided, in the manner directed by Netstar.
        </td>
      </tr>
      <tr>
        <td>5.2.</td>
        <td>
          Where a Vehicle has been added or substituted for another Vehicle, the Subscriber shall be liable to Netstar for any additional fees relating to the
          Netstar Service, including the costs of installation of the Netstar Unit as the case may be and authorises Netstar to increase the amount of any debit
          order stated under the Application Schedule accordingly, which increase will be effective from the date of installation of the Netstar Unit in the new
          or substituted Vehicle.
        </td>
      </tr>
      <tr>
        <td>5.3.</td>
        <td>The terms set out in this Contract will apply in all respects to the Netstar Service in respect of any new or substituted Vehicle.</td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">6. THE NETSTAR SERVICE, NETSTAR UNIT AND WARRANTIES</td>
      </tr>
      <tr>
        <td>6.1.</td>
        <td>
          Netstar will provide the Subscriber with the Netstar Service for the duration of the Contract, provided that the Subscriber complies with its
          obligations under the Contract.
        </td>
      </tr>
      <tr>
        <td>6.2.</td>
        <td>
          On conclusion of the Contract, the Subscriber shall present the Vehicle for the installation of the Netstar Unit at a Netstar Fitment Centre or
          alternatively at such place as pre-arranged with Netstar.
        </td>
      </tr>
      <tr>
        <td>6.3.</td>
        <td>
          Netstar will only be obliged to provide the Netstar Service for Netstar Unit which has been provided and installed in the Vehicle by a Netstar Fitment
          Centre or by Netstar.
        </td>
      </tr>
      <tr>
        <td>6.4.</td>
        <td>
          Although the Contract will commence on the Effective Date, the Subscriber accepts that the Netstar Service cannot be provided by Netstar or used by
          the Subscriber unless the Netstar Unit is properly installed in the Vehicle, is programmed, enabled and is functioning according to Netstar’s
          specification.
        </td>
      </tr>
      <tr>
        <td>6.5.</td>
        <td>
          If the Netstar Unit is purchased from Netstar, ownership and risk of any loss or damage, in the Netstar Unit will pass to the Subscriber on the
          Effective Date.
        </td>
      </tr>
      <tr>
        <td>6.6.</td>
        <td>
          If the Netstar Unit is rented from Netstar, ownership in the Netstar Unit will stay with Netstar but the risk of loss or damage in the Netstar Unit
          will pass to the Subscriber on the Effective Date.
        </td>
      </tr>
      <tr>
        <td>6.7.</td>
        <td>
          Netstar warrants that the Netstar Unit will be free of defects in workmanship, design and materials for the full duration of the Initial Period and
          where the Netstar Unit is purchased for cash by the Subscriber the Netstar Unit will carry a 12 (twelve) month warranty from the Effective Date,
          subject to clause 6.11.
        </td>
      </tr>
      <tr>
        <td>6.8.</td>
        <td>
          The Subscriber agrees not to alter or modify the Netstar Unit under any circumstances. If the Netstar Unit is tampered with, altered, modified,
          misused or is damaged as a result of a collision, water or any other cause beyond Netstar’s control, including fair wear and tear, then the warranty
          set out under clause 6.7 will not apply and Netstar shall have not have any obligation to repair or replace the Netstar Unit or provide the Netstar
          Service, or refund to the Subscriber any amounts claimed by the Subscriber, in respect of any warranty, damages or contractual claim.
        </td>
      </tr>
      <tr>
        <td>6.9.</td>
        <td>
          Any maintenance, repairs or replacement of the Netstar Unit required in terms of the Netstar warranty will be carried out by Netstar at no cost to the
          Subscriber.
        </td>
      </tr>
      <tr>
        <td>6.10.</td>
        <td>
          Upon the expiry of the Netstar warranty period as set out in clause 6.7, the Subscriber shall bear all costs relating to any maintenance, repairs or
          replacement of the Netstar Unit at the standard retail rates applicable from time to time.
        </td>
      </tr>
      <tr>
        <td>6.11.</td>
        <td>
          THE SUBSCRIBER UNDERSTANDS AND AGREES THAT THE MINIMUM BATTERY LIFE OF THE NETSTAR UNIT IS 3 (THREE) YEARS. THE SUBSCRIBER MUST REPLACE THE NETSTAR
          UNIT AFTER THE NETSTAR UNIT HAS BEEN ACTIVATED FOR ANY REASON OR A PERIOD OF 3 (THREE) YEARS HAS LAPSED FROM THE DATE OF INSTALLATION, WHICHEVER
          OCCURS FIRST.
        </td>
      </tr>
      <tr>
        <td>6.12.</td>
        <td>NETSTAR SHALL BE RELIEVED OF ITS OBLIGATION TO PROVIDE THE NETSTAR SERVICE AT ANY TIME THAT THE NETSTAR UNIT IS NOT FUNCTIONING PROPERLY.</td>
      </tr>
      <tr>
        <td>6.13.</td>
        <td>
          The Subscriber shall notify the Netstar Control Centre immediately should the Netstar Unit be activated in a situation other than an emergency
          situation. The Subscriber accepts responsibility for all consequences of any activation of the Netstar Unit, which may include but is not limited to
          any damage to the Netstar Unit, a response by the police or response teams and or a subsequent wrongful arrest of the Subscriber or any third party.
          THE SUBSCRIBER AGREES TO HOLD NETSTAR HARMLESS FROM, AND INDEMNIFIES NETSTAR AGAINST ANY CLAIMS FOR DAMAGES THAT MAY BE BROUGHT BY ANY PARTY AS A
          RESULT OF ANY ACTIVATION OF THE NETSTAR UNIT.
        </td>
      </tr>
      <tr>
        <td>6.14.</td>
        <td>
          The Subscriber agrees to use the Netstar Unit and the Netstar Service in accordance with the Netstar User Manual and other literature provided by
          Netstar from time to time.
        </td>
      </tr>
      <tr>
        <td>6.15.</td>
        <td>The Netstar Service will be provided in the Territory. Netstar is not obliged to render the Netstar Service outside the Territory.</td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">7. NETSTAR SERVICE FEES</td>
      </tr>
      <tr>
        <td>7.1.</td>
        <td>The fees for the Netstar Services will include the following:</td>
      </tr>
      <tr>
        <td>7.1.1.</td>
        <td>where the Netstar Unit is purchased by the Subscriber, the once off purchase price for the Netstar Unit;</td>
      </tr>
      <tr>
        <td>7.1.2.</td>
        <td>where the Netstar Unit is rented by the Subscriber, the monthly rental fee in respect of the Netstar Unit;</td>
      </tr>
      <tr>
        <td>7.1.3.</td>
        <td>an installation fee for installing the Netstar Unit in the Vehicle;</td>
      </tr>
      <tr>
        <td>7.1.4.</td>
        <td>the monthly service fee for the Netstar Service; and</td>
      </tr>
      <tr>
        <td>7.1.5.</td>
        <td>
          where a Vehicle has been added on or substituted for the existing Vehicle, a fee for installation or removal of the Netstar Unit as the case may be.
        </td>
      </tr>
      <tr>
        <td>7.2.</td>
        <td>
          Where the Effective Date does not fall on the first day of the month, the Subscriber shall pay a pro-rata amount for the Netstar Service in respect of
          that month, calculated on a daily basis from the Effective Date and the Subscriber shall not be billed for the period prior to the Effective Date.
        </td>
      </tr>
      <tr>
        <td>7.3.</td>
        <td>
          Notwithstanding anything to the contrary in this clause, the fees stated in clause 7.1 as applicable, for the first 2 (two) months of the Contract
          (pro rata where applicable) will be paid on the Effective Date, in the manner directed by Netstar.
        </td>
      </tr>
      <tr>
        <td>7.4.</td>
        <td>The fees will be set out in a monthly VAT invoice which will be provided to the Subscriber on a monthly basis.</td>
      </tr>
      <tr>
        <td>7.5.</td>
        <td>
          All fees will be paid by way of debit order in favour of Netstar free of bank charges or in any other manner approved by Netstar, monthly in advance
          on or before the 7th (seventh) day of each calendar month or such date as specified in the debit order authorization contained in the Application
          Schedule.
        </td>
      </tr>
      <tr>
        <td>7.6.</td>
        <td>
          Should any debit order be returned unpaid or dishonoured for any reason, the Subscriber authorises Netstar to submit additional debit orders as may be
          necessary for the full outstanding balance including any arrear amounts.
        </td>
      </tr>
      <tr>
        <td>7.7.</td>
        <td>
          Netstar will have the right to increase the Netstar Service fee and charges on an annual basis, provided that such increase is reasonable and that it
          provides the Subscriber with at least 1 (one) month’s prior written notice of such increase in which event the Subscriber shall be entitled to cancel
          this Contract as permitted in terms of clause 8.
        </td>
      </tr>
      <tr>
        <td>7.8.</td>
        <td>
          If, as a result of a signal from the Netstar Unit, or at the Subscriber’s request, Netstar renders any service not required of it in terms of this
          Contract, the Subscriber agrees that it will pay Netstar an amount determined in accordance with Netstar’s standard fees applicable from time to time,
          for such service rendered.
        </td>
      </tr>
      <tr>
        <td>7.9.</td>
        <td>
          The Subscriber shall not be allowed to withhold payment of any fees or other amounts due to Netstar where the Netstar Unit is not functioning properly
          for any reason. Where this is the case, the Subscriber must immediately inform Netstar and make the necessary arrangements with Netstar for the
          Netstar Unit to be repaired by a Netstar Fitment Centre.
        </td>
      </tr>
      <tr>
        <td>7.10.</td>
        <td>
          If the Subscriber fails to pay to Netstar any amount owing in terms of this Contract, Netstar shall have the right to suspend the Netstar Services and
          will give the Subscriber 20 (twenty) Business Days to make payment of all outstanding amounts. Should Netstar not receive payment as requested in the
          notice, Netstar shall have the right to terminate the Contract and hand the outstanding account to an attorney or debt collector for recovery.
        </td>
      </tr>
      <tr>
        <td>7.11.</td>
        <td>
          Where the Subscriber's insurance company or employer pays the Netstar Service fees to Netstar on behalf of the Subscriber or where the Subscriber
          receives any discount on the Netstar Service fees, THE SUBSCRIBER AUTHORISES NETSTAR IN THE EVENT OF ANY DEFAULT OF PAYMENT, CANCELLATION OF THE
          INSURANCE POLICY OR TERMINATION OF EMPLOYMENT CONTRACT, TO DEBIT THE SUBSCRIBER’S BANK ACCOUNT DIRECTLY WITH THE MONTHLY NETSTAR SERVICE FEES AT THE
          NORMAL RETAIL RATE.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">8. RIGHT TO TERMINATE PRIOR TO EXPIRY OF THE INITIAL PERIOD</td>
      </tr>
      <tr>
        <td>8.1.</td>
        <td>
          The Subscriber may elect to cancel the Contract at any time for any reason including where the Vehicle is sold or written off in an accident subject
          to clause 8.2.
        </td>
      </tr>
      <tr>
        <td>8.2.</td>
        <td>
          Where the Subscriber elects to cancel the Contract, the Subscriber may do so upon 20 (twenty) Business Days written notice to Netstar and where such
          notice has been provided by the Subscriber prior to the Initial Period, the Subscriber must pay a reasonable cancellation fee together with any other
          amounts due and payable in terms of this Contract to Netstar.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">9. FURNISHING INFORMATION AND NOTICES</td>
      </tr>
      <tr>
        <td>9.1.</td>
        <td>
          The Subscriber confirms that all information which it has provided to Netstar under the Contract is true and accurate and can be relied on by Netstar.
        </td>
      </tr>
      <tr>
        <td>9.2.</td>
        <td>In the event of an emergency, the Subscriber agrees that it or Emergency Contact, may be contacted.</td>
      </tr>
      <tr>
        <td>9.3.</td>
        <td>
          If there is any change to the information set out under the Contract, the Subscriber shall notify Netstar immediately in writing of the change. Where
          the Subscriber fails to give Netstar written notice of any changes then the Subscriber agrees to hold Netstar harmless should Netstar rely or act upon
          the former and outdated information.
        </td>
      </tr>
      <tr>
        <td>9.4.</td>
        <td>
          Where Netstar is required to notify the Subscriber or its Emergency Contact of any fact, notice and document relating to or in connection with this
          Contract, Netstar will communicate such message or notice using any form of electronic communication of its choice, including communication sent by
          SMS, email or phone as Netstar deems appropriate and the Subscriber agrees that communication can be given in such a manner.
        </td>
      </tr>
      <tr>
        <td>9.5.</td>
        <td>
          Where Netstar cannot reach the Subscriber or its Emergency Contact at the details supplied by Subscriber, Netstar shall be excused and legally
          relieved of the duty to provide such notice.
        </td>
      </tr>
      <tr>
        <td>9.6.</td>
        <td>
          Where legal notice is to be served, in terms of the Contract on the Subscriber, the Subscriber chooses its address for service of all legal notices
          and documents in connection with this Contract (domicilium citandi et executandi) at its physical address as set forth in the Application Schedule.
        </td>
      </tr>
      <tr>
        <td>9.7.</td>
        <td>
          Where legal notice is to be served, in terms of the Contract on Netstar, Netstar chooses its respective address for service of all legal notices and
          documents in connection with this Contract (domicilium citandi et executandi) at the following physical addresses: Central Park Offices, Block O, 16th
          Road, Randjespark, Extension 5, Midrand.
        </td>
      </tr>
      <tr>
        <td>9.8.</td>
        <td>
          Either Party may change its address, by written notice to the other Party provided that any new address selected by it will be a physical address, and
          such changes will only be effective upon receipt of notice in writing by the other Party.
        </td>
      </tr>
      <tr>
        <td>9.9.</td>
        <td>
          The Subscriber acknowledges that Netstar is obligated to ascertain the Subscriber’s credit worthiness as per the provisions of the National Credit
          Act, 34 of 2005. The Subscriber expressly gives Netstar permission to:
        </td>
      </tr>
      <tr>
        <td>9.9.1.</td>
        <td>
          access from any duly registered credit bureau, the Subscriber’s Personal Information concerning financial risk and payment habits (“payment profile”)
          to process the Subscriber’s application for the Netstar Service; and
        </td>
      </tr>
      <tr>
        <td>9.9.2.</td>
        <td>share or disclose information about the Subscriber’s payment profile to credit bureau.</td>
      </tr>
      <tr>
        <td>9.10.</td>
        <td>
          Where the Subscriber has authorised a third party which is also a business partner of Netstar such as the Subscriber’s insurance company, emergency
          response company or any other third party to receive Personal Information from Netstar, then to the extent of such authorisation, THE SUBSCRIBER
          EXPRESSLY CONSENTS TO NETSTAR PROVIDING PERSONAL INFORMATION TO SUCH PARTY AND WILL HOLD NETSTAR HARMLESS FROM ANY LOSS OR DAMAGE RESULTING FROM SUCH
          DISCLOSURE.
        </td>
      </tr>
      <tr>
        <td>9.11.</td>
        <td>
          THE SUBSCRIBER REPRESENTS AND UNDERTAKES IN RELATION TO ANY DATA SUBJECT, INCLUDING BUT NOT LIMITED TO ANYOF THE SUBSCRIBER’S DESIGNATED DRIVERS OR
          EMERGENCY CONTACT PERSONS, THAT THE SUBSCRIBER HAS COMPLIED WITH, AND WILL CONTINUE TO COMPLY WITH, ANY AND ALL RELEVANT DATA PROTECTION LAWS SUCH AS
          POPIA. THE SUBSCRIBER UNDERSTANDS THAT SUCH COMPLIANCE WILL INCLUDE BUT NOT BE LIMITED TO OBTAINING CONSENT TO THE EXTENT NECESSARY, IN THE EVENT THAT
          THE SUBSCRIBER SHARES PERSONAL INFORMATION THAT THE SUBSCRIBER HOLDS IN RESPECT OF SUCH DATA SUBJECT WITH NETSTAR FOR THE PURPOSES OF THIS CONTRACT.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">10. EXCLUSION OF LIABILITY</td>
      </tr>
      <tr>
        <td>10.1.</td>
        <td>
          The Subscriber accepts that the Netstar Service is intended to reduce the risk of loss if the Subscriber’s Vehicle is stolen or hijacked, but that
          recovery of the Vehicle is not guaranteed.
        </td>
      </tr>
      <tr>
        <td>10.2.</td>
        <td>
          UNLESS PROHIBITED BY LAW, THE SUBSCRIBER AGREES TO HOLD NETSTAR HARMLESS FOR ANY LOSS OR DAMAGE CAUSED TO THE SUBSCRIBER OR ANY THIRD PARTY ARISING
          FROM THE NETSTAR SERVICE, AND OR NETSTAR NOT BEING ABLE TO PERFORM THE NETSTAR SERVICE FOR ANY REASON, INCLUDING NETSTAR’S NEGLIGENCE, OR DUE TO ANY
          MALFUNCTION OF THE NETSTAR UNIT AND OR THE NETWORK.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">11. DATA PROTECTION AND PRIVACY</td>
      </tr>
      <tr>
        <td>11.1.</td>
        <td>
          In order for Netstar to provide the Netstar Service, the Subscriber understands and agrees that Netstar requires information including but not limited
          to Personal Information to enable Netstar to fulfil its obligations in terms of this Contract. The Subscriber undertakes to provide such information
          to Netstar as and when required in relation to the Netstar Services.
        </td>
      </tr>
      <tr>
        <td>11.2.</td>
        <td>Netstar will collect the following categories of Personal Information from the Subscriber:</td>
      </tr>
      <tr>
        <td>11.2.1.</td>
        <td>name and surname;</td>
      </tr>
      <tr>
        <td>11.2.2.</td>
        <td>contact number;</td>
      </tr>
      <tr>
        <td>11.2.3.</td>
        <td>email address;</td>
      </tr>
      <tr>
        <td>11.2.4.</td>
        <td>physical address;</td>
      </tr>
      <tr>
        <td>11.2.5.</td>
        <td>postal address;</td>
      </tr>
      <tr>
        <td>11.2.6.</td>
        <td>identity number;</td>
      </tr>
      <tr>
        <td>11.2.7.</td>
        <td>banking details;</td>
      </tr>
      <tr>
        <td>11.2.8.</td>
        <td>Vehicle registration number;</td>
      </tr>
      <tr>
        <td>11.2.9.</td>
        <td>make and model of Vehicle; and</td>
      </tr>
      <tr>
        <td>11.2.10.</td>
        <td>location information.</td>
      </tr>
      <tr>
        <td>11.3.</td>
        <td>
          Netstar will collect Personal Information directly from the Subscriber and third parties, such as, including but not limited to, brokers, insurance
          companies, credit bureaus, dealerships and business partners.
        </td>
      </tr>
      <tr>
        <td>11.4.</td>
        <td>
          Netstar will exercise all reasonable measures to process the Subscriber’s Personal Information in terms of the Netstar Privacy Policy, POPIA and the
          provisions of this clause.
        </td>
      </tr>
      <tr>
        <td>11.5.</td>
        <td>
          By entering into this Contract, the Subscriber confirms, acknowledges and agrees, with express consent, that Netstar may collect Process and or store
          the Personal Information contained in the Contract, or any transaction under it, or any entry, account or other information held by Netstar in
          relation to this Contract (which may include the Subscriber’s Personal Information and/or Personal Information of the Subscriber’s Emergency Contact
          as well as De-identified Data) for the purposes of
        </td>
      </tr>
      <tr>
        <td>11.5.1.</td>
        <td>
          sharing certain of the Subscriber’s Personal Information (and non-personal information such as make and model of the Vehicle and theft and hi-jacking
          statistics) with Netstar’s business partners, service providers and or sub-contractors for purposes of providing the Netstar Services to the
          Subscriber;
        </td>
      </tr>
      <tr>
        <td>11.5.2.</td>
        <td>concluding, implementing and monitoring the operation of this Contract;</td>
      </tr>
      <tr>
        <td>11.5.3.</td>
        <td>
          to banks for purposes of effecting the deduction and payment of amounts due to Netstar and all ancillary actions related to such deduction and
          payment;
        </td>
      </tr>
      <tr>
        <td>11.5.4.</td>
        <td>assessing financial risks;</td>
      </tr>
      <tr>
        <td>11.5.5.</td>
        <td>fraud prevention and preventing and detecting crime;</td>
      </tr>
      <tr>
        <td>11.5.6.</td>
        <td>
          providing the Subscriber, any combination of services, analysis, advice or intermediary service linked to the Contract or the Subscriber’s
          relationship with Netstar as a client;
        </td>
      </tr>
      <tr>
        <td>11.5.7.</td>
        <td>
          SMS and other electronic forms of direct marketing for Netstar products and services as well as products and services of third parties affiliated with
          Netstar (unless the Subscriber has requested not to receive such information);
        </td>
      </tr>
      <tr>
        <td>11.5.8.</td>
        <td>carrying out statistical and other analyses to identify potential markets and trends;</td>
      </tr>
      <tr>
        <td>11.5.9.</td>
        <td>developing new products and services and enhancing and developing Netstar’s existing products and services;</td>
      </tr>
      <tr>
        <td>11.5.10.</td>
        <td>training of Netstar employees;</td>
      </tr>
      <tr>
        <td>11.5.11.</td>
        <td>
          inclusion in data lists which may be used by third parties to improve and enhance Netstar’s products and service and offerings to the Subscriber;
        </td>
      </tr>
      <tr>
        <td>11.5.12.</td>
        <td>assessing the Subscriber’s insurance needs;</td>
      </tr>
      <tr>
        <td>11.5.13.</td>
        <td>
          referring it to a credit reference agency or credit bureau (which may make records of searches and enquiries which may be used by others for lending,
          credit or purchasing decisions about the Subscriber or any individual).
        </td>
      </tr>
      <tr>
        <td>11.5.14.</td>
        <td>
          any person, subsidiary, holding company or associated company or other company who is engaged in Netstar’s business or who is acting on Netstar’s
          behalf; and
        </td>
      </tr>
      <tr>
        <td>11.5.15.</td>
        <td>disclosing of Personal Information required or permitted by Law.</td>
      </tr>
      <tr>
        <td>11.6.</td>
        <td>THE SUBSCRIBER HEREBY EXPRESSLY CONSENTS TO NETSTAR:</td>
      </tr>
      <tr>
        <td>11.6.1.</td>
        <td>
          DISCLOSING ITS PERSONAL INFORMATION, TO ANY PERSON, SUBSIDIARY, HOLDING COMPANY OR ASSOCIATED COMPANY OR OTHER COMPANY WHO IS ENGAGED IN NETSTAR’S
          BUSINESS OR WHO IS ACTING ON NETSTAR’S BEHALF FOR THE ABOVE PURPOSES;
        </td>
      </tr>
      <tr>
        <td>11.6.2.</td>
        <td>
          DISCLOSING THE SUBSCRIBER PERSONAL INFORMATION TO ANY PERSON WHO PROVIDES SERVICES TO NETSTAR OR ACTS AS NETSTAR’S AGENT OR TO WHOM NETSTAR HAS
          TRANSFERRED OR PROPOSE TO TRANSFER ANY OF NETSTAR’S RIGHTS AND DUTIES IN RESPECT OF THIS CONTACT, LOCALLY AND OUTSIDE THE REPUBLIC OF SOUTH AFRICA, AS
          NECESSARY. NETSTAR REQUESTS PERSONS WHO PROVIDE SERVICES TO NETSTAR TO AGREE TO THE NETSTAR PRIVACY POLICIES IF THEY NEED ACCESS TO ANY PERSONAL
          INFORMATION TO CARRY OUT THEIR SERVICES.
        </td>
      </tr>
      <tr>
        <td>11.7.</td>
        <td>THE SUBSCRIBER ACKNOWLEDGES THAT:</td>
      </tr>
      <tr>
        <td>11.7.1.</td>
        <td>
          NETSTAR WILL AT ALL TIMES REMAIN RESPONSIBLE FOR DETERMINING THE PURPOSE OF AND MEANS FOR PROCESSING THE SUBSCRIBER’S PERSONAL INFORMATION IN TERMS OF
          AND SUBJECT TO THIS CLAUSE 11;
        </td>
      </tr>
      <tr>
        <td>11.7.2.</td>
        <td>
          NETSTAR IS REQUIRED BY VARIOUS LAWS, TO COLLECT AND DISCLOSE SOME OF THE SUBSCRIBER’S PERSONAL INFORMATION. WITHOUT THIS PERSONAL INFORMATION NETSTAR
          WILL BE UNABLE TO CONCLUDE AND OPERATE THIS CONTRACT; AND
        </td>
      </tr>
      <tr>
        <td>11.7.3.</td>
        <td>THE SUBSCRIBER IS PROVIDING NETSTAR WITH ITS PERSONAL INFORMATION VOLUNTARILY.</td>
      </tr>
      <tr>
        <td>11.8.</td>
        <td>
          The Subscriber expressly consents thereto that Netstar may transfer the details of this Contract, to computer system operators in countries outside of
          South Africa, which have data protection laws equivalent or greater than those in South Africa.
        </td>
      </tr>
      <tr>
        <td>11.9.</td>
        <td>
          The Subscriber consents to and acknowledges that Netstar may monitor and/or record telephone calls with the Subscriber for quality security, and
          training purposes.
        </td>
      </tr>
      <tr>
        <td>11.10.</td>
        <td>
          The Subscriber waives any right, title or interest in and to the De-identified Data and expressly agrees that Netstar may process the De-identified
          Data in any manner whatsoever which may include commercial gain.
        </td>
      </tr>
      <tr>
        <td>11.11.</td>
        <td>
          Netstar will use reasonable endeavours to ensure that the Subscriber’s Personal Information as provided is accurate, however, it is the Subscriber’s
          responsibility to ensure that the information provided is accurate. The Subscriber undertakes to immediately advise Netstar of any changes to the
          Subscriber’s Personal Information should any of these details change. Netstar will not be responsible for any loss or damage, howsoever caused, in the
          event of the Subscriber providing inaccurate or incomplete information.
        </td>
      </tr>
      <tr>
        <td>11.12.</td>
        <td>
          The Subscriber has the right to lodge a complaint with the Information Regulator, under POPIA. The contact details of the Information Regulator are
          available on the following website: https://www.justice.gov.za/inforeg/.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">12. ACCESS TO DATA</td>
      </tr>
      <tr>
        <td>12.1.</td>
        <td>
          The Subscriber acknowledges that Netstar will have access to and Process Personal Information for the duration of the Contract in accordance with
          clause 11 for the provision of the Netstar Service to the Subscriber. The Netstar Service, including access to the provision of any data to the
          Subscriber shall terminate upon termination of the Contract.
        </td>
      </tr>
      <tr>
        <td>12.2.</td>
        <td>
          Netstar will delete the Personal Information within a reasonable period of time following termination of the Contract except where required retention
          of the Personal Information is required by law in which case Netstar will retain such Personal Information in accordance with applicable legislation.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">13. FORCE MAJEURE</td>
      </tr>
      <tr>
        <td></td>
        <td>
          If Netstar is prevented or restricted in any way from carrying out all or any of its obligations under this Contract by reason of force majeure (an
          event or circumstance beyond the control of the Parties, such as a war, strike, riot, crime, or an “act of God” such as flooding or an earthquake
          which prevents one or both Parties from performing their obligations under the Contract), then NETSTAR SHALL BE RELIEVED OF ITS OBLIGATIONS TO PROVIDE
          THE NETSTAR SERVICE DURING SUCH PERIOD OF FORCE MAJEURE, AND NETSTAR SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE, ACTION OR CLAIM WHICH MAY BE BROUGHT BY
          THE SUBSCRIBER OR BY ANY OTHER PARTY IN CONSEQUENCE OF SUCH DELAY OR INABILITY TO PERFORM.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">14. BREACH AND CONSEQUENCES</td>
      </tr>
      <tr>
        <td>14.1.</td>
        <td>If the Subscriber or its authorised agent, which includes an insurer, employer or bank:</td>
      </tr>
      <tr>
        <td>14.1.1.</td>
        <td>fails to pay any amount under this Contract on due date; or</td>
      </tr>
      <tr>
        <td>14.1.2.</td>
        <td>fails to carry out or perform any of its contractual obligations or breaches any term or condition of this Contract; or</td>
      </tr>
      <tr>
        <td>14.1.3.</td>
        <td>in Netstar’s reasonable opinion raises false alarms or abuses the Netstar Service, (referred to as “the breach”);</td>
      </tr>
      <tr>
        <td>14.1.4.</td>
        <td>
          then Netstar may immediately suspend the Netstar Service and provide the Subscriber with a written notice requiring it to rectify the breach within 20
          (twenty) Business Days of the date of such notice.
        </td>
      </tr>
      <tr>
        <td>14.2.</td>
        <td>
          Where the Subscriber fails to rectify the breach within the 20 (twenty) Business Day period, Netstar shall thereafter have the right to immediately
          terminate the Contract, without notice to the Subscriber, which termination shall be without prejudice to any rights which Netstar may then have in
          law, including:
        </td>
      </tr>
      <tr>
        <td>14.2.1.</td>
        <td>
          where the Initial Period of the Contract has not expired, the right to claim from the Subscriber an early termination fee reasonably calculated by
          Netstar;
        </td>
      </tr>
      <tr>
        <td>14.2.2.</td>
        <td>
          where the Initial Period of the Contract has not expired, the right to immediately remove and recover ownership and possession of the Netstar Unit
          from the Vehicle, at the Subscriber’s risk and expense;
        </td>
      </tr>
      <tr>
        <td>14.2.3.</td>
        <td>the right to demand from the Subscriber, all amounts payable, by the Subscriber to Netstar under the Contract; and</td>
      </tr>
      <tr>
        <td>14.2.4.</td>
        <td>the right to claim any other damages from the Subscriber, which it may have incurred in law in consequence of the Subscriber’s breach.</td>
      </tr>
      <tr>
        <td></td>
        <td>
          Where Netstar breaches any material term of the Contract, the Subscriber has the right to send Netstar a written notice requiring Netstar to rectify
          the breach within 20 (twenty) Business Days of receipt of such notice.
        </td>
      </tr>

      <tr>
        <td>14.3.</td>
        <td>
          Where Netstar fails to rectify the breach within the 20 (twenty) Business Day period, the Subscriber shall thereafter have the right to immediately
          terminate the Contract, on written notice to Netstar which termination shall be without prejudice to any rights which the Subscriber may have in law,
          including the right to claim damages from Netstar which it may have incurred in consequence of Netstar’s breach.
        </td>
      </tr>
      <tr>
        <td>14.4.</td>
        <td>
          Where the Subscriber cancels the Contract during the Initial Period in terms of clause 12.3, the Subscriber shall still be liable to pay Netstar an
          early termination fee and Netstar shall have the right to immediately remove and recover ownership and possession of the Netstar Unit from the
          Vehicle.
        </td>
      </tr>
      <tr>
        <td>14.5.</td>
        <td>
          Where Netstar has to remove the Netstar Unit in terms of this clause, the Subscriber shall do all such things as may be reasonably necessary to enable
          Netstar to uplift and collect the Netstar Unit from the Vehicle.
        </td>
      </tr>
      <tr>
        <td>14.6.</td>
        <td>
          Where Netstar has to use the services of a debt collector or attorney to successfully enforce any provisions of the Contract, then the Subscriber
          shall bear the costs incurred by Netstar, including legal fees, on an attorney and client basis.
        </td>
      </tr>
      <tr>
        <td class="section-title" colspan="2">15. GENERAL</td>
      </tr>
      <tr>
        <td>15.1.</td>
        <td>This Contract will be interpreted in accordance with the laws of the Republic of South Africa.</td>
      </tr>
      <tr>
        <td>15.2.</td>
        <td>
          Should any provision of this Contract be declared unlawful whilst the Contract is in force, such provision will be deleted from the Contract and the
          remaining parts of the Contract will continue to operate.
        </td>
      </tr>
      <tr>
        <td>15.3.</td>
        <td>Netstar shall have the right to change the terms of this Agreement upon written notice to the Subscriber.</td>
      </tr>
      <tr>
        <td>15.4.</td>
        <td>
          Where one of the Parties does not act on a breach of the Contract by the other Party, failure to act will not result in that Party giving up its
          rights to act upon that breach or another breach, at a later stage.
        </td>
      </tr>
      <tr>
        <td>15.5.</td>
        <td>
          The Subscriber shall not be able to transfer, cede, delegate or assign its rights or obligations in terms of this Contract to another person without
          the prior written consent of Netstar, which consent will not unreasonably be withheld.
        </td>
      </tr>
      <tr>
        <td>15.6.</td>
        <td>
          Netstar shall be entitled to transfer, cede, delegate or assign its rights or obligations under this Contract provided that the Subscriber is not
          reasonably prejudiced in any manner.
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.upgrade-terms-and-conditions-for-nano-customers-component {
  table {
    tr {
      td {
        vertical-align: top;

        &.section-title {
          font-weight: bold;
          padding-top: 15px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "UpgradeTermsAndConditionsForNanoCustomers",
};
</script>