import { api } from "@/services/api.service";

export const downloadService = {
  getLatestStatement,
  getLatestInvoice,
  getAccountSummary,
  getFitmentCertificate,
  getTestCertificate,
  getVehicleLogbook,
  getVehicleTripReport,
};

const apiBasePath = "/api/download";

function getLatestStatement() {
  return api
    .get(`${apiBasePath}/latest-statement`, {
      responseType: "blob",
    })
    .then((request) => {
      return request.data;
    });
}

function getLatestInvoice() {
  return api
    .get(`${apiBasePath}/latest-invoice`, {
      responseType: "blob",
    })
    .then((request) => {
      return request.data;
    });
}

function getAccountSummary() {
  return api
    .get(`${apiBasePath}/account-summary`, {
      responseType: "blob",
    })
    .then((request) => {
      return request.data;
    });
}

function getFitmentCertificate(vehicleId) {
  return api
    .get(`${apiBasePath}/fitment-certificate`, {
      responseType: "blob",
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getTestCertificate(vehicleId) {
  return api
    .get(`${apiBasePath}/test-certificate`, {
      responseType: "blob",
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleLogbook(vehicleId, fromDate, toDate, format) {
  return api
    .get(`${apiBasePath}/vehicle-logbook`, {
      responseType: "blob",
      params: {
        vehicleId: vehicleId,
        fromDate: fromDate,
        toDate: toDate,
        format: format,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleTripReport(tripId, format) {
  return api
    .get(`${apiBasePath}/vehicle-trip-report`, {
      responseType: "blob",
      params: {
        tripId: tripId,
        format: format,
      },
    })
    .then((request) => {
      return request.data;
    });
}
