<template>
  <transition name="fade">
    <div id="tooltip-container" v-show="showTooltip">
      <transition name="fade">
        <div class="tooltip-content" v-if="showTooltip">
          <div class="tooltip-icon">
            <img
              src="~@/assets/images/tooltips/tooltip-head.png"
              height="85"
              alt=""
            />
          </div>
          <div class="close" @click="closeTooltip()"></div>
          <div class="header">
            <strong>TIP</strong>
            <span v-if="tooltipTitle">
              <span class="separator">|</span>
              <span class="title">{{ tooltipTitle }}</span>
            </span>
          </div>
          <hooper ref="slider" :infiniteScroll="true">
            <slide
              class="slide"
              v-for="(item, index) in tooltipContent"
              :key="index"
            >
              <img :src="item.image" alt="" v-if="item.image" />
              <div class="message">{{ item.message }}</div>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
          <div class="buttons">
            <button class="btn btn-outline white" @click="prevSlide">
              PREVIOUS
            </button>
            <button class="btn btn-outline white" @click="nextSlide">
              NEXT
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

#tooltip-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($brand-color-1, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;

  .tooltip-content {
    background: $brand-color-2;
    position: relative;
    width: 450px;
    max-width: 100%;
    padding: 60px 30px 30px 40px;
    box-shadow: $drop-shadow;

    .tooltip-icon {
      background: $brand-color-2;
      position: absolute;
      top: -55px;
      left: 50%;
      transform: translateX(-50%);
      padding: 15px 15px 0 15px;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
    }

    .close {
      background: transparent;
      position: absolute;
      top: 10px;
      right: 15px;
      width: 16px;
      height: 16px;
      opacity: 1;

      &:before,
      &:after {
        background: #fff;
        position: absolute;
        top: 0;
        left: 8px;
        content: " ";
        height: 16px;
        width: 2px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .header {
      font-size: 1.25rem;
      color: #fff;
      margin-bottom: 15px;

      .separator {
        display: inline-block;
        margin: 0 10px;
      }

      .title {
        text-transform: uppercase;
      }
    }

    .slide {
      img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
      }

      .message {
        color: #fff;
        text-align: center;
      }
    }

    .buttons {
      text-align: center;
      margin-top: 30px;

      button {
        width: 110px;
        margin: 0 8px;
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import { tooltipService } from "@/services";

import "hooper/dist/hooper.css";

export default {
  name: "TooltipContainer",
  components: {
    Hooper,
    HooperPagination,
    Slide,
  },
  computed: {
    ...mapState("navigation", [
      "showTooltip",
      "tooltipTitle",
      "tooltipContent",
    ]),
  },
  methods: {
    closeTooltip() {
      tooltipService.closeTooltip();
    },
    prevSlide() {
      this.$refs.slider.slidePrev();
    },
    nextSlide() {
      this.$refs.slider.slideNext();
    },
  },
};
</script>
