<template>
  <div class="thank-you-for-upgrade-component screen">
    <div class="header">THANK YOU</div>
    <div class="content">
      <div v-if="fitmentRequired">
        <div class="message">
          Your product upgrade request has been submitted. One of our Planning Consultants will contact you shortly to schedule a fitment.
        </div>
        <div class="separator"></div>
        <div class="reference-container">
          <div>REFERENCE NUMBER</div>
          <div class="font-weight-bold">{{ ticketNumber ? ticketNumber : referenceNumber }}</div>
        </div>
      </div>
      <template v-else>
        <div class="message">
          <p>Your product upgrade request has been submitted. Please allow a few hours for the upgraded features to reflect on MyNetstar.</p>
          <p>An additional R30 will be deducted from your account on the next billing cycle.</p>
        </div>
      </template>
      <div class="footer">
        <!-- <div class="d-flex align-items-center justify-content-end" style="min-height: 32px" v-if="canBookFitment">
          <div class="font-italic mr-3" v-if="!fitmentSchedulerIsReady">Preparing fitment scheduler...</div>
          <a :href="fitmentBookingUrl" target="_blank" class="btn btn-outline" @click="close()" v-if="fitmentSchedulerIsReady"> BOOK FITMENT </a>
          <Spinner v-else />
        </div> -->
        <button class="btn btn-outline" @click="close()">FINISH</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.thank-you-for-upgrade-component {
  .header {
    background: $brand-color-2;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    padding: 20px;
    margin: -20px -30px 30px -30px;
    box-shadow: $drop-shadow;
  }

  .content {
    padding: 20px;

    .message {
      line-height: 1.5;
    }

    .separator {
      height: 40px;
      margin: 15px 0;
      border-left: 1px solid $brand-color-2;
    }

    .reference-container {
      background: $brand-color-1;
      font-size: 1.125rem;
      font-weight: 300;
      color: #fff;
      width: fit-content;
      max-width: 400px;
      padding: 10px 50px 10px 20px;
      clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
    }
  }

  .footer {
    text-align: right;
    padding-top: 20px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { fitmentSchedulerService } from "@/services";
import Spinner from "@/components/Spinner";

export default {
  name: "ThankYouForUpgrade",
  components: {
    Spinner,
  },
  props: {
    fitmentBookingUrl: {
      type: String,
      default: "",
    },
    referenceNumber: {
      type: String,
      default: "",
    },
    ticketNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      intervalId: null,
      fitmentSchedulerIsReady: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveVehicleProductName"]),
    fitmentRequired() {
      return this.getActiveVehicleProductName() === "Nano";
    },
    canBookFitment() {
      return this.fitmentRequired && this.ticketNumber;
    },
  },
  // created() {
  //   if (this.canBookFitment) {
  //     this.intervalId = setInterval(() => {
  //       fitmentSchedulerService.isReady(this.ticketNumber).then((result) => {
  //         if (result.IsReady) {
  //           this.fitmentSchedulerIsReady = true;
  //           clearInterval(this.intervalId);
  //         }
  //       });
  //     }, 3000);
  //   }
  // },
  methods: {
    close() {
      this.$emit("close");
      location.reload();
    },
  },
};
</script>