import { store } from "@/store";
import { v4 as uuidv4 } from "uuid";
import Alerts from "@/components/screens/Alerts";
import Behaviour from "@/components/screens/Behaviour";
import Certificates from "@/components/screens/Certificates";
import CompanyDetails from "@/components/screens/CompanyDetails";
import ChangePassword from "@/components/screens/ChangePassword";
import Documents from "@/components/screens/Documents";
import EarlyWarning from "@/components/screens/EarlyWarning";
import FAQs from "@/components/screens/FAQs";
import Feedback from "@/components/screens/Feedback";
import FinancialDetails from "@/components/screens/FinancialDetails";
import FitmentAddressForUpgrade from "@/components/screens/FitmentAddressForUpgrade";
import GeoZones from "@/components/screens/GeoZones";
import GetInTouch from "@/components/screens/GetInTouch";
import HealthStatus from "@/components/screens/HealthStatus";
import Logbooks from "@/components/screens/Logbooks";
import ManageAlerts from "@/components/screens/ManageAlerts";
import ManageVehicle from "@/components/screens/ManageVehicle";
import NewAlert from "@/components/screens/NewAlert";
import NewAlertEntry from "@/components/screens/NewAlertEntry";
import NewReminder from "@/components/screens/NewReminder";
import NewShareVehicle from "@/components/screens/NewShareVehicle";
import PersonalDetails from "@/components/screens/PersonalDetails";
import ProductUpgradeOptions from "@/components/screens/ProductUpgradeOptions";
import Reminders from "@/components/screens/Reminders";
import ShareVehicles from "@/components/screens/ShareVehicles";
import TermsAndConditions from "@/components/screens/TermsAndConditions";
import TermsAndConditionsForUpgrade from "@/components/screens/TermsAndConditionsForUpgrade";
import ThankYouForUpgrade from "@/components/screens/ThankYouForUpgrade";
import Trips from "@/components/screens/Trips";

export const modalService = {
  showModal,
  closeModal,
  closeDialog,
  showLeftDrawer,
  closeLeftDrawer,
  showSuccessDialog,
  showErrorDialog,
  showSaveDialog,
  showDeleteDialog,
  showRefreshDialog,
  showNotSupportedDialog,
  showRecoveryInProgressDialog,
  showNotSupportedNoUpgradeDialog,
  showSystemNotificationDialog,
  showArmDisarmConfirmationDialog,
  showArmDisarmRequestReceivedDialog,
};

function showModal(context, screenName, props = {}) {
  let component = null;
  let width = "600";

  switch (screenName) {
    case "behaviour":
      component = Behaviour;
      width = "630";
      break;
    case "certificates":
      component = Certificates;
      width = "400";
      break;
    case "change-password":
      component = ChangePassword;
      width = "360";
      break;
    case "company-details":
      component = CompanyDetails;
      break;
    case "documents":
      component = Documents;
      break;
    case "early-warning":
      component = EarlyWarning;
      width = "450";
      break;
    case "faqs":
      component = FAQs;
      break;
    case "feedback":
      component = Feedback;
      width = "400";
      break;
    case "financial-details":
      component = FinancialDetails;
      break;
    case "fitment-address-for-upgrade":
      component = FitmentAddressForUpgrade;
      break;
    case "geo-zones":
      component = GeoZones;
      break;
    case "get-in-touch":
      component = GetInTouch;
      width = "500";
      break;
    case "health-status":
      component = HealthStatus;
      break;
    case "logbooks":
      component = Logbooks;
      width = "400";
      break;
    case "manage-alerts":
      component = ManageAlerts;
      width = "640";
      break;
    case "manage-vehicle":
      component = ManageVehicle;
      width = "620";
      break;
    case "new-alert":
      component = NewAlert;
      width = "920";
      break;
    case "new-alert-entry":
      component = NewAlertEntry;
      width = "450";
      break;
    case "new-reminder":
      component = NewReminder;
      width = "480";
      break;
    case "new-share-vehicle":
      component = NewShareVehicle;
      break;
    case "personal-details":
      component = PersonalDetails;
      break;
    case "product-upgrade-options":
      component = ProductUpgradeOptions;
      // width = "975";
      break;
    case "reminders":
      component = Reminders;
      break;
    case "share-vehicles":
      component = ShareVehicles;
      width = "675";
      break;
    case "terms-and-conditions":
      component = TermsAndConditions;
      width = "500";
      break;
    case "terms-and-conditions-for-upgrade":
      component = TermsAndConditionsForUpgrade;
      width = "600";
      break;
    case "thank-you-for-upgrade":
      component = ThankYouForUpgrade;
      width = "600";
      break;
    case "trips":
      component = Trips;
      break;
  }
  if (component) {
    closeLeftDrawer();
    store.commit("alert/clear");
    store.commit("navigation/updateShow", false);
    store.commit("navigation/updateActiveScreenName", screenName);
    store.commit("navigation/updateModalWidth", width);
    store.commit("navigation/updateShowModal", true);
    store.commit("navigation/updateModalComponentProps", props);
    store.commit("navigation/updateModalComponent", component);
  }
}

function closeModal() {
  store.commit("navigation/updateShowModal", false);
  store.commit("navigation/updateModalCount", 0);
  store.commit("navigation/updateActiveScreenName", null);
  setTimeout(() => {
    store.commit("navigation/updateModalWidth", null);
    store.commit("navigation/updateModalComponent", null);
    store.commit("navigation/updateModalComponentProps", null);
  }, 500);
}

function showLeftDrawer(context, screenName, props = {}) {
  let component = null;

  switch (screenName) {
    case "alerts":
      component = Alerts;
      break;
    case "geo-zones":
      component = GeoZones;
      break;
    case "trips":
      component = Trips;
      break;
  }
  if (component) {
    closeModal();
    store.commit("alert/clear");
    store.commit("navigation/updateShow", false);
    store.commit("navigation/updateActiveScreenName", screenName);
    store.commit("navigation/updateLeftDrawerComponent", component);
    store.commit("navigation/updateLeftDrawerComponentProps", props);
    store.commit("navigation/updateShowLeftDrawer", true);
  }
}

function closeLeftDrawer() {
  store.commit("navigation/updateShowLeftDrawer", false);
  store.commit("navigation/updateActiveScreenName", null);
  setTimeout(() => {
    store.commit("navigation/updateLeftDrawerComponent", null);
    store.commit("navigation/updateLeftDrawerComponentProps", null);
  }, 500);
}

function showSuccessDialog(message) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    isSuccess: true,
    title: "SUCCESS",
    message: message,
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showErrorDialog(message = "") {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    isError: true,
    title: "ERROR",
    message: message
      ? message
      : "Unable to process request at this time. Please try again.",
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showSaveDialog(instance, message, callback) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "SAVE CHANGES",
    message: message,
    buttons: [
      {
        title: "Yes",
        handler: () => {
          const success = callback();
          if (success) {
            instance.$emit("close");
          }
          closeDialog(id);
        },
      },
      {
        title: "No",
        handler: () => {
          instance.$emit("close");
          closeDialog(id);
        },
      },
      {
        title: "Cancel",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showDeleteDialog(message, callback) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "DELETE",
    message: message,
    buttons: [
      {
        title: "Yes",
        handler: () => {
          callback();
          closeDialog(id);
        },
      },
      {
        title: "No",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showRefreshDialog() {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "UPDATE REQUIRED",
    message: "Updated content is available.",
    buttons: [
      {
        title: "Refresh",
        handler: () => {
          window.location.reload();
        },
      },
    ],
  });
}

function showNotSupportedDialog(instance) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "NOT SUPPORTED",
    message:
      "Unfortunately, this feature is not supported on your current package.",
    buttons: [
      {
        title: "View Upgrade Options",
        handler: () => {
          closeDialog(id);
          showModal(instance, "product-upgrade-options");
        },
      },
      {
        title: "Cancel",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
    width: "450px",
  });
}

function showNotSupportedNoUpgradeDialog(instance) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "NOT SUPPORTED",
    message:
      "Unfortunately, this feature is not supported on your current package.",
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
    width: "450px",
  });
}

function showRecoveryInProgressDialog(instance) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "RECOVERY IN PROGRESS",
    message:
        "This feature is temporarily unavailable while your vehicle is being recovered.",
    buttons: [
      {
        title: "CLOSE",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showSystemNotificationDialog(message) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    isSystemNotification: true,
    title: "PLEASE NOTE",
    message: message,
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showArmDisarmConfirmationDialog(requestType, callback) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    componentName: "ArmDisarmConfirmationDialog",
    requestType: requestType,
    buttons: [
      {
        title: "Cancel",
        handler: () => {
          closeDialog(id);
        },
      },
      {
        title: "Yes",
        handler: () => {
          callback();
          closeDialog(id);
        },
      },
    ],
  });
}

function showArmDisarmRequestReceivedDialog(onConfirm) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    componentName: "ArmDisarmConfirmationDialog",
    requestType: "status",
    buttons: [
      {
        title: "Okay",
        handler: () => {
          closeDialog(id);
          onConfirm();
        },
      },
    ],
  });
}

function closeDialog(id) {
  store.commit("navigation/removeDialog", id);
}
