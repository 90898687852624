<template>
  <div class="page">
    <div fluid class="container-fluid main">
      <Header/>
      <div class="content" :class="{ scrolled: scrolled }" @scroll="handleScroll">
        <div class="container" v-if="!isIE">
          <div class="row">
            <div class="col-md-12 col-lg-4 primary">
              <img src="~@/assets/images/small-logo.png" width="30" height="30" class="logo" alt="Netstar"/>
              <div class="heading">
                <div>REGISTER</div>
                <div class="last">
                  <span>ACCOUNT</span>
                </div>
              </div>
              <div class="spacer"></div>
              <div class="form-container">
                <Alert/>
                <div class="message-box text-center" v-if="success">
                  Account created successfully.
                  <br/>
                  <router-link to="login" class="d-inline-block font-weight-bold mt-2">LOGIN</router-link>
                </div>
                <div v-if="!success">
                  <div v-if="!subscriberType">
                    <div class="text">
                      <span class="text-link">Register as</span>
                    </div>
                    <br/>
                    <div class="form-group buttons">
                      <button class="btn btn-register-as" @click="selectSubscriberType('Individual')">
                        <span>MYSELF</span>
                      </button>
                      <br/>
                      <button class="btn btn-register-as" @click="selectSubscriberType('Business')">
                        <span>MY COMPANY</span>
                      </button>
                    </div>
                  </div>
                  <div v-if="subscriberType && !subscriberTypeWith">
                    <div class="text">
                      <span class="text-link">Register with</span>
                    </div>
                    <br/>
                    <div class="form-group buttons">
                      <button class="btn btn-register-as" @click="selectSubscriberTypeWith('Email')">
                        <span>EMAIL ADDRESS</span>
                      </button>
                      <br/>
                      <button class="btn btn-register-as" @click="selectSubscriberTypeWith('Mobile')">
                        <span>MOBILE</span>
                      </button>
                    </div>
                  </div>
                  <form v-if="subscriberType && subscriberTypeWith" method="post" @submit.prevent="handleSubmit">
                    <div v-if="!isMobileSubscriberWithType" class="form-group">
                      <input
                          type="email"
                          v-model="emailAddress"
                          id="emailAddress"
                          name="emailAddress"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && !emailAddress }"
                          :disabled="inOtpPhase"
                      />
                      <label for="emailAddress">Email Address</label>
                      <div v-show="submitted && !emailAddress" class="invalid-feedback">Email address is required</div>
                    </div>
                    <div v-if="isMobileSubscriberWithType" class="form-group">
                      <input
                          type="tel"
                          pattern="^\d{10,15}$"
                          title="Please enter a valid mobile number"
                          v-model="mobileNumber"
                          id="mobileNumber"
                          name="mobileNumber"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && !mobileNumber }"
                      />
                      <label for="mobileNumber">Mobile Number</label>
                      <div v-show="submitted && !mobileNumber" class="invalid-feedback"> Mobile number is required</div>
                    </div>
                    <div class="form-group">
                      <input
                          type="text"
                          pattern=".{5,15}"
                          maxlength="15"
                          title="Must be between 5 and 15 characters long"
                          v-model="idNumber"
                          id="idNumber"
                          name="idNumber"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && !idNumber }"
                          :disabled="inOtpPhase"
                      />
                      <label for="idNumber">{{
                          isIndividual ? "ID Number/Passport Number/Netstar Account Number" : "Company Registration Number"
                        }}</label>
                      <div v-show="submitted && !idNumber" class="invalid-feedback">
                        {{
                          isIndividual ? "ID Number/Passport Number/Netstar Account Number is required" : "Company Registration Number is required"
                        }}
                      </div>
                    </div>
                    <div class="form-group">
                      <input
                          type="password"
                          autocomplete="off"
                          pattern=".{8,}"
                          title="At least 8 characters"
                          v-model="password"
                          id="password"
                          name="password"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && !password }"
                          :disabled="inOtpPhase"
                      />
                      <label for="password">Password</label>
                      <div v-show="submitted && !password" class="invalid-feedback">New Password is required</div>
                    </div>
                    <div class="form-group">
                      <input
                          type="password"
                          autocomplete="off"
                          pattern=".{8,}"
                          title="At least 8 characters"
                          v-model="confirmPassword"
                          id="confirmPassword"
                          name="confirmPassword"
                          class="form-control"
                          :class="{
                        'is-invalid': submitted && (!confirmPassword || !passwordsMatch),
                      }"
                          :disabled="inOtpPhase"
                      />
                      <label for="confirmPassword">Confirm Password</label>
                      <div v-show="submitted && (!confirmPassword || !passwordsMatch)" class="invalid-feedback">
                        Passwords
                        must match
                      </div>
                    </div>
                    <div class="form-group" v-show="inOtpPhase">
                      <input
                          type="text"
                          pattern=".{5}"
                          maxlength="5"
                          title="Must be 5 characters"
                          v-model="otp"
                          id="otp"
                          name="otp"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && !otp }"
                      />
                      <label for="otp">OTP</label>
                      <div v-show="submitted && !otp" class="invalid-feedback">OTP is required</div>
                      <div class="message-box mt-3">
                        Enter the One Time PIN (OTP) sent to your mobile number (******{{ otpCellNumber }}) and then
                        click
                        on the 'CONFIRM' button to confirm the
                        password change.
                      </div>
                    </div>
                    <div class="form-group buttons">
                      <button class="btn btn-submit" :disabled="processing">
                        <span v-show="!processing">{{ inOtpPhase ? "CONFIRM" : "SUBMIT" }}</span>
                        <Spinner v-show="processing"/>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="footer line">
                <div class="text">
                  <router-link to="login" class="back text-link"><span>&lt;</span> BACK</router-link>
                </div>
                <div class="text">
                  Need more information?
                  <a href="https://www.netstar.co.za/mynetstar/" class="text-link">CLICK HERE</a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 column-spacer"></div>
            <div class="col-12 col-md-6 col-lg-4 secondary">
              <div class="spacer"></div>
              <div class="heading">
                Stay connected
                <br/>from anywhere, <br/>with <span>MyNetstar</span>.
              </div>
              <a href="https://www.netstar.co.za/mynetstar/" class="btn btn-outline">READ MORE</a>
            </div>
          </div>
        </div>
        <IncompatibleBrowser v-else/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/pages.scss";

.page {
  .content {
    > .container {
      .primary {
        .message-box {
          background: #fff;
          color: $brand-color-2;
          padding: 15px;
          border: 1px solid $brand-color-2;
        }

        .text-link {
          font-weight: bold;
        }

        .btn-register-as {
          background: #52c3df;
          font-weight: bold;
          min-width: 100%;
          font-size: 0.75rem;
          font-weight: 600;
          color: #fff;
          padding: 10px 30px;
          margin: 10px 0 20px 0;
          border-radius: 20px;
          border: none;

          &:hover {
            background: $brand-color-2;
            border-color: $brand-color-2;
          }

          &:disabled {
            opacity: 1;
          }
        }

        .footer {
          .text {
            color: $brand-color-2;
          }

          .back.text-link {
            font-style: normal;
            color: $brand-color-2;

            span {
              color: $brand-color-1;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {mapState, mapActions} from "vuex";
import {alertService, userService} from "@/services";
import Header from "@/pages/components/Header";
import IncompatibleBrowser from "@/components/IncompatibleBrowser";
import {helper} from "@/helper";

export default {
  components: {
    Header,
    IncompatibleBrowser,
  },
  data() {
    return {
      submitted: false,
      processing: false,
      success: false,
      subscriberType: "",
      subscriberTypeWith: "",
      idNumber: "",
      emailAddress: "",
      mobileNumber: "",
      password: "",
      confirmPassword: "",
      otp: "",
      token: "",
      otpCellNumber: "",
      passwordsMatch: false,
      inOtpPhase: false,
      scrolled: false,
    };
  },
  computed: {
    ...mapState("account", ["status"]),
    ...mapState(["alert"]),
    isIndividual() {
      return this.subscriberType === "Individual";
    },
    isMobileSubscriberWithType() {
      return this.subscriberTypeWith === "Mobile";
    },
    isIE() {
      return /*@cc_on!@*/ false || !!document.documentMode;
    },
  },
  methods: {
    ...mapActions("alert", {
      clearAlert: "clear",
    }),
    handleScroll(event) {
      this.scrolled = event.target.scrollTop > 5;
    },
    handleSubmit(e) {
      this.clearAlert();
      this.submitted = true;

      const {
        idNumber,
        password,
        mobileNumber,
        emailAddress,
        confirmPassword,
        passwordsMatch,
        otp,
        token,
        inOtpPhase
      } = this;
      const username = this.isMobileSubscriberWithType ? mobileNumber : emailAddress;

      if (idNumber && username && password && confirmPassword && passwordsMatch) {
        if (inOtpPhase && (!otp || !token)) {
          return;
        }
        this.processing = true;
        userService
            .register({
              idNumber: idNumber,
              username: username,
              password: password,
              otp: otp,
              token: token,
              origin: helper.getOriginInfo(),
            })
            .then((data) => {
              this.processing = false;
              this.submitted = false;
              this.token = data.Token;
              this.otpCellNumber = data.OTPCellNumber;
              this.inOtpPhase = !data.OTPVerified;
              this.success = data.TransactionComplete;
            })
            .catch((error) => {
              this.processing = false;
              this.submitted = false;
              alertService.showErrorAlert(error);
            });
      }
    },
    selectSubscriberType(type) {
      this.subscriberType = type;
    },
    selectSubscriberTypeWith(withType) {
      this.subscriberTypeWith = withType;
    },
  },
  watch: {
    password(value) {
      this.passwordsMatch = this.confirmPassword == value;
    },
    confirmPassword(value) {
      this.passwordsMatch = this.password == value;
    },
  },
};
</script>
