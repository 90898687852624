<template>
  <div ref="leftDrawer" class="left-drawer-component" :class="{ active: showLeftDrawer, hide: hide }">
    <component
      class="component"
      :class="{ small: isSmallScreen }"
      :is="leftDrawerComponent"
      v-bind="leftDrawerComponentProps"
      @open="hide = false"
      @hide="hide = true"
      @close="closeDrawer()"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.left-drawer-component {
  background: #fff;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  width: 50%;
  height: 100%;
  max-width: 740px;
  overflow-y: auto;
  box-shadow: $omni-shadow;
  transition: left 1s;

  &.active {
    left: 0;
  }

  &.hide {
    left: -100%;
  }

  .component {
    padding: 20px 40px;
  }
}
</style>

<script>
import { mapState } from "vuex";
import { modalService } from "@/services";

export default {
  name: "LeftDrawer",
  data() {
    return {
      hide: false,
      isSmallScreen: false,
    };
  },
  computed: {
    ...mapState("navigation", ["showLeftDrawer", "leftDrawerComponent", "leftDrawerComponentProps"]),
  },
  mounted() {
    const componentWidth = this.$refs.leftDrawer.clientWidth;
    this.$emit("width", componentWidth);
    this.isSmallScreen = componentWidth < 740;
  },
  methods: {
    closeDrawer() {
      modalService.closeLeftDrawer();
    },
  },
};
</script>