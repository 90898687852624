<template>
  <div class="terms-and-conditions-component screen">
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'Contact Netstar',
        subTitle: 'Terms & Conditions',
      }"
      @close="beforeClose()"
    />
    <div class="panel">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <a class="block" @click.prevent="download('Netstar_Individual_Subscriber_Agreement_v1_2020.pdf')">
              <div class="icon">
                <img src="~@/assets/images/individual-subscriber-agreement.svg" alt="Individual Subscriber" />
              </div>
              <div class="title">
                INDIVIDUAL
                <br />
                <span>SUBSCRIBER</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6">
            <a class="block" href="https://bis.netstar.co.za/docs/SMBTermsv1_2022.pdf" target="_blank">
              <div class="icon">
                <img src="~@/assets/images/small-corporate-customer-agreement.svg" alt="Fleet Tracker and Fleet Manager Subscribers" />
              </div>
              <div class="title">
                FLEET TRACKER AND FLEET MANAGER
                <br />
                <span>SUBSCRIBERS</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <a class="block" href="https://documents.netstar.co.za/BusinessTermsv1_2022.pdf" target="_blank">
              <div class="icon">
                <img src="~@/assets/images/large-corporate-customer-agreement.svg" alt="Standard Business Subscribers" />
              </div>
              <div class="title">
                STANDARD BUSINESS
                <br />
                <span>SUBSCRIBERS</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6">
            <a class="block" @click.prevent="download('PAIA_Manual_2019_February_1.pdf')">
              <div class="icon">
                <img src="~@/assets/images/paia-manual.svg" alt="Altron Group PAIA Manual" />
              </div>
              <div class="title">
                ALTRON GROUP
                <br />
                <span>PAIA MANUAL</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <a href="https://www.altron.com/privacy-policy/" target="_blank" class="block">
              <div class="icon">
                <img src="~@/assets/images/privacy-policy.svg" alt="Altron Privacy Policy" />
              </div>
              <div class="title">
                ALTRON
                <br />
                <span>PRIVACY POLICY</span>
              </div>
              <div class="footer">
                <svg width="15" height="15" viewBox="0 0 16.521 16.521" class="download-icon">
                  <g>
                    <g>
                      <path
                        style="fill: none; stroke-width: 1.5"
                        d="M9.183,10.993c-0.936,0-1.873-0.356-2.585-1.069c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			c1.037,1.036,2.722,1.034,3.757,0l3.432-3.431c1.035-1.036,1.035-2.722,0-3.758c-1.035-1.035-2.723-1.035-3.758,0L9.189,3.574
			c-0.195,0.195-0.512,0.195-0.707,0s-0.195-0.512,0-0.707l1.546-1.546c1.426-1.426,3.746-1.426,5.172,0s1.426,3.746,0,5.172
			l-3.432,3.431C11.056,10.637,10.119,10.993,9.183,10.993z"
                      />
                    </g>
                    <g>
                      <path
                        style="fill: none; stroke-width: 1.5"
                        d="M3.907,16.27c-0.937,0-1.873-0.356-2.586-1.069c-1.425-1.426-1.425-3.746,0-5.172l3.432-3.431
			c1.381-1.382,3.79-1.383,5.171,0c0.195,0.195,0.195,0.512,0,0.707s-0.512,0.195-0.707,0c-1.036-1.036-2.721-1.037-3.757,0
			l-3.432,3.431c-1.036,1.036-1.036,2.722,0,3.758c1.037,1.035,2.722,1.035,3.758,0l1.467-1.467c0.195-0.195,0.512-0.195,0.707,0
			s0.195,0.512,0,0.707L6.493,15.2C5.78,15.913,4.843,16.27,3.907,16.27z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </a>
          </div>
          <div class="col-12 col-md-6">
            <a href="https://www.altron.com/terms-conditions/" target="_blank" class="block">
              <div class="icon">
                <img src="~@/assets/images/terms-and-conditions.svg" alt="Altron Website Terms & Conditions" />
              </div>
              <div class="title">
                ALTRON WEBSITE
                <br />
                <span>TERMS & CONDITIONS</span>
              </div>
              <div class="footer">
                <svg width="15" height="15" viewBox="0 0 16.521 16.521" class="download-icon">
                  <g>
                    <g>
                      <path
                        style="fill: none; stroke-width: 1.5"
                        d="M9.183,10.993c-0.936,0-1.873-0.356-2.585-1.069c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0
			c1.037,1.036,2.722,1.034,3.757,0l3.432-3.431c1.035-1.036,1.035-2.722,0-3.758c-1.035-1.035-2.723-1.035-3.758,0L9.189,3.574
			c-0.195,0.195-0.512,0.195-0.707,0s-0.195-0.512,0-0.707l1.546-1.546c1.426-1.426,3.746-1.426,5.172,0s1.426,3.746,0,5.172
			l-3.432,3.431C11.056,10.637,10.119,10.993,9.183,10.993z"
                      />
                    </g>
                    <g>
                      <path
                        style="fill: none; stroke-width: 1.5"
                        d="M3.907,16.27c-0.937,0-1.873-0.356-2.586-1.069c-1.425-1.426-1.425-3.746,0-5.172l3.432-3.431
			c1.381-1.382,3.79-1.383,5.171,0c0.195,0.195,0.195,0.512,0,0.707s-0.512,0.195-0.707,0c-1.036-1.036-2.721-1.037-3.757,0
			l-3.432,3.431c-1.036,1.036-1.036,2.722,0,3.758c1.037,1.035,2.722,1.035,3.758,0l1.467-1.467c0.195-0.195,0.512-0.195,0.707,0
			s0.195,0.512,0,0.707L6.493,15.2C5.78,15.913,4.843,16.27,3.907,16.27z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.terms-and-conditions-component {
  .panel {
    background: $brand-color-4;
    padding: 30px 15px 0px 15px;

    .row {
      .col-12 {
        display: flex;
        margin-bottom: 30px;
      }
    }

    .block {
      background: #fff;
      position: relative;
      flex-grow: 1;
      text-align: center;
      text-decoration: none;
      padding: 15px 15px 35px 15px;
      box-shadow: $drop-shadow;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        img {
          height: 80%;
        }
      }

      .title {
        color: $brand-color-2;
        margin-top: 5px;

        span {
          font-weight: bold;
        }
      }

      .footer {
        background: $brand-color-2;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 30px;
        padding-top: 3px;
        margin: 0 auto;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;

        .download-icon {
          stroke: #fff;
        }
      }

      &:hover {
        background: $brand-color-3;
        cursor: pointer;

        .title {
          color: $brand-color-1;
        }

        .footer {
          background: $brand-color-1;
        }
      }
    }
  }
}
</style>

<script>
import axios from "axios";
import { helper } from "@/helper";

export default {
  name: "TermsAndConditions",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    download(fileName) {
      const url = process.env.VUE_APP_STORAGE_URL + "/" + fileName;
      axios
        .get(url, {
          responseType: "blob",
        })
        .then((request) => {
          helper.saveToBrowser(fileName, request.data);
        });
    },
  },
};
</script>