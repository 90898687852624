<template>
  <main :class="{ mobile: $isMobile }">
    <router-view />
    <ModalContainer />
    <DialogContainer />
    <TooltipContainer />
    <TourContainer v-if="$showTour || (getIsNewUser && getShowTour)" />
    <CookieConsent />
  </main>
</template>

<style lang="scss">
@import "~@/assets/scss/app.scss";
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DialogContainer from "@/components/DialogContainer";
import ModalContainer from "@/components/ModalContainer";
import TooltipContainer from "@/components/TooltipContainer";
import TourContainer from "@/components/TourContainer";
import CookieConsent from "@/components/CookieConsent";
import { modalService } from "@/services";

export default {
  name: "app",
  components: {
    DialogContainer,
    ModalContainer,
    TooltipContainer,
    TourContainer,
    CookieConsent,
  },
  data() {
    return {
      refreshing: false,
    };
  },
  computed: {
    ...mapGetters("account", ["getIsNewUser", "getShowTour"]),
  },
  created() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        modalService.showRefreshDialog();
      });
    }

    if (this.getPWADisplayMode() !== "standalone" && !this.$isMacOS) {
      this.updateCanInstallApp(true);
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.updateDeferredPromptForInstall(e);
    });

    window.addEventListener("appinstalled", () => {
      this.updateCanInstallApp(false);
      this.updateDeferredPromptForInstall(null);
    });
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapMutations("pwa", [
      "updateDeferredPromptForInstall",
      "updateCanInstallApp",
    ]),
    getPWADisplayMode() {
      const isStandalone = window.matchMedia("(display-mode: standalone)")
        .matches;
      if (document.referrer.startsWith("android-app://")) {
        return "twa";
      } else if (navigator.standalone || isStandalone) {
        return "standalone";
      }
      return "browser";
    },
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
    },
  },
};
</script>
