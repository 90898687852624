import { store } from "@/store";
import { api } from "@/services/api.service";

export const assetService = {
  getVehicles,
  getVehiclesLocationData,
  getVehicleDetails,
  updateVehicleDetails,
  deleteVehicleImage,
  getVehicleAlerts,
  getVehicleAlertsHistory,
  getVehicleAlertsHistoryByDate,
  updateVehicleAlert,
  deleteVehicleAlert,
  isVehicleArmed,
  updateVehicleArmedStatus,
  getVehicleBehaviour,
  getVehicleBehaviourGoals,
  updateVehicleBehaviourGoal,
  getGeoZones,
  updateGeoZone,
  deleteGeoZone,
  emailVehicleLogbookReport,
  getVehicleReminders,
  updateVehicleReminder,
  deleteVehicleReminder,
  getVehicleOwnersSharedWithMe,
  getVehiclesSharedWithOthers,
  updateSharedVehiclesAccess,
  deleteSharedVehiclesAccess,
  deleteVehicleSharedWithUser,
  getVehicleTrips,
  getVehicleTripsByDate,
  getVehicleTripDetails,
  getVehicleTripNotifications,
  updateVehicleTripType,
  getProductConfigurationSettings,
  getSubscriberDetails,
  preUpgradeValidation,
  productUpgrade,
  hasAlertsFeature,
  hasArmDisarmFeature,
  hasBehaviourFeature,
  hasMapFeature,
  hasShareVehicleFeature,
  hasTripsFeature,
  hasRealTimeLocationTracking,
  deleteGeoZoneAndLinkedAlerts
};

const apiBasePath = "/api/asset";

function getVehicles() {
  return api.get(`${apiBasePath}/get-vehicles`).then((request) => {
    return request.data;
  });
}

function getVehiclesLocationData() {
  return api
    .get(`${apiBasePath}/get-vehicles-location-data`)
    .then((request) => {
      return request.data;
    });
}

function getVehicleDetails(vehicleId) {
  return api
    .get(`${apiBasePath}/get-vehicle-details`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function updateVehicleDetails(vehicle) {
  return api
    .post(`${apiBasePath}/update-vehicle-details`, vehicle)
    .then((request) => {
      return request.data;
    });
}

function deleteVehicleImage(vehicleId) {
  return api
    .delete(`${apiBasePath}/delete-vehicle-image`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleAlerts(vehicleId) {
  return api
    .get(`${apiBasePath}/get-vehicle-alerts`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleAlertsHistory(vehicleId) {
  return api
    .get(`${apiBasePath}/get-vehicle-alerts-history`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleAlertsHistoryByDate(vehicleId, fromDate, toDate) {
  return api
    .get(`${apiBasePath}/get-vehicle-alerts-history-by-date`, {
      params: {
        vehicleId: vehicleId,
        fromDate: fromDate,
        toDate: toDate,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function updateVehicleAlert(model) {
  return api
    .post(`${apiBasePath}/update-vehicle-alert`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteVehicleAlert(alertId) {
  return api
    .post(`${apiBasePath}/delete-vehicle-alert`, {
      alertId: alertId,
    })
    .then((request) => {
      return request.data;
    });
}

function isVehicleArmed(vehicleId) {
  return api
    .get(`${apiBasePath}/is-vehicle-armed`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function updateVehicleArmedStatus(vehicleId, status) {
  return api
    .post(`${apiBasePath}/update-vehicle-armed-status`, {
      vehicleId: vehicleId,
      isArmed: status,
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleBehaviour(vehicleId) {
  return api
    .get(`${apiBasePath}/get-vehicle-behaviour`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleBehaviourGoals() {
  return api
    .get(`${apiBasePath}/get-vehicle-behaviour-goals`)
    .then((request) => {
      return request.data;
    });
}

function updateVehicleBehaviourGoal(vehicleId, goal) {
  return api
    .post(`${apiBasePath}/update-vehicle-behaviour-goal`, {
      vehicleId: vehicleId,
      goal: goal,
    })
    .then((request) => {
      return request.data;
    });
}

function getGeoZones() {
  return api.get(`${apiBasePath}/get-geo-zones`).then((request) => {
    return request.data;
  });
}

function updateGeoZone(model) {
  return api
    .post(`${apiBasePath}/update-geo-zone`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteGeoZone(geoZoneId) {
  return api
    .post(`${apiBasePath}/delete-geo-zone`, {
      geoZoneId: geoZoneId,
    })
    .then((request) => {
      return request.data;
    });
}

function emailVehicleLogbookReport(
  vehicleIds,
  fromDate,
  toDate,
  format,
  emailAddress
) {
  return api
    .post(`${apiBasePath}/email-vehicle-logbook-report`, {
      vehicleIds: vehicleIds,
      fromDate: fromDate,
      toDate: toDate,
      format: format,
      emailAddress: emailAddress,
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleReminders() {
  return api.get(`${apiBasePath}/get-vehicle-reminders`).then((request) => {
    return request.data;
  });
}

function updateVehicleReminder(model) {
  return api
    .post(`${apiBasePath}/update-vehicle-reminder`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteVehicleReminder(reminderId) {
  return api
    .post(`${apiBasePath}/delete-vehicle-reminder`, {
      reminderId: reminderId,
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleOwnersSharedWithMe() {
  return api
    .get(`${apiBasePath}/get-vehicle-owners-shared-with-me`)
    .then((request) => {
      return request.data;
    });
}

function getVehiclesSharedWithOthers() {
  return api
    .get(`${apiBasePath}/get-vehicles-shared-with-others`)
    .then((request) => {
      return request.data;
    });
}

function updateSharedVehiclesAccess(model) {
  return api
    .post(`${apiBasePath}/update-shared-vehicles-access`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteSharedVehiclesAccess(sharedVehicleAccessId) {
  return api
    .post(`${apiBasePath}/delete-shared-vehicles-access`, {
      sharedVehicleAccessId: sharedVehicleAccessId,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteVehicleSharedWithUser(vehicleId) {
  return api
    .post(`${apiBasePath}/delete-vehicle-shared-with-user`, {
      vehicleId: vehicleId,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteGeoZoneAndLinkedAlerts(alertIds, geoZoneId) {
  return api
    .post(`${apiBasePath}/delete-geo-zone-and-linked-alerts`, {
      alertIds: alertIds,
      geoZoneId: geoZoneId,
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleTrips(vehicleId) {
  return api
    .get(`${apiBasePath}/get-vehicle-latest-trips`, {
      params: {
        vehicleId: vehicleId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleTripsByDate(vehicleId, fromDate, toDate) {
  return api
    .get(`${apiBasePath}/get-vehicle-trips-by-date`, {
      params: {
        vehicleId: vehicleId,
        fromDate: fromDate,
        toDate: toDate,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleTripDetails(tripId) {
  return api
    .get(`${apiBasePath}/get-vehicle-trip-details`, {
      params: {
        tripId: tripId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleTripNotifications(tripId) {
  return api
    .get(`${apiBasePath}/get-vehicle-trip-notifications`, {
      params: {
        tripId: tripId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function updateVehicleTripType(tripId, isBusiness) {
  return api
    .post(`${apiBasePath}/update-vehicle-trip-type`, {
      tripId: tripId,
      isBusiness: isBusiness,
    })
    .then((request) => {
      return request.data;
    });
}

function getProductConfigurationSettings() {
  return api
    .get(`${apiBasePath}/get-product-configuration-settings`)
    .then((request) => {
      return request.data;
    });
}

function getSubscriberDetails() {
  return api.get(`${apiBasePath}/get-subscriber-details`).then((request) => {
    return request.data;
  });
}

function preUpgradeValidation(vehicleId, unitSerialNumber) {
  return api
    .post(`${apiBasePath}/pre-upgrade-validation`, {
      vehicleId: vehicleId,
      unitSerialNumber: unitSerialNumber,
    })
    .then((request) => {
      return request.data;
    });
}

function productUpgrade(
  vehicleId,
  unitSerialNumber,
  productName,
  partnerCode,
  fitmentAddress
) {
  return api
    .post(`${apiBasePath}/product-upgrade`, {
      vehicleId: vehicleId,
      unitSerialNumber: unitSerialNumber,
      productName: productName,
      partnerCode: partnerCode,
      fitmentAddress: fitmentAddress,
    })
    .then((request) => {
      return request.data;
    });
}

function hasAlertsFeature(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return productFeatures.AlertManagement;
}

function hasArmDisarmFeature(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return productFeatures.ArmDisarm;
}

function hasBehaviourFeature(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return productFeatures.ViewDriverBehaviour;
}

function hasMapFeature(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return productFeatures.LatestVehicleMapLocation;
}

function hasShareVehicleFeature(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return productFeatures.ShareVehicleLocation;
}

function hasTripsFeature(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return productFeatures.LatestTripMenuBar;
}

function hasRealTimeLocationTracking(product) {
  const productFeatures = store.getters["asset/getProductFeatures"](product);
  return (
    productFeatures.LatestTripMenuBar &&
    productFeatures.LatestVehicleMapLocation
  );
}
