<template>
  <div class="geo-zone-editor-component screen" :class="{ overlay: !$isMobile }">
    <div class="top">
      <div class="heading mb-3">Create Geo Zone</div>
      <CloseButton @click="destroy()" v-if="!$isMobile" />
    </div>
    <Alert />
    <div class="panel">
      <form ref="form" @submit.prevent>
        <div class="form-group">
          <label class="subheading" for="zoneName">ZONE NAME</label>
          <input type="text" class="form-control" id="zoneName" v-model="model.Name" required />
        </div>
      </form>
      <div class="controls container">
        <div class="row mb-2">
          <div class="col">
            <button class="btn" :class="{ active: isDrawPolygon }" @click="drawPolygon">
              <svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="currentColor">
                <path fill-rule="evenodd" d="M8 1.288l-6.842 5.56L3.733 15h8.534l2.575-8.153L8 1.288zM16 6.5L8 0 0 6.5 3 16h10l3-9.5z" />
              </svg>
              Draw Polygon
            </button>
          </div>
          <div class="col">
            <button class="btn" :class="{ active: isDrawCircle }" @click="drawCircle">
              <svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="currentColor">
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              </svg>
              Draw Circle
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="btn no-hover">
              <v-swatches
                class="mr-2"
                v-model="shapeColor"
                :trigger-style="{ width: '1rem', height: '1rem' }"
                :swatches="swatches"
                shapes="circles"
                popover-x="left"
              />
              <span>Colour</span>
            </div>
          </div>
          <div class="col">
            <button class="btn" @click="prepareEditor">
              <svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M6.104 2.396a.5.5 0 0 1 0 .708L3.457 5.75l2.647 2.646a.5.5 0 1 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M2.75 5.75a.5.5 0 0 1 .5-.5h6.5a2.5 2.5 0 0 1 2.5 2.5v5.5a.5.5 0 0 1-1 0v-5.5a1.5 1.5 0 0 0-1.5-1.5h-6.5a.5.5 0 0 1-.5-.5z"
                />
              </svg>
              Undo
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons mt-3">
      <button class="btn btn-outline mr-3" @click="destroy">CANCEL</button>
      <button class="btn btn-outline" @click="submit">
        <span v-show="!saving">SAVE</span>
        <Spinner v-show="saving" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.geo-zone-editor-component {
  width: 100%;
  max-width: 480px;
  padding: 1.5rem;
  margin: 0 auto;

  &.overlay {
    background: #fff;
    position: fixed;
    top: 20px;
    right: 20px;
    max-width: 340px;
    box-shadow: $omni-shadow;
  }

  .top {
    .heading {
      font-size: 1.25rem;
    }
  }

  .panel {
    background: $brand-color-4;
    padding: 1rem;

    .form-group {
      .form-control {
        background: #fff;
        border: 1px solid $brand-color-2;
      }

      label {
        color: $brand-color-1;
      }
    }

    .controls {
      .row {
        .col {
          padding: 0;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        font-size: 1em;
        color: $brand-color-1;
        line-height: 1;
        padding: 6px 0;

        &.no-hover:hover {
          cursor: inherit;
        }

        &.active {
          color: $brand-color-2;
        }

        svg {
          vertical-align: top;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import wkt from "terraformer-wkt-parser";
import VSwatches from "vue-swatches";
import CloseButton from "@/components/CloseButton";
import { alertService, assetService, mapService, modalService } from "@/services";
import styles from "@/assets/scss/_variables.scss";

export default {
  name: "GeoZoneEditor",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    CloseButton,
    VSwatches,
  },
  data() {
    return {
      saving: false,
      gmShape: null,
      gmShapeOptions: {},
      shapeType: "",
      shapeColor: "",
      swatches: [
        "#1fbc9c",
        "#1ca085",
        "#2ecc70",
        "#27af60",
        "#3398db",
        styles.brandColor1,
        "#a463bf",
        "#8e43ad",
        "#3d556e",
        "#222f3d",
        "#f2c511",
        "#f39c19",
        "#e84b3c",
        "#c0382b",
        "#dde6e8",
        "#bdc3c8",
      ],
      model: {},
    };
  },
  computed: {
    ...mapState(["map"]),
    ...mapGetters("asset", ["getActiveVehicleOwnerId"]),
    selectedGeoZone() {
      return this.map.selectedGeoZone;
    },
    isDrawCircle() {
      return this.shapeType === "circle";
    },
    isDrawPolygon() {
      return this.shapeType === "polygon";
    },
    isValid() {
      return this.gmShape;
    },
  },
  mounted() {
    if (this.map.gmapRef) {
      this.gmShapeOptions = {
        fillColor: styles.brandColor2,
        fillOpacity: 0.2,
        strokeColor: styles.brandColor1,
        strokeOpacity: 1.0,
        strokeWeight: 4,
        editable: true,
        draggable: true,
        geodesic: true,
        map: this.map.gmapRef.$mapObject,
      };
      this.$options.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        drawingControlOptions: {
          position: this.$isMobile ? google.maps.ControlPosition.TOP_CENTER : google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON],
        },
        circleOptions: this.gmShapeOptions,
        polygonOptions: this.gmShapeOptions,
      });
      this.$options.drawingManager.setMap(this.map.gmapRef.$mapObject);
      this.prepareEditor();
      this.initModel();
    }
  },
  beforeDestroy() {
    if (this.$isMobile) {
      this.destroy();
    }
  },
  methods: {
    ...mapActions("map", ["setMapBounds", "closeGeoZoneEditor"]),
    ...mapMutations("map", ["updateShowMarkers"]),
    initModel() {
      this.model = {
        Id: 0,
        OwnerId: this.getActiveVehicleOwnerId(),
        Name: "",
        Zone: "",
        EditorMetadata: {
          Shape: "",
          Height: 0,
          Width: 0,
          Color: "",
        },
      };

      if (this.selectedGeoZone) {
        this.model.Id = this.selectedGeoZone.Id;
        this.model.Name = this.selectedGeoZone.Name;
        this.model.OwnerId = this.selectedGeoZone.OwnerId;
      }
    },
    drawCircle() {
      this.resetGeoZoneDisplay();
      this.updateColorInShapeOptions();
      this.shapeType = "circle";
      this.$options.drawingManager.setOptions({
        drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        circleOptions: this.gmShapeOptions,
      });
    },
    drawPolygon() {
      this.resetGeoZoneDisplay();
      this.updateColorInShapeOptions();
      this.shapeType = "polygon";
      this.$options.drawingManager.setOptions({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        polygonOptions: this.gmShapeOptions,
      });
    },
    updateColorInShapeOptions() {
      this.gmShapeOptions.fillColor = this.shapeColor;
      this.gmShapeOptions.strokeColor = this.shapeColor;
    },
    prepareEditor() {
      this.shapeType = "";

      if (!this.shapeColor) {
        this.shapeColor = styles.brandColor1;
      }

      this.updateShowMarkers(false);
      this.resetGeoZoneDisplay();

      if (this.selectedGeoZone) {
        this.shapeType = JSON.parse(this.selectedGeoZone.EditorMetadata).Shape;
        this.shapeColor = JSON.parse(this.selectedGeoZone.EditorMetadata).Color;
        this.displayGeoZone(this.selectedGeoZone.Zone);
      }

      this.$options.overlayCompleteListener = google.maps.event.addListener(this.$options.drawingManager, "overlaycomplete", (event) => {
        this.gmShape = event.overlay;
        this.shapeType = event.type;
        this.gmShape.setOptions({
          fillColor: this.shapeColor,
          strokeColor: this.shapeColor,
        });
        this.$options.drawingManager.setOptions({
          drawingMode: null,
        });
      });
    },
    displayGeoZone(zoneString) {
      const geoData = mapService.getGeoData(zoneString);

      if (geoData.type === "Circle") {
        this.gmShape = new google.maps.Circle(
          Object.assign(this.gmShapeOptions, {
            fillColor: this.shapeColor,
            strokeColor: this.shapeColor,
            center: geoData.center,
            radius: geoData.radius,
          })
        );

        this.map.gmapRef.fitBounds(this.gmShape.getBounds());
      } else {
        const bounds = new google.maps.LatLngBounds();
        const coordinates = [];

        geoData.coordinates.forEach((shapes) => {
          shapes.forEach((coordinate) => {
            const point = { lat: coordinate[1], lng: coordinate[0] };
            bounds.extend(point);
            coordinates.push(point);
          });
        });

        this.gmShape = new google.maps.Polygon(
          Object.assign(this.gmShapeOptions, {
            fillColor: this.shapeColor,
            strokeColor: this.shapeColor,
            paths: coordinates,
          })
        );

        this.map.gmapRef.fitBounds(bounds);
      }
    },
    resetGeoZoneDisplay() {
      if (this.gmShape) {
        this.gmShape.setMap(null);
        this.gmShape = null;
      }

      this.shapeType = "";
    },
    destroy() {
      this.resetGeoZoneDisplay();
      this.updateShowMarkers(true);
      this.setMapBounds();
      this.closeGeoZoneEditor();

      if (this.$options.overlayCompleteListener) {
        google.maps.event.removeListener(this.$options.overlayCompleteListener);
        this.$options.overlayCompleteListener = null;
      }

      if (this.$options.drawingManager) {
        this.$options.drawingManager.setMap(null);
        this.$options.drawingManager = null;
      }

      if (this.map.alertBeingCreated) {
        modalService.showModal(this, "new-alert");
      } else if (this.$isMobile) {
        this.$emit("showScreen", "geo-zones");
      } else {
        modalService.showLeftDrawer(this, "geo-zones");
      }
    },
    submit() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.saving = true;
      if (this.isValid) {
        if (this.shapeType === "circle") {
          const centerPoint = this.gmShape.getCenter();
          const radius = this.gmShape.getRadius();
          const cardinalPoints = [];
          for (let i = 0; i <= 360; i += 90) {
            cardinalPoints.push(mapService.getPointOnCircle(centerPoint, radius, i).join(" "));
          }
          this.model.Zone = "CIRCULARSTRING(" + cardinalPoints.join(", ") + ")";
          this.model.EditorMetadata.Shape = "circle";
        } else if (this.shapeType === "polygon") {
          const coordinates = [];
          const vertices = this.gmShape.getPath();
          vertices.forEach((point) => coordinates.push([point.lng(), point.lat()]));
          coordinates.push(coordinates[0]); // Last point must equal first
          this.model.Zone = wkt.convert({
            type: "Polygon",
            coordinates: [coordinates],
          });
          this.model.EditorMetadata.Shape = "polygon";
        }

        this.model.EditorMetadata.Color = this.shapeColor;

        const formattedModel = JSON.parse(JSON.stringify(this.model));
        formattedModel.EditorMetadata = JSON.stringify(formattedModel.EditorMetadata);

        assetService
          .updateGeoZone(formattedModel)
          .then((result) => {
            this.saving = false;
            if (result) {
              if (this.$isMobile) {
                modalService.showSuccessDialog("Geo Zone saved successfully.");
              } else {
                alertService.showSuccessAlert();
              }
              setTimeout(
                () => {
                  this.destroy();
                },
                this.$isMobile ? 0 : 3000
              );
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.saving = false;
            if (error.userMessage) {
              alertService.showErrorAlert(error);
            }
          });
      } else {
        alertService.showErrorAlert("Please draw a shape before saving.");
        this.saving = false;
      }
    },
  },
  watch: {
    shapeColor(value) {
      if (this.gmShape) {
        this.gmShape.setOptions({
          fillColor: value,
          strokeColor: value,
        });
      }
      this.updateColorInShapeOptions();
      this.$options.drawingManager.setOptions({
        circleOptions: this.gmShapeOptions,
        polygonOptions: this.gmShapeOptions,
      });
    },
  },
};
</script>