<template>
  <div class="new-share-vehicle-component screen">
    <ModalHeader
      :config="{
        type: 'back',
        screenName: 'share-vehicles',
        navigateBack: navigateBack,
        title: 'Share Vehicle',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <Alert />
    <div class="heading">SHARE VEHICLES</div>
    <div class="subheading">DRIVER DETAILS</div>
    <form ref="form" @submit.prevent>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input type="text" class="form-control" :class="{ invalid: submitted && !model.Owner.Name }" id="firstName" v-model="model.Owner.Name" required />
            <label for="firstName">First Name</label>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              :class="{ invalid: submitted && !model.Owner.Surname }"
              id="surname"
              v-model="model.Owner.Surname"
              required
            />
            <label for="surname">Surname</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input type="email" class="form-control" :class="{ invalid: submitted && !model.Owner.Email }" id="email" v-model="model.Owner.Email" required />
            <label for="email">Email</label>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              :class="{ invalid: submitted && !model.Owner.CellNo }"
              id="cellNumber"
              pattern="[0-9]{10}"
              v-model="model.Owner.CellNo"
              required
            />
            <label for="cellNumber">Cell Number</label>
          </div>
        </div>
      </div>
      <div class="subheading">VEHICLES</div>
      <div class="panel" :class="{ invalid: submitted && !units.length }">
        <div class="container">
          <div class="row units">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="allUnits" @click="toggleAllUnits" />
              <label class="custom-control-label" for="allUnits">All</label>
            </div>
            <div v-for="(asset, index) in getMyAssets()" :key="asset.SafeAndSoundVehicleId" class="custom-control custom-checkbox unit">
              <input
                class="custom-control-input"
                type="checkbox"
                :id="'unit' + index"
                :value="asset.SafeAndSoundVehicleId"
                @click="toggleUnit(asset.SafeAndSoundVehicleId)"
                :disabled="allUnits"
              />
              <label class="custom-control-label" :for="'unit' + index">{{ asset.RegistrationNumber }}</label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="buttons">
      <StatusIndicator class="mr-3" :success="submitSuccess" :error="submitError" />
      <button class="btn btn-outline mr-3" :disabled="processing" @click="goBack()">CANCEL</button>
      <button class="btn btn-outline" :disabled="processing" @click="save()">
        <span v-show="!processing">CREATE</span>
        <Spinner v-show="processing" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.new-share-vehicle-component {
  > .heading {
    color: $brand-color-2;
    margin-bottom: 20px;
  }

  .subheading {
    color: $brand-color-1;
    margin-bottom: 10px;
  }

  .panel {
    background: $brand-color-4;
    padding: 20px;

    .custom-checkbox {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }

      .custom-control-label {
        font-style: normal;
        font-weight: bold;
        color: $brand-color-2;
      }
    }
  }

  .buttons {
    margin-top: 20px;

    button {
      min-width: 90px;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { alertService, assetService, modalService } from "@/services";
import StatusIndicator from "@/components/StatusIndicator";

export default {
  name: "NewShareVehicle",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    StatusIndicator,
  },
  data() {
    return {
      navigateBack: false,
      processing: false,
      submitted: false,
      submitSuccess: false,
      submitError: false,
      unsavedChanges: false,
      model: {
        Id: 0,
        Active: true,
        Name: "",
        Owner: {
          Name: "",
          Surname: "",
          Email: "",
          CellNo: "",
        },
        Vehicles: [],
      },
      modelUnedited: {},
      units: [],
      allUnits: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getMyAssets"]),
    isValid() {
      return this.units.length;
    },
  },
  methods: {
    ...mapActions("navigation", ["triggerParentScreenRefresh"]),
    beforeClose() {
      if (this.unsavedChanges) {
        modalService.showSaveDialog(this, "Do you want to save the changes made to your shared vehicle(s) before leaving?", this.save);
      } else {
        this.$emit("close");
      }
    },
    goBack() {
      if (this.$isMobile) {
        this.beforeClose();
      } else {
        this.navigateBack = true;
      }
    },
    toggleUnit(value) {
      const index = this.units.indexOf(value);
      if (index === -1) {
        this.units.push(value);
      } else {
        this.units.splice(index, 1);
      }
    },
    toggleAllUnits() {
      this.allUnits = !this.allUnits;
      const elements = document.querySelectorAll(".units .unit input[type=checkbox]");
      if (this.allUnits) {
        this.units = [];
        for (let i = 0, element; (element = elements[i++]); ) {
          element.checked = true;
          this.units.push(element.value);
        }
      } else {
        this.units = [];
        for (let i = 0, element; (element = elements[i++]); ) {
          element.checked = false;
        }
      }
    },
    save() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
        return true;
      } else {
        alertService.showErrorAlert("Please fill in missing fields.");
        return false;
      }
    },
    handleSubmit() {
      this.submitted = true;
      if (this.isValid) {
        this.processing = true;

        this.units.forEach((unit) => {
          this.model.Vehicles.push({
            Id: unit,
          });
        });

        assetService
          .updateSharedVehiclesAccess(this.model)
          .then((result) => {
            this.editing = false;
            this.processing = false;
            if (result) {
              this.unsavedChanges = false;
              this.submitSuccess = true;

              if (this.$isMobile) {
                modalService.showSuccessDialog("Shared vehicle access created successfully.");
              } else {
                alertService.showSuccessAlert();
              }

              setTimeout(() => {
                if (this.$isMobile) {
                  this.triggerParentScreenRefresh();
                }
                this.goBack();
              }, 2000);
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.processing = false;
            this.submitError = true;
            setTimeout(() => {
              this.submitError = false;
            }, 3000);
            if (error.userMessage) {
              alertService.showErrorAlert(error);
            }
          });
      }
    },
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.unsavedChanges = JSON.stringify(this.model) !== JSON.stringify(this.modelUnedited);
      },
    },
  },
};
</script>
