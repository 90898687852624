import Vue from "vue";
import vSelect from "vue-select";
import * as GmapVue from "gmap-vue";
import VueResize from "vue-resize";
import VueTour from "vue-tour";
import VueGtag from "vue-gtag";
import { isIOS, isMacOs, isMobileOnly } from "mobile-device-detect";

import { store } from "./store";
import { router } from "./router";
import { api } from "./services/api.service";
import Alert from "./components/Alert";
import ErrorBox from "./components/ErrorBox";
import Spinner from "./components/Spinner";
import PanelSpinner from "./components/PanelSpinner";
import SmoothReflow from "./components/SmoothReflow";
import GradientScroll from "./components/GradientScroll";

import "bootstrap/dist/css/bootstrap.css";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import "vue2-timepicker/dist/VueTimepicker.css";
import "vue-slider-component/theme/default.css";
import "vue-select/dist/vue-select.css";
import "vue-swatches/dist/vue-swatches.css";
import "vue-resize/dist/vue-resize.css";
import "vue-tour/dist/vue-tour.css";

import App from "./App";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.prototype.$http = api;

Vue.component("v-select", vSelect);
Vue.component("Alert", Alert);
Vue.component("ErrorBox", ErrorBox);
Vue.component("Spinner", Spinner);
Vue.component("PanelSpinner", PanelSpinner);
Vue.component("SmoothReflow", SmoothReflow);
Vue.component("GradientScroll", GradientScroll);

Vue.use(VueResize);
Vue.use(VueTour);

Vue.use(GmapVue, {
  installComponents: true,
  dynamicLoad: true,
});

Vue.use(
  VueGtag,
  {
    bootstrap: false,
  },
  router
);

Vue.mixin({
  computed: {
    $isIOS() {
      return isIOS;
    },
    $isMacOS() {
      return isMacOs;
    },
    $isMobile() {
      return isMobileOnly;
    },
    $isDevEnv() {
      return process.env.NODE_ENV === "development";
    },
    $disableMap() {
      return this.$isDevEnv && process.env.VUE_APP_DISABLE_MAP === "true";
    },
    $liveUpdateMap() {
      if (this.$isDevEnv) {
        return process.env.VUE_APP_LIVE_UPDATE_MAP === "true";
      }
      return true;
    },
    $showTour() {
      return this.$isDevEnv && process.env.VUE_APP_SHOW_TOUR === "true";
    },
  },
});

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
