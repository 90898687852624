<template>
  <div class="time-picker-component">
    <div class="label" :style="{ width: labelWidth }">{{ labelValue }}</div>
    <div class="separator" />
    <div class="clock-icon">
      <img src="~@/assets/images/clock.svg" width="16" height="16" alt />
    </div>
    <div>
      <VueTimepicker v-model="time" hide-clear-button close-on-complete />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.time-picker-component {
  > div {
    display: inline-block;

    &.label {
      font-style: normal;
      color: $brand-color-2;
    }

    &.separator {
      position: relative;
      margin: 0 10px;

      &::before {
        content: " ";
        position: absolute;
        top: -15px;
        left: 0;
        width: 1px;
        height: 20px;
        border-left: 1px solid $brand-color-2;
      }
    }

    &.clock-icon {
      margin-right: 10px;

      img {
        vertical-align: sub;
      }
    }
  }
}
</style>

<script>
import VueTimepicker from "vue2-timepicker";

export default {
  name: "TimePicker",
  components: {
    VueTimepicker,
  },
  props: {
    label: {
      type: String,
    },
    labelWidth: {
      type: String,
      default: "auto",
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      time: this.value,
    };
  },
  computed: {
    labelValue() {
      return this.label ? this.label : "TIME";
    },
  },
  watch: {
    time() {
      this.$emit("input", this.time);
    },
  },
};
</script>
