import axios from "axios";
import { router } from "@/router";
import { store } from "@/store";
import { userService } from "@/services";

export const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      config.headers.common["Authorization"] = "Bearer " + user.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          if (router.currentRoute.path !== "/login") {
            store.dispatch("account/logout");
          }
          break;
      }

      if (error.response.data && error.response.data.userMessage) {
        return Promise.reject(error.response.data);
      }

      const errorMessage =
        (error.response.data && error.response.data.userMessage) ||
        (error.response.data && error.response.data.message) ||
        error.response.statusText;
      return Promise.reject(errorMessage);
    } else {
      return Promise.reject(error);
    }
  }
);
