<template>
  <div class="faqs-component screen">
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'Contact Netstar',
        subTitle: 'FAQs',
      }"
      @close="beforeClose()"
    />
    <div class="panel">
      <div class="categories">
        <Accordion class="category" :forceClose="activeAccordionId != 1" :useArrows="true" @click.native="handleAccordionClick(1)">
          <template v-slot:title>
            <div class="category-heading">
              <div class="icon">
                <img src="~@/assets/images/faqs/general-support.svg" width="34" height="38" alt="General Support" />
              </div>
              <div class="font-weight-bold mt-1">GENERAL SUPPORT</div>
            </div>
          </template>
          <template v-slot:content>
            <Accordion class="faq mt-3">
              <template v-slot:title>
                <strong>What is GPS car tracking?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Netstar makes use of the Global Positioning System (GPS) to determine the location of your vehicle. This information is then passed to Netstar
                  using cellular technology. This then allows the vehicle's location to be shown on a map in close to real time, if there is coverage.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I track my car?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Simply log in to the MyNetstar platform (Web or App) or your fleet management dashboard with your username and password. If your vehicle does not appear on the map, simply select it from the list of vehicles to reveal its location. If you are a
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Netstar Nano</a> or
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Basic</a> client, please remember that your package does not include live tracking capabilities.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Is live vehicle tracking really "live"?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes and no. While our system logs second-by-second data, it is only transmitted to the tracking platform every 1 to 2 minutes. This is done to keep monthly subscriptions as low as possible, as there are specific costs involved with transmitting every GPS coordinate needed for live tracking systems.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What is a GSM jamming device and how does this work?</strong>
              </template>
              <template v-slot:content>
                <p>
                  A <a href="https://www.netstar.co.za/glossary-of-features/" target="_blank" rel="noopener">jammer</a> is a device that creates a 'noise' or
                  interference around a GSM device such as a cellphone or GSM-based tracking device. This prevents communication with the cellular network
                  locally around a device. If a tracking unit is jammed it prevents tracking companies from receiving positions. Netstar offers
                  <a href="https://www.netstar.co.za/glossary-of-features/" target="_blank" rel="noopener">JammingResist™ technology</a>, which allows the
                  tracking device to transmit a distress signal on an alternative Netstar frequency that enables Netstar to continue receiving tracking
                  information.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Where do I buy a tracker?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can easily purchase a Netstar vehicle tracking product through the Netstar website –
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">for personal</a> or
                  <a href="https://www.netstar.co.za/netstar-business/" target="_blank" rel="noopener">for business</a>. Here you will also be able to schedule
                  a fitment, which could either be a mobile fitment at a location of your choice or at one of our Fitment Centres country wide. Alternatively,
                  you can also visit any Netstar Fitment Centre to buy a tracker.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Can I buy a unit online?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes, Netstar's vehicle tracking products can now be conveniently purchased online –
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">for personal</a> or
                  <a href="https://www.netstar.co.za/netstar-business/" target="_blank" rel="noopener">for business</a>. During the process, you will also be
                  able to schedule a fitment time and date that suits you.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Do insurance companies endorse Netstar?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes. Most insurance companies in South Africa recognise and accept Netstar. Once a Netstar unit has been fitted into a vehicle, the customer
                  receives a <a href="https://www.netstar.co.za/glossary-of-features/" target="_blank" rel="noopener">fitment certificate</a> for insurance.
                </p>
                <p>
                  Netstar also has alliances with a number of insurance underwriters, and in many cases, insurance premium discounts may be offered for fitting
                  a Netstar device.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What happens if I sell my car?</strong>
              </template>
              <template v-slot:content>
                <p><a href="https://www.netstar.co.za/contact-us/">Contact Netstar</a> to select one of the options below:</p>
                <p>A. If you sell the vehicle before the end of the agreement:</p>
                <ul>
                  <li>
                    You can transfer the contract to your next vehicle. There will be a charge for transferring the contract and/or installing a new unit in
                    your next vehicle.
                  </li>
                  <li>You can choose to end the agreement (early cancellations costs apply).</li>
                  <li>You can continue to pay the monthly fee until the end of the contract period.</li>
                  <li>The new owner can opt to take over your contract.</li>
                </ul>
                <p>B. If you sell the vehicle at the end of the agreement:</p>
                <ul>
                  <li>
                    You can transfer the contract to your next vehicle. There will be a charge for transferring the contract and/or installing a new unit in
                    your next vehicle.
                  </li>
                  <li>You can choose to end the agreement (no cost and one calendar month's notice will apply).</li>
                  <li>The new owner can initiate a new contract which utilises the existing unit.</li>
                </ul>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Do you have international roaming?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes, we offer international roaming at an additional fee. You may send a request to
                  <a href="mailto:contractsGSM@netstar.co.za" target="_blank" rel="noopener">contractsGSM@netstar.co.za</a> indicating your needs.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>I'm travelling over the border - will my Netstar device still work?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Most devices are compatible for cross border operation. You will have to contact Netstar before doing so to switch on your tracking unit's
                  international roaming at an additional fee if you have a compatible unit. Please send your request to
                  <a href="mailto:contractsGSM@netstar.co.za" target="_blank" rel="noopener">contractsGSM@netstar.co.za</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What is Car Park Jamming?</strong>
              </template>
              <template v-slot:content>
                <p>
                  <a href="https://www.netstar.co.za/glossary-of-features/" target="_blank" rel="noopener">Car Park Jamming Detection</a> is the detection of
                  <a href="https://www.netstar.co.za/glossary-of-features/" target="_blank" rel="noopener">'remote jamming'</a> on the vehicle's central
                  locking, by a criminal that may intend to steal belongings from the vehicle. If this jamming is detected, you will receive a warning SMS. You
                  may elect to opt out of this SMS by replying to the SMS with the word "Stop".
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do you keep track of the traffic?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Netstar has joined forces with the global leader in traffic information INRIX to form Netstar Traffic (NT). NT brings you intelligent,
                  real-time traffic flow information and journey times in a variety of applications.
                </p>
                <p>
                  Keep up to speed by knowing where the congestion is and incidences such as road works, vehicle accidents, road closures, social and sports
                  events, traffic light failure/malfunction via our Twitter feed
                  <a href="https://twitter.com/netstartraffic" target="_blank" rel="noopener">@netstartraffic</a>, or through our partners
                  <a href="https://www.garmin.com/en-ZA/" target="_blank" rel="noopener">Garmin</a>, Navigon,
                  <a href="https://www.toyota.co.za/" target="_blank" rel="noopener">Toyota</a>,
                  <a href="https://www.mercedes-benz.co.za/" target="_blank" rel="noopener">Mercedes</a>,
                  <a href="https://www.volvocars.com/za" target="_blank" rel="noopener">Volvo</a>,
                  <a href="https://www.bmw.co.za/en/index.html" target="_blank" rel="noopener">BMW</a> and McLaren via our RDS-TMC coverage for personal
                  navigation devices as well as Satnavs, available in the broader Gauteng, Cape Peninsula, Kwa-Zulu Natal, North West, Limpopo, Mpumalanga, Free
                  State and Eastern Cape.
                </p>
                <p>
                  Connected devices will receive traffic for the whole of South Africa including a higher resolution of traffic incidents and slow down alerts.
                </p>
                <p>
                  Netstar Traffic also provides analytical data for businesses to determine route impacts, hot spots, expected slowdown and bottlenecking by
                  time or seasonal comparison as well as Big Data services for business-to-business needs.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Where can I find the Netstar terms and conditions?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The Netstar Subscriber terms and conditions can be found by clicking on the following link <a href="https://www.netstar.co.za/legal-information/">https://www.netstar.co.za/legal-information/</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I acquire a trip logbook for SARS purposes?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Log into the MyNetstar platform (Web or App) or your fleet management dashboard with your username and password. Select a vehicle, and then select "Logbooks". Now simply choose a Start and End Date, your desired file format and whether you want to download or email the logbook file.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How long does Netstar retain my data?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Netstar stores your data securely and retains it in line with Netstar's Privacy policy as per our terms and conditions. This can be accessed on <a href="https://www.altron.com/privacy-policy">https://www.altron.com/privacy-policy</a>.
                </p>
              </template>
            </Accordion>
          </template>
        </Accordion>
        <Accordion class="category" :forceClose="activeAccordionId != 2" :useArrows="true" @click.native="handleAccordionClick(2)">
          <template v-slot:title>
            <div class="category-heading">
              <div class="icon">
                <img src="~@/assets/images/faqs/accounts.svg" width="30" height="38" alt="Accounts" />
              </div>
              <div class="font-weight-bold">ACCOUNTS</div>
            </div>
          </template>
          <template v-slot:content>
            <Accordion class="faq mt-3">
              <template v-slot:title>
                <strong>Where can I find my invoice or statement?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can access your latest invoice, statement or account summary at any time by logging in to MyNetstar Web via
                  <a href="https://my.netstar.co.za" target="_blank" rel="noopener">my.netstar.co.za</a>, the MyNetstar App or your fleet management dashboard. Alternatively, you
                  can also send a WhatsApp message to the
                  <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank" rel="noopener">Netstar Service Bot</a> on
                  <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank" rel="noopener">0860 12 24 36</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Where can I download my Fitment Certificate?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can download your fitment certificate by logging in to
                  MyNetstar Web via <a href="https://my.netstar.co.za" target="_blank" rel="noopener">my.netstar.co.za</a>, or the MyNetstar App or by sending a WhatsApp message to the
                  <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank" rel="noopener">Netstar Service Bot</a> on
                  <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank" rel="noopener">0860 12 24 36</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Where can I download my Test Certificate?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Simply log in to MyNetstar Web via <a href="https://my.netstar.co.za" target="_blank" rel="noopener">my.netstar.co.za</a>, or the MyNetstar App or send a WhatsApp message to the
                  <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank" rel="noopener">Netstar Service Bot</a> on
                  <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank" rel="noopener">0860 12 24 36</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I edit my personal details or financial details?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can log in to <a href="https://my.netstar.co.za" target="_blank" rel="noopener">my.netstar.co.za</a> or your
                  <a href="https://fleet.netstar.co.za/NetstarFleet/login.php" target="_blank" rel="noopener">fleet management</a> dashboard, then click or tap
                  on the menu at the top right corner of the screen. Select "personal details" or "financial details" and scroll to the bottom of the section.
                  Click "edit".
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I change my cellphone number?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can easily change your cellphone number and other personal details by logging in to MyNetstar Web via
                  <a href="https://my.netstar.co.za" target="_blank" rel="noopener">my.netstar.co.za</a> or the MyNetstar App. For business users who do not have access to
                  MyNetstar, please speak to your Netstar account manager or contact Fleet Customer Support on 011 207 5335 or
                  <a href="mailto:fleetcs@netstar.co.za" target="_blank" rel="noopener">fleetcs@netstar.co.za</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I cancel my contract?</strong>
              </template>
              <template v-slot:content>
                <p>
                  To cancel your contract, please call the Customer Support Centre on <a href="tel: 0860122436">0860 12 24 36</a> or send an email to
                  <a href="mailto:cs@netstar.co.za">cs@netstar.co.za</a> (personal clients). If you're a business client, please talk to your account manager or
                  email <a href="mailto:fleetcancellations@netstar.co.za">fleetcancellations@netstar.co.za</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How can I update my banking details?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can easily change your banking details and other personal details by logging in to MyNetstar Web via
                  <a href="https://my.netstar.co.za" target="_blank" rel="noopener">my.netstar.co.za</a> or the MyNetstar App. For business users who do not have access to
                  MyNetstar, please speak to your Netstar account manager or call the Customer Support Centre on <a href="tel:0860122436">0860 12 24 36</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why don't I receive my monthly invoices and statements?</strong>
              </template>
              <template v-slot:content>
                <p>
                  If your account is paid via debit order, you will not receive a monthly invoice or statement via post/email. However, you can access your
                  invoice or statement at any time by logging in to MyNetstar Web via <a href="https://my.netstar.co.za/">my.netstar.co.za</a> or the MyNetstar App, or by sending a WhatsApp message to
                  the <a href="https://api.whatsapp.com/send?phone=27663061096" target="_blank" rel="noopener">Netstar Service Bot</a> on
                  <a href="https://api.whatsapp.com/send?phone=27663061096" target="_blank" rel="noopener">0860 12 24 36</a>. If you're a business client, you
                  can use WhatsApp or contact your Netstar account manager.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why did you not call me when my contract expired?</strong>
              </template>
              <template v-slot:content>
                <p>If you opted out of receiving marketing material from Netstar, we will not contact you.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What forms of payment do you accept?</strong>
              </template>
              <template v-slot:content>
                <p>Netstar only accepts direct debit orders for all services rendered.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What are Netstar's banking details?</strong>
              </template>
              <template v-slot:content>
                <p>
                  <strong>Account Number:</strong> 4053773773<br /><strong>Bank:</strong> ABSA<br /><strong>Branch Number:</strong> 632005<br /><strong
                    >Reference Number:</strong
                  >
                  Always quote your Netstar account number
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>If I added a vehicle mid-month, would I be charged for the entire month?</strong>
              </template>
              <template v-slot:content>
                <p>No, all Netstar invoices are prorated so you only pay for the number of days your vehicles have been active on the Netstar network.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why do I have to pay a settlement when I sell my car?</strong>
              </template>
              <template v-slot:content>
                <p>
                  If you signed a 36-month rental contract and choose to end the contract before the period is over, you will be liable to pay a settlement.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why do I have to pay to transfer the unit from one vehicle to another?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The agreement you have with Netstar only covers the initial installation. If the unit needs to be moved to a different vehicle, a transfer fee
                  is payable to cover the additional costs involved.
                </p>
              </template>
            </Accordion>
          </template>
        </Accordion>
        <Accordion class="category" :forceClose="activeAccordionId != 3" :useArrows="true" @click.native="handleAccordionClick(3)">
          <template v-slot:title>
            <div class="category-heading">
              <div class="icon">
                <img src="~@/assets/images/faqs/stolen-vehicle-recovery-process.svg" width="29" height="30" alt="Stolen Vehicle Recovery Process" />
              </div>
              <div class="font-weight-bold">STOLEN VEHICLE RECOVERY PROCESS</div>
            </div>
          </template>
          <template v-slot:content>
            <Accordion class="faq mt-3">
              <template v-slot:title>
                <strong>What should I do if my vehicle is stolen or hijacked?</strong>
              </template>
              <template v-slot:content>
                <p>
                  If your vehicle is stolen or hijacked, you must immediately call Netstar's Emergency Call Centre on
                  <a href="tel:0800112222.">0800 11 22 22.</a> The emergency controller will capture the relevant details and begin the recovery process. You
                  will also have to confirm the vehicle description as any unique identifiers may assist with recovery. The relevant controller assigned to your
                  incident will update you on the progress.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What happens if my vehicle is stolen, and the unit is not working?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Once the case has been reported to Netstar, the recovery process begins by Netstar alerting the response teams of the case. You will be
                  contacted once full response has been dispatched to obtain more details of the incident such as model of the vehicle and any distinguishing
                  features. Once all has been completed, you will be informed to report the case to the SAPS.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What happens if Netstar cannot recover my vehicle?</strong>
              </template>
              <template v-slot:content>
                <p>Netstar will keep on looking for the vehicle. The case will never be closed until the vehicle is recovered.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Does Netstar charge a stolen vehicle recovery fee?</strong>
              </template>
              <template v-slot:content>
                <p>
                  No. Netstar offers free recovery to all its customers, provided your account is up to date. If a vehicle is recovered outside of South Africa,
                  a vehicle repatriation fee may be payable.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Does Netstar have support of the South African Police Services (SAPS)?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes. When a vehicle is being recovered, Netstar communicates with the SAPS as they are often in a position to assist. Netstar does not rely
                  exclusively on the SAPS as the company also has close relationships with highly trained professional recovery teams.
                </p>
              </template>
            </Accordion>
          </template>
        </Accordion>
        <Accordion class="category" :forceClose="activeAccordionId != 4" :useArrows="true" @click.native="handleAccordionClick(4)">
          <template v-slot:title>
            <div class="category-heading">
              <div class="icon">
                <img src="~@/assets/images/faqs/mynetstar.svg" width="22" height="40" alt="MyNetstar" />
              </div>
              <div class="font-weight-bold">MYNETSTAR</div>
            </div>
          </template>
          <template v-slot:content>
            <Accordion class="faq mt-3">
              <template v-slot:title>
                <strong>Why does the mobile app look so different?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The MyNetstar App has been completely rebuilt and redesigned as a Native App.
                  This means that you will be able to login using your biometrics, you will get push-notifications,
                  and the user-experience will be much more intuitive and user-friendly on a mobile device.
                  The old mobile app will be replaced by the new app. But the desktop view will remain,
                  and you can still access MyNetstar Web on <a href="https://my.netstar.co.za">my.netstar.co.za</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I log in or register on MyNetstar?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can access MyNetstar either via MyNetstar Web or the MyNetstar App.
                  On Web, simply visit <a href="https://my.netstar.co.za/">my.netstar.co.za</a>, or the App can be downloaded in the Apple and Android app stores.
                  You can login using your account username and password and click "Log in".
                  If you don't have a MyNetstar account, simply click on the "Register" link and follow the prompts.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Do I need to re-register or register on both MyNetstar Web and MyNetstar App?</strong>
              </template>
              <template v-slot:content>
                <p>No, you don't. Once your MyNetstar account is created, you can use the same login credentials on both MyNetstar App and MyNetstar Web.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>I didn't receive an OTP when trying to register, what should I do?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Our system could be temporarily offline. Please wait a few minutes before trying again. If you still experience issues, please phone the
                  Netstar Customer Support Centre on <a href="tel:0860122436">0860 12 24 36</a> for assistance.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What if I forget my Username or Password?</strong>
              </template>
              <template v-slot:content>
                <p>
                  If you forget your Username or Password, you can click or tap on the "Forgot Username/Password" link on the login page. Follow the prompts to
                  reset your password safely and receive an OTP and a reminder of your username.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Can I change my password?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can change your password by navigating to the "Admin" section on the top right section of the main menu. Tap on the "Change Password" button to change the
                  password. Please note that the app will log you out once you have changed the password to verify that you are the real user.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I edit my personal details or financial details?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Click or tap on the menu at the top right corner of the screen. Select "Personal Details" or "Financial Details" and scroll to the bottom of
                  the section. Click "Edit".
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>I need some of my account documents - how do I get them?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Click or tap on the menu at the top right corner of the screen. Select "Documents". You can now download your latest statement, your latest
                  invoice or a summary of your account.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I add vehicles to MyNetstar?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Once a compatible tracking unit has been installed in your vehicle, it should start appearing in
                  <a href="https://my.netstar.co.za/login" target="_blank" rel="noopener">MyNetstar</a> automatically after the unit has been activated.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How long after fitting my tracker will my vehicle appear on the map?</strong>
              </template>
              <template v-slot:content>
                <p>
                  After your tracking unit has been fitted and activated, it should appear on the map within an hour.
                  Should you experience any issues, please provide feedback on MyNetstar or call the Netstar Customer Support Centre on <a href="tel:0860122436">0860 12 24 36</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why does my unit not have live vehicle tracking?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Our <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Netstar Nano</a> and
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Basic</a> products are designed to offer affordable stolen
                  vehicle recovery, which doesn't include live tracking functionality. To access this functionality, please upgrade your product to either
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Netstar Plus</a> or
                  <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Netstar Early Warning</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I upgrade or downgrade my service on MyNetstar?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can upgrade your product from within MyNetstar by opening the main menu and selecting "Upgrade Product". To downgrade a product, please
                  call the Netstar Customer Support Centre on <a href="tel:0860122436">0860 12 24 36</a>.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why is there a difference between the functionality across MyNetstar Web and App?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The app is for on-the-go tracking, while MyNetstar Web will offer improved dashboards and reporting. There will however be a lot of overlap
                  in functionality between MyNetstar Web and App.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Where can I view the location of my vehicle?</strong>
              </template>
              <template v-slot:content>
                <p>
                  If you are logged in to <a href="https://my.netstar.co.za/login" target="_blank" rel="noopener">MyNetstar</a> Web, your list of vehicles will
                  be displayed in a menu at the top of the screen. You can hover over the vehicle on the map and more tools and options will appear.
                  In MyNetstar App, they will be displayed in the Home screen and Map view.
                  You can tap the vehicle in the scrollable asset list to see the location of the vehicle.
                  If you have a <a href="https://www.netstar.co.za/personal/" target="_blank" rel="noopener">Netstar Plus, Netstar Early Warning</a> or
                  <a href="https://www.netstar.co.za/netstar-business/" target="_blank" rel="noopener">Netstar Fleet Tracker</a> unit, your vehicles will also
                  be displayed as icons on the map.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What does the vehicle information pop-up mean in MyNetstar Web?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The pop-up above the vehicle icon on the map gives you all kinds of useful information: ignition status (on or off), date & time that the unit
                  last sent a message to Netstar, vehicle speed, street address, latitude & longitude, etc.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why is my vehicle location shown as "Address not Found"?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The unit in your vehicle reports GPS coordinates to Netstar. These coordinates are plotted on a mapping system to retrieve the street address.
                  Your address may be in an area that has not been properly mapped yet, e.g. some rural areas. Please report such locations to us so that we can
                  get the map provider to address this.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I search for alerts?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Simply click or tap on the relevant vehicle, click or tap the down arrow and select "Alerts". They will appear in a list. To configure Alerts,
                  click "Manage Alerts", and then "New Alert".
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I use the driving behaviour feature?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The Behaviour Analysis view displays a graph with a breakdown of your weekly driving style scores. Click or tap on the graph point for the
                  desired week. You can also navigate forward and backward in this view by using the navigation arrows. The Behaviour Rating view will give you
                  an overall rating of your driving style based on a weekly score calculated by analysing your driving behaviour. To see how your score will
                  change if you adapt your driving behaviour, move the slider up or down the scale line.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I manage a vehicle?</strong>
              </template>
              <template v-slot:content>
                <p>
                  To manage a vehicle's important information, click or tap on the relevant vehicle, select the down arrow and click or tap "Manage". You will
                  be presented with a range of fields of information that you can edit.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I send logbook details to someone?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Click or tap the relevant vehicle on the map or at the top left of your screen. Click "Logbook". Fill in the fields and select the desired
                  dates you want the logbook to consist of. Send the logbook to an email address of your choice.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>I have multiple vehicles and I want to share one of my vehicles with specific people. Can I do that on MyNetstar App?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes, you can! MyNetstar App has a Vehicle Share feature which enables you to share vehicles with specific people. And it does not matter
                  whether that person is an existing Netstar customer or not.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I view past trips of a specific vehicle?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Simply click or tap on the relevant vehicle, select the down arrow, and click or tap on "Trips". This will show you a list of past trips that
                  the vehicle has taken. Here you can also modify the trip type.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I get my fitment and test certificates?</strong>
              </template>
              <template v-slot:content>
                <p>Click or tap on the relevant vehicle, click or tap the down arrow, select "Certificates" and download the desired certificate.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I set a reminder in MyNetstar Web?</strong>
              </template>
              <template v-slot:content>
                <p>
                  To set a reminder, simply click or tap on the relevant vehicle, select the down arrow, and click or tap "Reminders". You can now set a
                  reminder by clicking "Create".
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What is the customisable home page?</strong>
              </template>
              <template v-slot:content>
                <p>The home screen is meant to be a personalised landing screen for the MyNetstar App. You can customise your favourite shortcuts to make using the app easier. You can also view insights on all your vehicles and your latest trips.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why do you need so many permissions such as location, push notifications, contact list, camera, picture gallery?</strong>
              </template>
              <template v-slot:content>
                <p>
                  We understand your concern about the permissions required by the MyNetstar App. These permissions are necessary to enable and enhance specific features that make your experience with the app more convenient and useful.
                  For example, allowing access to your location enables features like GPS tracking, which is essential for services such as vehicle tracking and route optimisation. Similarly, enabling push notifications ensures that you
                  receive important alerts and reminders directly to your mobile device, keeping you informed about your vehicle's status and any important updates in real-time.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Why can't I see the app in the Huawei App store?</strong>
              </template>
              <template v-slot:content>
                <p>The Huawei App will be available in June 2024. Whilst you wait for this, you can access MyNetstar Web via <a href="https://my.netstar.co.za/">my.netstar.co.za</a>.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What is Safe and Sound?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Safe and Sound is our legacy personal vehicle tracking app, which has been replaced with
                  <a href="https://www.netstar.co.za/mynetstar/" target="_blank" rel="noopener">MyNetstar</a>, our
                  <a href="https://www.netstar.co.za/mynetstar/" target="_blank" rel="noopener">customer engagement platform.</a>
                  MyNetstar offers more functionality and empowers the user to manage their account as well.
                </p>
                <p>
                  You can download the MyNetstar App from the
                  <a href="https://play.google.com/store/apps/details?id=za.co.netstar.my.twa" target="_blank" rel="noopener">Google Play Store</a> and
                  <a href="https://apps.apple.com/za/app/mynetstar/id1629057326" target="_blank" rel="noopener">Apple App Store</a>.
                  You can access MyNetstar Web via <a href="https://my.netstar.co.za/" target="_blank" rel="noopener">my.netstar.co.za</a>.
                  If you don't have a MyNetstar account, simply click on the "Register" link and follow the prompts.
                </p>
                <p><strong>Please note:</strong> Safe and Sound users must re-register on MyNetstar to enjoy the benefits of the new platform.</p>
              </template>
            </Accordion>
          </template>
        </Accordion>
        <Accordion class="category" :forceClose="activeAccordionId != 5" :useArrows="true" @click.native="handleAccordionClick(5)">
          <template v-slot:title>
            <div class="category-heading">
              <div class="icon">
                <img src="~@/assets/images/faqs/netstar-companion.svg" width="22" height="40" alt="Netstar Companion" />
              </div>
              <div class="font-weight-bold">NETSTAR COMPANION</div>
            </div>
          </template>
          <template v-slot:content>
            <Accordion class="faq mt-3">
              <template v-slot:title>
                <strong>What is Netstar Companion?</strong>
              </template>
              <template v-slot:content>
                <p>
                  <a href="https://www.netstar.co.za/netstar-companion/">Netstar Companion</a> is a personal armed security and medical response service brought
                  to you by Netstar in association with Project Help. If you feel unsafe and you tap the Netstar Companion button on your app, we will send
                  armed or medical response services to you.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Where does Netstar Companion work?</strong>
              </template>
              <template v-slot:content>
                <p>
                  <a href="https://www.netstar.co.za/netstar-companion/">Netstar Companion</a> works nationwide in South Africa.
                  We have one of the largest networks of security partners in the country. If you are in a situation where you feel unsafe, let us know via the app.
                  The closest service provider to your current location will come to your aid.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Can I use Netstar Companion when I'm at home?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can use <a href="https://www.netstar.co.za/netstar-companion/" target="_blank" rel="noopener">Netstar Companion</a> anywhere, anytime,
                  whether you are in your car, at home, going for a walk, jogging, riding a bicycle, or even when you are in a shopping centre.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Do I need to be a Netstar Customer to subscribe to Netstar Companion?</strong>
              </template>
              <template v-slot:content>
                <p>No. <a href="https://www.netstar.co.za/netstar-companion/">Netstar Companion</a> is stand-alone value-added service that is available to anyone.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Do I need to own a vehicle to subscribe to Netstar Companion?</strong>
              </template>
              <template v-slot:content>
                <p>No. Anyone can subscribe to this service - whether you travel by car, commute or work from home.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What happens if I need help?</strong>
              </template>
              <template v-slot:content>
                <p>If you need help:</p>
                <ul>
                  <li>Press the 'TAP FOR HELP' button in the app.</li>
                  <li>We will call you to find out more and support you where needed.</li>
                  <li>We will dispatch the nearest security services to your location.</li>
                  <li>If you do not answer the call, we will still dispatch security services to your location.</li>
                </ul>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What if I feel unsafe?</strong>
              </template>
              <template v-slot:content>
                <p>It is always better to act proactively. If you feel unsafe:</p>
                <ul>
                  <li>Press the red 'TAP FOR HELP' button in the app.</li>
                  <li>We will call you and find out more about your situation.</li>
                  <li>We will guide you to a place of safety or provide an escort.</li>
                </ul>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Can I call you in the case of domestic violence?</strong>
              </template>
              <template v-slot:content>
                <p>Yes, you can.</p>
                <ul>
                  <li>Press the 'TAP FOR HELP' button in the app.</li>
                  <li>We will call you and find out more about your situation.</li>
                  <li>We will guide you to a place of safety or provide an escort.</li>
                  <li>If you are unable to use the app, please phone 010 822 9288.</li>
                </ul>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Do I need to have a smartphone to use Netstar Companion?</strong>
              </template>
              <template v-slot:content>
                <p>
                  Yes, you do. <a href="https://www.netstar.co.za/netstar-companion/">Netstar Companion</a> uses location-based services to find you. If you do
                  not have data, please phone <a href="tel:0108229288">010 822 9288</a> if you need help.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I pay for Netstar Companion?</strong>
              </template>
              <template v-slot:content>
                <p>
                  You can pay for Netstar Companion via a monthly debit order, or if you are an existing Netstar customer, the payment can be added to your
                  existing Netstar subscription.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>I'm already a Netstar customer. Does this service form part of my monthly tracking fee?</strong>
              </template>
              <template v-slot:content>
                <p>
                  No. <a href="https://www.netstar.co.za/netstar-companion/" target="_blank" rel="noopener">Netstar Companion</a> is a value-added service. You
                  need to subscribe to this service separately.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Will I get an escort on request?</strong>
              </template>
              <template v-slot:content>
                <p>
                  No, the service is responsive and cannot provide an escort. Our services can be activated once the situation is deemed threatening or criminal
                  with immediate effect.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>Is there a limit on how often one can use the service?</strong>
              </template>
              <template v-slot:content>
                <p>No, there is no limit to usage as long as all events are confirmed incidents and not false alarms.</p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>What happens when I need assistance on a hiking or mountain bike trail?</strong>
              </template>
              <template v-slot:content>
                <p>
                  The control room and local service providers will engage with local authorities and resources to assist as access will be difficult without
                  specialised vehicles.
                </p>
              </template>
            </Accordion>
            <Accordion class="faq">
              <template v-slot:title>
                <strong>How do I cancel the Netstar Companion service?</strong>
              </template>
              <template v-slot:content>
                <p>
                  To cancel your subscription, simply call the Netstar Companion Support Centre on<a href="tel:0108229288"> 010 822 9288</a> or email
                  <a href="mailto:companion@netstar.co.za" target="_blank" rel="noopener">companion@netstar.co.za</a> for assistance.
                </p>
              </template>
            </Accordion>
          </template>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.faqs-component {
  .panel {
    background: $brand-color-4;
    padding: 25px 20px;

    strong {
      font-weight: bold;
    }

    .categories {
      .category {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $brand-color-2;

        &:last-of-type {
          padding-bottom: 0;
          margin-bottom: 0;
          border: none;
        }

        &-heading {
          display: flex;
          align-items: center;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 40px;
            text-align: center;
            margin-right: 20px;
          }
        }

        .faq {
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 1px solid $brand-color-2;

          &:last-of-type {
            margin-bottom: 0;
            border: none;
          }

          p:first-of-type {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>

<script>
import Accordion from "@/components/Accordion";

export default {
  name: "FAQs",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    Accordion,
  },
  data() {
    return {
      activeAccordionId: null,
    };
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    handleAccordionClick(id) {
      this.activeAccordionId = id;
    },
  },
};
</script>
