<template>
  <div class="fitment-address-for-upgrade-component screen">
    <UpgradeStepHeader
      :stepNo="4"
      title="Enter Fitment Location"
      nextStepScreenName="terms-and-conditions-for-upgrade"
      :showNextButton="formIsInitialised"
      :showSpinner="!formIsInitialised"
      :formValidationFailureCount="formValidationFailureCount"
      :stepCompleted="upgradeStepCompleted"
      @submit="update()"
      @close="beforeClose()"
    />
    <Alert />
    <SmoothReflow class="form-container">
      <form ref="form" @submit.prevent>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="physicalAddressLine1"
                v-model="fitmentAddress.Street"
                required
              />
              <label for="physicalAddressLine1">Street</label>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="physicalAddressLine2"
                v-model="fitmentAddress.ComplexName"
              />
              <label for="physicalAddressLine2">Complex</label>
            </div>
            <div class="form-group">
              <v-select
                id="physicalPostalCode"
                ref="postalCodePicker"
                class="form-control fixed"
                :style="cssVars"
                @input="postalCodeChange"
                v-model="fitmentAddress.PostalCodeCodeOnly"
                :options="postalCodes"
                :reduce="(item) => item.value"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!fitmentAddress.PostalCodeCodeOnly"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="physicalPostalCode">Postal Code</label>
            </div>
            <div class="form-group mb-0">
              <v-select
                id="physicalCity"
                class="form-control fixed"
                :style="cssVars"
                @input="cityChange"
                v-model="fitmentAddress.City"
                :options="suburbCities"
                :reduce="(item) => item.value"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!fitmentAddress.City"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="physicalCity">City</label>
            </div>
            <div class="text-right mt-3">
              <button
                class="btn btn-outline light"
                @click="updateMapPin"
                :disabled="hasUpdatedMapPin || processing.mapPin"
              >
                <Spinner v-if="processing.mapPin" />
                <span v-else>UPDATE MAP PIN</span>
              </button>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <GmapMap
              id="fitmentMap"
              ref="gmap"
              :center="
                latLngIsPresent
                  ? { lat: fitmentAddress.Lat, lng: fitmentAddress.Long }
                  : { lat: -30.5595, lng: 22.9375 }
              "
              :zoom="latLngIsPresent ? 14 : 4"
              :options="{
                maxZoom: 20,
                clickableIcons: false,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: true,
              }"
            >
              <GmapMarker
                :position="{
                  lat: fitmentAddress.Lat,
                  lng: fitmentAddress.Long,
                }"
                :draggable="true"
                @dragend="markerPositionChanged"
                v-if="latLngIsPresent"
              />
            </GmapMap>
          </div>
        </div>
      </form>
    </SmoothReflow>
  </div>
</template>

<style lang="scss">
.fitment-address-for-upgrade-component {
  .v-select {
    &.fixed {
      .vs__dropdown-menu {
        width: var(--width);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.fitment-address-for-upgrade-component {
  display: flex;
  flex-direction: column;
  max-height: $modalHeight;

  @include scrollbar;

  .form-container {
    padding: 10px 0 20px 0;

    #fitmentMap {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { adminService, utilityService } from "@/services";
import UpgradeStepHeader from "@/components/screens/UpgradeStepHeader";

export default {
  name: "FitmentAddressForUpgrade",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    UpgradeStepHeader,
  },
  data() {
    return {
      loading: true,
      formValidationFailureCount: 0,
      upgradeStepCompleted: false,
      formIsInitialised: false,
      fitmentAddress: {
        Street: "",
        ComplexName: "",
        Suburb: "",
        City: "",
        PostalCodeCodeOnly: "",
        Lat: "",
        Long: "",
      },
      postalCodes: [],
      suburbCities: [],
      postalCodePickerWidth: 0,
      hasUpdatedMapPin: false,
      processing: {
        mapPin: false,
      },
      latLngIsPresent: false,
    };
  },
  computed: {
    ...mapState(["account"]),
    ...mapGetters("account", ["getProfileImageUrl"]),
    ...mapGetters("asset", { upgradeInProgress: "getUpgradeInProgress" }),
    cssVars() {
      return {
        "--width": this.postalCodePickerWidth + "px",
      };
    },
  },
  created() {
    const postalCodesRequest = utilityService.getPostalCodes();
    const customerDetailsRequest = adminService.getCustomerDetails();
    const startTime = Date.now();

    Promise.all([postalCodesRequest, customerDetailsRequest])
      .then((responses) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.postalCodes = responses[0];
            this.fitmentAddress = responses[1].PhysicalAddress;
            // Amend object to fitment address format
            delete this.fitmentAddress.Addressline2;
            this.fitmentAddress.ComplexName = "";
            this.fitmentAddress.PostalCodeCodeOnly = this.fitmentAddress.PostalCode;
            delete this.fitmentAddress.PostalCode;

            utilityService
              .getSuburbCities(this.fitmentAddress.PostalCodeCodeOnly)
              .then((data) => {
                this.suburbCities = data;
              });

            this.loading = false;
            this.formIsInitialised = true;
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((errors) => {
        this.showErrorAlert(errors);
      });
  },
  mounted() {
    this.postalCodePickerWidth = this.$refs.postalCodePicker.$el.offsetWidth;
  },
  methods: {
    ...mapActions("alert", {
      showErrorAlert: "error",
      clearAlert: "clear",
    }),
    ...mapMutations("asset", ["setUpgradeFitmentAddress"]),
    beforeClose() {
      this.$emit("close");
    },
    postalCodeChange(postalCode) {
      utilityService.getSuburbCities(postalCode).then((data) => {
        this.suburbCities = data;
        this.fitmentAddress.City = "";
        this.fitmentAddress.Suburb = "";
      });
    },
    cityChange(value) {
      this.fitmentAddress.City = value;
      this.fitmentAddress.Suburb = value;
    },
    updateMapPin() {
      if (this.$refs.form.reportValidity()) {
        this.clearAlert();

        this.processing.mapPin = true;

        this.geocodeAddress((success) => {
          this.processing.mapPin = false;

          if (success) {
            this.hasUpdatedMapPin = true;
          } else {
            this.showErrorAlert(
              "Unable to update the map pin. Please ensure your address is correct."
            );
          }
        });

        return true;
      }
    },
    markerPositionChanged(location) {
      this.fitmentAddress.Lat = location.latLng.lat();
      this.fitmentAddress.Long = location.latLng.lng();
      this.$refs.gmap.$mapObject.setCenter({
        lat: this.fitmentAddress.Lat,
        lng: this.fitmentAddress.Long,
      });
    },
    geocodeAddress(callback) {
      const fullAddress =
        this.fitmentAddress.Street +
        "," +
        this.fitmentAddress.ComplexName +
        "," +
        this.fitmentAddress.City +
        "," +
        this.fitmentAddress.PostalCodeCodeOnly +
        ",South Africa";

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (status === "OK" && results) {
          const location = results[0].geometry.location;
          this.fitmentAddress.Lat = location.lat();
          this.fitmentAddress.Long = location.lng();
          this.latLngIsPresent = true;
          callback(true);
        } else {
          callback(false);
        }
      });
    },
    update() {
      if (this.$refs.form.reportValidity()) {
        if (this.latLngIsPresent) {
          this.handleSubmit();
          return true;
        }

        this.showErrorAlert("Please update the map pin.");
      }

      this.formValidationFailureCount++;
      return false;
    },
    handleSubmit() {
      const { fitmentAddress } = this;

      if (fitmentAddress) {
        try {
          this.$gtag.event("upgrade_fitment_details");
        } catch (err) {}

        this.setUpgradeFitmentAddress(fitmentAddress);
        this.upgradeStepCompleted = true;
      }
    },
  },
  watch: {
    fitmentAddress: {
      deep: true,
      handler() {
        this.hasUpdatedMapPin = false;
        this.fitmentAddress.Lat = "";
        this.fitmentAddress.Long = "";
        this.latLngIsPresent = false;
      },
    },
  },
};
</script>
