<template>
  <div class="change-password-component screen">
    <div class="header">
      <div class="title">Change Password</div>
      <button class="close-button" @click="close()"></button>
    </div>
    <SmoothReflow class="form-container">
      <Alert />
      <div class="box" v-if="changedSuccessfully">
        <svg width="33.735" height="35.579" viewBox="0 0 33.735 35.579">
          <g transform="translate(-98.984 -138.113)">
            <path
              d="M124.653,157.561v9.881a2.708,2.708,0,0,1-2.707,2.708H102.692a2.708,2.708,0,0,1-2.708-2.708V152.573a2.708,2.708,0,0,1,2.708-2.708h14.492"
              transform="translate(0 2.542)"
              class="path"
            />
            <path d="M119.4,146.994a7.88,7.88,0,0,0-7.88-7.881h0a7.881,7.881,0,0,0-7.881,7.881v5.413" transform="translate(0.865)" class="path" />
            <circle cx="2.201" cy="2.201" r="2.201" transform="translate(110.118 158.148)" class="path" />
            <line y2="4.934" transform="translate(112.319 162.549)" class="path" />
            <circle cx="7.066" cy="7.066" r="7.066" transform="translate(117.588 145.76)" class="path" />
            <path d="M115.74,152.167l2.08,2.163,4.595-4.576" transform="translate(5.14 1.204)" class="path" />
          </g>
        </svg>
        <div class="message">Your password has been changed.</div>
      </div>
      <template v-else>
        <form ref="form" @submit.prevent>
          <div class="form-group">
            <input type="password" class="form-control" id="oldPassword" v-model="oldPassword" required />
            <label for="oldPassword">Old Password</label>
          </div>
          <div class="form-group position-relative">
            <div id="passwordStrengthIndicator" :data-score="passwordScore" v-show="newPassword">
              <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" v-if="passwordScore < 2">
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z" />
              </svg>
              <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16" v-else>
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </div>
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': submitted && passwordScore < 2 }"
              id="newPassword"
              maxlength="32"
              v-model="newPassword"
              required
            />
            <label for="newPassword">New Password</label>
            <div v-show="submitted && passwordScore < 2" class="invalid-feedback">Password strength indicator must be green</div>
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': submitted && !passwordsMatch }"
              id="newPasswordConfirm"
              maxlength="32"
              v-model="newPasswordConfirm"
              required
            />
            <label for="newPasswordConfirm">Confirm New Password</label>
            <div v-show="submitted && !passwordsMatch" class="invalid-feedback">Passwords must match</div>
          </div>
        </form>
        <div class="text-right">
          <router-link to="reset-password" class="text-link">Forgot Password?</router-link>
        </div>
      </template>
      <div class="buttons">
        <button class="btn btn-outline inverted" @click="close()" v-if="changedSuccessfully">FINISH</button>
        <template v-else>
          <button class="btn btn-outline mr-3" @click="close()">CANCEL</button>
          <button class="btn btn-outline inverted" @click="update()">
            <span v-show="!saving">SUBMIT</span>
            <Spinner v-show="saving" />
          </button>
        </template>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.change-password-component {
  display: flex;
  flex-direction: column;
  max-height: $modalHeight;
  padding: 0;

  @include scrollbar;

  .header {
    background: $brand-color-1;
    padding: 10px 20px;

    .title {
      font-size: 1rem;
      font-style: normal;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }

    .close-button {
      background: transparent;
      position: absolute;
      right: 15px;
      top: 10px;
      width: 20px;
      height: 20px;
      opacity: 1;
      border: none;

      &:before,
      &:after {
        background: #fff;
        position: absolute;
        top: 0;
        left: 10px;
        content: " ";
        height: 20px;
        width: 2px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:focus {
        outline: none;
      }
    }
  }

  .box {
    background: $brand-color-4;
    text-align: center;
    padding: 20px;

    svg {
      fill: none;

      .path {
        stroke: $brand-color-1;
        stroke-width: 2px;
      }
    }

    .message {
      font-weight: 600;
      color: $brand-color-2;
      margin-top: 12px;

      &::after {
        content: "";
        display: block;
        width: 90px;
        margin: 12px auto 0 auto;
        border-top: 1px solid $brand-color-1;
      }
    }
  }

  .form-container {
    padding: 30px;

    form {
      .form-control + label {
        background: $brand-color-2;
        font-weight: 600;
        color: #fff;
      }

      #newPassword {
        padding-right: 35px;

        &.is-invalid {
          background-image: none;
        }
      }

      #passwordStrengthIndicator {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 11px;
        right: 10px;
        width: 13px;
        height: 13px;
        color: #fff;
        border-radius: 50%;

        &[data-score="0"] {
          background: #ff4500;
          // background: darkred;
        }

        &[data-score="1"] {
          background: orange;
          // background: #ff4500;
        }

        &[data-score="2"] {
          background: green;
          // background: orange;
        }

        // &[data-score="3"] {
        //   background: #9acd32;
        // }

        // &[data-score="4"] {
        //   background: green;
        // }
      }
    }
  }

  .text-link {
    font-style: normal;
    font-weight: 600;
  }

  .buttons {
    margin-top: 30px;

    button {
      min-width: 90px;
    }
  }
}
</style>

<script>
import zxcvbn from "zxcvbn";
import { mapActions } from "vuex";
import { adminService, alertService } from "@/services";
import CloseButton from "@/components/CloseButton";

export default {
  name: "ChangePassword",
  components: {
    CloseButton,
  },
  data() {
    return {
      saving: false,
      submitted: false,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      passwordsMatch: false,
      passwordScore: 0,
      changedSuccessfully: false,
    };
  },
  methods: {
    ...mapActions("alert", ["error"]),
    close() {
      this.$emit("close");
    },
    update() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
        return true;
      } else {
        return false;
      }
    },
    handleSubmit() {
      this.submitted = true;
      const { oldPassword, newPassword, newPasswordConfirm, passwordsMatch, passwordScore } = this;

      if (oldPassword && newPassword && newPasswordConfirm && passwordsMatch && passwordScore >= 2) {
        this.saving = true;
        adminService
          .changePassword({
            oldPassword: oldPassword,
            newPassword: newPassword,
          })
          .then((data) => {
            this.saving = false;
            if (data.result) {
              this.changedSuccessfully = true;
            } else {
              alertService.showErrorAlert(data.message);
            }
          })
          .catch((error) => {
            this.saving = false;
            alertService.showErrorAlert(error);
          });
      }
    },
  },
  watch: {
    newPassword(value) {
      this.passwordsMatch = this.newPasswordConfirm == value;
      this.passwordScore = zxcvbn(value).score;
    },
    newPasswordConfirm(value) {
      this.passwordsMatch = this.newPassword == value;
    },
  },
};
</script>
