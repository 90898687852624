<template>
  <div class="behaviour-component screen">
    <SheetVehicleFeatureHeader title="Behaviour" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Behaviour',
        subTitle: '',
        tooltipScreenName: 'behaviour',
      }"
      @close="beforeClose()"
    />
    <SmoothReflow>
      <Alert />
      <div class="panel metrics">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="block">
                <svg width="30" height="30" viewBox="0 0 17.654 31.594" class="icon">
                  <path
                    d="M17.654,8.827c0-2.434-0.99-4.64-2.588-6.239S11.261,0,8.827,0s-4.64,0.99-6.239,2.588S0,6.394,0,8.827
	c0,1.391,0.324,2.707,0.901,3.878s1.405,2.196,2.414,3.006v7.428v7.428c0,0.101,0.031,0.197,0.084,0.278s0.131,0.146,0.224,0.184
	c0.093,0.039,0.193,0.047,0.288,0.029c0.095-0.019,0.185-0.065,0.257-0.137l2.197-2.197l2.197-2.197l2.461,2.46l2.461,2.46
	c0.048,0.048,0.104,0.084,0.164,0.109s0.125,0.037,0.19,0.037c0.032,0,0.065-0.003,0.097-0.01s0.064-0.016,0.095-0.029
	c0.093-0.039,0.17-0.104,0.224-0.184s0.084-0.177,0.084-0.278v-7.691v-7.691c1.009-0.81,1.838-1.835,2.414-3.006
	S17.654,10.219,17.654,8.827z M13.338,29.887l-2.211-2.21l-2.211-2.21c-0.098-0.098-0.226-0.146-0.354-0.146
	s-0.256,0.049-0.354,0.146l-1.947,1.947l-1.947,1.947v-6.48V16.4c0.661,0.395,1.377,0.709,2.133,0.924s1.554,0.33,2.379,0.33
	c0.825,0,1.622-0.116,2.378-0.33s1.472-0.528,2.133-0.923v6.743V29.887z M8.827,16.654c-2.158,0-4.115-0.878-5.532-2.295
	S1,10.985,1,8.827s0.878-4.115,2.295-5.532S6.67,1,8.827,1s4.114,0.878,5.531,2.295s2.295,3.374,2.295,5.532
	s-0.878,4.115-2.295,5.532S10.985,16.654,8.827,16.654z"
                  />
                </svg>
                <div class="metric">
                  Rating
                  <div class="score">{{ currentRating }}</div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="block">
                <svg width="30" height="30" viewBox="0 0 26.519 27.394" class="icon mr-2">
                  <path
                    d="M18.795,12.82c1-0.024,1.917-0.206,2.743-0.546s1.562-0.838,2.202-1.493c1.435-1.47,2.131-3.458,2.468-5.105
	s0.314-2.952,0.312-3.058c-0.003-0.136-0.061-0.258-0.151-0.346S26.154,2.13,26.018,2.13h-2.14h-2.14
	c0.014-0.268,0.024-0.537,0.031-0.809S21.78,0.776,21.78,0.5c0-0.138-0.056-0.263-0.146-0.354S21.418,0,21.28,0h-8.059H5.163
	C5.025,0,4.9,0.056,4.809,0.147S4.663,0.362,4.663,0.5c0,0.276,0.004,0.549,0.011,0.821S4.691,1.863,4.705,2.13H2.603H0.501
	c-0.136,0-0.259,0.054-0.349,0.142s-0.147,0.21-0.151,0.346C-0.002,2.725-0.025,4.03,0.312,5.677s1.032,3.635,2.467,5.105
	c0.631,0.646,1.356,1.139,2.169,1.478s1.714,0.525,2.697,0.557c0.69,1.105,1.477,2,2.338,2.643c0.86,0.643,1.794,1.033,2.776,1.127
	v2.254v2.254h-2.004H8.752c-0.105,0-0.205,0.033-0.288,0.091s-0.147,0.141-0.183,0.24l-0.95,2.65l-0.95,2.65
	c-0.027,0.077-0.035,0.157-0.025,0.236s0.04,0.154,0.086,0.221c0.047,0.066,0.108,0.119,0.179,0.156s0.149,0.056,0.231,0.056h6.407
	h6.407c0.082,0,0.16-0.02,0.231-0.056s0.132-0.089,0.179-0.156c0.047-0.067,0.076-0.143,0.086-0.221s0.002-0.159-0.025-0.236
	l-0.952-2.65l-0.952-2.65c-0.036-0.099-0.101-0.182-0.183-0.24s-0.182-0.091-0.288-0.091h-2.002H13.76v-2.257V16.58
	c0.966-0.109,1.885-0.504,2.732-1.144C17.338,14.795,18.114,13.91,18.795,12.82z M18.956,26.394h-5.697H7.562l0.771-2.15l0.771-2.15
	h2.068h2.068c0.003,0,0.007,0.001,0.01,0.002s0.006,0.002,0.01,0.002s0.007-0.001,0.01-0.002s0.006-0.002,0.01-0.002h2.066h2.066
	l0.772,2.15L18.956,26.394z M25.509,3.13c-0.027,0.644-0.127,1.849-0.482,3.16s-0.963,2.728-2.007,3.796
	c-0.476,0.487-1.021,0.867-1.627,1.144s-1.275,0.452-1.998,0.53c0.59-1.15,1.086-2.469,1.47-3.92s0.656-3.034,0.796-4.71h1.924
	H25.509z M3.498,10.086C2.454,9.018,1.846,7.601,1.492,6.29S1.038,3.774,1.01,3.13h1.886h1.886c0.14,1.675,0.412,3.256,0.795,4.706
	s0.879,2.768,1.468,3.917c-0.706-0.084-1.359-0.261-1.952-0.538S3.965,10.565,3.498,10.086z M8.411,12.151
	c-0.005-0.013-0.011-0.024-0.018-0.035s-0.014-0.022-0.021-0.034c-0.797-1.339-1.455-2.998-1.923-4.878S5.703,3.223,5.667,1h7.555
	h7.555c-0.066,4.056-0.934,7.713-2.296,10.356s-3.217,4.273-5.26,4.273c-0.913,0-1.789-0.328-2.599-0.926S9.065,13.235,8.411,12.151
	z"
                  />
                </svg>
                <div class="metric">
                  Goal
                  <div class="score">{{ currentGoal }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subheading">DRIVER SCORE PER PERIOD</div>
      <div class="panel graph">
        <transition name="fade">
          <BehaviourWeeklyBreakdown :index="selectedPeriodIndex" :periods="periods" @close="showWeeklyBreakdown = false" v-if="showWeeklyBreakdown" />
        </transition>
        <div class="block">
          <div class="legend">
            <div class="label">
              <span class="indicator scores" />
              Scores
            </div>
            <div class="label">
              <span class="indicator rating" />
              Rating
            </div>
            <div class="label">
              <span class="indicator goal" />
              Goal
            </div>
          </div>
          <line-chart :chart-data="chartData" :options="chartOptions" :styles="chartStyles" />
        </div>
      </div>
      <div class="subheading">
        <div>SET NEW GOAL</div>
        <div class="goal">
          <span>Goal</span>
          {{ currentNewGoal }}
        </div>
      </div>
      <div class="panel goal">
        <PanelSpinner v-if="loading" />
        <template v-for="(goal, i) in goals" v-else>
          <div class="item" :key="i">
            <div class="type">
              <div class="image">
                <img :src="goal.image" width="40" :alt="goal.name" />
              </div>
              <div class="name">{{ goal.name }}</div>
            </div>
            <div class="controls">
              <button class="slider-control" @click="decrement(goal)">
                <svg width="24" height="24" viewBox="0 0 16 16" class="icon" fill="currentColor">
                  <path fill-rule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z" />
                </svg>
              </button>
              <vue-slider class="goal-slider" v-model="goal.value" />
              <button class="slider-control" @click="increment(goal)">
                <svg width="24" height="24" viewBox="0 0 16 16" class="icon" fill="currentColor">
                  <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                  <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                </svg>
              </button>
            </div>
            <div class="value">{{ goal.value }}%</div>
          </div>
        </template>
      </div>
      <div class="buttons">
        <button class="btn btn-outline mr-3" @click="reset()">RESET</button>
        <button class="btn btn-outline" @click="setGoal()">
          <span v-show="!saving">SET GOAL</span>
          <Spinner v-show="saving" />
        </button>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.behaviour-component {
  .subheading {
    display: flex;
    justify-content: space-between;
    color: $brand-color-1;
    margin: 15px 0;

    .goal {
      color: $brand-color-2;
      text-transform: none;
    }
  }

  .panel {
    background: $brand-color-4;
    padding: 15px;

    .row {
      .col-md-4 {
        padding: 0 8px;
      }
    }

    &.metrics {
      .block {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        color: $brand-color-2;
        padding: 15px;
        box-shadow: $drop-shadow-light;

        .icon {
          fill: $brand-color-1;
        }

        .metric {
          color: $brand-color-2;

          .score {
            font-weight: bold;
          }
        }
      }
    }

    &.graph {
      .block {
        background: #fff;
        padding: 15px;
        box-shadow: $drop-shadow-light;

        .legend {
          display: flex;
          padding-left: 5px;
          margin-bottom: 20px;

          .label {
            display: flex;
            align-items: center;
            font-style: normal;
            color: $brand-color-2;
            margin-right: 15px;

            .indicator {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 6px;
              border-radius: 50%;
              border: 1px solid $brand-color-2;

              &::before {
                content: "";
                display: inline-block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 2px solid #fff;
              }

              &.scores::before {
                background: $brand-color-2;
              }

              &.rating::before {
                background: #d50000;
              }

              &.goal::before {
                background: #1db299;
              }
            }
          }
        }
      }
    }

    &.goal {
      .item {
        display: flex;
        align-items: center;
        background: #fff;
        text-align: center;
        padding: 8px 15px;
        margin-bottom: 15px;
        box-shadow: $drop-shadow-light;

        &:last-of-type {
          margin-bottom: 0;
        }

        .type {
          display: flex;
          flex-direction: row;
          align-items: center;

          .image {
            width: 50px;

            &::after {
              content: "";
              display: inline-block;
              height: 30px;
              margin-left: 5px;
              vertical-align: middle;
              border-right: 1px solid $red;
            }
          }

          .name {
            font-weight: 600;
            width: 100px;
            padding: 0 10px;
          }
        }

        .controls {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          .slider-control {
            background: none;
            width: 40px;
            outline: none;
            border: none;

            .icon {
              fill: $brand-color-1;
            }
          }

          .goal-slider {
            flex: 1;
            margin: 0 10px;
          }
        }

        .value {
          width: 60px;
        }
      }
    }
  }

  .buttons {
    margin-top: 15px;
  }
}

@media (max-width: $md) {
  .behaviour-component {
    .panel {
      &.goal {
        .item {
          flex-direction: column;
          padding: 15px;

          .type {
            width: 100%;

            .image {
              &::after {
                display: none;
              }
            }

            .name {
              width: auto;
              padding: 0;
            }
          }

          .controls {
            margin: 15px 0;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import VueSlider from "vue-slider-component";
import { alertService, assetService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import LineChart from "@/components/LineChart.js";
import BehaviourWeeklyBreakdown from "@/components/screens/BehaviourWeeklyBreakdown";
import styles from "@/assets/scss/_variables.scss";

export default {
  name: "Behaviour",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
    BehaviourWeeklyBreakdown,
    LineChart,
    VueSlider,
  },
  data() {
    return {
      loading: true,
      saving: false,
      rating: 0,
      goal: 0,
      newGoal: 0,
      periods: [],
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.openWeeklyBreakdownModal,
        layout: {
          padding: 0,
        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 5,
            borderWidth: 2,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontFamily: "Mont",
                fontColor: styles.brandColor1,
              },
              scaleLabel: {
                display: true,
                fontFamily: "Mont",
                fontColor: styles.brandColor1,
                labelString: "Week",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: "Mont",
                fontColor: styles.brandColor1,
                beginAtZero: true,
                stepSize: 20,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
      },
      chartStyles: {
        height: "200px",
      },
      allVehicleGoals: [],
      goals: [],
      goalsOriginal: [],
      selectedPeriodIndex: 0,
      showWeeklyBreakdown: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveSafeAndSoundVehicleId"]),
    activeAssetId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
    currentRating() {
      if (this.rating) {
        return Math.round(this.rating) + "%";
      }
      return "0%";
    },
    currentGoal() {
      if (this.goal) {
        return Math.round(this.goal) + "%";
      }
      return "0%";
    },
    currentNewGoal() {
      if (this.newGoal) {
        return Math.round(this.newGoal) + "%";
      }
      return "0%";
    },
  },
  created() {
    this.init();
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    init() {
      this.loading = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      const behaviourRequest = assetService.getVehicleBehaviour(vehicleId);
      const goalsRequest = assetService.getVehicleBehaviourGoals();
      const startTime = Date.now();

      Promise.all([behaviourRequest, goalsRequest])
        .then((responses) => {
          const timeElapsed = Date.now() - startTime;
          setTimeout(
            () => {
              this.allVehicleGoals = responses[1];
              this.goal = 0;
              this.allVehicleGoals.forEach((goal) => {
                if (goal.VehicleId == vehicleId) {
                  this.goal = goal.Goal;
                }
              });

              this.processResponseData(responses[0]);

              this.$nextTick(() => {
                this.newGoal = this.goal;
                this.loading = false;
              });
            },
            timeElapsed < 500 ? 500 - timeElapsed : 0
          );
        })
        .catch((errors) => {
          alertService.showErrorAlert();
          this.loading = false;
        });
    },
    processResponseData(data) {
      this.rating = data.Score;
      this.goals = [];

      const categories = data.Categories;
      categories.forEach((category) => {
        switch (category.Name) {
          case "Harsh Acceleration":
            this.goals.push({
              name: category.Name,
              image: require("@/assets/images/trips/harsh-acceleration.png"),
              value: Math.round(category.Score),
            });
            break;
          case "Harsh Braking":
            this.goals.push({
              name: category.Name,
              image: require("@/assets/images/trips/harsh-braking.png"),
              value: Math.round(category.Score),
            });
            break;
          case "Harsh Cornering":
            this.goals.push({
              name: category.Name,
              image: require("@/assets/images/trips/harsh-cornering.png"),
              value: Math.round(category.Score),
            });
            break;
          case "Impacts":
            this.goals.push({
              name: category.Name,
              image: require("@/assets/images/trips/impacts.png"),
              value: Math.round(category.Score),
            });
            break;
          case "Maximum Speed":
            this.goals.push({
              name: "Over Speeding",
              image: require("@/assets/images/trips/over-speeding.png"),
              value: Math.round(category.Score),
            });
            break;
        }
      });
      this.goalsOriginal = JSON.parse(JSON.stringify(this.goals));

      this.periods = data.Periods;
      let scoresPerPeriod = [];
      this.periods.forEach((period) => {
        scoresPerPeriod.push(Math.round(period.Score));
      });

      this.chartData = {
        labels: [...Array(scoresPerPeriod.length).keys()].map((i) => i + 1),
        datasets: [
          {
            label: "Score",
            backgroundColor: "transparent",
            borderColor: "#4CC3E1",
            pointBackgroundColor: "#fff",
            data: scoresPerPeriod,
          },
          {
            label: "Rating",
            backgroundColor: "transparent",
            borderColor: "#D50000",
            pointRadius: 0,
            data: [...Array(scoresPerPeriod.length)].map((i) => data.Score),
          },
          {
            label: "Goal",
            backgroundColor: "transparent",
            borderColor: "#1DB299",
            pointRadius: 0,
            data: [...Array(scoresPerPeriod.length)].map((i) => this.goal),
          },
        ],
      };
    },
    openWeeklyBreakdownModal(point, event) {
      if (event.length) {
        this.selectedPeriodIndex = event[0]._index;
        this.showWeeklyBreakdown = true;
      }
    },
    decrement(goal) {
      if (goal.value > 0) {
        goal.value--;
      }
    },
    increment(goal) {
      if (goal.value < 100) {
        goal.value++;
      }
    },
    reset() {
      this.goals = JSON.parse(JSON.stringify(this.goalsOriginal));
      this.$nextTick(() => {
        this.newGoal = this.goal;
      });
    },
    setGoal() {
      this.saving = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
        .updateVehicleBehaviourGoal(vehicleId, this.newGoal)
        .then((result) => {
          this.saving = false;
          if (result) {
            this.init();
          } else {
            alertService.showErrorAlert();
          }
        })
        .catch((error) => {
          this.saving = false;
          if (error.userMessage) {
            alertService.showErrorAlert(error);
          }
        });
    },
  },
  watch: {
    activeAssetId() {
      this.init();
    },
    goals: {
      deep: true,
      handler() {
        let total = 0;
        this.goals.forEach((goal) => {
          total += goal.value;
        });
        this.newGoal = Math.round(total / this.goals.length);
      },
    },
  },
};
</script>
