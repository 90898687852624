<template>
  <div class="early-warning-component screen" :class="{ desktop: !$isMobile }">
    <SheetVehicleFeatureHeader title="Early Warning" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Early Warning',
        subTitle: '',
        tooltipScreenName: 'early-warning',
      }"
      @close="beforeClose()"
    />
    <SmoothReflow class="content">
      <Alert />
      <div class="panel">
        <PanelSpinner v-if="loading" />
        <div class="container" v-else>
          <template v-if="notSupported">
            <ErrorBox message="This vehicle does not support this feature." />
          </template>
          <template v-else>
            <div class="row align-items-center text-center text-md-left">
              <div class="col-5 p-0">
                <div class="d-flex align-items-center justify-content-center justify-content-md-end">
                  <img src="@/assets/images/early-warning-disarm.svg" class="alert-icon" width="14" height="24" alt />
                  <span class="ml-3">Disarm</span>
                </div>
              </div>
              <div class="col-2 text-center p-0 pl-2">
                <Spinner v-if="processing" />
                <div class="custom-control custom-switch" v-else>
                  <input type="checkbox" class="custom-control-input" id="toggle" v-model="isArmed" :disabled="processing" @click="toggle()" />
                  <label class="custom-control-label" for="toggle" />
                </div>
              </div>
              <div class="col-5 p-0">
                <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                  <span class="mr-3">Arm</span>
                  <img src="@/assets/images/early-warning-arm.svg" class="alert-icon" width="14" height="24" alt />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.early-warning-component {
  &.desktop {
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }
  }

  .content {
    .panel {
      background: $brand-color-4;
      padding: 20px;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { alertService, assetService, modalService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";

export default {
  name: "EarlyWarning",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
  },
  data() {
    return {
      loading: true,
      processing: false,
      notSupported: false,
      armed: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveSafeAndSoundVehicleId", "getActiveVehicleIsEarlyWarning", "getActiveVehicleIsArmed"]),
    activeAssetId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
    isArmed() {
      return this.getActiveVehicleIsArmed();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations("asset", ["updateVehicleArmedStatus"]),
    beforeClose() {
      this.$emit("close");
    },
    init() {
      if (this.getActiveVehicleIsEarlyWarning()) {
        this.loading = true;
        this.notSupported = false;
        const vehicleId = this.getActiveSafeAndSoundVehicleId();
        const startTime = Date.now();
        assetService
          .isVehicleArmed(vehicleId)
          .then((result) => {
            const timeElapsed = Date.now() - startTime;
            setTimeout(
              () => {
                this.updateVehicleArmedStatus({
                  vehicleId: vehicleId,
                  status: result,
                });
                this.loading = false;
              },
              timeElapsed < 500 ? 500 - timeElapsed : 0
            );
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.notSupported = true;
      }
    },
    toggle() {
      this.processing = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      const updatedArmedValue = !this.isArmed;

      assetService
        .updateVehicleArmedStatus(vehicleId, updatedArmedValue)
        .then((result) => {
          this.processing = false;
          if (result) {
            this.updateVehicleArmedStatus({
              vehicleId: vehicleId,
              status: updatedArmedValue,
            });
          } else {
            alertService.showErrorAlert();
          }
        })
        .catch((error) => {
          this.processing = false;
          alertService.showErrorAlert();
        });
    },
  },
  watch: {
    activeAssetId() {
      this.init();
    },
  },
};
</script>