import { api } from "@/services/api.service";

export const publicApiService = {
  getSystemNotification,
};

const apiBasePath = "/public/api";

function getSystemNotification() {
  return api.get(`${apiBasePath}/get-system-notification`).then((request) => {
    const message = request.data.message;
    if (message) {
      const timestamp = request.data.timestamp;
      const timestampSavedInLocalStorage = localStorage.getItem("nt");
      if (
        !timestampSavedInLocalStorage ||
        timestampSavedInLocalStorage !== timestamp
      ) {
        localStorage.setItem("nt", timestamp);
        return message;
      }
    }
    return "";
  });
}
