<template>
  <div class="documents-component screen">
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'My Admin',
        subTitle: 'Documents',
      }"
      @close="beforeClose()"
    />
    <div class="panel">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="block" @click="getLatestStatement()">
              <div class="icon">
                <Spinner large v-if="latestStatementDownloading && !$isIOS" />
                <img src="~@/assets/images/statement.svg" alt="Latest Statement" v-else />
              </div>
              <div class="title">
                LATEST
                <br />
                <span>STATEMENT</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="block" @click="getLatestInvoice()">
              <div class="icon">
                <Spinner large v-if="latestInvoiceDownloading && !$isIOS" />
                <img src="~@/assets/images/invoice.svg" alt="Latest Invoice" v-else />
              </div>
              <div class="title">
                LATEST
                <br />
                <span>INVOICE</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="block" @click="getAccountSummary()">
              <div class="icon">
                <Spinner large v-if="accountSummaryDownloading && !$isIOS" />
                <img src="~@/assets/images/account-summary.svg" alt="Account Summary" v-else />
              </div>
              <div class="title">
                ACCOUNT
                <br />
                <span>SUMMARY</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-12 col-md-6">
            <div class="block">
              <div class="icon">
                <img src="~@/assets/images/contract.svg" alt="Your Contract" />
              </div>
              <div class="title">
                YOUR
                <br />
                <span>CONTRACT</span>
              </div>
              <div class="footer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  class="download-icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.documents-component {
  .panel {
    background: $brand-color-4;
    padding: 30px 15px 0 15px;

    .row {
      .col-12 {
        margin-bottom: 30px;
      }
    }

    .block {
      background: #fff;
      text-align: center;
      padding-top: 15px;
      box-shadow: $drop-shadow;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        img {
          height: 80%;
        }
      }

      .title {
        color: $brand-color-2;
        margin-top: 5px;

        span {
          font-weight: bold;
        }
      }

      .footer {
        background: $brand-color-2;
        position: relative;
        bottom: -5px;
        width: 60px;
        height: 30px;
        padding-top: 3px;
        margin: 0 auto;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;

        .download-icon {
          stroke: #fff;
        }
      }

      &:hover {
        background: $brand-color-3;
        cursor: pointer;

        .title {
          color: $brand-color-1;
        }

        .footer {
          background: $brand-color-1;
        }
      }
    }
  }
}
</style>

<script>
import { downloadService } from "@/services";
import { helper } from "@/helper";

export default {
  name: "Documents",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
  },
  data() {
    return {
      latestStatementDownloading: false,
      latestInvoiceDownloading: false,
      accountSummaryDownloading: false,
    };
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    getLatestStatement() {
      if (!this.latestStatementDownloading) {
        this.latestStatementDownloading = true;
        downloadService
          .getLatestStatement()
          .then((data) => {
            this.latestStatementDownloading = false;
            helper.saveToBrowser("Latest Statement.pdf", data);
          })
          .catch((error) => {});
      }
    },
    getLatestInvoice() {
      if (!this.latestInvoiceDownloading) {
        this.latestInvoiceDownloading = true;
        downloadService
          .getLatestInvoice()
          .then((data) => {
            this.latestInvoiceDownloading = false;
            helper.saveToBrowser("Latest Invoice.pdf", data);
          })
          .catch((error) => {});
      }
    },
    getAccountSummary() {
      if (!this.accountSummaryDownloading) {
        this.accountSummaryDownloading = true;
        downloadService
          .getAccountSummary()
          .then((data) => {
            this.accountSummaryDownloading = false;
            helper.saveToBrowser("Account Summary.pdf", data);
          })
          .catch((error) => {});
      }
    },
  },
};
</script>