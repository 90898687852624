<template>
  <div class="certificates-component screen">
    <SheetVehicleFeatureHeader title="Certificates" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Certificates',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <div class="panel">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="block" @click="getFitmentCertificate()">
              <div class="icon">
                <Spinner large v-if="fitmentCertificateDownloading && !$isIOS" />
                <img src="~@/assets/images/certificate.svg" alt="Fitment Certificate" v-else />
              </div>
              <div class="title">
                FITMENT
                <br />
                <span>CERTIFICATE</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="block" @click="getTestCertificate()">
              <div class="icon">
                <Spinner large v-if="testCertificateDownloading && !$isIOS" />
                <img src="~@/assets/images/certificate.svg" alt="Test Certificate" v-else />
              </div>
              <div class="title">
                TEST
                <br />
                <span>CERTIFICATE</span>
              </div>
              <div class="footer">
                <svg width="20" height="20" viewBox="0 0 15.34 12.34" class="download-icon">
                  <g>
                    <polyline style="fill: none; stroke-width: 1.5" points="14.59,5.94 14.59,11.59 0.75,11.59 0.75,5.94" />
                    <polyline style="fill: none; stroke-width: 1.5" points="10.07,5.94 7.67,8.34 5.26,5.94" />
                    <line style="fill: none; stroke-width: 1.5" x1="7.67" y1="8.34" x2="7.67" y2="0.75" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.certificates-component {
  .panel {
    background: $brand-color-4;
    padding: 30px 15px;

    .row {
      .col-12 {
        margin-bottom: 30px;
      }
    }

    .block {
      background: #fff;
      text-align: center;
      padding-top: 15px;
      box-shadow: $drop-shadow;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        img {
          height: 80%;
        }
      }

      .title {
        color: $brand-color-2;
        margin-top: 5px;

        span {
          font-weight: bold;
        }
      }

      .footer {
        background: $brand-color-2;
        position: relative;
        bottom: -7px;
        width: 60px;
        height: 30px;
        padding-top: 3px;
        margin: 0 auto;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;

        .download-icon {
          stroke: #fff;
        }
      }

      &:hover {
        background: $brand-color-3;
        cursor: pointer;

        .title {
          color: $brand-color-1;
        }

        .footer {
          background: $brand-color-1;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import { downloadService } from "@/services";
import { helper } from "@/helper";

export default {
  name: "Certificates",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
  },
  data() {
    return {
      fitmentCertificateDownloading: false,
      testCertificateDownloading: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveVehicleId", "getActiveVehicleRegistrationNumber"]),
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    getFitmentCertificate() {
      if (!this.fitmentCertificateDownloading) {
        this.fitmentCertificateDownloading = true;
        const vehicleId = this.getActiveVehicleId();
        const vehicleRegistration = this.getActiveVehicleRegistrationNumber();
        downloadService
          .getFitmentCertificate(vehicleId)
          .then((data) => {
            this.fitmentCertificateDownloading = false;
            helper.saveToBrowser("Fitment Certificate (" + vehicleRegistration + ").pdf", data);
          })
          .catch((error) => {});
      }
    },
    getTestCertificate() {
      if (!this.testCertificateDownloading) {
        this.testCertificateDownloading = true;
        const vehicleId = this.getActiveVehicleId();
        const vehicleRegistration = this.getActiveVehicleRegistrationNumber();
        downloadService
          .getTestCertificate(vehicleId)
          .then((data) => {
            this.testCertificateDownloading = false;
            helper.saveToBrowser("Test Certificate (" + vehicleRegistration + ").pdf", data);
          })
          .catch((error) => {});
      }
    },
  },
};
</script>