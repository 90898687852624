<template>
  <div class="financial-details-component screen">
    <UpgradeStepHeader
      class="upgrade-step-header"
      :stepNo="3"
      title="Confirm Financial Details"
      :nextStepScreenName="nextStepScreenName"
      :showNextButton="passwordConfirmed && formIsInitialised"
      :showSpinner="passwordConfirmed && !formIsInitialised"
      :formValidationFailureCount="formValidationFailureCount"
      :stepCompleted="upgradeStepCompleted"
      @submit="update()"
      @close="beforeClose()"
      v-if="upgradeInProgress()"
    />
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'My Admin',
        subTitle: 'Financial Details',
      }"
      @close="beforeClose()"
      v-else
    />
    <SmoothReflow class="content">
      <Alert />
      <template v-if="passwordConfirmed">
        <ErrorBox
          message="Financial details not found."
          class="mt-4 mb-5"
          v-if="noRecordsFound"
        />
        <form ref="mainForm" @submit.prevent v-else>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="accountHolder"
                  v-model="model.AccountHolder"
                  :disabled="!editing"
                  required
                />
                <label for="accountHolder">Account Holder</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <v-select
                  class="form-control"
                  id="bank"
                  @input="bankChange"
                  v-model="model.Bank"
                  :options="banks"
                  :reduce="(item) => item.value"
                  :disabled="!editing"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!model.Bank"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <label for="bank">Bank Name</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="accountNumber"
                  v-model="model.AccountNumber"
                  :disabled="!editing"
                  required
                />
                <label for="accountNumber">Account Number</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <v-select
                  class="form-control"
                  :class="{ loading: !bankBranches.length }"
                  id="bankBranch"
                  v-model="model.Branch"
                  :options="bankBranches"
                  :reduce="(item) => item.value"
                  :disabled="!editing"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!model.Branch"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <label for="bankBranch">Branch</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <v-select
                  class="form-control"
                  id="accountType"
                  v-model="model.AccountType"
                  :options="bankAccountTypes"
                  :reduce="(item) => parseInt(item.value)"
                  :disabled="!editing"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!model.AccountType"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <label for="accountType">Account Type</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <v-select
                  class="form-control"
                  id="debitOrderDate"
                  v-model="model.DebitOrderDay"
                  :options="debitOrderDays"
                  :reduce="(item) => item.value"
                  :disabled="!editing"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!model.DebitOrderDay"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <label for="debitOrderDate">Debit Order Date</label>
              </div>
            </div>
          </div>
          <div class="row otp-container" v-show="editing">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <div class="icon">
                <img
                  src="~@/assets/images/mobile-phone.svg"
                  width="22"
                  height="22"
                />
              </div>
              <div class="form-text">
                Once you've updated your details, please enter the OTP that has
                been sent to your mobile number (******{{ otpCellNumber }}) to
                confirm.
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  maxlength="5"
                  class="form-control"
                  id="otp"
                  v-model="otp"
                  :disabled="!editing"
                  required
                />
                <label for="otp">One-Time Pin</label>
              </div>
              <div class="text-right mt-3">
                <button
                  class="btn btn-outline"
                  v-show="editing"
                  @click="sendOTP()"
                >
                  RESEND OTP
                </button>
              </div>
            </div>
          </div>
        </form>
        <div
          class="buttons"
          :class="{ editing: editing }"
          v-if="!upgradeInProgress()"
        >
          <StatusIndicator
            class="mr-3"
            :success="submitSuccess"
            :error="submitError"
          />
          <button class="btn btn-outline mr-3" @click="cancel()">CANCEL</button>
          <button
            class="btn btn-outline"
            :disabled="loading || noRecordsFound"
            @click="edit()"
            v-if="!editing"
          >
            <span v-show="!loading">EDIT</span>
            <Spinner v-show="loading" />
          </button>
          <button
            class="btn btn-outline"
            :disabled="saving"
            @click="update()"
            v-else
          >
            <span v-show="!saving">UPDATE</span>
            <Spinner v-show="saving" />
          </button>
        </div>
      </template>
      <template v-else>
        <form id="confirmForm" ref="confirmForm" @submit.prevent>
          <div class="form-group row text-right">
            <label
              for="inputConfirmPassword"
              class="col-sm-4 col-form-label text-left"
              >Confirm Password</label
            >
            <div class="col-sm-5 mb-3 mb-md-0">
              <input
                type="password"
                autocomplete="off"
                class="form-control"
                id="inputConfirmPassword"
                v-model="password"
                required
              />
            </div>
            <div class="col-sm-3">
              <button
                class="btn btn-outline"
                :disabled="confirming"
                @click="confirm()"
              >
                <span v-show="!confirming">CONFIRM</span>
                <Spinner v-show="confirming" />
              </button>
            </div>
          </div>
        </form>
      </template>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.financial-details-component {
  .content {
    padding: 0 20px;

    #confirmForm {
      button {
        min-width: 100px;
      }
    }

    .form-control:not(input[type="password"]) {
      text-transform: uppercase;
    }

    .otp-container {
      align-items: center;
      padding: 15px 0;
      margin: 5px 0 20px 0;
      border: 1px solid $brand-color-2;

      .icon {
        background: #fff;
        position: absolute;
        top: 50%;
        left: -19px;
        padding: 7px;
        transform: translateY(-50%);
        border-radius: 50%;
        border: 1px solid $brand-color-2;
      }

      .form-group,
      .form-control + label {
        margin-bottom: 0;
      }

      .form-text {
        font-size: 0.75rem;
        margin-left: 20px;
      }
    }

    .buttons {
      justify-content: flex-end;

      button {
        min-width: 90px;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  adminService,
  alertService,
  modalService,
  utilityService,
} from "@/services";
import StatusIndicator from "@/components/StatusIndicator";
import UpgradeStepHeader from "@/components/screens/UpgradeStepHeader";

export default {
  name: "FinancialDetails",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    StatusIndicator,
    UpgradeStepHeader,
  },
  data() {
    return {
      loading: true,
      confirming: false,
      editing: false,
      saving: false,
      submitSuccess: false,
      submitError: false,
      unsavedChanges: false,
      formValidationFailureCount: 0,
      upgradeStepCompleted: false,
      formIsInitialised: false,
      passwordConfirmed: false,
      password: "",
      noRecordsFound: false,
      model: {
        AccountHolder: "",
        AccountNumber: "",
        AccountType: "",
        Bank: "",
        BankAccountId: "",
        Branch: "",
        DebitOrderDay: "",
        PaymentMethod: "",
      },
      modelUnedited: {},
      otp: "",
      otpCellNumber: "",
      banks: [],
      bankBranches: [],
      bankAccountTypes: [],
      debitOrderDays: [
        {
          label: "1st day of month",
          value: 1,
        },
        {
          label: "25th day of month",
          value: 25,
        },
      ],
    };
  },
  created() {
    if (this.upgradeInProgress()) {
      if (this.$isDevEnv) {
        this.editing = true;
      } else {
        this.edit();
      }
    }
  },
  computed: {
    ...mapGetters("asset", {
      upgradeInProgress: "getUpgradeInProgress",
      activeVehicleProductName: "getActiveVehicleProductName",
    }),
    isNano() {
      return this.activeVehicleProductName() === "Nano";
    },
    nextStepScreenName() {
      return this.isNano
        ? "fitment-address-for-upgrade"
        : "terms-and-conditions-for-upgrade";
    },
  },
  methods: {
    beforeClose() {
      if (this.unsavedChanges) {
        modalService.showSaveDialog(
          this,
          "Do you want to save the changes made to your financial details before leaving?",
          this.update
        );
      } else {
        this.$emit("close");
      }
    },
    initForm() {
      const banksRequest = utilityService.getBanks();
      const bankAccountTypesRequest = utilityService.getBankAccountTypes();
      const financialDetailsRequest = adminService.getFinancialDetails();
      Promise.all([
        banksRequest,
        bankAccountTypesRequest,
        financialDetailsRequest,
      ])
        .then((responses) => {
          if (responses[2] == null && !this.upgradeInProgress()) {
            this.noRecordsFound = true;
          } else {
            this.banks = responses[0];
            this.bankAccountTypes = responses[1];

            if (responses[2] != null) {
              this.model = responses[2];
              this.modelUnedited = JSON.parse(JSON.stringify(this.model));
            }

            if (this.model.Bank) {
              utilityService
                .getBankBranches(this.model.Bank)
                .then((data) => {
                  this.bankBranches = data;
                })
                .catch(() => {});
            }
          }

          this.loading = false;
          this.formIsInitialised = true;
        })
        .catch((errors) => {
          alertService.showErrorAlert(errors);
        });
    },
    bankChange(bankId) {
      utilityService.getBankBranches(bankId).then((data) => {
        this.bankBranches = data;
        this.model.Branch = "";
      });
    },
    edit() {
      this.sendOTP()
        .then((data) => {
          this.editing = true;
        })
        .catch((error) => {});
    },
    sendOTP() {
      return new Promise((resolve, reject) => {
        adminService
          .sendOTP()
          .then((data) => {
            if (data.Result) {
              this.otpCellNumber = data.CellNumber;
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => reject(error));
      });
    },
    cancel() {
      if (this.editing) {
        this.editing = false;
        this.model = this.modelUnedited;
      } else {
        this.beforeClose();
      }
    },
    confirm() {
      if (this.$refs.confirmForm.reportValidity()) {
        this.handleConfirmPassword();
      }
    },
    update() {
      if (this.$refs.mainForm.reportValidity()) {
        this.handleSubmit();
        return true;
      } else {
        alertService.showErrorAlert("Please fill in missing fields.");
        this.formValidationFailureCount++;
        return false;
      }
    },
    handleConfirmPassword() {
      this.confirming = true;
      adminService
        .verifyPassword(this.password)
        .then((result) => {
          if (result) {
            this.passwordConfirmed = true;
            this.initForm();
          }
          this.password = "";
          this.confirming = false;
        })
        .catch((error) => {
          this.confirming = false;
          if (error.userMessage) {
            alertService.showErrorAlert(error);
          }
        });
    },
    handleSubmit() {
      if (this.$isDevEnv && this.upgradeInProgress()) {
        this.upgradeStepCompleted = true;
        return;
      }

      const { model, otp } = this;

      if (model && otp) {
        this.saving = true;
        adminService
          .updateFinancialDetails(model, otp)
          .then((result) => {
            this.editing = false;
            this.saving = false;
            if (result) {
              if (this.upgradeInProgress()) {
                try {
                  this.$gtag.event("upgrade_financial_details");
                } catch (err) {}

                this.upgradeStepCompleted = true;
              }

              this.unsavedChanges = false;
              this.submitSuccess = true;
              setTimeout(() => {
                this.submitSuccess = false;
              }, 3000);

              if (this.$isMobile) {
                modalService.showSuccessDialog(
                  "Financial Details updated successfully."
                );
              } else {
                alertService.showSuccessAlert();
              }
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.saving = false;
            this.submitError = true;
            setTimeout(() => {
              this.submitError = false;
            }, 3000);
            if (error.userMessage) {
              alertService.showErrorAlert(error);
            }
          });
      }
    },
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.unsavedChanges =
          JSON.stringify(this.model) !== JSON.stringify(this.modelUnedited);
      },
    },
  },
};
</script>
