<template>
  <div class="modal-component">
    <FocusTrap :disabled="!active">
      <div ref="modal" id="modal" @scroll.passive="handleScroll">
        <div role="dialog" aria-modal="true">
          <slot />
        </div>
        <resize-observer @notify="handleResize" />
      </div>
      <div class="blur" v-show="!scrolledToBottom" />
    </FocusTrap>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.modal-component {
  position: relative;
  max-width: 100%;

  #modal {
    background: #fff;
    position: relative;
    max-height: $modalHeight;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: $omni-shadow-brand-color;
  }

  .blur {
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: calc(100% - 4px);
    height: 50px;
  }
}

@include scrollbar;

@media (max-width: 767px) {
  .modal-component {
    #modal {
      max-height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100);
    }
  }
}
</style>

<script>
import FocusTrap from "vue-focus-lock";

export default {
  name: "Modal",
  components: {
    FocusTrap,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrolledToBottom: false,
    };
  },
  methods: {
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 10;
    },
    handleResize({ width, height }) {
      this.scrolledToBottom = this.$refs.modal.clientHeight === this.$refs.modal.scrollHeight;
    },
  },
};
</script>
