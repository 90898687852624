<template>
  <div class="trips-component screen">
    <template v-if="$isMobile">
      <SheetVehicleFeatureHeader title="Trips" v-if="showSearchTopUI" />
    </template>
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Trips',
        subTitle: '',
        isLeftDrawer: true,
        tooltipScreenName: 'trips',
      }"
      @close="beforeClose()"
    />
    <Alert />
    <template v-if="showSearchTopUI">
      <div class="heading">SEARCH TRIPS</div>
      <div class="search-top">
        <div class="date-pickers">
          <DatePicker label="FROM" v-model="fromDate" />
          <DatePicker label="TO" :minDate="fromDate" v-model="toDate" />
        </div>
        <div class="buttons">
          <button
            class="btn btn-outline"
            :disabled="processing"
            @click="getTripsByDate()"
          >
            SEARCH
          </button>
          <div class="export">
            <Spinner v-if="downloadingLogbook && !$isIOS" />
            <template v-else>
              <img
                src="~@/assets/images/pdf.svg"
                width="35"
                height="30"
                alt="PDF"
                @click="
                  downloadLogbook(
                    fromDate + 'T00:00:00',
                    toDate + 'T23:59:59',
                    'pdf'
                  )
                "
              />
              <img
                src="~@/assets/images/xls.svg"
                width="35"
                height="30"
                alt="XLS"
                @click="
                  downloadLogbook(
                    fromDate + 'T00:00:00',
                    toDate + 'T23:59:59',
                    'excel'
                  )
                "
              />
            </template>
          </div>
        </div>
      </div>
    </template>
    <div class="search-results">
      <template v-if="showSearchTopUI">
        <div class="search-results-heading" v-if="searchResults">
          {{ trips.length }} Trips between <span>{{ fromDate }}</span> and
          <span>{{ toDate }}</span>
        </div>
        <div class="search-results-heading" v-else>Last 10 Trips</div>
      </template>
      <SmoothReflow :class="{ 'smooth-reflow-custom': !showSearchTopUI && $isMobile }">
        <PanelSpinner v-if="loading" />
        <template v-else>
          <div class="position-relative" v-if="$isMobile">
            <div class="top-nav" v-if="!showSearchTopUI">
              <div class="back" @click="back">
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
                BACK
              </div>
            </div>
            <div class="panel" v-if="$isMobile">
              <div class="single-trip-view" v-if="selectedTrip">
                <div class="trip-type">
                  <Spinner
                    v-if="
                      savingTripType && savingTripTypeId === selectedTrip.Id
                    "
                  />
                  <span
                    :class="{ business: selectedTrip.IsBusiness }"
                    @click="toggleTripType(activeTripKey, selectedTrip)"
                    v-else
                    >{{ selectedTrip.IsBusiness ? "B" : "P" }}</span
                  >
                </div>
                <div class="label">Trip Start</div>
                <span class="font-weight-bold"
                  >{{ getDate(selectedTrip.Start.DateTime) }} </span
                ><span>{{ getTime(selectedTrip.Start.DateTime) }}</span>
                <div class="address mb-2">
                  {{ selectedTrip.Start.GeoAddress }}
                </div>
                <div class="label">Trip End</div>
                <span class="font-weight-bold"
                  >{{ getDate(selectedTrip.End.DateTime) }} </span
                ><span>{{ getTime(selectedTrip.End.DateTime) }}</span>
                <div class="address">{{ selectedTrip.End.GeoAddress }}</div>
                <div class="trip-stats">
                  <div class="row">
                    <div class="col-6">
                      <div class="item mb-2">
                        <img
                          src="~@/assets/images/trips/stats/distance.svg"
                          width="13"
                          height="19"
                          alt="Distance"
                        />
                        <div>
                          <span class="font-weight-bold"
                            >Distance: <br class="newline"/></span
                          >{{ selectedTrip.Distance + "km" }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="item">
                        <img
                          src="~@/assets/images/trips/stats/duration.svg"
                          width="16"
                          height="16"
                          alt="Duration"
                        />
                        <div>
                          <span class="font-weight-bold"
                            >Duration: <br class="newline"/></span
                          >{{ getDuration(selectedTrip.Duration) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="item">
                        <img
                          src="~@/assets/images/trips/stats/speed.svg"
                          width="16"
                          height="16"
                          alt="Max Speed"
                        />
                        <div>
                          <span class="font-weight-bold"
                            >Max Speed: <br class="newline"/></span
                          >{{ selectedTrip.MaxSpeed }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="item">
                        <img
                          src="~@/assets/images/trips/stats/alerts.svg"
                          width="13"
                          height="16"
                          alt="Alerts"
                        />
                        <div>
                          <span class="font-weight-bold"
                            >Alerts: <br class="newline"/></span
                          ><span>{{ selectedTrip.NotificationCount }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="downloads">
                  <div class="label mr-3">Download this trip</div>
                  <img
                    src="~@/assets/images/pdf.svg"
                    width="35"
                    height="30"
                    alt="PDF"
                    class="mr-3"
                    @click="downloadTripReport(selectedTrip.Id, 'pdf')"
                  />
                  <img
                    src="~@/assets/images/xls.svg"
                    width="35"
                    height="30"
                    alt="XLS"
                    @click="downloadTripReport(selectedTrip.Id, 'excel')"
                  />
                </div>
              </div>
              <div
                v-for="(trip, i) in trips"
                :key="trip.Id"
                class="trip-item"
                v-else
              >
                <div class="datetime" @click="displayTrip(i, trip)">
                  <div class="font-weight-bold">
                    {{ getDate(trip.Start.DateTime) }}
                  </div>
                  {{ getTime(trip.Start.DateTime) }}
                </div>
                <div class="address" @click="displayTrip(i, trip)">
                  {{ trip.Start.GeoAddress }}
                </div>
                <div class="trip-type">
                  <Spinner
                    v-if="savingTripType && savingTripTypeId === trip.Id"
                  />
                  <span
                    :class="{ business: trip.IsBusiness }"
                    @click="toggleTripType(i, trip)"
                    v-else
                    >{{ trip.IsBusiness ? "B" : "P" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" v-else>
            <table class="table">
              <thead>
                <tr>
                  <th width="115">Date</th>
                  <th>Trip Start</th>
                  <th>Distance</th>
                  <th>Duration</th>
                  <th>Max Speed</th>
                  <th>Alerts</th>
                  <th>Trip Type</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(trip, i) in trips">
                  <tr
                    :key="i + '0'"
                    class="primary"
                    :class="{ active: activeTripKey == i }"
                  >
                    <td @click="displayTrip(i, trip)">
                      <div class="label">Trip Start</div>
                      <div class="font-weight-bold">
                        {{ getDate(trip.Start.DateTime) }}
                      </div>
                      {{ getTime(trip.Start.DateTime) }}
                    </td>
                    <td @click="displayTrip(i, trip)">
                      {{ trip.Start.GeoAddress }}
                    </td>
                    <td class="text-center" @click="displayTrip(i, trip)">
                      {{ trip.Distance + "km" }}
                    </td>
                    <td class="text-center" @click="displayTrip(i, trip)">
                      {{ getDuration(trip.Duration) }}
                    </td>
                    <td class="text-center" @click="displayTrip(i, trip)">
                      {{ trip.MaxSpeed }}
                    </td>
                    <td class="text-center alert" @click="displayTrip(i, trip)">
                      <span :class="{ active: trip.NotificationCount > 0 }">{{
                        trip.NotificationCount
                      }}</span>
                    </td>
                    <td class="text-center trip-type">
                      <Spinner
                        v-if="savingTripType && savingTripTypeId === trip.Id"
                      />
                      <span
                        :class="{ business: trip.IsBusiness }"
                        @click="toggleTripType(i, trip)"
                        v-else
                        >{{ trip.IsBusiness ? "B" : "P" }}</span
                      >
                    </td>
                  </tr>
                  <tr
                    :key="i + '1'"
                    class="secondary"
                    :class="{ active: activeTripKey == i }"
                  >
                    <td>
                      <div class="label">Trip End</div>
                      <div class="font-weight-bold">
                        {{ getDate(trip.End.DateTime) }}
                      </div>
                      {{ getTime(trip.End.DateTime) }}
                    </td>
                    <td>{{ trip.End.GeoAddress }}</td>
                    <td colspan="5">
                      <div class="container">
                        <div class="row">
                          <div class="col text-right p-0">
                            <div class="label">
                              DOWNLOAD
                              <br />THIS TRIP
                            </div>
                          </div>
                          <div class="col buttons">
                            <Spinner
                              v-if="
                                activeTripKey == i &&
                                  downloadingTripReport &&
                                  !$isIOS
                              "
                            />
                            <template v-else>
                              <img
                                src="~@/assets/images/pdf.svg"
                                width="35"
                                height="30"
                                alt="PDF"
                                @click="downloadTripReport(trip.Id, 'pdf')"
                              />
                              <img
                                src="~@/assets/images/xls.svg"
                                width="35"
                                height="30"
                                alt="XLS"
                                @click="downloadTripReport(trip.Id, 'excel')"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>
        <div class="map-spinner" v-if="updatingMap">
          <Spinner />
        </div>
      </SmoothReflow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.trips-component {
  > .heading {
    font-size: 0.875rem;
    color: $brand-color-2;
    margin-bottom: 15px;
  }

  .search-top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .date-pickers {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .buttons {
      display: flex;
      justify-content: flex-start;

      button {
        margin-right: 20px;
      }

      .export {
        img:first-of-type {
          margin-right: 10px;
        }

        img {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &.small {
    .search-top {
      display: block;

      .buttons {
        margin: 15px 0 20px 0;
      }
    }
  }

  .search-results {
    &-heading {
      font-weight: bold;
      margin-bottom: 5px;

      span {
        color: $brand-color-2;
      }
    }

    .smooth-reflow-custom {
      overflow: visible !important;
    }

    .top-nav {
      position: absolute;
      top: -48px;
      z-index: 3;

      .back {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: $brand-color-2;
        line-height: 1;
        padding: 5px 5px 5px 0;
        margin-left: -5px;

        svg {
          color: $brand-color-1;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .panel {
      background: $brand-color-4;
      padding: 20px;
      margin-top: 10px;

      .trip-type {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;

        span {
          display: inline-block;
          font-weight: bold;
          color: $brand-color-2;
          width: 30px;
          height: 30px;
          line-height: 28px;
          text-align: center;
          border-radius: 50%;
          border: 1px solid $brand-color-2;

          &:hover {
            cursor: pointer;
          }

          &.business {
            color: $brand-color-1;
            border-color: $brand-color-1;
          }
        }
      }

      .trip-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        padding: 15px;
        margin-bottom: 15px;
        box-shadow: $drop-shadow-light;

        &:last-of-type {
          margin-bottom: 0;
        }

        .datetime {
          width: 80px;
        }

        .address {
          flex: 1;
          padding: 0 10px;
        }
      }

      .single-trip-view {
        .trip-type {
          position: absolute;
          top: 15px;
          right: 15px;
        }

        .label {
          font-weight: bold;
          color: $brand-color-2;
        }

        .trip-stats {
          padding: 10px 0;
          margin: 10px 0 15px 0;
          border-top: 1px solid $brand-color-1;
          border-bottom: 1px solid $brand-color-1;

          .item {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
            }

            .newline {
              display: none;
            }
          }
        }

        .downloads {
          display: flex;
          align-items: center;
        }
      }
    }

    .table {
      thead {
        tr {
          th {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }

      tbody {
        tr {
          &.active {
            background-color: $brand-color-4;

            &.secondary {
              display: table-row;
            }

            .label {
              display: block;
            }
          }

          &.primary {
            &:hover {
              cursor: pointer;
            }
          }

          &.secondary {
            display: none;
          }

          td {
            padding: 10px;
            border-right: 1px solid #bac5d0;
            border-bottom: 1px solid #bac5d0;

            &:last-of-type {
              border-right: none;
            }

            .label {
              display: none;
              font-weight: bold;
              font-size: 0.75rem;
              color: $brand-color-2;
              line-height: 14px;
            }

            .buttons {
              align-items: center;
              justify-content: center;
              min-height: 35px;

              img {
                margin: 0 8px;

                &:hover {
                  cursor: pointer;
                }
              }
            }

            &.alert {
              span {
                display: inline-block;
                font-weight: bold;
                width: 30px;
                height: 30px;
                line-height: 28px;

                &.active {
                  color: $red;
                  border-radius: 50%;
                  border: 1px solid $red;
                }
              }
            }

            &.trip-type {
              span {
                display: inline-block;
                font-weight: bold;
                color: $brand-color-2;
                width: 30px;
                height: 30px;
                line-height: 28px;
                border-radius: 50%;
                border: 1px solid $brand-color-2;

                &:hover {
                  cursor: pointer;
                }

                &.business {
                  color: $brand-color-1;
                  border-color: $brand-color-1;
                }
              }
            }
          }
        }
      }
    }
  }

  .map-spinner {
    background: #fff;
    position: fixed;
    width: 50px;
    height: 50px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $drop-shadow;

    .mobile & {
      top: 15%;
    }
  }
}

@media (max-width: 405px) {
  .trips-component {
    .search-results {
      .panel {
        .single-trip-view {
          .trip-stats {
            .item {
              .newline {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md-1) {
  .trips-component {
    > .heading {
      margin: 0 0 10px 0;
    }

    .search-top {
      display: block;

      .buttons {
        margin: 15px 0 20px 0;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { alertService, assetService, downloadService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import DatePicker from "@/components/DatePicker";
import { helper } from "@/helper";
import styles from "@/assets/scss/_variables.scss";

export default {
  name: "Trips",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
    DatePicker,
  },
  data() {
    return {
      loading: true,
      processing: false,
      updatingMap: false,
      downloadingLogbook: false,
      downloadingTripReport: false,
      searchResults: false,
      savingTripType: false,
      savingTripTypeId: null,
      activeTripKey: -1,
      selectedTrip: null,
      trips: [],
      fromDate: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState(["map", "navigation"]),
    ...mapGetters("asset", [
      "getActiveSafeAndSoundVehicleId",
      "getActiveVehicleRegistrationNumber",
    ]),
    activeAssetId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
    activeRegistrationNumber() {
      return this.getActiveVehicleRegistrationNumber();
    },
    showSearchTopUI() {
      if (this.$isMobile && this.selectedTrip) {
        return false;
      }

      return true;
    },
  },
  created() {
    this.processing = true;
    const vehicleId = this.getActiveSafeAndSoundVehicleId();
    const startTime = Date.now();
    assetService
      .getVehicleTrips(vehicleId)
      .then((data) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.trips = data;

            if (data.length) {
              const mostRecentTripDateTime = data[0].Start.DateTime;
              const leastRecentTripDateTime =
                data[data.length - 1].Start.DateTime;
              this.fromDate = moment(leastRecentTripDateTime).format(
                "YYYY-MM-DD"
              );
              this.toDate = moment(mostRecentTripDateTime).format("YYYY-MM-DD");
            }

            this.loading = false;
            this.processing = false;
          },
          timeElapsed < 1000 ? 1000 - timeElapsed : 0
        );
      })
      .catch((error) => {
        this.processing = false;
      });
  },
  mounted() {
    if (this.map.gmapRef) {
      this.$options.markers = [];
      this.$options.infoWindows = [];
      this.$options.routeLine = null;
    }
  },
  beforeDestroy() {
    this.resetTripDisplay();
    this.updateShowMarkers(true);
    this.setMapBounds();
  },
  methods: {
    ...mapActions("map", ["setMapBounds"]),
    ...mapMutations("map", ["updateShowMarkers"]),
    // ...mapMutations("navigation", ["updateShowSheet"]),
    beforeClose() {
      this.$emit("close");
    },
    getDate(dateTime) {
      return dateTime.split("T")[0];
    },
    getTime(dateTime) {
      return dateTime.split("T")[1];
    },
    getDuration(durationObject) {
      let hours = durationObject.Hours;
      let minutes = durationObject.Minutes;
      let seconds = durationObject.Seconds;

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },
    downloadLogbook(fromDate, toDate, format) {
      if (!this.downloadingLogbook && fromDate && toDate && format) {
        this.downloadingLogbook = true;
        const vehicleId = this.getActiveSafeAndSoundVehicleId();
        downloadService
          .getVehicleLogbook(vehicleId, fromDate, toDate, format)
          .then((data) => {
            const filename =
              format === "pdf"
                ? `${this.activeRegistrationNumber} Logbook.pdf`
                : `${this.activeRegistrationNumber} Logbook.xls`;
            helper.saveToBrowser(filename, data);
            this.downloadingLogbook = false;
          })
          .catch((error) => {
            this.downloadingLogbook = false;
            alertService.showErrorAlert();
          });
      }
    },
    downloadTripReport(tripId, format) {
      if (!this.downloadingTripReport && tripId && format) {
        this.downloadingTripReport = true;
        downloadService
          .getVehicleTripReport(tripId, format)
          .then((data) => {
            const filename =
              format === "pdf"
                ? `${this.activeRegistrationNumber} Trip Report.pdf`
                : `${this.activeRegistrationNumber} Trip Report.xls`;
            helper.saveToBrowser(filename, data);
            this.downloadingTripReport = false;
          })
          .catch((error) => {
            this.downloadingTripReport = false;
            alertService.showErrorAlert();
          });
      }
    },
    getTripsByDate() {
      this.processing = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
        .getVehicleTripsByDate(vehicleId, this.fromDate, this.toDate)
        .then((data) => {
          this.clearTripsState();
          this.searchResults = true;
          this.trips = data.reverse();
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
        });
    },
    displayTrip(key, trip, retryCount = 0) {
      if (this.activeTripKey !== key && !this.updatingMap) {
        this.activeTripKey = key;
        this.selectedTrip = trip;
        this.updatingMap = true;
        this.updateShowMarkers(false);
        this.resetTripDisplay();

        if (this.$isMobile) {
          this.$emit("showTopUI", false);
        }

        let waypoints = [];
        let bounds = new google.maps.LatLngBounds();

        const tripDetailsRequest = assetService.getVehicleTripDetails(trip.Id);
        const tripNotificationsRequest = assetService.getVehicleTripNotifications(
          trip.Id
        );

        Promise.all([tripDetailsRequest, tripNotificationsRequest])
          .then((responses) => {
            if (this.navigation.activeScreenName !== "trips") {
              return;
            }

            const tripDetails = responses[0];
            const tripNotifications = responses[1];

            if (!Array.isArray(tripDetails) || tripDetails.length == 0) {
              this.updatingMap = false;

              if (retryCount == 2) {
                alertService.showErrorAlert(
                  "We apologise, but we are unable to replay the trip at this time. Please try again later."
                );
              } else {
                this.activeTripKey = -1;
                this.selectedTrip = null;
                this.displayTrip(key, trip, retryCount + 1);
              }

              return;
            }

            // Create array of waypoints to load onto map
            tripDetails.forEach((waypoint) => {
              waypoints.push(
                new google.maps.LatLng(waypoint.Latitude, waypoint.Longitude)
              );
              bounds.extend({
                lat: waypoint.Latitude,
                lng: waypoint.Longitude,
              });
            });

            // Append trip notifications (alerts) to array of waypoints
            tripNotifications.forEach((notification) => {
              waypoints.push(
                new google.maps.LatLng(
                  notification.Location.Latitude,
                  notification.Location.Longitude
                )
              );
              bounds.extend({
                lat: notification.Location.Latitude,
                lng: notification.Location.Longitude,
              });
            });

            waypoints.forEach((position, i) => {
              const isNotification = i >= tripDetails.length; // Check if waypoint is notification (alert) event
              const dataIndex = isNotification ? i - tripDetails.length : i; // Calculate the index to be used to retrieve the waypoint event object
              const tripEvent = isNotification
                ? tripNotifications[dataIndex].Location
                : tripDetails[dataIndex]; // Get the (common) event object

              let icon = {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: styles.brandColor1,
                fillOpacity: 1,
                strokeColor: styles.brandColor1,
                strokeWeight: 1,
                scale: 3,
                rotation: tripEvent.Heading,
              };

              let showDanger = false;
              const eventDescription = isNotification
                ? tripNotifications[dataIndex].Message
                : tripEvent.EventDescription;
              let infoWindowHeading = `<h5>${eventDescription}</h5>`;

              if (i == 0) {
                infoWindowHeading = "<h5>Trip Start</h5>";
                icon = this.getMarkerIcon("trip-start");
              } else if (i == tripDetails.length - 1) {
                infoWindowHeading = "<h5>Trip End</h5>";
                icon = this.getMarkerIcon("trip-end");
              } else {
                switch (
                  isNotification
                    ? tripNotifications[dataIndex].Category
                    : tripEvent.EventId
                ) {
                  case 53:
                  case "OverSpeeding":
                  case "Speeding":
                    showDanger = true;
                    icon = this.getMarkerIcon("speeding");
                    break;
                  case 56:
                  case "Cornering":
                    showDanger = true;
                    icon = this.getMarkerIcon("harsh-cornering");
                    break;
                  case 58:
                  case "Braking":
                    showDanger = true;
                    icon = this.getMarkerIcon("harsh-braking");
                    break;
                  case 59:
                  case "Acceleration":
                    showDanger = true;
                    icon = this.getMarkerIcon("harsh-acceleration");
                    break;
                  case "106":
                  case "Geo-Fencing:EnterGeoZone":
                  case "Geo-Fencing:ExitGeoZone":
                  case "Geo-Fencing:IgnitionOff":
                  case "Geo-Fencing:IgnitionOn":
                    showDanger = true;
                    icon = this.getMarkerIcon("geo-zone");
                    break;
                  case 108:
                  case 109:
                  case 110:
                  case 111:
                  case "Impacts":
                    showDanger = true;
                    icon = this.getMarkerIcon("impacts");
                    break;
                  case 129:
                  case "Lane-Change":
                    showDanger = true;
                    icon = this.getMarkerIcon("lane-changing");
                    break;
                }
              }

              this.$options.markers[i] = new google.maps.Marker({
                position: position,
                map: this.map.gmapRef.$mapObject,
                icon: icon,
                title: "",
              });

              const dangerClass = showDanger ? " danger" : "";
              const alertNameRow = isNotification
                ? `
                <tr>
                  <td style="font-weight: bold;">Name: </td>
                  <td>${tripNotifications[dataIndex].Name}</td>
                </tr>
                `
                : "";

              const content = `
                <div class="trip-content${dangerClass}">
                  ${infoWindowHeading}
                  <table style="width: 300px;">
                    <tbody>
                      ${alertNameRow}
                      <tr>
                        <td style="font-weight: bold;">Date: </td>
                        <td>${tripEvent.DateTime.replace("T", " ")}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold;">Speed: </td>
                        <td>${tripEvent.Speed} kph</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold;">Heading:  </td>
                        <td>${tripEvent.Heading}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold;">GPS Coordinates: </td>
                        <td>${tripEvent.Latitude}, ${tripEvent.Longitude}</td>
                      </tr>
                      <tr>
                        <td rolspan="2" style="font-weight: bold;">Address: </td>
                        <td>${tripEvent.GeoAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                `;

              this.$options.infoWindows[i] = new google.maps.InfoWindow({
                content: content,
              });

              this.$options.markers[i].addListener("click", () => {
                this.$options.infoWindows.forEach((infoWindow) =>
                  infoWindow.close()
                );
                this.$options.infoWindows[i].open(
                  this.map.gmapRef.$mapObject,
                  this.$options.markers[i]
                );
              });
            });

            this.$options.routeLine = new google.maps.Polyline({
              path: waypoints.slice(0, tripDetails.length), // Only draw route through trip detail waypoints
              geodesic: true,
              strokeColor: styles.brandColor1,
              strokeOpacity: 0.5,
              strokeWeight: 5,
            });

            this.$options.routeLine.setMap(this.map.gmapRef.$mapObject);
            this.map.gmapRef.fitBounds(bounds);
            this.updatingMap = false;

            // if (this.$isMobile) {
            //   this.updateShowSheet(false);
            // }
          })
          .catch((errors) => {});
      }
    },
    getMarkerIcon(eventName) {
      switch (eventName) {
        case "trip-start":
          return {
            url: require("@/assets/images/trips/trip-start.svg"),
            scaledSize: new google.maps.Size(46, 60),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(23, 60),
          };
        case "trip-end":
          return {
            url: require("@/assets/images/trips/trip-end.svg"),
            scaledSize: new google.maps.Size(46, 60),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(23, 60),
          };
        case "speeding":
          return {
            url: require("@/assets/images/trips/over-speeding.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        case "harsh-cornering":
          return {
            url: require("@/assets/images/trips/harsh-cornering.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        case "harsh-braking":
          return {
            url: require("@/assets/images/trips/harsh-braking.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        case "harsh-acceleration":
          return {
            url: require("@/assets/images/trips/harsh-acceleration.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        case "geo-zone":
          return {
            url: require("@/assets/images/trips/geo-zone.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        case "impacts":
          return {
            url: require("@/assets/images/trips/impacts.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        case "lane-changing":
          return {
            url: require("@/assets/images/trips/lane-changing.png"),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 25),
          };
        default:
          return {};
      }
    },
    resetTripDisplay() {
      if (this.$options.routeLine) {
        this.$options.routeLine.setMap(null);
      }

      if (this.$options.markers) {
        this.$options.markers.map((marker) => marker.setMap(null));
      }

      this.$options.markers = [];
    },
    clearTripsState() {
      this.resetTripDisplay();
      this.activeTripKey = -1;
      this.selectedTrip = null;
      this.searchResults = false;
    },
    toggleTripType(key, trip) {
      if (!this.savingTripType) {
        this.savingTripType = true;
        this.savingTripTypeId = trip.Id;
        assetService
          .updateVehicleTripType(trip.Id, !trip.IsBusiness)
          .then((result) => {
            if (result) {
              this.trips[key].IsBusiness = !trip.IsBusiness;
              this.savingTripType = false;
              this.savingTripTypeId = null;
            }
          })
          .catch((error) => {});
      }
    },
    back() {
      if (!this.updatingMap) {
        this.activeTripKey = -1;
        this.selectedTrip = null;
        this.resetTripDisplay();
        this.$emit("showTopUI", true);
      }
    },
  },
  watch: {
    activeAssetId() {
      this.processing = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
        .getVehicleTrips(vehicleId)
        .then((data) => {
          this.clearTripsState();
          this.trips = data;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
        });
    },
  },
};
</script>
