<template>
  <div class="bottom-sheet-component" :class="{ active: showSheet }" v-if="$isMobile" @scroll.passive="handleScroll">
    <div class="header" :class="{ scrolled: isHeaderScrolled }" @click="updateShowSheet(!showSheet)">
      <div class="sheet-down" v-if="showSheet">
        <div>
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
      <div class="d-flex align-items-center" v-else>
        <div class="sheet-up">
          <div>
            <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
          </div>
        </div>
        <div class="title">
          {{ activeVehicleDisplayName ? activeVehicleDisplayName : "My Vehicles" }}
          <template v-if="screenName">
            <span class="divider">|</span>
            <span class="subtitle">{{ screenName }}</span>
          </template>
        </div>
      </div>
    </div>
    <SmoothReflow>
      <div class="content" v-show="showSheet">
        <AssetPickerMobile @screenName="updateScreenName" />
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.bottom-sheet-component {
  background: #fff;
  padding: 0 12px;
  overflow-y: visible;
  box-shadow: $omni-shadow;

  &.active {
    max-height: 70%;
    overflow-y: auto;

    .header {
      position: fixed;
      top: auto;
      left: 0;
      z-index: 2;
      width: 100%;
      // padding: 20px 0;

      &.scrolled {
        background: #fff;
      }
    }

    .content {
      padding: 35px 0 15px 0;
    }
  }

  .header {
    position: relative;
    padding: 5px 0;

    .title {
      font-weight: bold;
      color: $brand-color-2;
      text-transform: uppercase;
      padding: 10px 0;

      .divider {
        display: inline-block;
        font-weight: normal;
        color: $brand-color-1;
        margin: 0 10px;
      }

      .subtitle {
        font-weight: normal;
        color: $brand-color-1;
      }
    }

    .sheet-down,
    .sheet-up {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);

      > div {
        background: $brand-color-2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import AssetPickerMobile from "@/components/AssetPickerMobile";

export default {
  name: "BottomSheet",
  components: {
    AssetPickerMobile,
  },
  data() {
    return {
      screenName: "",
      scrollPosition: null,
    };
  },
  computed: {
    ...mapState("navigation", ["show", "showSheet", "sheetComponent"]),
    ...mapGetters("asset", ["getActiveVehicleDisplayName"]),
    activeVehicleDisplayName() {
      return this.getActiveVehicleDisplayName();
    },
    isHeaderScrolled() {
      return this.scrollPosition > 10;
    },
  },
  methods: {
    ...mapMutations("navigation", ["updateShowSheet"]),
    handleScroll(event) {
      this.scrollPosition = event.target.scrollTop;
    },
    updateScreenName(screenName) {
      this.screenName = screenName.replaceAll("-", " ");
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.updateShowSheet(false);
      }
    },
  },
};
</script>
