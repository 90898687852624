<template>
  <component :is="tag">
    <slot />
  </component>
</template>

<script>
import smoothReflow from "vue-smooth-reflow";

export default {
  name: "SmoothReflow",
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: "div",
    },
    options: Object,
    active: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.active) {
      this.$smoothReflow(this.options);
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.$smoothReflow(this.options);
      } else {
        this.$unsmoothReflow(this.options);
      }
    },
  },
};
</script>