<template>
  <div class="company-details-component screen">
    <UpgradeStepHeader
      :stepNo="1"
      title="Confirm Company Details"
      nextStepScreenName="manage-vehicle"
      :showNextButton="formIsInitialised"
      :showSpinner="!formIsInitialised"
      :formValidationFailureCount="formValidationFailureCount"
      :stepCompleted="upgradeStepCompleted"
      @submit="update()"
      @close="beforeClose()"
      v-if="upgradeInProgress()"
    />
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'My Admin',
        subTitle: 'Company Details',
      }"
      @close="beforeClose()"
      v-else
    />
    <Alert />
    <div class="buttons" v-if="!upgradeInProgress()">
      <button class="btn btn-outline mr-3" @click="cancel()" v-if="editing">CANCEL</button>
      <button class="btn btn-outline" :disabled="loading" @click="editing = true" v-if="!editing">
        <span v-show="!loading">EDIT</span>
        <Spinner v-show="loading" />
      </button>
      <button class="btn btn-outline inverted" @click="update()" v-else>
        <span v-show="!saving">UPDATE</span>
        <Spinner v-show="saving" />
      </button>
    </div>
    <GradientScroll class="form-container">
      <form ref="form" :class="{ editing: editing }" @submit.prevent>
        <div class="mb-3 d-flex">
          <div id="avatar">
            <img :src="userImageUrl" width="64" height="64" />
            <div class="d-flex justify-content-center">
              <button type="button" id="choose-image" v-show="editing">Edit</button>
              <div v-if="editing && !isDefaultProfileImage">|<button type="button" @click="confirmImageDeletion">Delete</button></div>
            </div>
            <avatar-cropper
              :labels="{
                submit: 'Submit',
                cancel: 'Cancel',
              }"
              :output-options="{
                width: 150,
                height: 150,
              }"
              mimes="image/jpeg"
              trigger="#choose-image"
              :uploadHandler="imageUploadHandler"
              @error="imageError"
            />
          </div>
          <div class="form-group flex-grow-1 mt-2">
            <input type="text" class="form-control" id="nickname" v-model="model.NickName" :disabled="!editing" />
            <label for="nickname">Nickname</label>
          </div>
        </div>
        <div class="subheading">COMPANY DETAILS</div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="companyName" v-model="model.CompanyName" :disabled="!editing" required />
              <label for="companyName">Company Name</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="tel" class="form-control" id="telNumber" pattern="[0-9]{10}" v-model="model.WorkNumber" :disabled="!editing" />
              <label for="telNumber">Tel Number</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="vatNumber" v-model="model.VatNumber" :disabled="!editing" required />
              <label for="vatNumber">VAT Number</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="companyRegistrationNumber" v-model="model.CompanyRegistrationNumber" :disabled="!editing" />
              <label for="companyRegistrationNumber">Registration Number</label>
            </div>
          </div>
        </div>
        <div class="subheading">PHYSICAL ADDRESS</div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="physicalStreetAddress" v-model="model.PhysicalAddress.Street" :disabled="!editing" required />
              <label for="physicalStreetAddress">Street Address</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="physicalAddressLine2" v-model="model.PhysicalAddress.Addressline2" :disabled="!editing" />
              <label for="physicalAddressLine2">Address Line 2</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="physicalPostalCode"
                @input="physicalPostalCodeChange"
                v-model="model.PhysicalAddress.PostalCode"
                :options="postalCodes"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PhysicalAddress.PostalCode" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="physicalPostalCode">Postal Code</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="physicalSuburb"
                @input="physicalSuburbChange"
                v-model="model.PhysicalAddress.Suburb"
                :options="physicalSuburbCities"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PhysicalAddress.Suburb" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="physicalSuburb">Suburb</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="physicalCity"
                @input="physicalCityChange"
                v-model="model.PhysicalAddress.City"
                :options="physicalSuburbCities"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PhysicalAddress.City" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="physicalCity">City</label>
            </div>
          </div>
        </div>
        <div class="subheading">POSTAL ADDRESS</div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="postalStreetAddress" v-model="model.PostalAddress.Street" :disabled="!editing" required />
              <label for="postalStreetAddress">Street Address</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="postalAddressLine2" v-model="model.PostalAddress.Addressline2" :disabled="!editing" />
              <label for="postalAddressLine2">Address Line 2</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="physicalPostalCode"
                @input="postalPostalCodeChange"
                v-model="model.PostalAddress.PostalCode"
                :options="postalCodes"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PostalAddress.PostalCode" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="postalPostalCode">Postal Code</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="postalSuburb"
                @input="postalSuburbChange"
                v-model="model.PostalAddress.Suburb"
                :options="postalSuburbCities"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PostalAddress.Suburb" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="postalSuburb">Suburb</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="postalCity"
                @input="postalCityChange"
                v-model="model.PostalAddress.City"
                :options="postalSuburbCities"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PostalAddress.City" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="postalCity">City</label>
            </div>
          </div>
        </div>
        <div class="subheading">COMPANY REPRESENTATIVE</div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="primaryContactFirstName" v-model="model.PrimaryContact.FirstName" :disabled="!editing" required />
              <label for="primaryContactFirstName">First Name</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" id="primaryContactSurname" v-model="model.PrimaryContact.Surname" :disabled="!editing" required />
              <label for="primaryContactSurname">Surname</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="primaryContactTitle"
                v-model="model.PrimaryContact.Title"
                :options="titles"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!model.PrimaryContact.Title" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
              <label for="primaryContactTitle">Title</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                pattern="\d{13}"
                maxlength="13"
                title="Must be 13 digits"
                class="form-control"
                id="idNumber"
                v-model="model.PrimaryContact.IdNumber"
                :disabled="!editing"
                readonly
              />
              <label for="idNumber">ID Number</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="tel"
                class="form-control"
                id="primaryContactCellNumber"
                pattern="[0-9]{10}"
                v-model="model.PrimaryContact.CellNumber"
                :disabled="!editing"
                required
              />
              <label for="primaryContactCellNumber">Cell Number</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input type="email" class="form-control" id="primaryContactEmail" v-model="model.PrimaryContact.Email" :disabled="!editing" required />
              <label for="primaryContactEmail">Email Address</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="tel"
                class="form-control"
                id="primaryContactWorkTelNumber"
                pattern="[0-9]{10}"
                v-model="model.PrimaryContact.WorkNumber"
                :disabled="!editing"
              />
              <label for="primaryContactWorkTelNumber">Work Tel Number</label>
            </div>
          </div>
          <div class="col-12 col-md-6"></div>
        </div>
      </form>
    </GradientScroll>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.company-details-component {
  display: flex;
  flex-direction: column;
  max-height: $modalHeight;

  @include scrollbar;

  .buttons {
    margin: 0 34px 15px 0;

    button {
      min-width: 90px;
    }
  }

  .form-container {
    form {
      padding: 10px 30px 0 15px;

      &.editing {
        .subheading {
          color: $brand-color-1;
        }
      }

      .form-control {
        text-transform: uppercase;
      }
    }
  }

  #avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 95px;
    margin-right: 20px;

    img {
      border-radius: 50%;
      box-shadow: $drop-shadow;
    }

    button {
      background: transparent;
      font-size: 0.75rem;
      font-weight: 600;
      color: $brand-color-1;
      margin-top: 8px;
      border: none;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { adminService, alertService, modalService, utilityService } from "@/services";
import UpgradeStepHeader from "@/components/screens/UpgradeStepHeader";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "CompanyDetails",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    UpgradeStepHeader,
    AvatarCropper,
  },
  data() {
    return {
      loading: true,
      editing: false,
      saving: false,
      unsavedChanges: false,
      formValidationFailureCount: 0,
      upgradeStepCompleted: false,
      formIsInitialised: false,
      userImageUrl: "",
      model: {
        CompanyName: "",
        CompanyRegistrationNumber: "",
        VatNumber: "",
        WorkTelephone: "",
        NickName: "",
        ProfileImageExtension: "",
        ProfileImageUrl: "",
        PhysicalAddress: {
          AddressLine2: "",
          City: "",
          PostalCode: "",
          Street: "",
          Suburb: "",
        },
        PostalAddress: {
          AddressLine2: "",
          City: "",
          PostalCode: "",
          Street: "",
          Suburb: "",
        },
        PrimaryContact: {
          CellNumber: "",
          Email: "",
          FirstName: "",
          IdNumber: "",
          Surname: "",
          Title: "",
          WorkNumber: "",
        },
      },
      modelUnedited: null,
      postalCodes: [],
      physicalSuburbCities: [],
      postalSuburbCities: [],
      titles: [],
    };
  },
  computed: {
    ...mapState(["account"]),
    ...mapGetters("account", ["getDefaultProfileImageUrl", "getProfileImageUrl"]),
    ...mapGetters("asset", { upgradeInProgress: "getUpgradeInProgress" }),
    isDefaultProfileImage() {
      return this.getDefaultProfileImageUrl === this.getProfileImageUrl;
    },
  },
  created() {
    if (this.upgradeInProgress()) {
      this.editing = true;
    }

    this.userImageUrl = this.getProfileImageUrl;
    const titlesRequest = utilityService.getTitles();
    const postalCodesRequest = utilityService.getPostalCodes();
    const customerDetailsRequest = adminService.getCustomerDetails();
    const startTime = Date.now();

    Promise.all([titlesRequest, postalCodesRequest, customerDetailsRequest])
      .then((responses) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.titles = responses[0];
            this.postalCodes = responses[1];
            this.model = responses[2];
            this.model.NickName = this.account.user.nickname;
            this.model.ProfileImageUrl = ""; // Remove value to prevent invalid upload
            this.modelUnedited = JSON.parse(JSON.stringify(this.model));

            utilityService.getSuburbCities(this.model.PhysicalAddress.PostalCode).then((data) => {
              this.physicalSuburbCities = data;
            });

            utilityService.getSuburbCities(this.model.PostalAddress.PostalCode).then((data) => {
              this.postalSuburbCities = data;
            });

            this.loading = false;
            this.formIsInitialised = true;
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((errors) => {
        this.error(errors);
      });
  },
  methods: {
    ...mapActions("alert", ["error"]),
    ...mapMutations("account", ["updateNickname", "updateProfileImageUrl"]),
    beforeClose() {
      if (this.unsavedChanges) {
        modalService.showSaveDialog(this, "Do you want to save the changes made to your company details before leaving?", this.update);
      } else {
        this.$emit("close");
      }
    },
    imageUploadHandler(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas({
          width: 150,
          height: 150,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg");

      if (dataUrl) {
        this.userImageUrl = dataUrl;
        this.model.ProfileImageUrl = dataUrl.split(",")[1];
        this.model.ProfileImageExtension = "jpg";
      }
    },
    imageError(error) {
      alertService.showErrorAlert(error);
    },
    physicalPostalCodeChange(postalCode) {
      utilityService.getSuburbCities(postalCode).then((data) => {
        this.physicalSuburbCities = data;
        this.model.PhysicalAddress.Suburb = "";
      });
    },
    physicalSuburbChange(value) {
      this.model.PhysicalAddress.Suburb = value;
    },
    physicalCityChange(value) {
      this.model.PhysicalAddress.City = value;
    },
    postalPostalCodeChange(postalCode) {
      utilityService.getSuburbCities(postalCode).then((data) => {
        this.postalSuburbCities = data;
        this.model.PostalAddress.Suburb = "";
      });
    },
    postalSuburbChange(value) {
      this.model.PostalAddress.Suburb = value;
    },
    postalCityChange(value) {
      this.model.PostalAddress.City = value;
    },
    confirmImageDeletion() {
      modalService.showDeleteDialog("Are you sure you want to delete the image?", this.deleteImageFromCRM);
    },
    deleteImageFromCRM() {
      this.saving = true;
      adminService
        .deleteProfileImage(this.modelUnedited.CompanyRegistrationNumber)
        .then((result) => {
          if (result) {
            this.updateProfileImageUrl("");
            this.userImageUrl = this.getProfileImageUrl;
          } else {
            modalService.showErrorDialog();
          }
        })
        .catch((error) => {
          modalService.showErrorDialog(error.userMessage ? error.userMessage : "");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      if (this.editing) {
        this.editing = false;
        this.model = this.modelUnedited;
      } else {
        this.beforeClose();
      }
    },
    update() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
        return true;
      } else {
        this.formValidationFailureCount++;
        return false;
      }
    },
    handleSubmit() {
      if (this.$isDevEnv && this.upgradeInProgress()) {
        this.upgradeStepCompleted = true;
        return;
      }

      const { model } = this;

      if (model) {
        this.saving = true;
        adminService
          .updateCustomerDetails(model)
          .then((result) => {
            this.editing = false;
            this.saving = false;
            if (this.wasUpdatedSuccessfully(result)) {
              if (this.upgradeInProgress()) {
                this.upgradeStepCompleted = true;
              }

              this.unsavedChanges = false;
              this.updateNickname(this.model.NickName);

              if (result.imageUrl && result.imageUrl.toLowerCase().startsWith("http")) {
                this.updateProfileImageUrl(result.imageUrl);
              }

              modalService.showSuccessDialog("Company details updated successfully.");
            } else {
              modalService.showErrorDialog();
            }
          })
          .catch((error) => {
            this.saving = false;
            modalService.showErrorDialog(error.userMessage ? error.userMessage : "");
          });
      }
    },
    wasUpdatedSuccessfully(responseData) {
      if (!responseData) {
        return false;
      }

      if (!responseData.success) {
        return false;
      }

      if (this.model.ProfileImageUrl && (!responseData.imageUrl || !responseData.imageUrl.toLowerCase().startsWith("http"))) {
        return false;
      }

      return true;
    },
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.unsavedChanges = JSON.stringify(this.model) !== JSON.stringify(this.modelUnedited);
      },
    },
  },
};
</script>