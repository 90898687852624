<template>
  <div class="new-reminder-component screen">
    <ModalHeader
      :config="{
        type: 'back',
        screenName: 'reminders',
        navigateBack: navigateBack,
        title: '',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <div class="heading">NEW REMINDER</div>
    <SmoothReflow>
      <Alert />
      <form ref="form" @submit.prevent>
        <div class="panel">
          <div class="subheading">REMINDER TYPE</div>
          <div class="container">
            <div class="row">
              <div class="col-6 custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="licenceType" id="vehicleLicence" value="Vehicle" v-model="form.reminderType" />
                <label class="custom-control-label" for="vehicleLicence">Vehicle Licence</label>
              </div>
              <div class="col-6 custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="licenceType" id="driversLicence" value="Driver" v-model="form.reminderType" />
                <label class="custom-control-label" for="driversLicence">Driver's Licence</label>
              </div>
            </div>
          </div>
          <div class="subheading mt-2">REMINDER NAME</div>
          <div class="form-group">
            <input type="text" class="form-control" id="reminderName" v-model="form.reminderName" required />
          </div>
        </div>
        <div class="panel" :class="{ invalid: submitted && !form.units.length }" v-if="isVehicleReminder">
          <div class="subheading">SELECT UNIT</div>
          <div class="container">
            <div class="row">
              <div class="col-12 custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="allUnits" v-model="allUnits" @click="toggleAllUnits" />
                <label class="custom-control-label" for="allUnits">All Units</label>
              </div>
              <div v-for="(asset, index) in getMyAssets()" :key="asset.SafeAndSoundVehicleId" id="units" class="col-6 custom-control custom-checkbox">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  :id="'unit' + index"
                  :value="asset.SafeAndSoundVehicleId"
                  :checked="isUnitSelected(asset.SafeAndSoundVehicleId)"
                  @click="toggleUnit(asset.SafeAndSoundVehicleId)"
                  :disabled="allUnits"
                />
                <label class="custom-control-label" :for="'unit' + index">{{ asset.RegistrationNumber }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="panel" v-if="isDriverReminder">
          <div class="subheading">DRIVER DETAILS</div>
          <div class="form-group row mt-2 mb-2">
            <label for="driverName" class="col-sm-3 col-form-label text-md-right pr-md-1">NAME</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="driverName" v-model="form.driverName" required />
            </div>
          </div>
          <div class="form-group row">
            <label for="driverSurname" class="col-sm-3 col-form-label text-md-right pr-md-1">SURNAME</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="driverSurname" v-model="form.driverSurname" required />
            </div>
          </div>
        </div>
        <div class="panel date" :class="{ invalid: submitted && (!form.date || !form.time) }">
          <div class="subheading">REMINDER DATE</div>
          <div class="date-time-picker d-flex flex-wrap">
            <DatePicker :minDate="today" labelWidth="36px" v-model="form.date" />
            <TimePicker labelWidth="36px" v-model="form.time" />
          </div>
        </div>
        <div class="panel notifications">
          <div class="subheading mb-3">NOTIFICATIONS</div>
          <div class="custom-control custom-checkbox mb-2">
            <input class="custom-control-input" type="checkbox" id="pushNotifications" v-model="form.pushNotifications" />
            <label class="custom-control-label" for="pushNotifications">Notify Me</label>
          </div>
          <template v-for="(e, i) in numNotifications">
            <div class="form-group row mb-3" :key="i" v-show="numNotifications >= i">
              <label :for="'email' + i" class="col-sm-2 col-form-label">EMAIL</label>
              <div class="col-10 col-sm-8">
                <input type="email" class="form-control" :id="'email' + i" v-model="form.emails[i]" />
              </div>
              <div class="col-2 col-sm-2 p-0">
                <div class="add-button" @click="addNotification" v-show="numNotifications == i + 1">
                  <svg width="20" height="20" viewBox="0 0 16 16" class="plus-icon" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                    <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                  </svg>
                </div>
              </div>
            </div>
          </template>
        </div>
      </form>
      <div class="buttons">
        <StatusIndicator class="mr-3" :success="submitSuccess" :error="submitError" />
        <button class="btn btn-outline mr-3" :disabled="processing" @click="goBack()">CANCEL</button>
        <button class="btn btn-outline" :disabled="processing" @click="submit()">
          <span v-show="!processing">SAVE</span>
          <Spinner v-show="processing" />
        </button>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.new-reminder-component {
  > .heading {
    color: $brand-color-2;
    margin-bottom: 8px;
  }

  .panel {
    background: $brand-color-4;
    padding: 10px 20px 20px 20px;
    margin-bottom: 10px;

    .subheading {
      color: $brand-color-1;
    }

    .date-time-picker {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    .form-group {
      margin-bottom: 0;

      .form-control {
        background-color: #fff;
        text-transform: none;
        border: 1px solid $brand-color-2;
      }

      .col-form-label {
        font-style: normal;
        color: $brand-color-2;
      }

      .add-button {
        display: inline-block;
        line-height: 30px;

        &:hover {
          cursor: pointer;
        }

        .plus-icon {
          fill: $brand-color-1;
        }
      }
    }

    .custom-checkbox {
      .custom-control-label {
        font-style: normal;
        color: $brand-color-2;
      }
    }

    &.date {
      .first {
        font-style: normal;
        color: $brand-color-2;
        border-right: 1px solid $brand-color-2;
      }
    }

    &.notifications {
      padding-bottom: 10px;
    }
  }

  .buttons {
    margin-top: 15px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { alertService, assetService, modalService } from "@/services";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import StatusIndicator from "@/components/StatusIndicator";

export default {
  name: "NewReminder",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    DatePicker,
    TimePicker,
    StatusIndicator,
  },
  props: ["reminder", "type"],
  data() {
    return {
      navigateBack: false,
      processing: false,
      submitted: false,
      submitSuccess: false,
      submitError: false,
      numNotifications: 1,
      form: {},
      model: {},
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveSafeAndSoundVehicleId", "getActiveVehicleOwnerId", "getMyAssets", "getSubscriberEmailAddress"]),
    isModifyingExistingReminder() {
      return this.reminder !== undefined;
    },
    isVehicleReminder() {
      return this.form.reminderType == "Vehicle";
    },
    isDriverReminder() {
      return this.form.reminderType == "Driver";
    },
    isValid() {
      const { date, time, units } = this.form;
      const hasUnitsIfApplicable = this.isVehicleReminder ? units.length : true;
      return date && time && hasUnitsIfApplicable;
    },
  },
  created() {
    this.initForm();
    if (this.isModifyingExistingReminder) {
      this.model.Id = this.reminder.Id;
      this.model.VehicleOwnerId = this.reminder.VehicleOwnerId;
      this.model.DateCreated = this.reminder.DateCreated.replace("%20", "T");
      this.form.reminderType = this.reminder.EditorMetadata;
      this.form.reminderName = this.reminder.Name;
      this.form.driverName = this.reminder.DriverName;
      this.form.driverSurname = this.reminder.DriverSurname;

      this.reminder.Rules.forEach((rule) => {
        if (rule.Property == "VehicleId") {
          this.form.units = rule.Value.split(",").map((item) => item.trim());
        }
      });

      const formattedDate = this.reminder.DateSend.replace("%20", "T");
      this.form.date = formattedDate.split("T")[0];

      const timeSplit = formattedDate.split("T")[1].split(":");
      this.form.time = timeSplit[0] + ":" + timeSplit[1];

      this.form.pushNotifications = this.reminder.PushNotification;

      this.form.emails = [];

      this.reminder.Channels.forEach((channel) => {
        switch (channel.NotificationType) {
          case "Email":
            this.form.emails.push(channel.Address);
            this.numNotifications = this.form.emails.length;
            break;
        }
      });
    }
  },
  methods: {
    ...mapActions("navigation", ["triggerParentScreenRefresh"]),
    beforeClose() {
      this.$emit("close");
    },
    goBack() {
      if (this.$isMobile) {
        this.beforeClose();
      } else {
        this.navigateBack = true;
      }
    },
    initForm() {
      this.allUnits = false;
      this.today = moment().format("YYYY-MM-DD");
      this.form = {
        reminderType: this.type,
        reminderName: "",
        units: [],
        driverName: "",
        driverSurname: "",
        date: moment().format("YYYY-MM-DD"),
        time: "",
        pushNotifications: false,
        emails: [this.getSubscriberEmailAddress()],
      };
    },
    isUnitSelected(vehicleId) {
      if (this.form.units) {
        return this.form.units.includes(String(vehicleId));
      }
      return false;
    },
    toggleUnit(value) {
      const index = this.form.units.indexOf(value);
      if (index === -1) {
        this.form.units.push(value);
      } else {
        this.form.units.splice(index, 1);
      }
    },
    toggleAllUnits() {
      this.allUnits = !this.allUnits;
      const elements = document.querySelectorAll("#units input[type=checkbox]");
      if (this.allUnits) {
        this.form.units = [];
        for (let i = 0, element; (element = elements[i++]); ) {
          element.checked = true;
          this.form.units.push(element.value);
        }
      } else {
        this.form.units = [];
        for (let i = 0, element; (element = elements[i++]); ) {
          element.checked = false;
        }
      }
    },
    addNotification() {
      if (this.numNotifications < 5) {
        this.numNotifications++;
      }
    },
    submit() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.submitted = true;
      if (this.isValid) {
        this.processing = true;

        const { reminderType, reminderName, units, driverName, driverSurname, date, time, pushNotifications, emails } = this.form;

        this.model.Id = this.model.Id || 0;
        this.model.Active = true;
        this.model.EditorMetadata = reminderType;
        this.model.Name = reminderName;
        this.model.DriverName = driverName;
        this.model.DriverSurname = driverSurname;

        if (!this.model.VehicleOwnerId) {
          this.model.VehicleOwnerId = this.getActiveVehicleOwnerId();
        }

        if (!this.model.Rules) {
          this.model.Rules = [];
          if (this.isVehicleReminder) {
            this.model.Rules = [
              {
                Property: "VehicleId",
                Operator: "in",
                Value: units.join(", "),
              },
            ];
          } else {
            this.model.Rules = [
              {
                Property: "VehicleId",
                Operator: "=",
                Value: this.getActiveSafeAndSoundVehicleId(),
              },
            ];
          }
        }

        if (!this.model.DateCreated) {
          this.model.DateCreated = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS");
        }

        this.model.DateSend = date + "T" + time + ":00.000";
        this.model.PushNotification = pushNotifications;
        this.model.Channels = [];

        emails.forEach((email) => {
          if (email.trim()) {
            this.model.Channels.push({
              Address: email.trim(),
              NotificationType: "Email",
            });
          }
        });

        assetService
          .updateVehicleReminder(this.model)
          .then((result) => {
            this.processing = false;
            this.submitted = false;
            if (result) {
              this.submitSuccess = true;
              setTimeout(() => {
                this.submitSuccess = false;
              }, 3000);

              if (this.$isMobile) {
                modalService.showSuccessDialog("Reminder saved successfully.");
              } else {
                alertService.showSuccessAlert();
              }

              setTimeout(() => {
                if (this.$isMobile) {
                  this.triggerParentScreenRefresh();
                }
                this.goBack();
              }, 3000);
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.processing = false;
            this.submitted = false;
            this.submitError = true;
            setTimeout(() => {
              this.submitError = false;
            }, 3000);
            if (error.userMessage) {
              alertService.showErrorAlert(error);
            }
          });
      }
    },
  },
};
</script>
