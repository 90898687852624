function initialState() {
  return {
    deferredPromptForInstall: null,
    canInstallApp: false,
  };
}

const state = initialState();

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  updateDeferredPromptForInstall(state, value) {
    state.deferredPromptForInstall = value;
  },
  updateCanInstallApp(state, value) {
    state.canInstallApp = value;
  },
};

export const pwa = {
  namespaced: true,
  state,
  mutations,
};
