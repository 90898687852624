import { store } from "@/store";

export const alertService = {
  clearAlert,
  showErrorAlert,
  showSuccessAlert,
};

function clearAlert() {
  store.commit("alert/clear");
}

function showErrorAlert(error = {}) {
  let message = "";
  if (typeof error === "string") {
    message = error;
  } else {
    message = error.userMessage
      ? error.userMessage
      : "Unable to process request at this time. Please try again.";
  }
  store.commit("alert/error", message);
  setTimeout(() => {
    store.commit("alert/clear");
  }, 8000);
}

function showSuccessAlert(message = "Success") {
  store.commit("alert/success", message);
  setTimeout(() => {
    store.commit("alert/clear");
  }, 3000);
}
