function initialState() {
  return {
    gmapRef: null,
    showMarkers: true,
    showGeoZoneEditor: false,
    selectedGeoZone: null,
    alertBeingCreated: null,
    mapLoadedWithVehicles: false,
    selectedMarkerAssetId: null,
  };
}

const state = initialState();

const actions = {
  setMapBounds({ rootState, state }) {
    if (state.gmapRef) {
      let count = 0;
      let bounds = new google.maps.LatLngBounds();
      rootState.asset.assets.forEach((asset) => {
        if (asset.show && asset.Latitude && asset.Longitude) {
          bounds.extend({ lat: asset.Latitude, lng: asset.Longitude });
          count++;
        }
      });

      if (!bounds.isEmpty()) {
        state.gmapRef.fitBounds(bounds);
      }

      if (count == 1) {
        google.maps.event.addListenerOnce(
          state.gmapRef.$mapObject,
          "idle",
          () => {
            const currentZoomLevel = state.gmapRef.$mapObject.getZoom();
            const newZoomLevel = (this._vm.$isMobile || window.innerWidth <= 480) ? 14 : 16;

            if (currentZoomLevel !== newZoomLevel) {
              state.gmapRef.$mapObject.setZoom(newZoomLevel);
            }
          }
        );
      }
    }
  },
  panToBounds({ rootState, state }) {
    if (state.showMarkers && state.gmapRef) {
      let count = 0;
      let bounds = new google.maps.LatLngBounds();
      rootState.asset.assets.forEach((asset) => {
        // Only process visible and moving vehicles
        if (
          asset.show &&
          asset.IsIgnitionOn &&
          asset.Latitude &&
          asset.Longitude
        ) {
          bounds.extend({ lat: asset.Latitude, lng: asset.Longitude });
          count++;
        }
      });
      if (count == 1) {
        state.gmapRef.panToBounds(bounds);
        state.gmapRef.$mapObject.setCenter(bounds.getCenter());
      }
    }
  },
  setMapCenter({ state }, { latitude, longitude }) {
    if (state.showMarkers && state.gmapRef) {
      const bounds = new google.maps.LatLngBounds({
        lat: latitude,
        lng: longitude,
      });
      state.gmapRef.panToBounds(bounds);
      state.gmapRef.$mapObject.setCenter(bounds.getCenter());
    }
  },
  openGeoZoneEditorForAlert({ commit }, alert) {
    if (alert) {
      commit("updateAlertBeingCreated", alert);
    }
    commit("navigation/updateShow", false, { root: true });
    commit("updateShowGeoZoneEditor", true);
  },
  openGeoZoneEditor({ commit }, geoZone) {
    if (geoZone) {
      commit("updateSelectedGeoZone", geoZone);
    }
    commit("navigation/updateShow", false, { root: true });
    commit("updateShowGeoZoneEditor", true);
  },
  closeGeoZoneEditor({ commit }) {
    commit("updateSelectedGeoZone", null);
    commit("updateShowGeoZoneEditor", false);
  },
};

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  updateGmapRef(state, ref) {
    state.gmapRef = ref;
  },
  updateShowMarkers(state, value) {
    state.showMarkers = value;
  },
  updateShowGeoZoneEditor(state, value) {
    state.showGeoZoneEditor = value;
  },
  updateSelectedGeoZone(state, value) {
    state.selectedGeoZone = value;
  },
  updateAlertBeingCreated(state, value) {
    state.alertBeingCreated = value;
  },
  updateMapLoadedWithVehicles(state, value) {
    state.mapLoadedWithVehicles = value;
  },
  updateSelectedMarkerAssetId(state, value) {
    state.selectedMarkerAssetId = value;
  },
};

export const map = {
  namespaced: true,
  actions,
  state,
  mutations,
};
