<template>
  <div class="new-alert-entry-component screen">
    <ModalHeader
      :config="{
        type: 'back',
        screenName: 'manage-alerts',
        navigateBack: navigateBack,
        title: '',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <div class="panel">
      <div class="heading">NEW ALERT TYPE</div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Speeding')">
              <img src="~@/assets/images/trips/over-speeding.png" width="40" height="40" />
              <div class="name">Speeding</div>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Braking')">
              <img src="~@/assets/images/trips/harsh-braking.png" width="40" height="40" />
              <div class="name">Harsh Braking</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Acceleration')">
              <img src="~@/assets/images/trips/harsh-acceleration.png" width="40" height="40" />
              <div class="name">Acceleration</div>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Impacts')">
              <img src="~@/assets/images/trips/impacts.png" width="40" height="40" />
              <div class="name">Impacts</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Cornering')">
              <img src="~@/assets/images/trips/harsh-cornering.png" width="40" height="40" />
              <div class="name">Cornering</div>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Lane-Change')">
              <img src="~@/assets/images/trips/lane-changing.png" width="40" height="40" />
              <div class="name">Lane-change</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Geo-Fencing')">
              <img src="~@/assets/images/trips/geo-zone.png" width="40" height="40" />
              <div class="name">Geo Zone</div>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 mb-2">
            <div class="item" @click="open('Time Rule')">
              <img src="~@/assets/images/trips/time-rule.png" width="40" height="40" />
              <div class="name">Time Rule</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.new-alert-entry-component {
  .panel {
    background: $brand-color-4;
    padding: 15px 20px;

    .heading {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        cursor: pointer;
      }

      img {
        margin-right: 10px;
      }
    }
  }
}
</style>

<script>
import { modalService } from "@/services";

export default {
  name: "NewAlertEntry",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
  },
  data() {
    return {
      navigateBack: false,
    };
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    open(alertType) {
      modalService.showModal(this, "new-alert", { type: alertType });
    },
  },
};
</script>