<template>
  <div
    class="manage-vehicle-component screen"
    :class="{ upgrade: upgradeInProgress() }"
  >
    <UpgradeStepHeader
      :stepNo="2"
      title="Confirm Vehicle Details"
      nextStepScreenName="financial-details"
      :showNextButton="formIsInitialised"
      :showSpinner="!formIsInitialised"
      :formValidationFailureCount="formValidationFailureCount"
      :stepCompleted="upgradeStepCompleted"
      @submit="update()"
      @close="beforeClose()"
      v-if="upgradeInProgress()"
    />
    <template v-else>
      <SheetVehicleFeatureHeader title="Manage Vehicle" v-if="$isMobile" />
      <ModalHeader
        v-else
        :config="{
          type: 'vehicle',
          title: 'Manage Vehicle',
          subTitle: '',
          tooltipScreenName: 'manage-vehicle',
        }"
        @close="beforeClose()"
      />
    </template>
    <Alert />
    <div class="top">
      <div class="heading">VEHICLE INFO</div>
      <div class="buttons" v-if="!upgradeInProgress()">
        <button class="btn btn-outline mr-3" @click="cancel()" v-if="editing">
          CANCEL
        </button>
        <button
          class="btn btn-outline"
          :disabled="loading"
          @click="editing = true"
          v-if="!editing"
        >
          <span v-show="!loading">EDIT</span>
          <Spinner v-show="loading" />
        </button>
        <button class="btn btn-outline inverted" @click="update()" v-else>
          <span v-show="!saving">UPDATE</span>
          <Spinner v-show="saving" />
        </button>
      </div>
    </div>
    <GradientScroll class="form-container">
      <form ref="form" :class="{ editing: editing }" @submit.prevent>
        <div class="mb-3 d-flex">
          <div id="avatar">
            <img
              :src="userImageUrl"
              width="80"
              height="80"
              v-if="userImageUrl"
            />
            <AssetIcon
              :registrationNumber="activeRegistrationNumber"
              :vehicleId="activeSafeAndSoundAssetId"
              v-else
            />
            <div class="d-flex justify-content-center">
              <button type="button" id="choose-image" v-show="editing">
                Edit
              </button>
              <div v-if="editing && hasImageSavedInCRM">
                |<button type="button" @click="confirmImageDeletion">
                  Delete
                </button>
              </div>
            </div>
            <avatar-cropper
              :labels="{
                submit: 'Submit',
                cancel: 'Cancel',
              }"
              :output-options="{
                width: 150,
                height: 150,
              }"
              mimes="image/jpeg"
              trigger="#choose-image"
              :uploadHandler="imageUploadHandler"
              @error="imageError"
            />
          </div>
          <div class="form-group flex-grow-1 mt-2">
            <input
              type="text"
              class="form-control"
              id="nickname"
              v-model="model.Nickname"
              :disabled="!editing"
            />
            <label for="nickname">Nickname</label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="vehicleType"
                v-model="model.VehicleType"
                :options="vehicleTypes"
                :reduce="(item) => item.value"
                :disabled="true"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!model.VehicleType"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="vehicleType">Vehicle Type</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                id="odometerReading"
                v-model.number="model.OdoMeter"
                :disabled="!editing || isBasicIoT"
                required
              />
              <label for="odometerReading"
                >Odometer Reading{{
                  isBasicIoT ? " (Not Supported)" : ""
                }}</label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="make"
                @input="vehicleMakeChange"
                v-model="model.Make.MakeId"
                :options="makes"
                :reduce="(item) => item.value"
                :disabled="true"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!model.Make.MakeId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="make">Make</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                :class="{ loading: loading }"
                id="model"
                v-model="model.Model.ModelId"
                :options="vehicleModels"
                :reduce="(item) => item.value"
                :disabled="true"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!model.Model.ModelId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="model">Model</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="colour"
                v-model="model.Colour"
                :options="colours"
                :reduce="(item) => item.value"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!model.Colour"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="colour">Colour</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="engineNumber"
                v-model="model.EngineNumber"
                :disabled="true"
                required
              />
              <label for="engineNumber">Engine Number</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="registrationNumber"
                v-model="model.RegistrationNumber"
                :disabled="!editing"
                required
              />
              <label for="registrationNumber">Registration Number</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="chassisNumber"
                v-model="model.ChassisNumber"
                :disabled="true"
                required
              />
              <label for="chassisNumber">VIN/Chassis Number</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="registrationType"
                v-model="model.RegistrationType"
                :options="registrationTypes"
                :reduce="(item) => parseInt(item.value)"
                :disabled="!editing"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!model.RegistrationType"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="registrationType">Registration Type</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <v-select
                class="form-control"
                id="yearOfRegistration"
                v-model="model.YearOfRegistration"
                :options="yearsOfRegistration"
                :reduce="(item) => item.value"
                :disabled="true"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!model.YearOfRegistration"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
              <label for="yearOfRegistration">Year of Registration</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6" v-if="isBusinessAccount">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="fleetNumber"
                v-model="model.FleetNumber"
                :disabled="!editing"
              />
              <label for="fleetNumber">Fleet Number</label>
            </div>
          </div>
        </div>
        <div class="subheading">REGULAR DRIVER</div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="driverFirstName"
                v-model="model.RegularDriver.firstName"
                :disabled="!editing"
                required
              />
              <label for="driverFirstName">First Name</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="driverSurname"
                v-model="model.RegularDriver.surname"
                :disabled="!editing"
                required
              />
              <label for="driverSurname">Surname</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="driverContactNumber"
                pattern="[0-9]{10}"
                v-model="model.RegularDriver.contactNumber"
                :disabled="!editing"
                required
              />
              <label for="driverContactNumber">Number</label>
            </div>
          </div>
          <div class="col-12 col-md-6"></div>
        </div>
        <template v-for="(contact, i) in model.EmergencyContacts">
          <div class="subheading" :key="i + '0'">
            EMERGENCY CONTACT {{ i + 1 }}
          </div>
          <div class="row" :key="i + '1'">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  :id="'emergencyContact' + i + 'Name'"
                  v-model="contact.contactName"
                  :disabled="!editing"
                  required
                />
                <label :for="'emergencyContact' + i + 'Name'">Name</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  :id="'emergencyContact' + i + 'ContactNumber'"
                  pattern="[0-9]{10}"
                  v-model="contact.contactNumber"
                  :disabled="!editing"
                  required
                />
                <label :for="'emergencyContact' + i + 'ContactNumber'"
                  >Number</label
                >
              </div>
            </div>
            <!-- <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :id="'emergencyContact'+i+'Surname'"
                v-model="contact.contactSurname"
                :disabled="!editing"
                required
              />
              <label :for="'emergencyContact'+i+'Surname'">Surname</label>
            </div>
          </div>-->
          </div>
        </template>
      </form>
    </GradientScroll>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.manage-vehicle-component {
  display: flex;
  flex-direction: column;
  max-height: $modalHeight;

  .mobile &:not(.upgrade) {
    max-height: 60vh;
  }

  @include scrollbar;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .heading {
      color: $brand-color-2;
    }

    .buttons {
      margin-right: 34px;

      button {
        min-width: 90px;
      }
    }
  }

  .form-container {
    margin-left: -15px;

    form {
      padding: 10px 30px 0 15px;

      &.editing {
        .subheading {
          color: $brand-color-1;
        }
      }

      .form-control {
        text-transform: uppercase;
      }
    }
  }

  #avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 95px;
    margin-right: 20px;

    img {
      border-radius: 50%;
      box-shadow: $drop-shadow;
    }

    button {
      background: transparent;
      font-size: 0.75rem;
      font-weight: 600;
      color: $brand-color-1;
      margin-top: 8px;
      border: none;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import {
  alertService,
  assetService,
  modalService,
  utilityService,
} from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import UpgradeStepHeader from "@/components/screens/UpgradeStepHeader";

export default {
  name: "ManageVehicle",
  components: {
    AssetIcon: () => import("@/components/AssetIcon"),
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
    UpgradeStepHeader,
    AvatarCropper,
  },
  data() {
    return {
      loading: true,
      editing: false,
      saving: false,
      unsavedChanges: false,
      formValidationFailureCount: 0,
      upgradeStepCompleted: false,
      formIsInitialised: false,
      userImageUrl: "",
      model: {
        EmergencyContacts: [
          {
            contactName: "",
            contactNumber: "",
            contactType: "",
            contactRelation: "",
          },
          {
            contactName: "",
            contactNumber: "",
            contactType: "",
            contactRelation: "",
          },
        ],
        VehicleId: "",
        RegistrationNumber: "",
        FleetNumber: "",
        ChassisNumber: "",
        OdoMeter: "",
        Make: {
          MakeId: "",
          MakeName: "",
          MakeCode: "",
        },
        Model: {
          ModelId: "",
          ModelName: "",
          MMCode: "",
          ModelCode: "",
        },
        Nickname: "",
        ImageUrl: "",
        VehicleType: "",
        FuelType: "",
        YearOfRegistration: "",
        TransmissionType: "",
        Colour: "",
        EngineNumber: "",
        RegistrationType: "",
        RegularDriver: {
          driverId: "",
          title: "",
          firstName: "",
          surname: "",
          contactNumber: "",
        },
      },
      modelUnedited: {},
      vehicleTypes: [],
      yearsOfRegistration: [],
      makes: [],
      vehicleModels: [],
      fuelTypes: [],
      transmissionTypes: [],
      colours: [],
      registrationTypes: [],
    };
  },
  created() {
    if (this.upgradeInProgress()) {
      this.editing = true;
    }

    const vehicleId = this.getActiveVehicleId();
    const vehicleDetailsRequest = assetService.getVehicleDetails(vehicleId);
    const vehicleTypesRequest = utilityService.getVehicleTypes();
    const vehicleYearsOfRegistrationRequest = utilityService.getVehicleYearsOfRegistration();
    const vehicleMakesRequest = utilityService.getVehicleMakes();
    const vehicleColoursRequest = utilityService.getVehicleColours();
    const vehicleRegistrationTypesRequest = utilityService.getVehicleRegistrationTypes();
    const startTime = Date.now();

    Promise.all([
      vehicleDetailsRequest,
      vehicleTypesRequest,
      vehicleYearsOfRegistrationRequest,
      vehicleMakesRequest,
      vehicleColoursRequest,
      vehicleRegistrationTypesRequest,
    ])
      .then((responses) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.vehicleTypes = responses[1];
            this.yearsOfRegistration = responses[2];
            this.makes = responses[3];
            this.colours = responses[4];
            this.registrationTypes = responses[5];
            this.model = responses[0];

            this.model.ImageUrl = ""; // Leave blank unless new image is provided

            if (this.model.RegularDriver == null) {
              this.model.RegularDriver = {
                firstName: "",
                surname: "",
                contactNumber: "",
              };
            }

            this.modelUnedited = JSON.parse(JSON.stringify(this.model));

            utilityService
              .getVehicleModels(this.model.Make.MakeId)
              .then((data) => {
                this.vehicleModels = data;
                this.loading = false;
                this.formIsInitialised = true;
              });
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((errors) => {
        this.error(errors);
      });
  },
  computed: {
    ...mapGetters("account", { isBusinessAccount: "getIsBusinessAccount" }),
    ...mapGetters("asset", [
      "getActiveVehicleId",
      "getActiveSafeAndSoundVehicleId",
      "getActiveVehicleRegistrationNumber",
      "getActiveVehicleProductName",
    ]),
    ...mapGetters("asset", {
      vehicleImageUrl: "getActiveVehicleImageUrl",
      upgradeInProgress: "getUpgradeInProgress",
    }),
    activeAssetId() {
      return this.getActiveVehicleId();
    },
    activeSafeAndSoundAssetId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
    activeRegistrationNumber() {
      return this.getActiveVehicleRegistrationNumber();
    },
    hasImageSavedInCRM() {
      return (
        this.vehicleImageUrl() != null && this.vehicleImageUrl().length > 0
      );
    },
    isBasicIoT() {
      return this.getActiveVehicleProductName() === "Netstar Basic IoT";
    },
  },
  methods: {
    ...mapActions("alert", ["error"]),
    ...mapMutations("asset", [
      "updateVehicleImageUrl",
      "updateVehicleNickname",
    ]),
    beforeClose() {
      if (this.unsavedChanges) {
        modalService.showSaveDialog(
          this,
          "Do you want to save the changes made to your vehicle details before leaving?",
          this.update
        );
      } else {
        this.$emit("close");
      }
    },
    imageUploadHandler(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas({
          width: 150,
          height: 150,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg");

      if (dataUrl) {
        this.userImageUrl = dataUrl;
        this.model.ImageUrl = dataUrl.split(",")[1];
      }
    },
    imageError(error) {
      alertService.showErrorAlert(error);
    },
    vehicleMakeChange(vehicleMakeId) {
      utilityService.getVehicleModels(vehicleMakeId).then((data) => {
        this.vehicleModels = data;
        this.model.Model.ModelId = "";
      });
    },
    getVehicleDetails() {
      this.loading = true;
      this.editing = false;
      this.userImageUrl = "";
      const vehicleId = this.getActiveVehicleId();
      assetService.getVehicleDetails(vehicleId).then((data) => {
        this.model = data;
        this.model.ImageUrl = "";
        this.modelUnedited = JSON.parse(JSON.stringify(this.model));

        utilityService.getVehicleModels(this.model.Make.MakeId).then((data) => {
          this.vehicleModels = data;
          this.loading = false;
        });
      });
    },
    confirmImageDeletion() {
      modalService.showDeleteDialog(
        "Are you sure you want to delete the image?",
        this.deleteImageFromCRM
      );
    },
    deleteImageFromCRM() {
      this.userImageUrl = "";

      this.updateVehicleImageUrl({
        vehicleId: this.activeSafeAndSoundAssetId,
        value: "",
      });

      const vehicleId = this.getActiveVehicleId();
      assetService.deleteVehicleImage(vehicleId);
    },
    cancel() {
      if (this.editing) {
        this.editing = false;
        this.model = this.modelUnedited;
      } else {
        this.beforeClose();
      }
    },
    update() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
        return true;
      } else {
        this.formValidationFailureCount++;
        return false;
      }
    },
    handleSubmit() {
      if (this.$isDevEnv && this.upgradeInProgress()) {
        this.upgradeStepCompleted = true;
        return;
      }

      let vehicleDetails = JSON.parse(JSON.stringify(this.model));
      vehicleDetails.SnsVehicleId = JSON.parse(
        JSON.stringify(this.activeSafeAndSoundAssetId)
      );

      this.saving = true;
      assetService
        .updateVehicleDetails(vehicleDetails)
        .then((result) => {
          this.editing = false;
          this.saving = false;
          if (this.wasUpdatedSuccessfully(result)) {
            if (this.upgradeInProgress()) {
              try {
                this.$gtag.event("upgrade_vehicle_details");
              } catch (err) {}

              this.upgradeStepCompleted = true;
            }

            this.unsavedChanges = false;

            if (this.model.Nickname) {
              this.updateVehicleNickname({
                vehicleId: this.activeSafeAndSoundAssetId,
                value: this.model.Nickname,
              });
            }

            if (
              result.message &&
              result.message.toLowerCase().startsWith("http")
            ) {
              this.updateVehicleImageUrl({
                vehicleId: this.activeSafeAndSoundAssetId,
                value: result.message,
              });
            }

            modalService.showSuccessDialog(
              "Vehicle details updated successfully."
            );
          } else {
            modalService.showErrorDialog();
          }
        })
        .catch((error) => {
          this.saving = false;
          modalService.showErrorDialog(
            error.userMessage ? error.userMessage : ""
          );
        });
    },
    wasUpdatedSuccessfully(responseData) {
      if (!responseData) {
        return false;
      }

      if (!responseData.result) {
        return false;
      }

      if (
        this.model.ImageUrl &&
        (!responseData.message ||
          !responseData.message.toLowerCase().startsWith("http"))
      ) {
        return false;
      }

      return true;
    },
    next() {
      modalService.showModal(this, "financial-details", {});
    },
  },
  watch: {
    activeAssetId() {
      this.getVehicleDetails();
    },
    model: {
      deep: true,
      handler() {
        this.unsavedChanges =
          JSON.stringify(this.model) !== JSON.stringify(this.modelUnedited);
      },
    },
  },
};
</script>
