import { api } from "@/services/api.service";

export const utilityService = {
  getTitles,
  getPostalCodes,
  getSuburbCities,
  getVehicleTypes,
  getVehicleYearsOfRegistration,
  getVehicleMakes,
  getVehicleModels,
  getVehicleFuelTypes,
  getVehicleTransmissionTypes,
  getVehicleColours,
  getVehicleRegistrationTypes,
  getBanks,
  getBankBranches,
  getBankAccountTypes,
};

const apiBasePath = "/api/utility";

function getTitles() {
  return api.get(`${apiBasePath}/get-titles`).then((request) => {
    return request.data;
  });
}

function getPostalCodes() {
  return api.get(`${apiBasePath}/get-postal-codes`).then((request) => {
    return request.data;
  });
}

function getSuburbCities(postalCode) {
  return api
    .get(`${apiBasePath}/get-suburb-cities`, {
      params: {
        postalCode: postalCode,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleTypes() {
  return api.get(`${apiBasePath}/get-vehicle-types`).then((request) => {
    return request.data;
  });
}

function getVehicleYearsOfRegistration() {
  return api
    .get(`${apiBasePath}/get-vehicle-years-of-registration`)
    .then((request) => {
      return request.data;
    });
}

function getVehicleMakes() {
  return api.get(`${apiBasePath}/get-vehicle-makes`).then((request) => {
    return request.data;
  });
}

function getVehicleModels(vehicleMakeId) {
  return api
    .get(`${apiBasePath}/get-vehicle-models`, {
      params: {
        vehicleMakeId: vehicleMakeId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getVehicleFuelTypes() {
  return api.get(`${apiBasePath}/get-vehicle-fuel-types`).then((request) => {
    return request.data;
  });
}

function getVehicleTransmissionTypes() {
  return api
    .get(`${apiBasePath}/get-vehicle-transmission-types`)
    .then((request) => {
      return request.data;
    });
}

function getVehicleColours() {
  return api.get(`${apiBasePath}/get-vehicle-colours`).then((request) => {
    return request.data;
  });
}

function getVehicleRegistrationTypes() {
  return api
    .get(`${apiBasePath}/get-vehicle-registration-types`)
    .then((request) => {
      return request.data;
    });
}

function getBanks() {
  return api.get(`${apiBasePath}/get-banks`).then((request) => {
    return request.data;
  });
}

function getBankBranches(bankId) {
  return api
    .get(`${apiBasePath}/get-bank-branches`, {
      params: {
        bankId: bankId,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getBankAccountTypes() {
  return api.get(`${apiBasePath}/get-bank-account-types`).then((request) => {
    return request.data;
  });
}
