<template>
  <div class="product-upgrades-component screen">
    <ProductUpgradesHeader
      :config="{
        title: 'Your Upgrade Options',
      }"
      @close="beforeClose()"
    />
    <Alert />
    <SmoothReflow class="content">
      <div class="container">
        <div class="preloader" v-if="loading">
          <Spinner />
        </div>
        <ErrorBox :message="errorMessage" v-else-if="errorMessage" />
        <template v-else>
          <div class="heading">
            <span class="font-weight-bold">CURRENT PACKAGE:</span>
            <span class="text-uppercase ml-2"
              >NETSTAR {{ getActiveVehicleProductName() }}</span
            >
          </div>
          <div class="row">
            <div class="col" v-if="plusProduct.isAvailable">
              <div class="panel">
                <div class="top">
                  <img
                    src="~@/assets/images/product-features/netstar-plus.png"
                    width="54"
                    height="34"
                    alt
                  />
                  <div class="info">
                    <div>GET NETSTAR PLUS</div>
                    <div class="highlight" v-if="isNano">
                      R{{ plusProduct.price }} p/m
                    </div>
                    <div class="highlight" v-if="isBasic">
                      For just R30 more p/m
                    </div>
                  </div>
                </div>
                <div class="features">
                  <div class="intro mb-3">
                    <div><span>YOU</span> GET</div>
                  </div>
                  <div class="feature standout">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/web-app.png"
                        width="13"
                        height="20"
                        alt
                      />
                    </div>
                    <span>MyNetstar Live Tracking</span>
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/recovery-services.png"
                        width="25"
                        height="15"
                        alt
                      />
                    </div>
                    <span>Stolen Vehicle Recovery Services</span>
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/fitment-certificates.png"
                        width="17"
                        height="18"
                        alt
                      />
                    </div>
                    <span>Fitment Certificate for Insurance</span>
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/signal-jamming.png"
                        width="21"
                        height="18"
                        alt
                      />
                    </div>
                    <span
                      >Signal Jamming Detection Alert
                      (JammingResist<sup>TM</sup>)</span
                    >
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/impact-detection.png"
                        width="27"
                        height="14"
                        alt
                      />
                    </div>
                    <span>Impact Detection for Safety</span>
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/battery-disconnect.png"
                        width="17"
                        height="16"
                        alt
                      />
                    </div>
                    <span>Battery Disconnect Alert</span>
                  </div>
                  <h2 class="subheading mt-2" v-if="isBasic">
                    New <span>Features</span>
                  </h2>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/logbook.png"
                        width="21"
                        height="16"
                        alt
                      />
                    </div>
                    <span>Logbook for SARS</span>
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/driver-behaviour.png"
                        width="19"
                        height="19"
                        alt
                      />
                    </div>
                    <span>Personal Driver Behaviour Rating</span>
                  </div>
                  <div class="feature">
                    <div class="icon">
                      <img
                        src="~@/assets/images/product-features/car-park-jamming.png"
                        width="21"
                        height="16"
                        alt
                      />
                    </div>
                    <span>Car Park Jamming Alert</span>
                  </div>
                </div>
              </div>
              <div class="footer">
                <!-- <div>TOTAL: <span class="font-weight-bold">R169 p/m</span></div> -->
                <div>
                  <button
                    class="btn btn-outline"
                    @click="upgrade('Netstar Plus', plusProduct.partnerCode)"
                  >
                    UPGRADE TO <span>PLUS</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="col">
            <div class="panel">
              <div class="top">
                <img src="~@/assets/images/product-features/netstar-early-warning.png" width="53" height="41" alt />
                <div class="info">
                  <div>GET NETSTAR EARLY WARNING</div>
                  <div class="highlight">for just R100 more p/m</div>
                </div>
              </div>
              <div class="features">
                <div class="intro mb-1">
                  <div><span>YOU</span> GET</div>
                  <div class="product-highlight">
                    <div class="left">ALL</div>
                    <div class="right">NETSTAR <strong>PLUS</strong> FEATURES</div>
                  </div>
                  <div class="plus">+</div>
                </div>
                <div class="feature">
                  <div class="icon">
                    <img src="~@/assets/images/product-features/auto-arm.png" width="18" height="20" alt />
                  </div>
                  <span>Auto-arm Proximity Tag for Security</span>
                </div>
                <div class="feature">
                  <div class="icon">
                    <img src="~@/assets/images/product-features/panic-button.png" width="18" height="13" alt />
                  </div>
                  <span>Wireless Panic Button</span>
                </div>
                <div class="feature">
                  <div class="icon">
                    <img src="~@/assets/images/product-features/early-warning-theft.png" width="18" height="18" alt />
                  </div>
                  <span>Early Warning Theft Alert</span>
                </div>
                <div class="feature">
                  <div class="icon">
                    <img src="~@/assets/images/product-features/tow-away.png" width="24" height="13" alt />
                  </div>
                  <span>Tow-away Alert</span>
                </div>
              </div>
            </div>
          </div> -->
          </div>
        </template>
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.product-upgrades-component {
  display: flex;
  flex-direction: column;
  max-height: $modalHeight;
  padding: 0;
  overflow: hidden;

  .preloader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading {
    font-weight: normal;
    color: $brand-color-2;
    margin-bottom: 15px;
  }

  @include scrollbar;

  .content {
    overflow-y: scroll;

    .container {
      padding: 30px;

      .panel {
        display: flex;
        flex-direction: column;
        background: $brand-color-4;
        // height: 100%;
        padding: 20px;

        .top {
          display: flex;
          align-items: center;
          font-size: 1.375rem;
          font-weight: bold;
          margin-bottom: 20px;

          .info {
            padding-left: 10px;

            .highlight {
              display: inline-block;
              background: $brand-color-1;
              font-size: 1.125rem;
              font-style: normal;
              color: #fff;
              padding: 5px 15px 5px 10px;
              clip-path: polygon(0 0, 100% 0%, 92% 100%, 0% 100%);
            }
          }
        }

        .features {
          background: $brand-color-2;
          padding: 20px;
          flex: 1;

          .intro {
            font-size: 1.125rem;
            color: #fff;
            // margin-bottom: 15px;

            > div:first-of-type > span {
              font-weight: bold;
            }

            .product-highlight {
              display: table;
              font-style: normal;
              margin: 10px 0;
              border: 1px solid #fff;

              .left {
                display: table-cell;
                background: #fff;
                font-weight: bold;
                color: $brand-color-2;
                padding: 5px;
              }

              .right {
                display: table-cell;
                padding: 5px 10px;
              }
            }

            .plus {
              line-height: 1;
              font-size: 36px;
              font-weight: 200;
              padding-left: 10px;
            }
          }

          .subheading {
            display: inline-block;
            background: $brand-color-1;
            font-style: normal;
            font-weight: 400;
            color: #fff;
            padding: 5px 15px 5px 10px;
            margin-bottom: 10px;
            border: none;
            clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);

            span {
              font-weight: bold;
            }
          }

          .feature {
            display: flex;
            align-items: center;
            font-weight: 600;
            color: #fff;
            margin-bottom: 5px;

            &.standout {
              display: inline-flex;
              background: #fff;
              color: $brand-color-1;
              padding: 3px 25px 3px 20px;
              margin-left: -20px;
              clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 27px;
              height: 27px;
              text-align: center;
              margin-right: 15px;
            }
          }
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $brand-color-2;
        padding: 20px 0;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { adminService, assetService, modalService } from "@/services";

export default {
  name: "ProductUpgradeOptions",
  components: {
    ProductUpgradesHeader: () =>
      import("@/components/screens/ProductUpgradesHeader"),
  },
  props: ["mobile"],
  data() {
    return {
      loading: true,
      errorMessage: null,
      plusProduct: {
        isAvailable: false,
        price: 0,
      },
    };
  },
  computed: {
    ...mapGetters("asset", [
      "getActiveVehicleId",
      "getActiveVehicleProductName",
      "getActiveVehicleUnitSerialNumber",
      "getSubscriberIdNumber",
    ]),
    isNano() {
      return this.getActiveVehicleProductName() === "Nano";
    },
    isBasic() {
      return this.getActiveVehicleProductName() === "Basic";
    },
  },
  created() {
    const startTime = Date.now();
    assetService
      .preUpgradeValidation(
        this.getActiveVehicleId(),
        this.getActiveVehicleUnitSerialNumber()
      )
      .then((data) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            if (data.CanUpgrade) {
              for (const upgradeOption of data.UpgradeDetails) {
                const upgradeOptionName = upgradeOption.DealOption.Name;
                if (upgradeOptionName.startsWith("NETSTAR PLUS")) {
                  this.plusProduct = {
                    isAvailable: true,
                    price: upgradeOption.NewPremium,
                    partnerCode: upgradeOption.DealOption.PartnerCode,
                  };
                }
              }
            } else {
              if (!data.IsBankValid) {
                if (data.IsOneDebit) {
                  this.errorMessage =
                    "No upgrades currently available for pre-paid contracts.";
                } else if (data.IsUpfront) {
                  this.errorMessage =
                    "No upgrades currently available for pre-paid contracts.";
                } else {
                  this.errorMessage =
                    "Please update your banking details before upgrading.";
                }
              } else if (data.IsAccountInLegal) {
                this.errorMessage =
                  "No upgrades available, please contact Netstar for more information.";
                adminService.isInLegal(this.getSubscriberIdNumber());
              } else if (data.PendingUpgrade) {
                this.errorMessage =
                  "Upgrade pending, please check again in a few minutes.";
              } else {
                this.errorMessage = "No upgrades currently available.";
              }
            }
            this.loading = false;
          },
          timeElapsed < 1000 ? 1000 - timeElapsed : 0
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  methods: {
    ...mapMutations("asset", ["setUpgradeStarted"]),
    beforeClose() {
      this.$emit("close");
    },
    showModal(screenName, parameters = {}) {
      modalService.showModal(this, screenName, parameters);
    },
    upgrade(productName, partnerCode) {
      try {
        let eventName = "";

        switch (productName) {
          case "Netstar Plus":
            eventName = "upgrade_to_plus";
            break;
        }

        this.$gtag.event(eventName);
      } catch (err) {}

      this.setUpgradeStarted({ productName, partnerCode });
      modalService.showModal(this, "personal-details", {});
    },
  },
};
</script>
