<template>
  <div class="spinner-component">
    <span class="spinner-border" :class="!large ? 'spinner-border-sm' : ''" role="status" aria-hidden="true" />
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: { large: Boolean },
};
</script>