import { api } from "@/services/api.service";

export const adminService = {
  getCustomerDetails,
  updateCustomerDetails,
  deleteProfileImage,
  getFinancialDetails,
  updateFinancialDetails,
  sendOTP,
  sendFeedback,
  logFault,
  verifyPassword,
  changePassword,
  isInLegal,
};

const apiBasePath = "/api/admin";

function getCustomerDetails() {
  return api.get(`${apiBasePath}/get-customer-details`).then((request) => {
    return request.data;
  });
}

function updateCustomerDetails(model) {
  return api
    .post(`${apiBasePath}/update-customer-details`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function deleteProfileImage(idNumber) {
  return api
    .delete(`${apiBasePath}/delete-profile-image`, {
      params: {
        idNumber: idNumber,
      },
    })
    .then((request) => {
      return request.data;
    });
}

function getFinancialDetails() {
  return api.get(`${apiBasePath}/get-financial-details`).then((request) => {
    return request.data;
  });
}

function updateFinancialDetails(model, otp) {
  return api
    .post(`${apiBasePath}/update-financial-details`, {
      model: model,
      otp: otp,
    })
    .then((request) => {
      return request.data;
    });
}

function sendOTP() {
  return api.get(`${apiBasePath}/send-otp`).then((request) => {
    return request.data;
  });
}

function sendFeedback(model) {
  return api
    .post(`${apiBasePath}/send-feedback`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function logFault(model) {
  return api
    .post(`${apiBasePath}/log-fault`, {
      model: model,
    })
    .then((request) => {
      return request.data;
    });
}

function verifyPassword(password) {
  return api
    .post(`${apiBasePath}/verify-password`, {
      password: password,
    })
    .then((request) => {
      return request.data;
    });
}

function changePassword(params) {
  return api.post(`${apiBasePath}/change-password`, params).then((request) => {
    return request.data;
  });
}

function isInLegal(idNumber) {
  return api
    .post(`${apiBasePath}/is-in-legal`, {
      idNumber: idNumber,
    })
    .then((request) => {
      return request.data;
    });
}
