<template>
  <div class="panel-spinner-component">
    <span class="spinner-border" role="status" aria-hidden="true" />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.panel-spinner-component {
  width: 100%;
  text-align: center;

  .spinner-border {
    width: 30px;
    height: 30px;
    color: $brand-color-1;
  }
}
</style>

<script>
export default {
  name: "PanelSpinner",
};
</script>