<template>
  <div class="sheet-vehicle-feature-header-component">
    <div class="heading">{{ title }}</div>
    <VehiclePickerMenu v-if="hasVehiclePicker" />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.sheet-vehicle-feature-header-component {
  display: flex;
  margin-bottom: 15px;

  .heading {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.125rem;
    color: $brand-color-1;
    text-transform: uppercase;
    margin-right: 10px;
  }
}
</style>

<script>
import VehiclePickerMenu from "@/components/screens/VehiclePickerMenu";

export default {
  name: "SheetVehicleFeatureHeader",
  components: {
    VehiclePickerMenu,
  },
  props: {
    title: {
      type: String,
    },
    hasVehiclePicker: {
      type: Boolean,
      default: true,
    },
  },
};
</script>