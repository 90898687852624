<template>
  <div class="share-vehicles-component screen">
    <div class="sheet-heading" v-if="$isMobile">Share Vehicles</div>
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Share Vehicles',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <SmoothReflow>
      <template v-if="nothingShared">
        <div class="intro">
          <div class="panel">
            <div class="message-box mb-4">
              <div class="icon">
                <img src="~@/assets/images/pin.svg" width="22" height="22" />
              </div>
              <span>Share your vehicle location with other users.</span>
            </div>
            <div class="message-box mb-4">
              <div class="icon">
                <img src="~@/assets/images/share-vehicle2.svg" width="22" height="22" />
              </div>
              <span>View & manage vehicles shared with you.</span>
            </div>
            <div class="message-box mb-4">
              <div class="icon">
                <img src="~@/assets/images/trash.svg" width="22" height="22" />
              </div>
              <span>Easily add, remove or hide users.</span>
            </div>
            <div class="buttons">
              <button class="btn btn-outline" @click="nothingShared = false">GET STARTED</button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <Alert />
        <div class="panel mb-3">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="subheading">I'M SHARING WITH:</div>
            <button class="btn btn-outline" @click="showModal('new-share-vehicle')">ADD USER</button>
          </div>
          <PanelSpinner v-if="sharedWithOthersLoading" />
          <template v-else>
            <template v-if="sharedWithOthers.length">
              <div class="item container mb-3" v-for="(item, i) in sharedWithOthers" :key="item.Id">
                <div class="row align-items-center">
                  <div class="col-12 col-md-7 mb-3 mb-md-0">
                    <span class="name">{{ item.Owner.Name + " " + item.Owner.Surname }}</span>
                  </div>
                  <div class="col-4 col-md-2 text-center text-md-right mb-3 mb-md-0">
                    <img src="~@/assets/images/edit.svg" class="button" width="25" height="25" alt @click="edit(item.Id)" />
                  </div>
                  <div class="col-4 col-md-2 text-center mb-3 mb-md-0">
                    <Spinner v-if="processing && toggledSharedVehicleAccessId === item.Id" />
                    <div class="custom-control custom-switch" v-else>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="'sharingWithSwitch' + i"
                        v-model="item.Owner.Active"
                        :disabled="processing"
                        @click="toggleActive(item)"
                      />
                      <label class="custom-control-label" :for="'sharingWithSwitch' + i" />
                    </div>
                  </div>
                  <div class="col-4 col-md-1 text-center text-md-left p-0 mb-3 mb-md-0">
                    <Spinner v-if="processing && deletedSharedVehicleAccessId === item.Id" />
                    <svg
                      v-else
                      width="25"
                      height="25"
                      viewBox="0 0 16 16"
                      class="delete-icon button"
                      fill="currentColor"
                      @click="deleteItem('sharedWithOthers', item.Id)"
                    >
                      <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                      <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            </template>
            <div class="message-box" v-else>
              <div class="icon">
                <img src="~@/assets/images/pin.svg" width="22" height="22" />
              </div>
              You are not sharing vehicles with anyone yet.
            </div>
          </template>
        </div>
        <div class="panel">
          <div class="subheading mb-4">SHARED WITH ME:</div>
          <PanelSpinner v-if="sharedWithMeLoading" />
          <template v-else>
            <template v-if="sharedWithMe.length">
              <div class="item container mb-3" v-for="item in sharedWithMe" :key="item.Id">
                <div class="row align-items-center">
                  <div class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0">
                    <div class="icon">
                      <img src="~@/assets/images/share-vehicle2.svg" width="22" height="22" />
                    </div>
                    <span class="reg">{{ item.RegistrationNumber }}</span>
                  </div>
                  <div class="col-md-4 text-center mb-3 mb-md-0">
                    <span class="make">{{ item.Make }}</span>
                    <br />
                    {{ item.Model }}
                  </div>
                  <div class="col-12 col-md-4 text-center mb-3 mb-md-0">
                    <div>{{ item.vehicleOwner.Name }}</div>
                    <div>{{ item.vehicleOwner.Surname }}</div>
                  </div>
                  <!-- <div class="col-6 col-md-2 text-center p-0 mb-3 mb-md-0">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'sharedWithMeSwitch'+i"
                      v-model="item.IsActive"
                    />
                    <label class="custom-control-label" :for="'sharedWithMeSwitch'+i" />
                  </div>
                  </div>-->
                  <div class="col-12 col-md-1 text-center p-0 mb-3 mb-md-0">
                    <Spinner v-if="processing && deletedSharedWithMeVehicleId === item.SafeAndSoundVehicleId" />
                    <svg
                      v-else
                      width="25"
                      height="25"
                      viewBox="0 0 16 16"
                      class="delete-icon button"
                      fill="currentColor"
                      @click="deleteItem('sharedWithMe', item.SafeAndSoundVehicleId)"
                    >
                      <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                      <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            </template>
            <div class="message-box" v-else>
              <div class="icon">
                <img src="~@/assets/images/share-vehicle2.svg" width="22" height="22" />
              </div>
              No vehicles have been shared with you yet.
            </div>
          </template>
        </div>
      </template>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.share-vehicles-component {
  .subheading {
    color: $brand-color-1;
  }

  .panel {
    background: $brand-color-4;
    padding: 1.5rem;

    .item {
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      box-shadow: $drop-shadow-light;

      &:last-of-type {
        margin-bottom: 0 !important;
      }

      .icon {
        padding: 5px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid $brand-color-2;
      }

      .name,
      .reg,
      .make {
        font-weight: 600;
      }

      .delete-icon {
        fill: $brand-color-1;
      }

      .button {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .message-box {
      background: #fff;
      position: relative;
      font-weight: 600;
      color: $brand-color-1;
      text-align: center;
      padding: 25px;
      margin-left: 0.5rem;
      border: 2px solid $brand-color-2;
      box-shadow: $drop-shadow-light;

      .icon {
        background: #fff;
        position: absolute;
        top: 50%;
        left: -17px;
        padding: 5px;
        transform: translateY(-50%);
        border-radius: 50%;
        border: 2px solid $brand-color-2;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { alertService, assetService, modalService } from "@/services";

export default {
  name: "ShareVehicles",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
  },
  data() {
    return {
      processing: false,
      sharedWithMeLoading: true,
      sharedWithOthersLoading: true,
      nothingShared: false,
      deletedSharedVehicleAccessId: null,
      deletedSharedWithMeVehicleId: null,
      toggledSharedVehicleAccessId: null,
      sharedWithMe: [],
      sharedWithOthers: [],
    };
  },
  computed: {
    ...mapGetters("asset", ["getVehiclesSharedWithMe"]),
  },
  created() {
    const sharedWithMeRequest = assetService.getVehicleOwnersSharedWithMe();
    const sharedWithOthersRequest = assetService.getVehiclesSharedWithOthers();
    const startTime = Date.now();

    Promise.all([sharedWithMeRequest, sharedWithOthersRequest])
      .then((responses) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            const sharedVehicleOwners = responses[0];
            const sharedVehicles = this.getVehiclesSharedWithMe();
            sharedVehicleOwners.forEach((item) => {
              const owner = item.vehicleOwner;
              const vehicle = sharedVehicles.find((vehicle) => vehicle.VehicleOwnerId == owner.Id);
              if (vehicle) {
                vehicle.vehicleOwner = owner;
                this.sharedWithMe.push(vehicle);
              }
            });
            this.sharedWithMeLoading = false;

            this.sharedWithOthers = responses[1];
            this.sharedWithOthersLoading = false;

            this.nothingShared = !this.sharedWithMe.length && !this.sharedWithOthers.length;
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((errors) => {
        this.sharedWithMeLoading = false;
        this.sharedWithOthersLoading = false;
        alertService.showErrorAlert();
      });
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    edit(id) {
      this.showModal("new-share-vehicle", { shareId: id });
    },
    toggleActive(item) {
      this.processing = true;
      this.toggledSharedVehicleAccessId = item.Id;
      const itemCopy = JSON.parse(JSON.stringify(item));
      itemCopy.Active = !itemCopy.Active;

      assetService
        .updateSharedVehiclesAccess(itemCopy)
        .then((result) => {
          this.processing = false;
          if (result) {
            item.Active = !item.Active;
          } else {
            alertService.showErrorAlert();
          }
          this.toggledSharedVehicleAccessId = null;
        })
        .catch((error) => {
          this.processing = false;
          this.toggledSharedVehicleAccessId = null;
          alertService.showErrorAlert();
        });
    },
    deleteItem(type, id) {
      modalService.showDeleteDialog("Are you sure you want to delete this shared vehicle?", () => {
        this.processing = true;
        if (type == "sharedWithOthers") {
          this.deletedSharedVehicleAccessId = id;
          assetService
            .deleteSharedVehiclesAccess(id)
            .then((result) => {
              this.processing = false;
              if (result) {
                this.sharedWithOthers = this.sharedWithOthers.filter((e) => e.Id !== id);
              } else {
                alertService.showErrorAlert();
              }
              this.deletedSharedVehicleAccessId = null;
            })
            .catch((error) => {
              this.processing = false;
              this.deletedSharedVehicleAccessId = null;
              alertService.showErrorAlert();
            });
        } else if (type == "sharedWithMe") {
          this.deletedSharedWithMeVehicleId = id;
          assetService
            .deleteVehicleSharedWithUser(id)
            .then((result) => {
              this.processing = false;
              if (result) {
                this.sharedWithMe = this.sharedWithMe.filter((e) => e.SafeAndSoundVehicleId !== id);
              } else {
                alertService.showErrorAlert();
              }
              this.deletedSharedWithMeVehicleId = null;
            })
            .catch((error) => {
              this.processing = false;
              this.deletedSharedWithMeVehicleId = null;
              alertService.showErrorAlert();
            });
        }
      });
    },
    showModal(screenName, parameters) {
      modalService.showModal(this, screenName, parameters);
    },
  },
};
</script>