<template>
  <div
    role="dialog"
    aria-modal="true"
    aria-labelledby="dialogTitle"
    aria-describedby="dialogMessage"
    :style="{ width: width }"
    class="dialog-component"
    :class="{ error: isError }"
  >
    <div id="dialogTitle">{{ title }}</div>
    <div class="content">
      <svg width="24" height="24" viewBox="0 0 16 16" fill="currentColor" v-if="isSuccess">
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path
          fill-rule="evenodd"
          d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
        />
      </svg>
      <svg width="24" height="24" viewBox="0 0 16 16" fill="currentColor" v-else-if="isError">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
      </svg>
      <svg width="30" height="30" viewBox="0 0 47.387 54.869" fill="currentColor" v-else-if="isSystemNotification">
        <path
          d="M45.972,47.392h-1.629h-1.629l-7.148-23.178L28.418,1.036c-0.065-0.209-0.194-0.385-0.362-0.509s-0.374-0.195-0.592-0.195
	h-3.771h-3.771c-0.219,0-0.425,0.071-0.592,0.195s-0.297,0.3-0.362,0.509L11.82,24.214L4.671,47.392H3.043H1.416
	c-0.276,0-0.525,0.112-0.706,0.292s-0.292,0.43-0.292,0.706v2.575v2.575c0,0.276,0.112,0.525,0.292,0.706s0.43,0.292,0.706,0.292
	h22.278h22.278c0.276,0,0.525-0.112,0.706-0.292s0.292-0.43,0.292-0.706v-2.575V48.39c0-0.276-0.112-0.525-0.292-0.706
	S46.247,47.392,45.972,47.392z M11.294,32.691h12.399h12.399l1.347,4.367l1.347,4.367H23.693H8.6l1.347-4.367L11.294,32.691z
	 M13.135,26.723h10.558h10.558l0.612,1.986l0.612,1.986H23.693H11.91l0.613-1.986L13.135,26.723z M16.182,16.846h7.512h7.512
	l1.215,3.941l1.215,3.941h-9.943h-9.943l1.216-3.941L16.182,16.846z M18.022,10.879h5.671h5.671l0.612,1.986l0.612,1.986h-6.896
	h-6.896l0.612-1.986L18.022,10.879z M20.66,2.328h3.034h3.034l1.011,3.278l1.011,3.278h-5.056h-5.056l1.011-3.278L20.66,2.328z
	 M7.985,43.42h15.708h15.708l0.612,1.986l0.612,1.986H23.693H6.76l0.613-1.986L7.985,43.42z M44.974,52.541h-21.28H2.414v-1.577
	v-1.577h1.497h1.497h18.285h18.285h1.498h1.498v1.577V52.541z"
        />
      </svg>
      <img src="~@/assets/images/megaphone.svg" width="36" height="36" alt v-else />
      <div id="dialogMessage">{{ message }}</div>
    </div>
    <FocusTrap :disabled="!active">
      <div class="buttons" :class="{ multi: buttons && buttons.length > 1 }">
        <button v-for="(button, i) in buttons" :key="i" :ref="'button' + i" :style="buttonStyle" type="button" tabindex="0" @click.stop="click(i, $event)">
          {{ button.title }}
        </button>
      </div>
    </FocusTrap>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.dialog-component {
  background: #fff;
  position: absolute;
  z-index: 999;
  max-width: 90%;
  font-size: 14px;
  padding: 0 0 25px 0;
  box-shadow: $omni-shadow-brand-color;

  #dialogTitle {
    background: $brand-color-1;
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    color: #fff;
    padding: 10px 20px;
    box-shadow: $drop-shadow;
  }

  .content {
    background: $brand-color-4;
    text-align: center;
    padding: 20px;
    margin: 30px 20px 15px 20px;

    #dialogMessage {
      font-weight: 600;
      color: $brand-color-2;
      margin-top: 12px;

      &::after {
        content: "";
        display: block;
        width: 90px;
        margin: 12px auto 0 auto;
        border-top: 1px solid $brand-color-1;
      }
    }
  }

  .buttons {
    float: right;
    padding: 0 20px;

    &.multi {
      display: flex;
      flex: 0 1 auto;
      width: 100%;
      float: none;
    }

    button {
      background: transparent;
      font-size: 0.75rem;
      font-weight: bold;
      color: $brand-color-1;
      text-transform: uppercase;
      padding: 10px 20px;
      margin-right: 15px;
      border: 1px solid $brand-color-1;

      &:last-of-type {
        margin-right: 0;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background: $brand-color-2;
        color: #fff;
        border-color: $brand-color-2;
      }
    }
  }

  &.error {
    #dialogTitle {
      background: $red;
    }

    .content {
      background: rgba($red, 0.08);

      svg {
        fill: $red;
      }

      #dialogMessage {
        color: $red;

        &::after {
          border-color: $red;
        }
      }
    }

    .buttons {
      button {
        color: $red;
        border-color: $red;

        &:hover {
          background: $red;
          color: #fff;
        }
      }
    }
  }
}
</style>

<script>
import FocusTrap from "vue-focus-lock";

export default {
  name: "Dialog",
  components: {
    FocusTrap,
  },
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    buttons: {
      type: Array,
    },
    width: {
      type: String,
      default: "400px",
    },
    active: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isSystemNotification: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonStyle() {
      return {
        flex: `1 1 ${100 / this.buttons.length}%`,
      };
    },
  },
  methods: {
    click(buttonIndex, event, source = "click") {
      const button = this.buttons[buttonIndex];
      const handler = button?.handler;
      if (typeof handler === "function") {
        handler(buttonIndex, event, { source });
      }
    },
  },
};
</script>
