<template>
  <header>
    <div class="shadowWrap">
      <div class="logo">
        <img src="~@/assets/images/main-logo.png" width="150" height="54" alt="Netstar" />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .shadowWrap {
    position: relative;
    box-shadow: $omni-shadow;
  }

  .logo {
    background: #fff;
    padding: 25px 35px;

    &:after {
      content: " ";
      background: #fff;
      position: absolute;
      width: 40px;
      height: 100%;
      right: -20px;
      top: 0;
      z-index: 0;
      transform: skew(-20deg);
      box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>

<script>
export default {
  name: "Header",
};
</script>
