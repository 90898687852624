import { isMobileOnly } from "mobile-device-detect";
import { store } from "@/store";

export const tooltipService = {
  showTooltip,
  closeTooltip,
};

function showTooltip(screenName) {
  let content = [];

  switch (screenName) {
    case "install-app":
      content = [
        {
          image: require("@/assets/images/tooltips/add-to-home-screen.gif"),
          message:
            "Install the app for easier access and a fullscreen user interface.",
        },
      ];
      break;
    case "alerts":
      content = [
        {
          image: require("@/assets/images/tooltips/alerts-4.gif"),
          message: "This is where you can set up new alert criteria.",
        },
        {
          image: require("@/assets/images/tooltips/alerts-5.gif"),
          message: "Select the type of alert you'd like to create.",
        },
        {
          image: require("@/assets/images/tooltips/alerts-6.gif"),
          message:
            "Define the time and days when this alert needs to be active.",
        },
        {
          image: require("@/assets/images/tooltips/alerts-7.gif"),
          message:
            "Complete this section if you'd like to receive an email every time the alert is triggered.",
        },
        {
          image: require("@/assets/images/tooltips/alerts-3.gif"),
          message:
            "Use these icons to switch the existing alert on/off, edit its criteria or delete it.",
        },
        {
          image: require("@/assets/images/tooltips/alerts-2.gif"),
          message: "Here you can control the types of alerts you receive.",
        },
        {
          image: require("@/assets/images/tooltips/alerts-1.gif"),
          message: "Select a specific event to view it on the map.",
        },
      ];
      break;
    case "behaviour":
      content = [
        {
          image: require("@/assets/images/tooltips/behaviour-3.gif"),
          message: "Adjust the sliders to set your new driving goal.",
        },
        {
          image: require("@/assets/images/tooltips/behaviour-1.gif"),
          message:
            "This rating is based on your actual driving behaviour for the period.",
        },
        {
          image: require("@/assets/images/tooltips/behaviour-2.gif"),
          message: "This is the overall rating you wanted to achieve.",
        },
      ];
      break;
    case "certificates":
      content = [];
      break;
    case "early-warning":
      content = [
        {
          image: require("@/assets/images/tooltips/early-warning-1.gif"),
          message:
            "Easily monitor or change the status of your Early Warning device here.",
        },
      ];
      break;
    case "geo-zones":
      content = [
        {
          image: require("@/assets/images/tooltips/geo-zones-3.gif"),
          message: "Click here to set up a new geo zone.",
        },
        {
          image: require("@/assets/images/tooltips/geo-zones-4.gif"),
          message:
            "Create your new geo zone on the map using the polygon or circle tool.",
        },
        {
          image: require("@/assets/images/tooltips/geo-zones-2.gif"),
          message: "Create a location-based alert using an existing geo zone.",
        },
        {
          image: require("@/assets/images/tooltips/geo-zones-1.gif"),
          message:
            "Use the toggle switch to view each geo zone on the map. You can also edit or delete each item.",
        },
      ];
      break;
    case "geo-zone-editor":
      content = [];
      break;
    case "logbooks":
      content = [
        {
          image: require("@/assets/images/tooltips/logbooks-1.gif"),
          message:
            "If you receive a travel allowance from an employer or principal, you can claim a deduction on an assessment of your annual income tax return for the use of a private motor vehicle for business purposes. Easily download your logbook here.",
        },
      ];
      break;
    case "manage-vehicle":
      content = [
        {
          image: require("@/assets/images/tooltips/manage-vehicle-1.gif"),
          message:
            "Update your emergency contact details to better help us assist you when you need our help.",
        },
      ];
      break;
    case "reminders":
      content = [
        {
          image: require("@/assets/images/tooltips/reminders-1.gif"),
          message: "Click here to set up a new reminder.",
        },
        {
          image: require("@/assets/images/tooltips/reminders-2.gif"),
          message: "Use these icons to edit or delete your existing reminders.",
        },
      ];
      break;
    case "share-vehicles":
      content = [];
      break;
    case "trips":
      content = [
        {
          image: isMobileOnly
            ? require("@/assets/images/tooltips/trips-1-mobile.gif")
            : require("@/assets/images/tooltips/trips-1.gif"),
          message: "Tap on a trip to view on a map.",
        },
        {
          image: isMobileOnly
            ? require("@/assets/images/tooltips/trips-2-mobile.gif")
            : require("@/assets/images/tooltips/trips-2.gif"),
          message: "Click the icon to download your preferred file format.",
        },
        {
          image: isMobileOnly
            ? require("@/assets/images/tooltips/trips-3-mobile.gif")
            : require("@/assets/images/tooltips/trips-3.gif"),
          message:
            "Click to mark individual trips as either Private or Business.",
        },
      ];
      break;
  }
  if (content.length) {
    store.commit("navigation/updateShowTooltip", true);
    store.commit(
      "navigation/updateTooltipTitle",
      screenName.replaceAll("-", " ")
    );
    store.commit("navigation/updateTooltipContent", content);
  }
}

function closeTooltip() {
  store.commit("navigation/updateShowTooltip", false);
  store.commit("navigation/updateTooltipTitle", "");
  store.commit("navigation/updateTooltipContent", []);
}
