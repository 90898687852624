<template>
  <div id="tour-container" :class="{ active: tourActive }">
    <transition name="fade">
      <div id="tour-popup" v-if="showTourPopup">
        <div class="tour-content">
          <div class="tour-icon">
            <img src="~@/assets/images/tour/tour-head.png" height="85" alt="" />
          </div>
          <template v-if="tourStarted">
            <SmoothReflow>
              <div class="tour-step">
                <div class="step-progress">
                  {{ currentStep }}/{{ tourSteps.length }}
                </div>
                <img :src="tourSteps[currentStep - 1].image" alt="" />
                <div>{{ tourSteps[currentStep - 1].content }}</div>
                <button @click="nextStep()">
                  {{ currentStep === tourSteps.length ? "FINISH" : "NEXT" }}
                </button>
                <div class="notes" v-if="tourSteps[currentStep - 1].notes">
                  {{ tourSteps[currentStep - 1].notes }}
                </div>
              </div>
            </SmoothReflow>
          </template>
          <template v-else>
            <div class="header">
              Welcome to<br />
              <strong>MyNetstar</strong>
              <div>
                <div class="separator"></div>
              </div>
            </div>
            <div class="text-center">
              We see you're new to our web app. Allow us to give you a brief
              tour.
            </div>
            <div class="buttons">
              <button @click="closeTour()">NO THANKS</button>
              <button @click="startTour()">CONTINUE</button>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

#tour-container {
  &.active {
    background: rgba($brand-color-1, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    overflow-x: hidden;
  }

  #tour-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($brand-color-1, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0 20px;
    overflow-y: auto;
    overflow-x: hidden;

    .tour-content {
      background: $brand-color-2;
      position: relative;
      width: 320px;
      max-width: 100%;
      color: #fff;
      padding: 60px 30px 30px 30px;
      box-shadow: $drop-shadow;

      .tour-icon {
        background: $brand-color-2;
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        padding: 15px 15px 0 15px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
      }

      .header {
        font-size: 1.25rem;
        color: #fff;
        text-align: center;

        .separator {
          display: inline-block;
          background: rgba(255, 255, 255, 0.7);
          width: 1px;
          height: 30px;
          margin: 10px 0;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        button {
          background: transparent;
          font-size: 0.8125rem;
          font-weight: 600;
          color: #fff;
          padding: 5px 20px;
          border: 1px solid #fff;

          &:hover {
            background: #fff;
            color: $brand-color-2;
            outline: none;
          }
        }
      }

      .tour-step {
        text-align: center;

        .step-progress {
          font-weight: 600;
          margin-bottom: 15px;
        }

        img {
          max-width: 100%;
          height: auto;
          margin-bottom: 20px;
          box-shadow: $drop-shadow-light;
        }

        button {
          background: transparent;
          font-size: 0.8125rem;
          font-weight: 600;
          color: #fff;
          padding: 5px 20px;
          margin-top: 15px;
          border: 1px solid #fff;

          &:focus {
            outline: none;
          }
        }

        .notes {
          font-size: 12px;
          font-style: normal;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: "TourContainer",
  data() {
    return {
      showTourPopup: false,
      tourStarted: false,
      currentStep: 1,
      tourSteps: [
        {
          image: isMobileOnly
            ? require("@/assets/images/tour/step-1-mobile.gif")
            : require("@/assets/images/tour/step-1.gif"),
          content:
            "Tap the menu to manage your personal details and find our contact details.",
          notes: "",
        },
        {
          image: isMobileOnly
            ? require("@/assets/images/tour/step-2-mobile.gif")
            : require("@/assets/images/tour/step-2.gif"),
          content:
            "Your active vehicles are grouped here. Select one to show its location on the map.",
          notes: "*Exclusive to Netstar Plus & Early Warning.",
        },
        {
          image: isMobileOnly
            ? require("@/assets/images/tour/step-3-mobile.gif")
            : require("@/assets/images/tour/step-3.gif"),
          content: "View & edit vehicle info & emergency contacts with ease.",
          notes: "",
        },
        {
          image: isMobileOnly
            ? require("@/assets/images/tour/step-4-mobile.gif")
            : require("@/assets/images/tour/step-4.gif"),
          content: "Easily arm or disarm your Early Warning device here.",
          notes: "*Exclusive to Netstar Early Warning.",
        },
      ],
      tourCallbacks: {
        onStart: this.onTourStart,
        onFinish: this.onTourFinish,
      },
    };
  },
  computed: {
    ...mapState("map", ["mapLoadedWithVehicles"]),
    ...mapState("navigation", ["tourActive"]),
  },
  methods: {
    ...mapMutations("account", ["updateShowTour"]),
    ...mapMutations("navigation", ["updateTourActive"]),
    onTourStart() {
      this.updateTourActive(true);
    },
    onTourFinish() {
      this.updateTourActive(false);
    },
    startTour() {
      this.tourStarted = true;
    },
    nextStep() {
      if (this.currentStep === this.tourSteps.length) {
        this.closeTour();
      } else {
        this.currentStep++;
      }
    },
    closeTour() {
      this.showTourPopup = false;
      this.updateShowTour(false);
    },
  },
  watch: {
    mapLoadedWithVehicles(value) {
      if (value) {
        this.showTourPopup = true;
      }
    },
  },
};
</script>
