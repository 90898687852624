import UAParser from 'ua-parser-js';
import * as MobileDetect from "mobile-device-detect";

export const helper = {
  saveToBrowser,
  getOriginInfo,
  getVendor,
  getDeviceType,
  getDeviceModel
};

function saveToBrowser(filename, data) {
  let fileURL = window.URL.createObjectURL(
    new Blob([data], { type: "application/octet-stream" })
  );
  let fileLink = document.createElement("a");

  fileLink.href = fileURL;
  fileLink.target = "_blank";
  fileLink.download = filename;
  document.body.appendChild(fileLink);

  fileLink.click();
}

function getOriginInfo() {
  const parser = new UAParser();
  const uaResult = parser.getResult();

  return {
    Type: "PWA",
    OS: MobileDetect.osName || uaResult.os.name || "",
    OSVersion: MobileDetect.osVersion || uaResult.os.version || "",
    Vendor: getVendor(),
    DeviceType: getDeviceType(),
    DeviceModel: getDeviceModel(),
    Browser: MobileDetect.browserName || uaResult.browser.name || "",
    BrowserVersion: MobileDetect.browserVersion || uaResult.browser.version || ""
  };
}

function getVendor() {
  if (MobileDetect.mobileVendor && MobileDetect.mobileVendor !== "none") {
    return MobileDetect.mobileVendor;
  }
  if (MobileDetect.isWindows) return "Microsoft";
  if (MobileDetect.isMacOs) return "Apple";
  return "";
}

function getDeviceType() {
  if (MobileDetect.isMobile) return "Mobile";
  if (MobileDetect.isTablet) return "Tablet";
  if (MobileDetect.isSmartTV) return "Smart TV";
  if (MobileDetect.isConsole) return "Console";
  if (MobileDetect.isWearable) return "Wearable";
  return "Desktop";
}

function getDeviceModel() {
  return MobileDetect.mobileModel !== "none" ? MobileDetect.mobileModel : "";
}
