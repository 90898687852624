<template>
  <div class="get-in-touch-component screen">
    <ModalHeader
      :config="{
        type: 'admin',
        title: 'Contact Netstar',
        subTitle: 'Get In Touch',
      }"
      @close="beforeClose()"
    />
    <div class="subheading">EMERGENCY NUMBER</div>
    <div class="panel mb-3">
      <img src="~@/assets/images/phone.svg" class="phone-icon" height="16" />
      <a href="tel:0800112222">0800 11 22 22</a>
    </div>
    <div class="subheading">CUSTOMER SUPPORT & SALES</div>
    <div class="panel mb-3">
      <img src="~@/assets/images/phone.svg" class="phone-icon" height="16" />
      <a href="tel:0860122436">0860 12 24 36</a>
      <div class="block">
        <template v-if="$isMobile">
          <div class="mb-2">
            <img src="~@/assets/images/email.svg" class="email-icon" height="12" />
            <a href="mailto:cs@netstar.co.za">cs@netstar.co.za</a>
          </div>
          <div>
            <img src="~@/assets/images/email.svg" class="email-icon" height="12" />
            <a href="mailto:sales@netstar.co.za">sales@netstar.co.za</a>
          </div>
        </template>
        <template v-else>
          <img src="~@/assets/images/email.svg" class="email-icon" height="12" />
          <a href="mailto:cs@netstar.co.za">cs@netstar.co.za</a>
          <span class="divider">|</span>
          <a href="mailto:sales@netstar.co.za">sales@netstar.co.za</a>
        </template>
      </div>
    </div>
    <div class="panel last">
      <div class="subheading">CHAT NOW!</div>
      <div class="box container">
        <div class="row">
          <img src="~@/assets/images/whatsapp.svg" class="whatsapp-icon" height="25" />
          <div class="col">
            <a href="https://api.whatsapp.com/send?phone=27860122436" target="_blank">0860 12 24 36</a>
            <br />
            <a href="https://www.netstar.co.za/service-bot" target="_blank">www.netstar.co.za/service-bot</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.get-in-touch-component {
  .subheading {
    color: $brand-color-1;
  }

  .panel {
    background: $brand-color-4;
    padding: 5px 15px;

    &.last {
      padding-bottom: 15px;

      .box {
        background: #fff;
        padding: 10px 15px;
        border: 1px solid $brand-color-2;
      }
    }

    a {
      color: $brand-color-1;
    }

    .phone-icon {
      vertical-align: sub;
      margin-right: 8px;
    }

    .email-icon {
      vertical-align: baseline;
      margin-right: 8px;
    }

    .whatsapp-icon {
      margin: auto 0px auto 20px;
    }

    .block {
      background: #edf0f3;
      padding: 5px 15px;
      margin: 5px -15px -5px -15px;

      .divider {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
}
</style>

<script>
import { adminService } from "@/services";
import { helper } from "@/helper";

export default {
  name: "GetInTouch",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
  },
};
</script>