<template>
  <SmoothReflow>
    <transition name="fade">
      <div class="error-box-component">
        <img src="~@/assets/images/alert.svg" class="exclamation" width="26" height="26" />
        {{ message }}
      </div>
    </transition>
  </SmoothReflow>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.error-box-component {
  background: #fff;
  position: relative;
  font-weight: 600;
  color: $red;
  text-align: center;
  padding: 15px;
  border: 2px solid $red;
  box-shadow: $drop-shadow-light;

  .exclamation {
    background: #fff;
    position: absolute;
    top: 50%;
    left: -13px;
    transform: translateY(-50%);
    border-radius: 50%;
  }
}
</style>

<script>
export default {
  name: "ErrorBox",
  props: ["message"],
};
</script>