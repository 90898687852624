<template>
  <div class="health-status-component screen">
    <SheetVehicleFeatureHeader title="Health Status" :hasVehiclePicker="false" v-if="$isMobile" />
    <ModalHeader v-else :config="{ type: 'vehicle', title: 'Health Status', subTitle: '', hasVehiclePicker: false }" @close="beforeClose()" />
    <Alert />
    <SmoothReflow>
      <div class="success-box" v-if="submitted && submitSuccess">
        <svg width="44.005" height="30.269" viewBox="0 0 44.005 30.269">
          <g transform="translate(-940.595 -561.339)">
            <rect
              width="34.934"
              height="22.591"
              transform="translate(941.999 567.613)"
              fill="none"
              stroke="#536e89"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              d="M181.718,113.433l14.873,11.1s1.47,1.211,2.081,1.211H199.7c.611,0,2.081-1.211,2.081-1.211l14.873-11.1"
              transform="translate(760.281 454.18)"
              fill="none"
              stroke="#536e89"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <line
              x1="8.702"
              y2="11.126"
              transform="translate(941.999 579.078)"
              fill="none"
              stroke="#536e89"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <line
              x2="8.702"
              y2="11.126"
              transform="translate(968.231 579.078)"
              fill="none"
              stroke="#536e89"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path d="M223.319,115.675a7.516,7.516,0,1,1-7.516-7.516,7.516,7.516,0,0,1,7.516,7.516" transform="translate(760.281 454.18)" fill="#f6f8f9" />
            <path
              d="M223.319,115.675a7.516,7.516,0,1,1-7.516-7.516A7.516,7.516,0,0,1,223.319,115.675Z"
              transform="translate(760.281 454.18)"
              fill="none"
              stroke="#536e89"
              stroke-width="2"
            />
            <path
              d="M211.79,116.256l2.213,2.3,5.813-5.795"
              transform="translate(760.281 454.18)"
              fill="none"
              stroke="#536e89"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
        <div class="message">
          Your feedback has been sent to Netstar technical support.<br />We will contact you within 24-48 hours to attend to your issue.
        </div>
      </div>
      <template v-else>
        <template v-if="isHealthy">
          <div class="subheading mb-2">TRACKING DEVICE ONLINE</div>
          <div class="font-weight-bold mb-4">
            This unit is functioning correctly. If you're having issues or suspect that something might be wrong that requires technical attendance, please tell
            us about it.
          </div>
          <form ref="form" @submit.prevent>
            <div class="form-group">
              <textarea class="form-control" id="message" rows="5" placeholder="Message" v-model="form.message" required />
            </div>
          </form>
          <div class="buttons">
            <StatusIndicator class="mr-3" :success="submitSuccess" :error="submitError" />
            <button class="btn btn-outline" @click="submit()">
              <span v-show="!processing">SUBMIT</span>
              <Spinner v-show="processing" />
            </button>
          </div>
        </template>
        <template v-else>
          <div class="subheading warning mb-2">TRACKING DEVICE OFFLINE</div>
          <div class="font-weight-bold mb-4">
            We have completed a test on your vehicle and identified no activity from the tracking device. Kindly tick the option that applies to your vehicle,
            as technical attendance may be required:
          </div>
          <div class="panel">
            <form ref="form" @submit.prevent>
              <div class="message" v-for="(value, key, index) in options" :key="index">
                <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="option" :id="'option' + index" :value="value" v-model="form.message" required />
                  <label class="custom-control-label" :for="'option' + index">{{ key }}</label>
                </div>
                <div v-html="value"></div>
              </div>
            </form>
          </div>
          <div class="buttons">
            <StatusIndicator class="mr-3" :success="submitSuccess" :error="submitError" />
            <button class="btn btn-outline" @click="submit()">
              <span v-show="!processing">SUBMIT</span>
              <Spinner v-show="processing" />
            </button>
          </div>
        </template>
      </template>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.health-status-component {
  font-size: 0.75rem;

  .warning {
    color: $warning;
  }

  .panel {
    background: $brand-color-4;
    padding: 20px;
  }

  form {
    textarea {
      padding: 10px 15px;
    }

    .message {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 5px;
      }

      .custom-radio {
        .custom-control-label {
          font-size: 0.8125rem;
          font-style: normal;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }

  .buttons {
    margin-top: 15px;

    button {
      min-width: 90px;
    }
  }

  .success-box {
    background: $brand-color-4;
    text-align: center;
    padding: 30px;

    .message {
      font-weight: bold;
      color: $brand-color-2;
      margin-top: 10px;

      &::after {
        content: "";
        display: block;
        width: 90px;
        margin: 12px auto 0 auto;
        border-top: 1px solid $brand-color-1;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { adminService, alertService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import StatusIndicator from "@/components/StatusIndicator";

export default {
  name: "HealthStatus",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
    StatusIndicator,
  },
  data() {
    return {
      processing: false,
      submitted: false,
      submitSuccess: false,
      submitError: false,
      options: {
        "Option 1: Regular Use": "The vehicle is used regularly.<br>No accident and no major maintenance work.",
        "Option 2: Vehicle Out of Bounds": "Vehicle not in South Africa.<br>Parked in garage or basement for long periods.",
        "Option 3: Vehicle Fault": "Vehicle battery disconnected.<br>Vehicle has been in an accident or undergone major maintenance.",
        "Option 4: Request Call Back": "None of the above applies.<br>Request Netstar to call you.",
      },
      form: {
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveVehicleIsHealthy"]),
    isHealthy() {
      return this.getActiveVehicleIsHealthy();
    },
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    submit() {
      if (this.$refs.form.reportValidity()) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      const { form } = this;
      const message = form.message.replace("<br>", " ");

      alertService.clearAlert();
      this.processing = true;

      adminService
        .getCustomerDetails()
        .then((result) => {
          this.submitted = true;
          adminService
            .logFault({
              firstName: result.PrimaryContact.FirstName,
              surname: result.PrimaryContact.Surname,
              contactNumber: result.PrimaryContact.CellNumber,
              message: message,
            })
            .then((result) => {
              this.processing = false;
              this.submitted = true;
              if (result.result) {
                this.submitSuccess = true;
              } else {
                alertService.showErrorAlert();
              }
            })
            .catch((error) => {
              this.submitError = true;
              setTimeout(() => {
                this.submitError = false;
              }, 3000);
              alertService.showErrorAlert();
            })
            .finally(() => {
              this.processing = false;
            });
        })
        .catch((error) => {
          this.processing = false;
          this.submitError = true;
          setTimeout(() => {
            this.submitError = false;
          }, 3000);
          alertService.showErrorAlert();
        });
    },
  },
};
</script>