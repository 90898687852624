<template>
  <div
    class="asset-icon-component"
    :class="{ noMapFeatureViewer: !hasMap && showInfo }"
  >
      <div v-if="(hasAccuracyRing && totalAssets > 0 && zoomLevel > 13)"
         class="accuracy-ring">
      <GmapCircle
          :key="asset.SafeAndSoundVehicleId"
          :center="{ lat: lat, lng: lng }"
          :radius="ringDiameter"
          :strokeColor="ringColor"
          :strokeOpacity="1.0"
          :strokeWeight="3"
          :fillColor="'#28B6D5'"
          :fillOpacity="0.1"
      />
        <div
            v-if="isVisible"
            class="vehicle-registration"
            ref="vehicleRegistration"
            :style="dynamicStyles"
        >
          <span>{{ registrationNumber }}</span>
        </div>
    </div>
    <template v-else>
      <img
          src="~@/assets/images/early-warning-armed-badge.svg"
          width="26"
          height="26"
          alt
          class="armed-badge"
          v-if="isArmed && !isInRecovery"
      />
      <div
          class="health-warning"
          v-if="registrationNumber && !isHealthy && !isInRecovery"
      ></div>
      <div
          class="icon"
          :class="{
            default: !registrationNumber,
            marker: isMarker,
            featureViewer: isFeatureViewer,
            shared: isShared,
            active: active,
            assetPicker: isAssetPicker,
            lower: !active && isAssetPicker,
            sendToBack: sendToBack,
            isInRecovery: isInRecovery,
          }"
              @click="toggleInfo()"
              @mouseover="openInfo()"
              @mouseout="clearHover()"
          >
        <img
            v-if="hasUserImage"
            :src="asset.ImageUrl"
            :alt="registrationNumber"
        />
        <template v-else>
          <svg
              width="18"
              height="21"
              viewBox="0 0 25.86 29.7"
              class="share-vehicle-icon"
              v-if="registrationNumber && isShared"
          >
            <g>
              <g>
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M18.72,6.64H6.41c-1.08,0-2.02,0.84-2.26,2.03l-1.22,5.95H22.2l-1.22-5.95C20.74,7.48,19.8,6.64,18.72,6.64z"
                />
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M24.3,21.98v5.15c0,1-0.81,1.81-1.81,1.81h-1.05c-1,0-1.81-0.81-1.81-1.81v-2.61"
                />
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M5.42,24.52v2.61c0,1-0.81,1.81-1.81,1.81H2.56c-1,0-1.81-0.81-1.81-1.81v-5.15"
                />
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M21.71,14.62H3.4c-1.46,0-2.65,1.37-2.65,3.05v3.79c0,1.68,1.19,3.05,2.65,3.05h18.31
			c1.46,0,2.65-1.37,2.65-3.05v-3.79C24.36,15.99,23.17,14.62,21.71,14.62z"
                />
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M6.9,19.86c0,1.09-0.88,1.97-1.96,1.97c-1.09,0-1.96-0.88-1.96-1.97c0-1.09,0.88-1.97,1.96-1.97
			C6.02,17.89,6.9,18.77,6.9,19.86z"
                />
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M21.97,19.86c0,1.09-0.88,1.97-1.96,1.97c-1.09,0-1.96-0.88-1.96-1.97c0-1.09,0.88-1.97,1.96-1.97
			C21.09,17.89,21.97,18.77,21.97,19.86z"
                />
                <path
                    style="fill: none; stroke-width: 1.5"
                    d="M13.76,14.19c0-1.42,1.15-2.57,2.56-2.57c1.42,0,2.56,1.15,2.56,2.57"
                />
                <line
                    style="fill: none; stroke-width: 1.5"
                    x1="9.42"
                    y1="19.55"
                    x2="15.49"
                    y2="19.55"
                />
              </g>
              <path
                  style="fill: none; stroke-width: 1.5"
                  d="M25.11,5.92v2.09c0,0.27-0.22,0.49-0.49,0.49h-4.2v4.2c0,0.27-0.22,0.49-0.49,0.49h-2.09
		c-0.27,0-0.48-0.22-0.48-0.49v-4.2h-4.21c-0.27,0-0.48-0.22-0.48-0.49V5.92c0-0.27,0.21-0.48,0.48-0.48h4.21V1.23
		c0-0.27,0.21-0.48,0.48-0.48h2.09c0.27,0,0.49,0.21,0.49,0.48v4.21h4.2C24.89,5.44,25.11,5.65,25.11,5.92z"
              />
            </g>
          </svg>
          <svg
              width="15"
              height="21"
              viewBox="0 0 18.8 26.31"
              class="pin-icon"
              v-if="registrationNumber && !isShared"
          >
            <g>
              <path
                  style="fill: none; stroke-width: 2"
                  d="M1,9.4c0,4.64,8.4,15.91,8.4,15.91s8.4-11.27,8.4-15.91S14.04,1,9.4,1S1,4.76,1,9.4z"
              />
              <circle
                  style="fill: none; stroke-width: 2"
                  cx="9.4"
                  cy="9.26"
                  r="3.18"
              />
            </g>
          </svg>
          <div class="text" :class="{ truncate: registrationNumber }">
            {{ registrationNumber ? registrationNumber : "All Vehicles" }}
          </div>
          <hr v-if="registrationNumber" />
        </template>
      </div>
      <transition name="slide-diagonal">
        <div v-if="(isMarker || isFeatureViewer) && showInfo"
             class="info"
             :class="{
               active: active,
               starTag: starTag
             }"
        >
          <div class="header">
            <div class="title">
              {{ asset.Nickname ? asset.Nickname : registrationNumber }}
            </div>
            <div class="separator">|</div>
            <div class="product-name">
              <strong>{{ asset.ProductDisplayName }}</strong>
            </div>
            <img
                src="~@/assets/images/notification.png"
                width="30"
                height="30"
                alt
                class="notification"
                v-if="asset.notification"
            />
            <button class="close" @click="toggleInfo()"></button>
          </div>
          <div class="content container">
            <div class="recovery" v-if="isInRecovery">
              <img
                  src="~@/assets/images/vehicle-warning.svg"
                  width="34"
                  height="32"
                  alt
              />
              <strong>VEHICLE RECOVERY IN PROGRESS</strong>
              <p class="m-0">
                For your safety, tracking is temporarily unavailable.<br />Please
                contact the Emergency Call Centre on<br />
                <a href="tel:0800112222">0800 11 22 22</a> for further information.
              </p>
            </div>
            <div class="basic-iot" v-else-if="isBasicIoT">
              <div class="box">
                <div class="update-blurb">
                  <img
                      src="~@/assets/images/hourglass.gif"
                      width="42"
                      height="42"
                      alt
                  />
                  <div>
                    <div>
                      Last update at
                      <strong class="green">{{ time }}</strong> {{ date }}
                    </div>
                    <div>(Updates occur every 20 minutes while in motion)</div>
                  </div>
                </div>
                <div class="container p-3">
                  <div class="row">
                    <div class="col-12 d-flex mb-2">
                      <img
                          src="~@/assets/images/pin2.svg"
                          class="mr-2"
                          width="16"
                          height="16"
                          alt
                      />
                      <div>
                        <div class="address">{{ asset.GeoAddress }}</div>
                        <strong v-if="asset.Latitude && asset.Longitude">{{
                            asset.Latitude + ", " + asset.Longitude
                          }}</strong>
                      </div>
                    </div>
                    <div class="col-12 d-flex align-items-center">
                      <img
                          src="~@/assets/images/battery.svg"
                          class="mr-2"
                          width="16"
                          height="13"
                          alt
                      />
                      <div>
                        Unit Battery:
                        <strong :class="batteryPercentageClassName"
                        >{{ asset.BatteryPercentage }}%</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="star-tag" v-else-if="isStarTag">
              <div class="info-menu pb-19" v-if="isOutdated">
                <div class="container">
                  <div class="location-warning">
                    <div class="warning-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="28.145" height="23.145" viewBox="0 0 28.145 23.145">
                        <g id="megaphone.32df3b6b" transform="translate(0 -0.006)">
                          <g id="Group_1999" data-name="Group 1999" transform="translate(0 0.006)">
                            <g id="Group_1998" data-name="Group 1998">
                              <path id="Path_574" data-name="Path 574" d="M25.336,10.8A2.78,2.78,0,0,0,22.9,8.047V.952a.94.94,0,0,0-.7-.914A.95.95,0,0,0,21.13.477a11.33,11.33,0,0,1-7.022,5.237,14.875,14.875,0,0,1-4.381.578H4.593a4.6,4.6,0,0,0-.432,9.186l1.521,6.261a1.846,1.846,0,0,0,1.792,1.412H8.536a1.842,1.842,0,0,0,1.792-2.275L9.018,15.507h.629a.352.352,0,0,0,.088,0,14.9,14.9,0,0,1,4.381.578,11.382,11.382,0,0,1,7.022,5.237.94.94,0,0,0,.812.475,1.081,1.081,0,0,0,.256-.037.94.94,0,0,0,.7-.914V13.752A2.769,2.769,0,0,0,25.343,11l-.007-.2ZM9.084,20.415l.176.717a.747.747,0,0,1-.724.922H7.475a.743.743,0,0,1-.724-.57L5.3,15.5H7.885l.388,1.594H8.009a.549.549,0,1,0,0,1.1h.527l.271,1.119H8.631a.549.549,0,1,0,0,1.1h.453Zm.073-8.353H5.742a.549.549,0,1,0,0,1.1H9.157V14.4H4.586a3.5,3.5,0,0,1,.015-7H9.157v4.666ZM21.8,8.581V20.305a12.686,12.686,0,0,0-7.424-5.3,16.508,16.508,0,0,0-4.125-.614V7.4a16.911,16.911,0,0,0,4.125-.614,12.686,12.686,0,0,0,7.424-5.3Zm2.436,2.414A1.678,1.678,0,0,1,22.9,12.633V9.159A1.672,1.672,0,0,1,24.239,10.8Z" transform="translate(0 -0.006)" fill="#536e89"/>
                              <path id="Path_575" data-name="Path 575" d="M34.667,9.576a.537.537,0,0,0,.373-.146l1.587-1.441a.549.549,0,1,0-.739-.812L34.294,8.618a.546.546,0,0,0-.037.775A.535.535,0,0,0,34.667,9.576Z" transform="translate(-9.163 -1.893)" fill="#536e89"/>
                              <path id="Path_576" data-name="Path 576" d="M35.032,19.427a.549.549,0,0,0-.739.812l1.587,1.441a.558.558,0,0,0,.373.146.552.552,0,0,0,.373-.958Z" transform="translate(-9.163 -5.184)" fill="#536e89"/>
                              <path id="Path_577" data-name="Path 577" d="M37.083,14.14H35.869a.549.549,0,0,0,0,1.1h1.214a.549.549,0,0,0,0-1.1Z" transform="translate(-9.487 -3.802)" fill="#536e89"/>
                              <path id="Path_578" data-name="Path 578" d="M5.053,16.838a.394.394,0,0,0-.051-.1.777.777,0,0,0-.066-.08.637.637,0,0,0-.08-.066.394.394,0,0,0-.1-.051.539.539,0,0,0-.1-.029.518.518,0,0,0-.212,0,.419.419,0,0,0-.1.029.394.394,0,0,0-.1.051.778.778,0,0,0-.08.066.352.352,0,0,0-.066.08.394.394,0,0,0-.051.1.256.256,0,0,0-.044.1.518.518,0,0,0-.007.1.565.565,0,0,0,.015.11.539.539,0,0,0,.029.1.394.394,0,0,0,.051.1.777.777,0,0,0,.066.08.352.352,0,0,0,.08.066.394.394,0,0,0,.1.051.539.539,0,0,0,.1.029.293.293,0,0,0,.11.022.554.554,0,0,0,.11-.007.539.539,0,0,0,.1-.029.394.394,0,0,0,.1-.051.777.777,0,0,0,.08-.066A.352.352,0,0,0,5,17.364a.394.394,0,0,0,.051-.1.539.539,0,0,0,.029-.1.826.826,0,0,0,0-.219A.242.242,0,0,0,5.053,16.838Z" transform="translate(-1.074 -4.437)" fill="#536e89"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="warning-text">
                      <div class="warning-title">THE LOCATION IS OUTDATED</div>
                      <div class="warning-description">The device has not provided a location recently</div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="['info-menu', { 'pt-0': isOutdated }]">
                <div class="container">
                  <div class="estimated-last-location">
                    <h3 :class="{dated: isDated}">ESTIMATED LAST LOCATION</h3>
                    <div class="location-info">
                      <span class="location">{{ asset.GeoAddress }}</span>
                      <div class="vertical-border"></div>
                      <span class="date-time">
                        <span class="date">{{ date }}</span><br />
                        <span class="time">{{ time }}</span>
                      </span>
                    </div>
<!--                    <div class="battery-status">-->
<!--                      <img-->
<!--                          :src="batteryPercentageIconUrl"-->
<!--                          class="mr-2"-->
<!--                          width="26.9"-->
<!--                          height="18.23"-->
<!--                          alt-->
<!--                      />-->
<!--                      <div>-->
<!--                        Unit Battery-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="telemetry" v-else-if="hasRealTimeLocationTracking && !isStarTag">
              <div class="row">
                <div class="col">
                  <div class="speed" :class="{ danger: isSpeeding }">
                    {{ speedValue }}
                  </div>
                  <!-- <div class="road-speed">
                    Road Speed:
                    <span class="font-weight-bold"
                      >{{ asset.SpeedLimit }} kph</span
                    >
                  </div> -->
                </div>
                <div class="col">
                  <div class="date">{{ date }}</div>
                  <div class="time">{{ time }}</div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <div class="address">
                    <ul>
                      <li v-for="line in geoAddressLines" :key="line">
                        {{ line }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col">
                  <div class="location">
                    {{ asset.Latitude + ", " + asset.Longitude }}
                  </div>
                  <div>
                    Battery Voltage:
                    <strong>{{ asset.BatteryVoltage }}V</strong>
                  </div>
                  <div>
                    Odometer Reading:
                    <strong>{{ asset.Odometer }}km</strong>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="!isShared">
              <!-- <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="menu-toggle" v-if="!showInfoMenu" @click="showInfoMenu = !showInfoMenu">
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
              <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="menu-toggle" v-if="showInfoMenu" @click="showInfoMenu = !showInfoMenu">
                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
              </svg> -->
              <!-- <SmoothReflow> -->
              <template v-if="isStarTag">
                <div class="star-tag">
                  <div class="info-menu mt-16">
                    <div class="container pt-10 pb-10">
                      <div class="row">
                        <div class="col">
                          <div
                              class="menu-item"
                              :class="{
                                warning: !isHealthy && !isInRecovery,
                                disabled: isInRecovery,
                              }"
                              @click="
                                isInRecovery
                                  ? handleInactiveFeature()
                                  : showModal('health-status')
                              "
                          >
                            <div class="screen-icon">
                              <img
                                  :src="healthStatusIconUrl"
                                  width="21"
                                  height="21"
                                  alt
                              />
                            </div>
                            HEALTH STATUS
                          </div>
                        </div>
                        <div class="col">
                          <div class="menu-item" @click="showModal('reminders')">
                            <div class="screen-icon">
                              <img
                                  src="~@/assets/images/reminders.svg"
                                  width="19"
                                  height="20"
                                  alt
                              />
                            </div>
                            REMINDERS
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="menu-item" @click="showModal('manage-vehicle')">
                            <div class="screen-icon">
                              <img
                                  src="~@/assets/images/manage-vehicle.svg"
                                  width="21"
                                  height="21"
                                  alt
                              />
                            </div>
                            MANAGE VEHICLE
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
              <div class="info-menu">
                <div class="container pt-10 pb-10">
                    <div class="row">
                      <div class="col">
                        <div
                            class="menu-item"
                            :class="{ disabled: !hasTrips || isInRecovery  }"
                            @click="
                            hasTrips && !isInRecovery
                              ? showLeftDrawer('trips')
                              : handleInactiveFeature()
                          "
                        >
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/trips.svg"
                                width="22"
                                height="22"
                                alt
                            />
                          </div>
                          TRIPS
                        </div>
                      </div>
                      <div class="col">
                        <div
                            class="menu-item"
                            :class="{ disabled: !hasAlerts|| isInRecovery  }"
                            @click="
                            hasAlerts && !isInRecovery
                              ? showLeftDrawer('alerts')
                              : handleInactiveFeature()
                          "
                        >
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/alerts.svg"
                                width="22"
                                height="22"
                                alt
                            />
                          </div>
                          ALERTS
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                            class="menu-item"
                            :class="{ disabled: !hasLogbooks || isInRecovery  }"
                            @click="
                            hasLogbooks || isInRecovery
                              ? showModal('logbooks')
                              : handleInactiveFeature()
                          "
                        >
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/logbooks.svg"
                                width="21"
                                height="18"
                                alt
                            />
                          </div>
                          LOGBOOKS
                        </div>
                      </div>
                      <div class="col">
                        <div
                            class="menu-item"
                            :class="{ disabled: !hasMap || isInRecovery }"
                            @click="
                            hasMap && !isInRecovery
                              ? showLeftDrawer('geo-zones')
                              : handleInactiveFeature()
                          "
                        >
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/geo-zones.svg"
                                width="20"
                                height="20"
                                alt
                            />
                          </div>
                          GEO ZONES
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                            class="menu-item"
                            :class="{ disabled: !hasBehaviour || isInRecovery }"
                            @click="
                            hasBehaviour && !isInRecovery
                              ? showModal('behaviour')
                              : handleInactiveFeature()
                          "
                        >
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/behaviour.svg"
                                width="22"
                                height="20"
                                alt
                            />
                          </div>
                          BEHAVIOUR
                        </div>
                      </div>
                      <div class="col">
                        <div class="menu-item" @click="showModal('certificates')">
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/certificate.svg"
                                width="16"
                                height="25"
                                alt
                            />
                          </div>
                          CERTIFICATES
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="menu-item" @click="showModal('manage-vehicle')">
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/manage-vehicle.svg"
                                width="21"
                                height="21"
                                alt
                            />
                          </div>
                          MANAGE VEHICLE
                        </div>
                      </div>
                      <div class="col">
                        <div class="menu-item" @click="showModal('reminders')">
                          <div class="screen-icon">
                            <img
                                src="~@/assets/images/reminders.svg"
                                width="19"
                                height="20"
                                alt
                            />
                          </div>
                          REMINDERS
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col">
                          <div
                            class="menu-item"
                            :class="{ disabled: !hasShareVehicle }"
                            @click="hasShareVehicle ? showModal('share-vehicles') : handleInactiveFeature()"
                          >
                            <div class="screen-icon">
                              <img src="~@/assets/images/share-vehicle.svg" width="19" height="22" alt />
                            </div>
                            SHARE VEHICLE
                          </div>
                        </div> -->
                      <!-- <div class="col" v-if="hasArmDisarm">
                        <div
                          class="menu-item"
                          :class="{
                            armed: isArmed && !isInRecovery,
                            disabled: isInRecovery,
                          }"
                          @click="
                            isInRecovery
                              ? handleInactiveFeature()
                              : showModal('early-warning')
                          "
                        >
                          <div class="screen-icon">
                            <img
                              :src="earlyWarningIconUrl"
                              width="21"
                              height="20"
                              alt
                            />
                          </div>
                          ARM OR DISARM
                        </div>
                      </div> -->
                      <div class="col">
                        <div
                            class="menu-item"
                            :class="{
                            warning: !isHealthy && !isInRecovery,
                            disabled: isInRecovery,
                          }"
                            @click="
                            isInRecovery
                              ? handleInactiveFeature()
                              : showModal('health-status')
                          "
                        >
                          <div class="screen-icon">
                            <img
                                :src="healthStatusIconUrl"
                                width="21"
                                height="21"
                                alt
                            />
                          </div>
                          HEALTH STATUS
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              </template>
              <div class="arm-disarm-panel" v-if="hasArmDisarm">
                <div class="control">
                  <img src="@/assets/images/early-warning-disarm.svg" class="alert-icon" width="14" height="24" alt />
                  <span class="ml-3 mr-2">Disarm</span>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="toggleArmedStatus" v-model="asset.IsArmed" @click.prevent="toggleArmedStatus" />
                    <label class="custom-control-label" for="toggleArmedStatus" />
                  </div>
                  <span class="ml-2 mr-3">Arm</span>
                  <img src="@/assets/images/early-warning-arm.svg" class="alert-icon" width="14" height="24" alt />
                </div>
                <div class="status">
                <span>Status: </span
                ><span class="font-weight-bold" :class="{ armed: asset.IsArmed, disarmed: !asset.IsArmed }">{{ asset.IsArmed ? "Armed" : "Disarmed" }}</span>
                </div>
              </div>
              <div class="py-1 mt-3" v-if="isUpgradeable">
                <div class="upgrade-status" v-if="asset.PendingUpgrade">
                  <img
                      src="~@/assets/images/vehicle-fitment.svg"
                      width="24"
                      height="24"
                      alt
                  />
                  <div>Upgrade Status: <span>Awaiting Fitment</span></div>
                </div>
                <div class="text-right" v-else>
                  <button class="btn btn-outline" @click="upgrade()">
                    UPGRADE PRODUCT
                  </button>
                </div>
              </div>
              <!-- </SmoothReflow> -->
            </template>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.asset-icon-component {
  position: relative;

  .pt-10 {
    padding-top: 10px !important;
  }

  .pb-10 {
    padding-bottom: 10px !important;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .pb-19 {
    padding-bottom: 19px;
  }

  &.noMapFeatureViewer {
    z-index: 1;
  }

  .armed-badge {
    position: absolute;
    top: 0;
    right: 0;
  }

  .health-warning {
    background: $warning;
    position: absolute;
    top: 0;
    right: 20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    box-shadow: $drop-shadow;

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }

    &.marker {
      border: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid $brand-color-2;
        animation: pulse 2s ease 0s infinite;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        80% {
          opacity: 0;
          transform: scale(2);
        }
        100% {
          opacity: 0;
          transform: scale(3);
        }
      }
    }

    &.featureViewer {
      z-index: -2;

      &.active {
        position: relative;
        z-index: 2;
      }

      &.sendToBack {
        // position: relative;
      }
    }

    &.shared {
      background: $brand-color-5;
    }

    &.active {
      border: 3px solid rgba($brand-color-2, 0.8);
    }

    &.isInRecovery {
      border: 3px solid #c3281c;

      &:after {
        content: "";
        background: rgba(#c3281c, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &.assetPicker {
      &.lower {
        z-index: -2;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .pin-icon,
    .share-vehicle-icon {
      stroke: $brand-color-1;
    }

    .text {
      width: 100%;
      font-weight: bold;
      font-size: 0.75rem;
      color: $brand-color-1;
      text-transform: uppercase;
      margin: 2px 0;

      &.truncate {
        width: 60px;
        line-height: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    hr {
      width: 20px;
      margin: 0;
      border-top: 1px solid $brand-color-1;
    }

    &.default {
      background: $brand-color-1;

      .text {
        color: #fff;
      }
    }
  }


  .info {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 75%;
    z-index: -1;
    width: 425px;
    color: $brand-color-2;
    box-shadow: $drop-shadow;

    .header {
      display: flex;
      align-items: center;
      background: $brand-color-2;
      padding: 5px 40px 5px 30px;
      box-shadow: $drop-shadow-light;

      .title {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: bold;
        font-style: normal;
        color: #fff;
        text-transform: uppercase;
      }

      .separator {
        color: #fff;
        margin: 0 10px;
      }

      .product-name {
        color: #fff;
        text-transform: uppercase;
      }

      .notification {
        position: absolute;
        top: -10px;
        right: 35px;
      }

      .close {
        background: transparent;
        position: absolute;
        top: 7px;
        right: 9px;
        width: 16px;
        height: 16px;
        opacity: 1;

        &:before,
        &:after {
          background: #fff;
          position: absolute;
          top: 0;
          left: 8px;
          content: " ";
          height: 16px;
          width: 2px;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }

        &:focus {
          outline: none;
        }
      }
    }

    .content {
      padding: 15px 18px 23px;

      .recovery {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.3;
        color: #c3281c;
        text-align: center;
        padding: 5px 20px 20px 20px;

        strong {
          margin-top: 15px;
        }

        a {
          font-weight: bolder;
          color: #c3281c;
        }
      }

      .star-tag {

        .location-warning {
          display: flex;
          align-items: center;
          padding: 15px;
          padding-right: 0;

          .warning-icon {
            width: 28.14px;
            height: 23.14px;
            margin-right: 10px;
          }

          .warning-text {
            .warning-title {
              text-align: left;
              font: normal normal bold 13px/17px Montserrat;
              letter-spacing: 0px;
              color: #E62E2E;
              text-transform: uppercase;
              opacity: 1;
              margin-bottom: 2px;
            }

            .warning-description {
              text-align: left;
              font: normal normal normal 13px/17px Montserrat;
              letter-spacing: 0px;
              color: $brand-color-1;
              opacity: 1;
            }
          }
        }

        .estimated-last-location {
          color: $brand-color-6;
          padding: 15px 24px;

          h3 {
            text-align: left;
            font: normal normal bold 13px/17px Montserrat;
            letter-spacing: 0px;
            color: $brand-color-1;
            text-transform: uppercase;
            opacity: 1;
            margin: 0 0 10px 0;
          }

          .dated {
            color: #efa118;
          }

          .location-info {
            display: flex;
            justify-content: space-between;
            margin: 10px 0 16px;
            padding: 15px 0;
            border-top: 1px solid #BDC5CF;
            border-bottom: 1px solid #BDC5CF;

            .location {
              width: 140px;
              text-align: left;
              font: normal normal normal 13px/17px Montserrat;
              letter-spacing: 0px;
              color: $brand-color-1;
              opacity: 1;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .vertical-border {
              width: 0px;
              height: 35px;
              border-left: 1px solid #BDC5CF;
              opacity: 1;
              margin-top: 0;
              margin-right: 15px;
              margin-left: 45px;
              margin-bottom: 0;
            }

            .date-time {
              font-size: 13px;
              padding-right: 30px;

              .date {
                text-align: left;
                font: normal normal bold 13px/17px Montserrat;
                letter-spacing: 0px;
                color: $brand-color-1;
              }

              .time {
                text-align: left;
                font: normal normal normal 13px/17px Montserrat;
                letter-spacing: 0px;
                color: $brand-color-1;
                margin-top: 3px;
              }
            }
          }

          .battery-status {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: $brand-color-6;

            .battery-icon {
              margin-right: 5px;
            }

            span {
              text-align: left;
              font: normal normal normal 13px/15px Montserrat;
              letter-spacing: 0px;
              color: $brand-color-1;
              opacity: 1;
            }
          }
        }
      }

      .basic-iot {
        margin-bottom: 15px;

        .box {
          background: $brand-color-4;
          color: #4c5258;
          padding: 20px 15px 15px 15px;

          .update-blurb {
            display: flex;
            align-items: flex-start;
            font-size: 0.9375rem;
            margin-bottom: 15px;

            > img {
              margin-top: -2px;
              margin-right: 2px;
            }

            .green {
              color: #65bc36;
            }
          }

          .container {
            background: #fff;
            font-size: 0.8125rem;

            .good {
              color: #65bc36;
            }

            .warning {
              color: #ff7f00;
            }
          }
        }
      }

      .telemetry {
        margin-bottom: 15px;

        .row {
          .col {
            padding-top: 5px;
            padding-bottom: 5px;
          }

          .col:first-of-type {
            text-align: right;
            border-right: 1px solid $brand-color-2;
          }
        }

        hr {
          width: 90%;
          margin: 0 auto;
          border-color: $brand-color-2;
        }

        .speed {
          font-weight: bold;
          font-size: 1.375rem;
          color: #3aa000;

          &.danger {
            color: $red;
          }
        }

        .road-speed {
          font-size: 0.75rem;
        }

        .date {
          font-weight: bold;
          font-size: 1.125rem;
        }

        .time {
          font-size: 1.125rem;
        }

        .address {
          ul {
            font-weight: bold;
            list-style: none;
            text-align: right;
            padding: 0;
            margin: 0;
          }
        }

        .location {
          font-weight: bold;
          text-align: left;
          margin-bottom: 2px;
        }
      }

      .menu-toggle {
        display: block;
        fill: $brand-color-2;
        margin: 5px auto;

        &:hover {
          cursor: pointer;
        }
      }

      .info-menu {
        background: $brand-color-4;
        padding: 16px;

        .container {
          background: #fff;
          padding: 5px 0;
          box-shadow: $drop-shadow-light;

          .row {
            margin-left: 9px;
            margin-right: 9px;

            .menu-item {
              display: flex;
              align-items: center;
              font-weight: bold;
              font-style: normal;
              font-size: 0.8125rem;
              padding: 5px 0;

              &.armed {
                color: $red;
              }

              &.warning {
                color: $warning;
              }

              .screen-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 25px;
                margin-right: 5px;

                img {
                  vertical-align: bottom;
                }
              }

              &:hover {
                cursor: pointer;
              }

              &.disabled {
                opacity: 0.5;
                filter: grayscale(1);
              }
            }
          }
        }
      }

      .arm-disarm-panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $brand-color-4;
        color: $brand-color-1;
        padding: 15px;
        margin-top: 10px;

        .control {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          font-size: 0.9375rem;
          font-weight: 600;
        }

        .status {
          width: 120px;
          font-size: 0.75rem;
          text-align: center;

          .armed {
            color: #338905;
          }

          .disarmed {
            color: #d50002;
          }
        }
      }

      .upgrade-status {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8125rem;
        font-weight: bold;
        color: $brand-color-1;

        img {
          margin-right: 10px;
        }

        span {
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    &.active {
      z-index: 1;
    }
    &.starTag {
      width: 435px; // Increased width for the star tag unit
    }
  }
  .accuracy-ring {
    position: relative;
  }

  .vehicle-registration {
    position: absolute;
    padding-top: 0;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    height: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    display: table;
    text-align: center;
    z-index: 52;
    font: normal normal bold 13px/21px Montserrat;
    letter-spacing: 0px;
    color: #536E89;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 123px;

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  @media (max-width: 480px) {
    .vehicle-registration {
      width: 95px;
    }
  }

}


</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {alertService, assetService, modalService} from "@/services";
import ArmDisarmConfirmationDialog from "@/components/ArmDisarmConfirmationDialog";

export default {
  name: "AssetIcon",
  components: {
    ArmDisarmConfirmationDialog,
  },
  props: [
    "isMarker",
    "isFeatureViewer",
    "isAssetPicker",
    "hideFeatureViewer",
    "registrationNumber",
    "vehicleId",
    "active",
    "sendToBack",
    "hasAccuracyRing",
    "lat",
    "lng",
    "totalAssets",
    "starTag",
    "zoomLevel"
  ],
  data() {
    return {
      asset: {},
      openInfoTimeoutId: null,
      hovered: false,
      showInfo: false,
      width: 0,
      height: 0,
      isVisible: true,
    };
  },
  computed: {
    ...mapGetters("asset", ["getAsset", "getActiveVehicleId"]),
    ...mapState(["map"]),
    ...mapState("map", ["selectedMarkerAssetId"]),
    ...mapState("navigation", {
      mainMenuShow: "show",
    }),
    activeVehicleId() {
      return this.getActiveVehicleId();
    },
    isNano() {
      return this.asset && this.asset.ProductName === "Nano";
    },
    isBasic() {
      return this.asset && this.asset.ProductName === "Basic";
    },
    isBasicIoT() {
      return (
        this.asset && this.asset.ProductDisplayName === "Netstar Basic IoT"
      );
    },
    isStarTag() {
      return this.asset && this.asset.ProductDisplayName === "StarTag";
    },
    isUpgradeable() {
      return this.isNano || this.isBasic;
    },
    isHealthy() {
      return this.asset && this.asset.IsHealthy;
    },
    isInRecovery() {
      return this.asset && this.asset.IsInRecovery;
    },
    isArmed() {
      return this.hasArmDisarm && this.asset.IsArmed;
    },
    isShared() {
      return this.asset && this.asset.shared ? this.asset.shared : false;
    },
    isSpeeding() {
      return this.asset.Speed > Math.abs(this.asset.SpeedLimit);
    },
    speedValue() {
      return this.asset.Speed > 0
        ? this.asset.Speed + " kph"
        : this.asset.EventDescription;
    },
    geoAddressLines() {
      return this.asset.GeoAddress ? this.asset.GeoAddress.split(",") : "";
    },
    earlyWarningIconUrl() {
      return this.isArmed
        ? require("@/assets/images/early-warning-armed.svg")
        : require("@/assets/images/early-warning-disarmed.svg");
    },
    healthStatusIconUrl() {
      if (this.isStarTag) {
        return require("@/assets/images/health-status-good.svg")
      }
      return this.isHealthy
        ? require("@/assets/images/health-status-good.svg")
        : require("@/assets/images/health-status-bad.svg");
    },
    batteryPercentageClassName() {
      if (this.asset) {
        return this.asset.BatteryPercentage > 40 ? "good" : "warning";
      }
      return "";
    },
    batteryPercentageIconUrl() {
      if (this.isStarTag) {
        if (this.asset.BatteryPercentage >= 50) {
          return require("@/assets/images/battery-green.svg");
        } else if (this.asset.BatteryPercentage >= 10) {
          return require("@/assets/images/battery-orange.svg");
        } else {
          return require("@/assets/images/battery-red.svg");
        }
      }
      return require("@/assets/images/battery-green.svg");
    },
    date() {
      return this.asset.DateTime ? this.asset.DateTime.split("T")[0] : "";
    },
    time() {
      return this.asset.DateTime ? this.asset.DateTime.split("T")[1] : "";
    },
    hasAlerts() {
      return (
        this.asset && !this.isBasicIoT && assetService.hasAlertsFeature(this.asset.ProductName)
      );
    },
    hasArmDisarm() {
      return (
        this.asset && assetService.hasArmDisarmFeature(this.asset.ProductName)
      );
    },
    hasBehaviour() {
      return (
        this.asset &&
        !this.isBasicIoT &&
        assetService.hasBehaviourFeature(this.asset.ProductName)
      );
    },
    hasLogbooks() {
      return (
        this.asset &&
        !this.isBasicIoT &&
        assetService.hasTripsFeature(this.asset.ProductName)
      );
    },
    hasMap() {
      return (
        this.asset &&
        assetService.hasRealTimeLocationTracking(this.asset.ProductName)
      );
    },
    hasShareVehicle() {
      return false;
      // return this.asset && assetService.hasShareVehicleFeature(this.asset.ProductName);
    },
    hasTrips() {
      return (
          this.asset &&
          !this.isBasicIoT &&
          assetService.hasTripsFeature(this.asset.ProductName)
      );
    },
    hasRealTimeLocationTracking() {
      return (
        this.asset &&
        assetService.hasRealTimeLocationTracking(this.asset.ProductName)
      );
    },
    hasUserImage() {
      if (this.asset) {
        return (
          this.asset.RegistrationNumber &&
          this.asset.ImageUrl &&
          this.asset.ImageUrl.toLowerCase().startsWith("http")
        );
      }

      return false;
    },
    ringColor() {
      switch (this.asset.DateValidity) {
        case 'Fresh':
          return '#65bc36';  // Green
        case 'Dated':
          return '#FA8E22';  // Orange
        case 'Outdated':
          return '#E62E2E';  // Red
        default:
          return '#65bc36';  // Default to Green if DateValidity is not set or unknown ?
      }
    },
    ringDiameter() {
      const rangeMeters = this.asset.EstimatedRangeMeters || 1000;
      return rangeMeters;
    },
    isOutdated() {
      return this.asset.DateValidity == 'Outdated';
    },
    isDated() {
      return this.asset.DateValidity == 'Dated';
    },
    dynamicStyles() {
      return {
        bottom: `calc(50% - ${this.height / 2}px + ${20}px)`,
        left: `calc(50% - ${this.width / 2}px + ${40}px)`,
        transform: 'translate(50%, -50%)'
      };
    },
  },
  created() {
    this.asset = this.getAsset(this.vehicleId);
  },
  mounted() {
    if (!this.hasAccuracyRing) {
      return;
    }
    this.$nextTick(() => {
      this.updateVisibility();
      this.updateDimensions();
    });

  },
  methods: {
    ...mapActions("asset", ["setActiveAsset"]),
    ...mapActions("map", ["setMapCenter"]),
    ...mapMutations("map", ["updateSelectedMarkerAssetId"]),
    ...mapMutations("navigation", ["updateShowSheet"]),
    ...mapMutations("asset", ["updateVehicleArmedStatus"]),
    openInfo() {
      if (
        !this.$isMobile &&
        this.isMarker &&
        !this.hasAccuracyRing &&
        !this.showInfo &&
        !this.selectedMarkerAssetId
      ) {
        this.hovered = true;
        this.openInfoTimeoutId = setTimeout(() => {
          if (this.hovered) {
            this.showInfo = true;
            this.updateSelectedMarkerAssetId(this.vehicleId);

            this.setMapCenter({
              latitude: this.asset.Latitude,
              longitude: this.asset.Longitude,
            });
          }
        }, 300);
      }
    },
    clearHover() {
      this.hovered = false;
      clearTimeout(this.openInfoTimeoutId);
    },
    toggleInfo() {
      if ((this.isMarker && !this.hasAccuracyRing) || !this.hasMap || this.isInRecovery || (this.isAssetPicker && this.hasAccuracyRing)) {
        if (this.$isMobile) {
          this.setActiveAsset(this.vehicleId);
          this.updateShowSheet(true);
        } else {
          this.showInfo = !this.showInfo;

          if (this.isFeatureViewer) {
            this.$emit("featureViewerInfoOpen", {
              open: this.showInfo,
              vehicleId: this.vehicleId,
            });
          }

          if (
              this.showInfo &&
              this.asset &&
              this.asset.Latitude &&
              this.asset.Longitude &&
              !this.isInRecovery) {
            this.setMapCenter({
              latitude: this.asset.Latitude,
              longitude: this.asset.Longitude,
            });
          }
          this.updateSelectedMarkerAssetId(
            this.showInfo ? this.vehicleId : null
          );
        }
      }
    },
    hide() {
      this.showInfo = false;
      if (this.selectedMarkerAssetId == this.vehicleId) {
        this.updateSelectedMarkerAssetId(null);
      }
    },
    showModal(screenName) {
      try {
        this.$gtag.event("vehicle_" + screenName);
      } catch (err) {}
      this.setActiveAsset(this.vehicleId);
      modalService.showModal(this, screenName);
      this.hide();
    },
    showLeftDrawer(screenName) {
      try {
        this.$gtag.event("vehicle_" + screenName);
      } catch (err) {}
      this.setActiveAsset(this.vehicleId);
      modalService.showLeftDrawer(this, screenName);
      this.hide();
    },
    handleInactiveFeature() {
      this.setActiveAsset(this.vehicleId);

      if (this.isInRecovery) {
        modalService.showRecoveryInProgressDialog(this);
      } else if (this.isBasicIoT) {
        modalService.showNotSupportedNoUpgradeDialog(this);
      } else {
        modalService.showNotSupportedDialog(this);
      }
    },
    upgrade() {
      try {
        this.$gtag.event("upgrade_product");
      } catch (err) {}
      this.setActiveAsset(this.vehicleId);
      this.showInfo = false;
      modalService.showModal(this, "product-upgrade-options");
    },
    toggleArmedStatus() {
      modalService.showArmDisarmConfirmationDialog(this.asset.IsArmed ? "disarm" : "arm", () => {
        const vehicleId = this.asset.SafeAndSoundVehicleId;
        const updatedArmedValue = !this.asset.IsArmed;

        assetService
          .updateVehicleArmedStatus(vehicleId, updatedArmedValue)
          .then((result) => {
            if (result) {
              modalService.showArmDisarmRequestReceivedDialog(() => {
                setTimeout(() => {
                  this.updateVehicleArmedStatus({
                    vehicleId: vehicleId,
                    status: updatedArmedValue,
                  });
                }, 5000);
              });
            } else {
              modalService.showErrorDialog();
            }
          })
          .catch(() => {
            modalService.showErrorDialog();
          });
      });
    },
    updateVisibility() {
      const minZoomLevel = 13;
      if (this.zoomLevel < minZoomLevel) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    },
    updateDimensions() {
      const el = this.$refs.vehicleRegistration;
      if (el) {
        this.width = el.offsetWidth;
        this.height = el.offsetHeight;
      }
    },
  },
  watch: {
    activeVehicleId(value) {
      if (this.isFeatureViewer) {
        const isActive = this.asset && this.asset.VehicleId === value;
        if (this.hasMap) {
          this.showInfo = isActive;
        }
      }
    },
    vehicleId() {
      this.asset = this.getAsset(this.vehicleId);
      this.showInfo = false;
    },
    mainMenuShow(value) {
      if (value) {
        this.showInfo = false;
      }
    },
    hideFeatureViewer() {
      this.showInfo = false;
    },
    selectedMarkerAssetId(value) {
      if (value != this.vehicleId) {
        this.hide();
      }
    },
    zoomLevel() {
      if (!this.hasAccuracyRing) {
        return;
      }

      this.updateVisibility();
      this.updateDimensions();
    },
  },
};
</script>
