<template>
  <div class="gradient-scroll-component" @scroll.passive="handleScroll">
    <div class="gradient top" :class="{ active: !scrolledToTop }" />
    <slot />
    <div class="gradient bottom" :class="{ active: !scrolledToBottom }" />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

.gradient-scroll-component {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .gradient {
    position: sticky;
    z-index: 1;
    width: calc(100% - 4px);
    height: 0;
    transition: height 0.5s;

    &.active {
      height: 50px;
    }

    &.top {
      background: linear-gradient(white 0%, rgba(255, 255, 255, 0) 100%);
      top: 0;
    }

    &.bottom {
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
      bottom: 0;
    }
  }
}

@include scrollbar;
</style>

<script>
export default {
  name: "GradientScroll",
  data() {
    return {
      scrolledToTop: true,
      scrolledToBottom: false,
    };
  },
  methods: {
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scrolledToTop = scrollTop === 0;
      this.scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 10;
    },
  },
};
</script>