<template>
  <div class="status-indicator-component">
    <transition name="fade">
      <svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="currentColor" class="check" v-if="success">
        <path
          fill-rule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
        />
      </svg>
      <svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="currentColor" class="exclamation" v-if="error">
        <path
          fill-rule="evenodd"
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
        />
      </svg>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.status-indicator-component {
  line-height: 1;

  .check {
    fill: $green;
  }

  .exclamation {
    fill: $red;
  }
}
</style>

<script>
export default {
  name: "StatusIndicator",
  props: ["success", "error"],
};
</script>