<template>
  <div class="behaviour-weekly-breakdown-component screen">
    <div class="header">
      <div class="title">Weekly Breakdown</div>
      <button class="close" @click="close()"></button>
    </div>
    <div class="panel">
      <div class="controls">
        <svg width="20" height="20" viewBox="0 0 16 16" class="control" fill="currentColor" @click="previous()">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg>
        <div class="period">{{ periods[selectedIndex].Name }}</div>
        <svg width="20" height="20" viewBox="0 0 16 16" class="control" fill="currentColor" @click="next()">
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </div>
      <div class="content">
        <div class="heading">
          <svg width="20" height="20" viewBox="0 0 16 16" class="star-icon" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288l1.847-3.658 1.846 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.564.564 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
            />
          </svg>
          <div>Weekly Score</div>
          <div class="score">{{ Math.floor(periods[selectedIndex].Score) }}%</div>
        </div>
        <table class="table table-borderless">
          <tbody>
            <tr v-for="category in periods[selectedIndex].Category" :key="category.Name">
              <td class="name">{{ category.Name }}</td>
              <td class="score">{{ Math.floor(category.Score) }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.behaviour-weekly-breakdown-component {
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 340px;
  box-shadow: $omni-shadow;

  .header {
    margin-bottom: 15px;

    .title {
      font-weight: bold;
      color: $brand-color-2;
      text-align: center;
      margin-top: 4px;
    }
  }

  .close {
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    opacity: 1;

    &:before,
    &:after {
      background: $brand-color-2;
      position: absolute;
      top: 0;
      left: 11px;
      content: " ";
      height: 24px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:focus {
      outline: none;
    }
  }

  .panel {
    background: $brand-color-4;
    padding: 10px;

    .controls {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 10px;

      .control {
        user-select: none;

        &:hover {
          cursor: pointer;
        }
      }

      .period {
        min-width: 182px;
        font-weight: bold;
      }
    }

    .content {
      background: #fff;
      padding: 10px 20px;
      box-shadow: $drop-shadow-light;

      .heading {
        display: flex;
        justify-content: space-evenly;
        font-size: 1.125rem;
        color: $brand-color-2;
        margin-bottom: 10px;

        .star-icon {
          fill: $brand-color-1;
        }

        .score {
          min-width: 45px;
          font-weight: bold;
        }
      }

      table {
        border-top: 1px solid $brand-color-2;

        tr {
          td {
            padding-bottom: 0;

            &.name {
              text-align: right;
              border-right: 1px solid $brand-color-2;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "BehaviourWeeklyBreakdown",
  components: {},
  props: ["index", "periods"],
  data() {
    return {
      selectedIndex: this.index,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    previous() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--;
      }
    },
    next() {
      if (this.selectedIndex < this.periods.length - 1) {
        this.selectedIndex++;
      }
    },
  },
};
</script>