import Vue from "vue";
import Router from "vue-router";
import { userService } from "@/services";
import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPage";
import LogoutPage from "@/pages/LogoutPage";
import RegisterPage from "@/pages/RegisterPage";
import ResetPasswordPage from "@/pages/ResetPasswordPage";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    { name: "Home", path: "/", component: HomePage },
    { name: "Login", path: "/login", component: LoginPage },
    { name: "Logout", path: "/logout", component: LogoutPage },
    { name: "Register", path: "/register", component: RegisterPage },
    {
      name: "Reset Password",
      path: "/reset-password",
      component: ResetPasswordPage,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/logout", "/register", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = userService.getUser();

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  if (loggedIn && to.path == "/login") {
    return next(false);
  }

  next();
});
