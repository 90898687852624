<template>
  <div
    role="dialog"
    aria-modal="true"
    aria-labelledby="dialogTitle"
    aria-describedby="dialogMessage"
    :style="{ width: width }"
    class="arm-disarm-confirmation-dialog"
  >
    <div id="dialogTitle">
      {{ isRequestReceived ? "REQUEST RECEIVED" : "NOTIFICATION" }}
    </div>
    <div class="content">
      <template v-if="isRequestReceived">
        <div id="dialogMessage">
          Your Early Warning status will be updated shortly.
          <hr />
          <div class="standard-text mt-2">Please allow a moment for your unit to respond.</div>
        </div>
      </template>
      <template v-else>
        <div id="dialogMessage">
          Are you sure you want to <span class="text-capitalize">{{ requestType }}</span> your Early Warning Device?
          <hr />
        </div>
      </template>
    </div>
    <FocusTrap :disabled="!active">
      <div class="buttons">
        <button v-for="(button, i) in buttons" :key="i" :ref="'button' + i" type="button" tabindex="0" @click.stop="click(i, $event)">
          {{ button.title }}
        </button>
      </div>
    </FocusTrap>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.arm-disarm-confirmation-dialog {
  background: #fff;
  position: absolute;
  z-index: 999;
  max-width: 90%;
  font-size: 14px;
  padding: 0 0 25px 0;
  box-shadow: $omni-shadow-brand-color;

  #dialogTitle {
    background: $brand-color-1;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    color: #fff;
    padding: 10px 20px;
    box-shadow: $drop-shadow;
  }

  .content {
    background: $brand-color-4;
    text-align: center;
    padding: 20px;
    margin: 30px 20px 20px 20px;

    #dialogMessage {
      font-weight: bold;
      color: $brand-color-2;
      margin-top: 12px;

      .standard-text {
        font-weight: 400;
        color: $brand-color-1;
      }

      hr {
        display: block;
        width: 60px;
        margin: 8px auto 0 auto;
        border-top: 1px solid $brand-color-1;
      }
    }
  }
  .buttons {
    float: right;
    padding: 0 20px;

    button {
      background: transparent;
      font-size: 0.75rem;
      font-weight: bold;
      color: $brand-color-1;
      text-transform: uppercase;
      min-width: 95px;
      padding: 10px 20px;
      margin-right: 15px;
      border: 1px solid $brand-color-1;

      &:last-of-type {
        margin-right: 0;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background: $brand-color-2;
        color: #fff;
        border-color: $brand-color-2;
      }
    }
  }
}
</style>

<script>
import FocusTrap from "vue-focus-lock";

export default {
  name: "ArmDisarmConfirmationDialog",
  components: {
    FocusTrap,
  },
  props: {
    requestType: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
    },
    width: {
      type: String,
      default: "350px",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRequestReceived() {
      return this.requestType == "status";
    },
  },
  methods: {
    click(buttonIndex, event, source = "click") {
      const button = this.buttons[buttonIndex];
      const handler = button?.handler;
      if (typeof handler === "function") {
        handler(buttonIndex, event, { source });
      }
    },
  },
};
</script>