<template>
  <div class="accordion-component">
    <div class="accordion-heading" :aria-expanded="isOpen" @click="toggleAccordion()">
      <slot name="title" />
      <div class="toggle-icon style-1" v-if="useArrows">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24" v-if="isOpen">
          <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
        </svg>
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24" v-else>
          <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </div>
      <div class="toggle-icon style-2" v-else>
        <svg style="width: 18px; height: 18px" viewBox="0 0 24 24" v-if="isOpen">
          <path fill="currentColor" d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>
        <svg style="width: 18px; height: 18px" viewBox="0 0 24 24" v-else>
          <path fill="currentColor" d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>
      </div>
    </div>
    <SmoothReflow>
      <div class="accordion-content" v-show="isOpen">
        <slot name="content" />
      </div>
    </SmoothReflow>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.accordion-component {
  .accordion-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }

    .toggle-icon {
      width: 24px;
      text-align: center;

      &.style-1 {
        color: $brand-color-1;
      }

      &.style-2 {
        color: $brand-color-2;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Accordion",
  props: {
    forceClose: {
      type: Boolean,
      default: false,
    },
    useArrows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    forceClose(value) {
      if (value) {
        this.isOpen = false;
      }
    },
  },
};
</script>