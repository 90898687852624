<template>
  <div class="upgrade-step-header-component">
    <CloseButton @click="close()" />
    <template>
      <div class="main">
        <div class="heading">PRODUCT <span>UPGRADE</span></div>
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <span class="step-no">STEP {{ stepNo }}/{{ totalSteps }}:</span>
            <span class="step-title">{{ title }}</span>
            <div class="message">
              <template v-if="(isNano && stepNo == 5) || (!isNano && stepNo == 4)">
                Please scroll to the bottom of the Ts&Cs block and tick the box below.
              </template>
              <template v-else-if="isNano && stepNo == 4">Please provide your preferred fitment location.</template>
              <template v-else>Please verify your info and make changes where necessary.</template>
            </div>
          </div>
          <button class="btn btn-outline ml-3" @click="submit()" v-if="showNextButton">
            <span v-show="!processing">NEXT</span>
            <Spinner v-show="processing" />
          </button>
          <Spinner v-if="showSpinner" />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.upgrade-step-header-component {
  background: $brand-color-2;
  color: #fff;
  padding: 20px;
  margin: -20px -30px 30px -30px;
  box-shadow: $drop-shadow;

  .close-button {
    &:before,
    &:after {
      background: #fff;
    }
  }

  .main {
    .heading {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 15px;

      > span {
        font-weight: 400;
      }
    }

    .step-no,
    .step-title {
      text-transform: uppercase;
    }

    .step-no {
      margin-right: 8px;
    }

    .step-title {
      font-weight: bold;
    }

    .message {
      font-size: 0.75rem;
      margin-top: 5px;
    }

    button {
      min-width: 90px;
      color: #fff;
      border-color: #fff;

      &:hover {
        background: #fff;
        color: $brand-color-2;
        border-color: #fff;
      }
    }
  }

  .ns-tooltip {
    position: absolute;
    top: 9px;
    right: 55px;
  }
}

@media (max-width: 767px) {
  .upgrade-step-header-component {
    .main {
      .step-no {
        display: block;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { modalService } from "@/services";
import CloseButton from "@/components/CloseButton";

export default {
  name: "UpgradeStepHeader",
  components: {
    CloseButton,
  },
  props: {
    stepNo: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    nextStepScreenName: {
      type: String,
      required: true,
    },
    showNextButton: {
      type: Boolean,
      default: true,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    formValidationFailureCount: {
      type: Number,
      default: 0,
      required: true,
    },
    stepCompleted: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveVehicleProductName"]),
    isNano() {
      return this.getActiveVehicleProductName() === "Nano";
    },
    totalSteps() {
      return this.isNano ? 5 : 4;
    },
  },
  methods: {
    ...mapMutations("asset", ["resetUpgradeState"]),
    submit() {
      this.processing = true;
      this.$emit("submit");
    },
    goToNextStepInUpgrade() {
      modalService.showModal(this, this.nextStepScreenName, {});
    },
    close() {
      this.resetUpgradeState();
      this.$emit("close");
    },
  },
  watch: {
    formValidationFailureCount() {
      this.processing = false;
    },
    stepCompleted(value) {
      if (value) {
        this.goToNextStepInUpgrade();
      }
    },
  },
};
</script>