<template>
  <SmoothReflow>
    <div class="alert-component" :class="{ show: alert.message }">
      <div v-if="alert.message" class="alert" :class="alert.type ? alert.type : ''">
        <div class="icon">
          <svg width="18px" height="18px" viewBox="0 0 16 16" class="success" fill="currentColor" v-if="alert.type == 'alert-success'">
            <path
              fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
            />
            <path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z" />
          </svg>
          <svg width="18px" height="18px" viewBox="0 0 16 16" fill="currentColor" class="exclamation" v-if="alert.type == 'alert-danger'">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
          </svg>
        </div>
        <div class="message">{{ alert.message }}</div>
      </div>
    </div>
  </SmoothReflow>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.alert-component {
  height: 0;

  &.show {
    height: auto;
  }

  .alert {
    display: flex;
    align-items: center;
    line-height: 18px;

    .icon {
      display: flex;
      margin-right: 8px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "Alert",
  computed: {
    ...mapState(["alert"]),
  },
};
</script>