<template>
  <div class="asset-picker-component">
    <div v-if="isVehicleOnMap && !search">
      <AssetIcon class="item" @click.native="setAllVehiclesAsActive()" />
    </div>
    <div class="asset-slider">
      <button
          @click="previousSlide"
          :class="{ active: assetSlider.isPreviousSlideNavActive }"
          v-if="showSliderNav"
      >
        <svg width="28" height="28" viewBox="0 0 24 24">
          <path
              d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z"
          />
        </svg>
      </button>
      <div
          class="slide"
          v-for="(asset, index) in filteredAssets"
          :key="asset.SafeAndSoundVehicleId"
          :data-id="index"
          v-show="isSlideVisible(index)"
      >
        <AssetIcon
          class="item"
          :vehicleId="asset.SafeAndSoundVehicleId"
          :registrationNumber="asset.RegistrationNumber"
          :active="
            asset.show ||
              ((isFeatureViewer(asset.ProductName) || asset.hasAccuracyRing) &&
                isSelected(asset.SafeAndSoundVehicleId))
          "
          :isMarker="false"
          :isFeatureViewer="
            asset.IsInRecovery || asset.hasAccuracyRing || isFeatureViewer(asset.ProductName)
          "
          :isAssetPicker="true"
          :hideFeatureViewer="hideFeatureViewer"
          :sendToBack="
            activeFeatureViewerVehicleId &&
              asset.SafeAndSoundVehicleId != activeFeatureViewerVehicleId
          "
          :starTag="asset.ProductDisplayName === 'StarTag'"
          :hasAccuracyRing="asset.hasAccuracyRing"
          @featureViewerInfoOpen="handleFeatureViewerIsOpen"
          @click.native="activate(asset)"
        />
      </div>
      <button
          @click="nextSlide"
          :class="{ active: assetSlider.isNextSlideNavActive }"
          v-if="showSliderNav"
      >
        <svg width="28" height="28" viewBox="0 0 24 24">
          <path
              d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,18L16,12L10,6L8.6,7.4L13.2,12L8.6,16.6L10,18Z"
          />
        </svg>
      </button>
    </div>
    <div class="search-box" v-if="showSearchBox">
      <svg width="21.122" height="17.856" viewBox="0 0 21.122 17.856">
        <defs>
          <clipPath id="clip-path">
            <rect
                width="21.122"
                height="17.856"
                fill="none"
                stroke="#707070"
                stroke-width="1"
            />
          </clipPath>
        </defs>
        <g transform="translate(0 0)" clip-path="url(#clip-path)">
          <path
            d="M12.12,6.281a1.9,1.9,0,1,1,3.8,0M15.846.375H5.048A2.038,2.038,0,0,0,3.063,1.957L1.992,6.6H18.9L17.832,1.957A2.038,2.038,0,0,0,15.846.375Zm4.648,11.682v3.824a1.346,1.346,0,0,1-1.343,1.347h-.777a1.345,1.345,0,0,1-1.344-1.347V13.942m-13.193,0v1.939A1.346,1.346,0,0,1,2.5,17.228h-.78A1.346,1.346,0,0,1,.375,15.881V12.057m18.461-1.571A1.458,1.458,0,1,1,17.38,9.024,1.459,1.459,0,0,1,18.836,10.486Zm-13.9,0A1.457,1.457,0,1,1,3.479,9.024,1.459,1.459,0,0,1,4.936,10.486Zm13.3-3.89H2.635A2.271,2.271,0,0,0,.375,8.862v2.813a2.271,2.271,0,0,0,2.26,2.267h15.6a2.27,2.27,0,0,0,2.26-2.267V8.862A2.27,2.27,0,0,0,18.235,6.6Z"
            transform="translate(0.126 0.127)"
            fill="none"
            stroke="#536e84"
            stroke-width="1"
          />
          <line
              x2="7.258"
              transform="translate(6.932 10.382)"
              fill="none"
              stroke="#536e84"
              stroke-linecap="round"
              stroke-width="1"
          />
        </g>
      </svg>
      <input type="search" v-model="search" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.asset-picker-component {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 100px;
  z-index: 1;

  .asset-slider {
    display: flex;
    align-items: center;
    margin: 0 10px;

    .slide {
      margin: 0 5px;
    }

    button {
      background: #fff;
      padding: 0;
      margin: 0 8px;
      border-radius: 50%;
      border: none;
      box-shadow: $drop-shadow;

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: inherit;
      }

      svg {
        fill: $brand-color-4;
        margin: -3px;
      }

      &.active {
        svg {
          fill: $brand-color-2;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .search-box {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 15px;
    border-radius: 20px;
    box-shadow: $drop-shadow;

    input[type="search"] {
      font-weight: 600;
      color: $brand-color-1;
      margin-left: 10px;
      border: none;

      &:focus {
        outline: none;
        border-bottom: 1px solid $brand-color-1;
      }
    }
  }
}

@media (max-width: 1250px) {
  .asset-picker-component {
    top: 50px;
  }
}

@media (max-width: $md) {
  .asset-picker-component {
    top: 80px;
    left: 15px;

    .search-box {
      position: absolute;
      top: 100px;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { assetService } from "@/services";
import AssetIcon from "@/components/AssetIcon";

export default {
  name: "AssetPicker",
  components: {
    AssetIcon,
  },
  data() {
    return {
      hideFeatureViewer: 0,
      activeFeatureViewerVehicleId: "",
      assetSlider: {
        firstSlideIndex: 0,
        slidesToShow: 5,
        isPreviousSlideNavActive: false,
        isNextSlideNavActive: true,
      },
      search: "",
    };
  },
  computed: {
    ...mapGetters("asset", ["getAllAssets", "getActiveSafeAndSoundVehicleId"]),
    filteredAssets() {
      return this.getAllAssets().filter(
        (asset) =>
          asset.RegistrationNumber &&
          !asset.shared &&
            ((asset.RegistrationNumber &&
                    asset.RegistrationNumber.toLowerCase().includes(
                        this.search.toLowerCase()
                    )) ||
                (asset.Nickname &&
                    asset.Nickname.toLowerCase().includes(this.search.toLowerCase())))
      );
    },
    assetsEligibleForDisplay() {
      return this.getAllAssets().filter(
          (asset) => asset.RegistrationNumber && !asset.shared
      );
    },
    isVehicleOnMap() {
      return this.filteredAssets.filter((obj) => "show" in obj).length;
    },
    showSliderNav() {
      return this.filteredAssets.length > this.assetSlider.slidesToShow;
    },
    showSearchBox() {
      return (
          this.assetsEligibleForDisplay.length > this.assetSlider.slidesToShow
      );
    },
  },
  methods: {
    ...mapActions("asset", ["setActiveAsset"]),
    ...mapActions("map", ["setMapBounds"]),
    ...mapMutations("asset", ["setMapActive"]),
    ...mapMutations("map", ["updateSelectedMarkerAssetId"]),
    isFeatureViewer(productName) {
      return !assetService.hasRealTimeLocationTracking(productName);
    },
    isSelected(assetId) {
      return assetId === this.getActiveSafeAndSoundVehicleId();
    },
    setAllVehiclesAsActive() {
      this.hideFeatureViewer++;
      this.setMapActive();
      this.setMapBounds();
    },
    handleFeatureViewerIsOpen(value) {
      this.activeFeatureViewerVehicleId = value.open ? value.vehicleId : "";
    },
    activate(asset) {
      if (asset.disabled) {
        return;
      }
      const currentSafeAndSoundVehicleId = this.getActiveSafeAndSoundVehicleId();
      this.setActiveAsset(asset.SafeAndSoundVehicleId);
      this.setMapActive(asset.SafeAndSoundVehicleId);
      if (
          currentSafeAndSoundVehicleId !== this.getActiveSafeAndSoundVehicleId()
      ) {
        this.setMapBounds();
      }
      if (!asset.hasAccuracyRing) {
        this.updateSelectedMarkerAssetId(null);
      }
    },
    isSlideVisible(index) {
      return (
          index >= this.assetSlider.firstSlideIndex &&
          index < this.assetSlider.firstSlideIndex + this.assetSlider.slidesToShow
      );
    },
    previousSlide() {
      if (this.assetSlider.firstSlideIndex !== 0) {
        this.assetSlider.firstSlideIndex -= 1;
        this.assetSlider.isPreviousSlideNavActive =
            this.assetSlider.firstSlideIndex !== 0;
        this.assetSlider.isNextSlideNavActive =
            this.assetSlider.firstSlideIndex !==
            this.filteredAssets.length - this.assetSlider.slidesToShow;
      }
    },
    nextSlide() {
      if (
          this.assetSlider.firstSlideIndex <
          this.filteredAssets.length - this.assetSlider.slidesToShow
      ) {
        this.assetSlider.firstSlideIndex += 1;
        this.assetSlider.isPreviousSlideNavActive =
            this.assetSlider.firstSlideIndex !== 0;
        this.assetSlider.isNextSlideNavActive =
            this.assetSlider.firstSlideIndex !==
            this.filteredAssets.length - this.assetSlider.slidesToShow;
      }
    },
  },
};
</script>
