<template>
  <div class="menu">
    <ul class="menu__navbar">
      <li class="menu__item">
        <a class="menu__link">
          <button class="menu-button" v-if="show" key="on" @click="show = false">
            <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="icon">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
          </button>
          <button class="menu-button" v-else key="off" @click="show = true">
            <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="icon">
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
          <span class="selected-item" @click="show = !show">{{ activeVehicleDisplayName }}</span>
        </a>
        <transition name="dropdown">
          <div class="dropdown__menu" :class="{ active: show }" v-if="show">
            <ul class="dropdown__menu-nav">
              <li v-for="(asset, i) in getMyAssets()" :key="i" class="dropdown__menu-item">
                <a class="dropdown__menu-link" :title="asset.RegistrationNumber" @click="update(asset.SafeAndSoundVehicleId)">
                  <div class="dropdown__menu-text">{{ asset.Nickname ? asset.Nickname : asset.RegistrationNumber }}</div>
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.menu {
  position: relative;

  &__navbar {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    color: $brand-color-1;
    text-decoration: none;

    .selected-item {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-button {
    background-color: transparent;
    padding: 0 5px 0 0;
    cursor: pointer;
    border: 1px solid transparent;

    &:focus {
      outline: 0;
    }

    .icon {
      fill: $brand-color-1;
      vertical-align: sub;
    }
  }
}

.dropdown__menu {
  background: #fff;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 2;
  width: 220px;
  overflow-y: auto;
  padding: 15px;
  box-shadow: $drop-shadow;

  &-nav {
    list-style: none;
    padding: 0;
    border-left: 1px solid $brand-color-2;
  }

  &-item {
    &:first-of-type {
      a {
        padding-top: 0;
      }
    }

    &:last-of-type {
      a {
        padding-bottom: 0;
      }
    }
  }

  &-link {
    display: block;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-decoration: none;
    text-transform: uppercase;
    padding: 6px 10px;

    &:hover {
      color: $brand-color-2;
    }
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VehiclePickerMenu",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters("asset", ["getMyAssets", "getActiveVehicleDisplayName"]),
    activeVehicleDisplayName() {
      return this.getActiveVehicleDisplayName();
    },
  },
  methods: {
    ...mapActions("asset", ["setActiveAsset"]),
    update(vehicleId) {
      this.setActiveAsset(vehicleId);
      this.show = false;
    },
  },
};
</script>