<template>
  <div class="terms-and-conditions-for-upgrade-component screen">
    <UpgradeStepHeader
      class="upgrade-step-header"
      :stepNo="stepNo"
      title="Accept Terms & Conditions"
      nextStepScreenName="thank-you-for-upgrade"
      :formValidationFailureCount="formValidationFailureCount"
      :stepCompleted="upgradeStepCompleted"
      @submit="update()"
      @close="beforeClose()"
    />
    <Alert />
    <div class="notification" v-if="isBasic">
      By clicking next, you confirm that for <span>R30 MORE P/M</span> your
      device will be enabled to report near real-time data - allowing for
      vehicle tracking via MyNetstar.
    </div>
    <GradientScroll class="form-container">
      <form ref="form" @submit.prevent>
        <div class="terms">
          <UpgradeTermsAndConditionsForNanoCustomers v-if="isNano" />
          <UpgradeTermsAndConditionsForSVRCustomers v-else />
        </div>
      </form>
    </GradientScroll>
    <div class="cta">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="acceptTerms"
          v-model="acceptTerms"
        />
        <label class="custom-control-label" for="acceptTerms"
          >I have read and understood the Netstar subscriber agreement and agree
          to be bound by the Terms and Conditions.</label
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.terms-and-conditions-for-upgrade-component {
  display: flex;
  flex-direction: column;
  max-height: $modalHeight;

  .notification {
    background: $brand-color-4;
    font-size: 0.8125rem;
    font-weight: 600;
    color: $brand-color-2;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;

    span {
      font-style: normal;
      font-weight: bold;
      color: $brand-color-1;
    }
  }

  .terms {
    font-weight: 300;
    padding-right: 15px;
  }

  .cta {
    font-size: 0.8125rem;
    padding-top: 10px;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { alertService, assetService, modalService } from "@/services";
import UpgradeStepHeader from "@/components/screens/UpgradeStepHeader";
import UpgradeTermsAndConditionsForNanoCustomers from "@/components/UpgradeTermsAndConditionsForNanoCustomers";
import UpgradeTermsAndConditionsForSVRCustomers from "@/components/UpgradeTermsAndConditionsForSVRCustomers";

export default {
  name: "TermsAndConditionsForUpgrade",
  components: {
    UpgradeStepHeader,
    UpgradeTermsAndConditionsForNanoCustomers,
    UpgradeTermsAndConditionsForSVRCustomers,
  },
  data() {
    return {
      formValidationFailureCount: 0,
      upgradeStepCompleted: false,
      acceptTerms: false,
    };
  },
  computed: {
    ...mapGetters("asset", [
      "getActiveVehicleId",
      "getActiveVehicleProductName",
      "getActiveVehicleUnitSerialNumber",
      "getUpgradeProductName",
      "getUpgradePartnerCode",
      "getUpgradeFitmentAddress",
    ]),
    isNano() {
      return this.getActiveVehicleProductName() === "Nano";
    },
    isBasic() {
      return this.getActiveVehicleProductName() === "Basic";
    },
    stepNo() {
      return this.isNano ? 5 : 4;
    },
  },
  methods: {
    ...mapMutations("asset", ["resetUpgradeState"]),
    beforeClose() {
      this.$emit("close");
    },
    update() {
      if (this.acceptTerms) {
        this.handleSubmit();
        return true;
      } else {
        alertService.showErrorAlert("Please accept Terms and Conditions.");
        this.formValidationFailureCount++;
        return false;
      }
    },
    handleSubmit() {
      assetService
        .productUpgrade(
          this.getActiveVehicleId(),
          this.getActiveVehicleUnitSerialNumber(),
          this.getUpgradeProductName(),
          this.getUpgradePartnerCode(),
          this.getUpgradeFitmentAddress()
        )
        .then((response) => {
          try {
            this.$gtag.event("upgrade_terms_conditions");
            this.$gtag.event("upgrade_complete");
          } catch (err) {}

          this.resetUpgradeState();
          modalService.showModal(this, "thank-you-for-upgrade", {
            referenceNumber: response.ReferenceNumber,
            ticketNumber: response.TicketNumber,
            fitmentBookingUrl: response.BookingLink,
          });
        })
        .catch((error) => {
          alertService.showErrorAlert(
            "Unfortunately, we cannot process your upgrade. Please submit again."
          );
          this.formValidationFailureCount++;
        });
    },
  },
};
</script>
