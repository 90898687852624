import LatLon from "geodesy/latlon-ellipsoidal-vincenty.js";
import wkt from "terraformer-wkt-parser";

export const mapService = {
  getGeoData,
  getCenterPoint,
  getRadius,
  getPointOnCircle,
};

function getGeoData(zoneString) {
  let geoData = [];

  if (zoneString.indexOf("CURVEPOLYGON (CIRCULARSTRING (") !== -1) {
    const coordinatesString = zoneString
      .replace("CURVEPOLYGON (CIRCULARSTRING (", "")
      .replace(")", "");

    const coordinates = coordinatesString.split(",").map((item) => {
      return item
        .trim()
        .split(" ")
        .map((item) => parseFloat(item));
    });

    const centerPoint = getCenterPoint(coordinates);
    const radius = getRadius(centerPoint, coordinates);

    geoData = {
      type: "Circle",
      coordinates: coordinates,
      center: centerPoint,
      radius: radius,
    };
  } else {
    geoData = wkt.parse(zoneString);
  }

  return geoData;
}

function getCenterPoint(coordinates) {
  const bounds = new google.maps.LatLngBounds();

  coordinates.forEach((point) => {
    bounds.extend({ lat: point[1], lng: point[0] });
  });

  return bounds.getCenter();
}

function getRadius(centerPoint, coordinates) {
  const distances = [];

  coordinates.forEach((coordinate) => {
    const point = new google.maps.LatLng(coordinate[1], coordinate[0]);
    distances.push(
      google.maps.geometry.spherical.computeDistanceBetween(centerPoint, point)
    );
  });

  return distances.reduce((a, b) => a + b) / distances.length;
}

function getPointOnCircle(centerPoint, radius, bearing) {
  const p1 = new LatLon(centerPoint.lat(), centerPoint.lng());
  const p2 = p1.destinationPoint(radius, bearing);
  return [p2.longitude, p2.latitude];
}
