<template>
  <div class="menu">
    <ul class="menu__navbar">
      <li class="menu__item">
        <transition name="dropdown">
          <div class="dropdown__menu" :class="{ active: show }" v-if="show">
            <ul class="dropdown__menu-nav">
              <hr class="mt-2" />
              <Accordion :useArrows="true">
                <template v-slot:title>
                  <div class="dropdown__menu-heading">MY ADMIN</div>
                </template>
                <template v-slot:content>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Company Details"
                      @click="showModal('company-details')"
                      v-if="isBusinessAccount"
                    >
                      <div class="dropdown__menu-text">Company Details</div>
                    </a>
                    <a
                      class="dropdown__menu-link"
                      title="Personal Details"
                      @click="showModal('personal-details')"
                      v-else
                    >
                      <div class="dropdown__menu-text">Personal Details</div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Financial Details"
                      @click="showModal('financial-details')"
                    >
                      <div class="dropdown__menu-text">Financial Details</div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Documents"
                      @mouseover="hover = true"
                      @mouseleave="hover = false"
                      @click="showModal('documents')"
                    >
                      <div class="dropdown__menu-text">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 12.373 9.352"
                          class="icon"
                        >
                          <g>
                            <path
                              d="M5.839,6.543c0.048,0.048,0.104,0.084,0.163,0.108c0.059,0.024,0.122,0.036,0.185,0.036s0.126-0.012,0.185-0.036
		c0.059-0.024,0.115-0.06,0.163-0.108L7.358,5.72l0.823-0.823C8.278,4.8,8.326,4.674,8.326,4.548S8.278,4.296,8.181,4.2
		S7.959,4.056,7.833,4.056c-0.126,0-0.252,0.048-0.348,0.144L7.082,4.603L6.679,5.006V2.749V0.492c0-0.136-0.055-0.259-0.144-0.348
		C6.446,0.055,6.323,0,6.187,0S5.928,0.055,5.839,0.144C5.749,0.233,5.694,0.356,5.694,0.492v2.257v2.257L5.291,4.603L4.888,4.2
		C4.792,4.104,4.666,4.056,4.54,4.056c-0.126,0-0.252,0.048-0.348,0.144C4.096,4.296,4.048,4.422,4.048,4.548S4.096,4.8,4.192,4.896
		L5.015,5.72L5.839,6.543z"
                            />
                            <path
                              d="M11.881,4.11c-0.136,0-0.259,0.055-0.348,0.144c-0.089,0.089-0.144,0.212-0.144,0.348v1.882v1.882H6.187H0.985V6.485V4.603
		c0-0.136-0.055-0.259-0.144-0.348C0.751,4.166,0.628,4.11,0.492,4.11S0.233,4.166,0.144,4.255C0.055,4.344,0,4.467,0,4.603v2.128
		V8.86c0,0.136,0.055,0.259,0.144,0.348s0.212,0.144,0.348,0.144h5.694h5.694c0.136,0,0.259-0.055,0.348-0.144
		c0.089-0.089,0.144-0.212,0.144-0.348V6.731V4.603c0-0.136-0.055-0.259-0.144-0.348C12.14,4.166,12.017,4.11,11.881,4.11z"
                            />
                          </g>
                        </svg>
                        <span class="ml-2">Documents</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Change Password"
                      @click="showModal('change-password')"
                    >
                      <div class="dropdown__menu-text">Change Password</div>
                    </a>
                  </li>
                </template>
              </Accordion>
              <hr />
              <Accordion :useArrows="true">
                <template v-slot:title>
                  <div class="dropdown__menu-heading">Contact Netstar</div>
                </template>
                <template v-slot:content>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Get In Touch"
                      @click="showModal('get-in-touch')"
                    >
                      <div class="dropdown__menu-text">Get In Touch</div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Feedback"
                      @click="showModal('feedback')"
                    >
                      <div class="dropdown__menu-text">Feedback</div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="FAQs"
                      @click="showModal('faqs')"
                    >
                      <div class="dropdown__menu-text">FAQs</div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      class="dropdown__menu-link"
                      title="Terms & Conditions"
                      @click="showModal('terms-and-conditions')"
                    >
                      <div class="dropdown__menu-text">Terms & Conditions</div>
                    </a>
                  </li>
                </template>
              </Accordion>
              <hr />
              <Accordion :useArrows="true">
                <template v-slot:title>
                  <div class="dropdown__menu-heading">Products & Services</div>
                </template>
                <template v-slot:content>
                  <li class="dropdown__menu-item secondary">
                    <a
                      :href="addProductUrl"
                      target="_blank"
                      class="dropdown__menu-link"
                      title="Add Product"
                    >
                      <div class="dropdown__menu-text">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 15.83 13.422"
                          class="icon"
                        >
                          <g transform="translate(0 0)">
                            <g>
                              <path
                                d="M14.34,13.422h-.572a1.493,1.493,0,0,1-1.49-1.492V11H3.553v.928a1.492,1.492,0,0,1-1.488,1.492H1.491A1.493,1.493,0,0,1,0,11.93L.018,9.111A2.17,2.17,0,0,1,0,8.831V6.756A2.172,2.172,0,0,1,1.247,4.792L2,1.555A1.993,1.993,0,0,1,3.946,0H11.9a1.993,1.993,0,0,1,1.95,1.554L14.606,4.8A2.174,2.174,0,0,1,15.83,6.756V8.831a2.17,2.17,0,0,1-.017.28h.017V11.93a1.492,1.492,0,0,1-1.49,1.492M13.278,11v.928a.491.491,0,0,0,.49.492h.572a.491.491,0,0,0,.49-.492V10.66A2.151,2.151,0,0,1,13.664,11ZM1,10.66v1.27a.491.491,0,0,0,.491.492h.574a.49.49,0,0,0,.488-.492V11H2.166A2.157,2.157,0,0,1,1,10.66M2.166,5.586A1.17,1.17,0,0,0,1,6.756V8.831A1.171,1.171,0,0,0,2.166,10h11.5A1.17,1.17,0,0,0,14.83,8.831V6.756a1.169,1.169,0,0,0-1.166-1.17Zm10.275-1H13.53L12.88,1.78A1,1,0,0,0,11.9,1H3.946a1,1,0,0,0-.977.78l-.647,2.8H8.675a1.9,1.9,0,0,1,3.766,0m-2.744,0h1.722a.9.9,0,0,0-1.722,0M12.39,9.467A1.577,1.577,0,1,1,13.963,7.89,1.578,1.578,0,0,1,12.39,9.467m0-2.153a.577.577,0,1,0,.573.576.577.577,0,0,0-.573-.576M3.475,9.467A1.577,1.577,0,1,1,5.048,7.89,1.577,1.577,0,0,1,3.475,9.467m0-2.153a.577.577,0,1,0,.573.576.576.576,0,0,0-.573-.576"
                              />
                              <path
                                d="M9.485,8.283H6.367a.5.5,0,0,1,0-1H9.485a.5.5,0,0,1,0,1"
                              />
                            </g>
                          </g>
                        </svg>
                        <span class="ml-2">Add Vehicle</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      href="https://www.netstar.co.za/netstar-companion/"
                      target="_blank"
                      class="dropdown__menu-link"
                      title="Get Netstar Companion"
                    >
                      <div class="dropdown__menu-text">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 13.415 13.415"
                          class="icon"
                        >
                          <g>
                            <path
                              d="M11.065,13.415H2.351A2.354,2.354,0,0,1,0,11.064V2.351A2.354,2.354,0,0,1,2.351,0h8.714a2.353,2.353,0,0,1,2.35,2.351v8.713a2.353,2.353,0,0,1-2.35,2.351M2.351,1A1.352,1.352,0,0,0,1,2.351v8.713a1.352,1.352,0,0,0,1.351,1.351h8.714a1.351,1.351,0,0,0,1.35-1.351V2.351A1.351,1.351,0,0,0,11.065,1Z"
                            />
                            <path
                              d="M6.622,10.274a.5.5,0,0,1-.4-.2c-.37-.5-2.216-3.035-2.216-4.3A2.617,2.617,0,0,1,6.619,3.152h0A2.618,2.618,0,0,1,9.236,5.768c0,1.27-1.845,3.809-2.213,4.3a.5.5,0,0,1-.4.2m0-6.122A1.615,1.615,0,0,0,5.005,5.767h0A9.2,9.2,0,0,0,6.622,8.916,9.249,9.249,0,0,0,8.236,5.768,1.617,1.617,0,0,0,6.622,4.152Z"
                            />
                            <path
                              d="M7.267,5.731a.645.645,0,1,1-.644-.644.644.644,0,0,1,.644.644"
                            />
                          </g>
                        </svg>
                        <span class="ml-2">Get Netstar Companion</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      href="https://www.netstar.co.za/netstar-roadguard/"
                      target="_blank"
                      class="dropdown__menu-link"
                      title="Get Netstar Roadguard"
                    >
                      <div class="dropdown__menu-text">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14.177 14.177"
                          class="icon"
                        >
                          <g transform="translate(0 0)">
                            <g>
                              <path
                                d="M7.088,0a7.089,7.089,0,1,0,7.089,7.089A7.1,7.1,0,0,0,7.088,0m0,13.177a6.089,6.089,0,1,1,6.089-6.088,6.1,6.1,0,0,1-6.089,6.088"
                              />
                              <path
                                d="M11.066,4.81a.518.518,0,0,0-.034-.093.38.38,0,0,0-.043-.047,4.574,4.574,0,0,0-7.8,0,.438.438,0,0,0-.041.045.44.44,0,0,0-.033.092A4.458,4.458,0,0,0,3.126,9.4a.46.46,0,0,0,.019.052c.005.009.015.012.02.021a4.57,4.57,0,0,0,7.846,0c.006-.008.016-.012.021-.021a.35.35,0,0,0,.02-.054,4.458,4.458,0,0,0,.014-4.583M7.088,8.09a1,1,0,1,1,1-1,1,1,0,0,1-1,1m-3.6-1a3.583,3.583,0,0,1,.289-1.413l1.406.845a1.8,1.8,0,0,0,0,1.126l-1.408.844a3.579,3.579,0,0,1-.284-1.4M9,6.52,10.4,5.676a3.6,3.6,0,0,1,.005,2.815L9,7.647A1.8,1.8,0,0,0,9,6.52m.876-1.691-1.387.832a2,2,0,0,0-.9-.5V3.534a3.579,3.579,0,0,1,2.286,1.3m-3.286-1.3V5.158a2,2,0,0,0-.9.5L4.3,4.829a3.584,3.584,0,0,1,2.285-1.3M4.3,9.34l1.385-.831a2,2,0,0,0,.907.51v1.624A3.578,3.578,0,0,1,4.3,9.34m3.292,1.3V9.019a2,2,0,0,0,.908-.51l1.385.831a3.578,3.578,0,0,1-2.293,1.3"
                              />
                            </g>
                          </g>
                        </svg>
                        <span class="ml-2">Get Netstar Roadguard</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown__menu-item secondary">
                    <a
                      href="https://www.netstar.co.za/value-added-services/"
                      target="_blank"
                      class="dropdown__menu-link"
                      title="Get Netstar GO"
                    >
                      <div class="dropdown__menu-text">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 13.718 14.229"
                          class="icon"
                        >
                          <g transform="translate(0 -0.001)">
                            <g>
                              <path
                                d="M13.331,7.594a.792.792,0,0,1,0-.96A1.874,1.874,0,0,0,12.377,3.7a.788.788,0,0,1-.564-.777,1.874,1.874,0,0,0-2.5-1.814A.8.8,0,0,1,8.4.811a1.873,1.873,0,0,0-3.086,0,.79.79,0,0,1-.913.3A1.873,1.873,0,0,0,1.9,2.922.79.79,0,0,1,1.34,3.7,1.874,1.874,0,0,0,.386,6.635a.789.789,0,0,1,0,.96A1.874,1.874,0,0,0,1.34,10.53a.792.792,0,0,1,.565.778,1.874,1.874,0,0,0,2.5,1.813.794.794,0,0,1,.912.3,1.872,1.872,0,0,0,3.086,0,.788.788,0,0,1,.913-.3,1.875,1.875,0,0,0,2.5-1.814.79.79,0,0,1,.564-.778,1.874,1.874,0,0,0,.954-2.936m-.861.659a.79.79,0,0,1-.4,1.238,1.875,1.875,0,0,0-1.34,1.844.791.791,0,0,1-1.052.765,1.875,1.875,0,0,0-2.168.7.812.812,0,0,1-1.3,0,1.873,1.873,0,0,0-2.167-.7.79.79,0,0,1-1.052-.764,1.878,1.878,0,0,0-1.34-1.844.789.789,0,0,1-.4-1.237,1.875,1.875,0,0,0,0-2.278.79.79,0,0,1,.4-1.238,1.879,1.879,0,0,0,1.34-1.843.787.787,0,0,1,1.052-.764,1.877,1.877,0,0,0,2.167-.705.811.811,0,0,1,1.3,0,1.877,1.877,0,0,0,2.167.7.789.789,0,0,1,1.053.765,1.877,1.877,0,0,0,1.34,1.843.79.79,0,0,1,.4,1.237,1.875,1.875,0,0,0,0,2.278"
                              />
                              <path
                                d="M9.089,5.149l-3,3.005L4.971,7.041a.542.542,0,1,0-.766.767L5.7,9.3a.544.544,0,0,0,.767,0L9.857,5.917a.543.543,0,0,0-.768-.768"
                              />
                            </g>
                          </g>
                        </svg>
                        <span class="ml-2">Get Netstar GO</span>
                      </div>
                    </a>
                  </li>
<!--                  <li class="dropdown__menu-item secondary">-->
<!--                    <a-->
<!--                        href="https://www.netstar.co.za/safecity-initiative-v2/"-->
<!--                        target="_blank"-->
<!--                        class="dropdown__menu-link"-->
<!--                        title="Get SafeCity"-->
<!--                    >-->
<!--                      <div class="dropdown__menu-text">-->
<!--                        <svg-->
<!--                            width="14"-->
<!--                            height="14"-->
<!--                            viewBox="0 0 26.477 23.236"-->
<!--                            class="icon">-->
<!--                          <g id="Group_746" data-name="Group 746" transform="translate(-1765 -2654.266)">-->
<!--                            <path id="Rectangle_231" data-name="Rectangle 231" d="M1.468,1A.469.469,0,0,0,1,1.468v9.79a.468.468,0,1,0,.937,0V1.468A.469.469,0,0,0,1.468,1m0-1A1.468,1.468,0,0,1,2.937,1.468v9.79a1.468,1.468,0,1,1-2.937,0V1.468A1.468,1.468,0,0,1,1.468,0Z" transform="translate(1765 2664.774)" fill="#52c2df"/>-->
<!--                            <path id="Rectangle_232" data-name="Rectangle 232" d="M1,1V7.811h.937V1H1M0,0H2.937V8.811H0Z" transform="translate(1766.958 2666.733)" fill="#52c2df"/>-->
<!--                            <path id="Path_521" data-name="Path 521" d="M1.966,1C1.376,1,1,1.326,1,1.55V14.93c0,.224.376.55.966.55H4.308V1H1.966m0-1H5.308V16.48H1.966A1.8,1.8,0,0,1,0,14.93V1.55A1.8,1.8,0,0,1,1.966,0Z" transform="translate(1767.807 2663.135) rotate(-60)" fill="#52c2df"/>-->
<!--                            <path id="Path_520" data-name="Path 520" d="M1.033,1,1,15.979,4.89,19.266,4.9,1H1.033m-1-1H5.9L5.89,21.419,0,16.443Z" transform="translate(1769.98 2659.371) rotate(-60)" fill="#52c2df"/>-->
<!--                            <path id="Subtraction_1" data-name="Subtraction 1" d="M2.161,5.124A3.583,3.583,0,0,1,0,4.388L1.185,2.043l-.033-.022L6.065,0A5.2,5.2,0,0,1,6.1.623,4.254,4.254,0,0,1,2.161,5.124Z" transform="translate(1782.388 2664.956)" fill="#fff"/>-->
<!--                            <path id="Subtraction_1_-_Outline" data-name="Subtraction 1 - Outline" d="M2.161,4.124A3.088,3.088,0,0,0,5.005,1.517L1.933,2.781l-.606,1.2a2.5,2.5,0,0,0,.835.144m0,1A3.583,3.583,0,0,1,0,4.388L1.185,2.043l-.033-.022L6.065,0A5.2,5.2,0,0,1,6.1.623,4.254,4.254,0,0,1,2.161,5.124Z" transform="translate(1782.388 2664.956)" fill="#52c2df"/>-->
<!--                            <path id="Path_525" data-name="Path 525" d="M1956,3157.555v-1h3.561a1.327,1.327,0,0,0,1.022-.6,19.819,19.819,0,0,0,1.007-1.735l.892.452a20.445,20.445,0,0,1-1.067,1.838,2.338,2.338,0,0,1-1.854,1.042H1956Z" transform="translate(-186.549 -486.895)" fill="#52c2df"/>-->
<!--                            <path id="Path_526" data-name="Path 526" d="M1959.656,3182.979H1956v-1h3.593a4.631,4.631,0,0,0,2.719-1.283,22.919,22.919,0,0,0,1.541-2.417l.881.474a23.566,23.566,0,0,1-1.617,2.537,5.628,5.628,0,0,1-3.431,1.685Z" transform="translate(-186.549 -509.667)" fill="#52c2df"/>-->
<!--                          </g>-->
<!--                        </svg>-->
<!--                        <span class="ml-2">Get SafeCity</span>-->
<!--                      </div>-->
<!--                    </a>-->
<!--                  </li>-->
                </template>
              </Accordion>
              <hr v-if="canInstallApp" />
              <li class="dropdown__menu-item" v-if="canInstallApp">
                <div
                  class="dropdown__menu-link"
                  title="Install New App">
<!--                  @click="promptInstall()"-->
                  <div class="dropdown__menu-text">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14.3 23.4"
                      class="install-icon"
                    >
                      <g>
                        <path
                          d="M11.5,0H2.9C1.3,0,0,1.3,0,2.9v17.6c0,1.6,1.3,2.9,2.9,2.9h8.6c1.6,0,2.9-1.3,2.9-2.9V2.9C14.3,1.3,13,0,11.5,0z M2.9,1.3
		h8.6c0.9,0,1.6,0.7,1.6,1.6v15.8H1.3V2.9C1.3,2,2,1.3,2.9,1.3z M11.5,22.1H2.9c-0.9,0-1.6-0.7-1.6-1.6v-0.6h11.8v0.6
		C13.1,21.4,12.3,22.1,11.5,22.1z"
                        />
                        <path
                          d="M6.7,16.7c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2l2-2c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0l-1,1V9.3
		c0-0.3-0.3-0.6-0.6-0.6C6.8,8.7,6.6,9,6.6,9.3v5.5l-1-1c-0.2-0.2-0.6-0.2-0.9,0c-0.2,0.2-0.2,0.6,0,0.9L6.7,16.7z"
                        />
                        <path
                          d="M7.2,4.5c0.4,0,0.7-0.3,0.7-0.7S7.5,3.1,7.2,3.1S6.5,3.4,6.5,3.8S6.8,4.5,7.2,4.5z"
                        />
                      </g>
                    </svg>
                    <span class="ml-2">Install App</span>
                    <div class="app-buttons">
                      <a href="https://apps.apple.com/za/app/mynetstar/id1629057326" target="_blank">
                        <img src="~@/assets/images/apple-app-store-mobile.png" alt="Download on the App Store" class="app-store-image">
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=za.co.netstar.my.twa&hl=en&gl=US " target="_blank">
                        <img src="~@/assets/images/google-play-store-mobile.png" alt="Get it on Google Play" class="google-play-image">
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <hr />
              <li class="dropdown__menu-item">
                <router-link
                  to="/logout"
                  class="dropdown__menu-link"
                  title="Logout"
                  replace
                >
                  <div class="dropdown__menu-text">Logout</div>
                </router-link>
              </li>
              <li class="dropdown__menu-item">
                <a
                  href="tel:0800112222"
                  class="dropdown__menu-link emergency"
                  title="EMERGENCY"
                >
                  <div class="dropdown__menu-text">
                    EMERGENCY: 0800 11 22 22
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.menu {
  position: absolute;
  top: 52px;
  right: 0;
  z-index: 2;

  &__navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
  }
}

.dropdown__menu {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 250px;
  overflow-y: auto;
  padding: 0 15px 15px 15px;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.3);

  &-nav {
    list-style: none;
    padding: 0;
  }

  &-heading {
    font-weight: bold;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-transform: uppercase;
    padding: 6px 10px;
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-decoration: none;
    text-transform: uppercase;
    padding: 6px 10px;

    &:hover {
      background: $brand-color-3;
    }

    .secondary & {
      color: $brand-color-2;

      &:hover {
        color: $brand-color-1;
      }

      .icon {
        fill: $brand-color-2;
        vertical-align: sub;
      }

      &:hover {
        .icon {
          fill: $brand-color-1;
        }
      }
    }

    &.emergency {
      color: $red;
      text-align: center;
      padding-top: 8px;
      margin-top: 25px;
      border-radius: 18px;
      border: solid 2px $red;

      &:hover {
        background: $red;
        color: #fff;
      }
    }
  }

  &-text {
    width: 100%;
    font-weight: bold;

    .secondary & {
      font-weight: 600;
    }

    .install-icon {
      fill: $brand-color-1;
      vertical-align: sub;
    }
  }

  hr {
    margin: 10px 0;
    border-top: 1px solid $brand-color-2;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.app-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  a {
    display: flex;
    justify-content: center;
    img {
      display: block;
      height: auto;
      max-width: 100%;
    }
  }

  .app-store-image {
    width: 120px;
  }

  .google-play-image {
    width: 130px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { modalService, tooltipService } from "@/services";
import Accordion from "@/components/Accordion";

export default {
  name: "Menu",
  components: {
    Accordion,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapState("navigation", ["show"]),
    ...mapState("pwa", ["deferredPromptForInstall", "canInstallApp"]),
    ...mapGetters("account", {
      isBusinessAccount: "getIsBusinessAccount",
    }),
    addProductUrl() {
      return this.isBusinessAccount
        ? "https://www.netstar.co.za/netstar-business/"
        : "https://www.netstar.co.za/mynetstar/#products";
    },
  },
  methods: {
    ...mapMutations("navigation", ["updateShow"]),
    ...mapMutations("pwa", ["updateDeferredPromptForInstall"]),
    // promptInstall() {
    //   if (this.deferredPromptForInstall) {
    //     this.deferredPromptForInstall.prompt();
    //     this.deferredPromptForInstall.userChoice.then((choiceResult) => {
    //       // if (choiceResult.outcome === "accepted") {
    //       //   console.log("User accepted the install prompt");
    //       // } else {
    //       //   console.log("User dismissed the install prompt");
    //       // }
    //       this.updateDeferredPromptForInstall(null);
    //     });
    //   } else if (this.$isIOS) {
    //     tooltipService.showTooltip("install-app");
    //   }
    // },
    showModal(screenName) {
      try {
        this.$gtag.event("admin_" + screenName);
      } catch (err) {}
      this.updateShow(false);
      modalService.showModal(this, screenName);
    },
  },
};
</script>
