<template>
  <div class="alerts-component screen">
    <SheetVehicleFeatureHeader title="Alerts" v-if="$isMobile" />
    <ModalHeader
      v-else
      :config="{
        type: 'vehicle',
        title: 'Alerts',
        subTitle: '',
        tooltipScreenName: 'alerts',
      }"
      @close="beforeClose()"
    />
    <Alert />
    <div class="heading">ALERT SEARCH</div>
    <div class="search-top">
      <div class="date-pickers">
        <DatePicker label="FROM" v-model="fromDate" />
        <DatePicker label="TO" :minDate="fromDate" v-model="toDate" />
      </div>
      <div class="buttons">
        <button class="btn btn-outline mr-3" :disabled="processing" @click="getAlertsByDate()">
          <span v-show="!processing">SEARCH</span>
          <Spinner v-show="processing" />
        </button>
        <button class="btn btn-outline" @click="showModal('manage-alerts')">MANAGE ALERTS</button>
      </div>
    </div>
    <div class="search-results">
      <div class="search-results-heading" v-if="searchResults">
        {{ alerts.length }} Alerts between <span>{{ fromDate }}</span> and
        <span>{{ toDate }}</span>
      </div>
      <div class="search-results-heading" v-else>Last 10 Alerts</div>
      <div class="search-results-content">
        <PanelSpinner v-if="loading" />
        <SmoothReflow>
          <template v-if="!loading">
            <template v-if="alerts.length">
              <div class="item container" :class="{ active: item.Id === activeAlertId }" v-for="item in alerts" :key="item.Id">
                <div class="row align-items-center" @click="displayAlertOnMap(item.Id, item)">
                  <div class="col-lg-4 d-flex align-items-center mb-3 mb-lg-0">
                    <img :src="getAlertIconURL(item.Category)" class="alert-icon" width="40" height="40" alt />
                    <span class="alert-name">{{ item.Name }}</span>
                  </div>
                  <div class="geo-address col-lg-4 mb-0 mb-md-2 mb-lg-0 text-left text-lg-center">
                    <span>{{ item.Location.GeoAddress }}</span>
                  </div>
                  <div class="col-lg-4 pl-lg-0 mb-2 mb-lg-0 d-flex justify-content-lg-center">
                    <div class="datetime d-flex d-lg-block flex-grow-1 flex-lg-grow-0 justify-content-md-evenly">
                      <div class="date mr-3 mr-lg-0">
                        <img src="~@/assets/images/calendar.svg" width="15" height="15" alt />
                        <span>{{ getDate(item.Location.DateTime) }}</span>
                      </div>
                      <div class="time">
                        <img src="~@/assets/images/clock.svg" width="15" height="15" alt />
                        <span>{{ getTime(item.Location.DateTime) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <ErrorBox message="No Alerts Received" v-else />
          </template>
        </SmoothReflow>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.alerts-component {
  > .heading {
    font-size: 0.875rem;
    color: $brand-color-2;
    margin-bottom: 15px;
  }

  .search-top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .date-pickers {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .buttons {
      display: flex;

      button {
        min-width: 92px;
      }
    }
  }

  &.small {
    .search-top {
      display: block;

      .buttons {
        display: block;
        margin: 15px 0 20px 0;
      }
    }
  }

  .search-results {
    &-heading {
      font-weight: bold;
      margin-bottom: 5px;

      span {
        color: $brand-color-2;
      }
    }

    &-content {
      background: $brand-color-4;
      padding: 20px;

      .item {
        background: #fff;
        padding: 10px;
        margin-bottom: 15px;
        border: 2px solid #fff;
        box-shadow: $drop-shadow-light;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          cursor: pointer;
        }

        &.active {
          border: 2px solid $red;
        }

        .alert {
          &-icon {
            margin-right: 5px;
          }

          &-name {
            position: relative;
            font-weight: 600;
            padding-left: 20px;

            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              height: 30px;
              transform: translateY(-50%);
              border-left: 1px solid #d50000;
            }
          }
        }

        .date {
          line-height: 16px;
          margin-bottom: 5px;

          img {
            vertical-align: top;
            margin-right: 8px;
          }
        }

        .time {
          line-height: 16px;

          img {
            vertical-align: top;
            margin-right: 8px;
          }
        }

        .delete-icon {
          fill: $brand-color-1;
        }

        .button {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: $md-1) {
  .alerts-component {
    > .heading {
      margin: 0 0 10px 0;
    }

    .search-top {
      display: block;

      .buttons {
        display: block;
        margin: 15px 0 20px 0;
      }
    }

    .search-results-content {
      .item {
        .alert-name {
          padding-left: 10px;

          &:before {
            display: none;
          }
        }

        .geo-address span,
        .datetime {
          padding-left: 10px;
          border-left: 1px solid $brand-color-2;
        }

        .datetime {
          padding-top: 13px;
          margin-top: -3px;

          .date {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import { assetService, modalService } from "@/services";
import SheetVehicleFeatureHeader from "@/components/screens/SheetVehicleFeatureHeader";
import DatePicker from "@/components/DatePicker";

export default {
  name: "Alerts",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
    SheetVehicleFeatureHeader,
    DatePicker,
  },
  data() {
    return {
      loading: true,
      processing: false,
      searchResults: false,
      submitSuccess: false,
      submitError: false,
      activeAlertId: null,
      alerts: [],
      fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState(["map"]),
    ...mapGetters("asset", ["getActiveSafeAndSoundVehicleId"]),
    activeAssetId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
  },
  created() {
    const vehicleId = this.getActiveSafeAndSoundVehicleId();
    const startTime = Date.now();
    assetService
      .getVehicleAlertsHistory(vehicleId)
      .then((data) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.alerts = data;
            this.loading = false;
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  mounted() {
    if (this.map.gmapRef) {
      this.$options.marker = null;
      this.$options.infoWindow = null;
    }
  },
  beforeDestroy() {
    this.resetAlertMapDisplay();
    this.updateShowMarkers(true);
    this.setMapBounds();
  },
  methods: {
    ...mapActions("map", ["setMapBounds"]),
    ...mapMutations("map", ["updateShowMarkers"]),
    ...mapMutations("navigation", ["updateShowSheet"]),
    beforeClose() {
      this.$emit("close");
    },
    getDate(dateTime) {
      return dateTime.split("T")[0];
    },
    getTime(dateTime) {
      return dateTime.split("T")[1];
    },
    showModal(screenName) {
      modalService.showModal(this, screenName);
    },
    getAlertsByDate() {
      this.processing = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
        .getVehicleAlertsHistoryByDate(vehicleId, this.fromDate, this.toDate)
        .then((data) => {
          this.clearAlertsState();
          this.searchResults = true;
          this.alerts = data;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
        });
    },
    getAlertIconURL(category) {
      let url = "";
      switch (category) {
        case "Acceleration":
          url = require("@/assets/images/trips/harsh-acceleration.png");
          break;
        case "Braking":
          url = require("@/assets/images/trips/harsh-braking.png");
          break;
        case "Cornering":
          url = require("@/assets/images/trips/harsh-cornering.png");
          break;
        case "Geo-Fencing:EnterGeoZone":
        case "Geo-Fencing:ExitGeoZone":
        case "Geo-Fencing:IgnitionOff":
        case "Geo-Fencing:IgnitionOn":
          url = require("@/assets/images/trips/geo-zone.png");
          break;
        case "Impacts":
          url = require("@/assets/images/trips/impacts.png");
          break;
        case "Lane-Change":
          url = require("@/assets/images/trips/lane-changing.png");
          break;
        case "OverSpeeding":
        case "Speeding":
          url = require("@/assets/images/trips/over-speeding.png");
          break;
        case "Time Rule:IgnitionOn":
          url = require("@/assets/images/trips/time-rule.png");
          break;
      }
      return url;
    },
    displayAlertOnMap(key, alert) {
      if (this.activeAlertId !== key) {
        this.activeAlertId = key;
        this.updateShowMarkers(false);
        this.resetAlertMapDisplay();

        const position = new google.maps.LatLng(alert.Location.Latitude, alert.Location.Longitude);

        const bounds = new google.maps.LatLngBounds();
        bounds.extend(position);

        const icon = {
          url: this.getAlertIconURL(alert.Category),
          scaledSize: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(25, 25),
        };

        this.$options.marker = new google.maps.Marker({
          position: position,
          map: this.map.gmapRef.$mapObject,
          icon: icon,
          title: "",
        });

        const content = `
        <div class="alert-content">
          <table style="width: 300px;">
            <tbody>
              <tr>
                <td style="font-weight: bold;">Date: </td>
                <td>${alert.Location.DateTime.replace("T", " ")}</td>
              </tr>
              <tr>
                <td style="font-weight: bold;">Speed: </td>
                <td>${alert.Location.Speed} kph</td>
              </tr>
              <tr>
                <td style="font-weight: bold;">Heading:  </td>
                <td>${alert.Location.Heading}</td>
              </tr>
              <tr>
                <td style="font-weight: bold;">GPS Coordinates: </td>
                <td>${alert.Location.Latitude}, ${alert.Location.Longitude}</td>
              </tr>
              <tr>
                <td rolspan="2" style="font-weight: bold;">Address: </td>
                <td>${alert.Location.GeoAddress}</td>
              </tr>
            </tbody>
          </table>
        </div>
        `;

        this.$options.infoWindow = new google.maps.InfoWindow({
          content: content,
        });

        this.$options.infoWindow.open(this.map.gmapRef.$mapObject, this.$options.marker);

        this.$options.marker.addListener("click", () => {
          this.$options.infoWindow.open(this.map.gmapRef.$mapObject, this.$options.marker);
        });

        this.map.gmapRef.fitBounds(bounds);
        google.maps.event.addListenerOnce(this.map.gmapRef.$mapObject, "idle", () => {
          const zoomLevel = this.$isMobile ? 13 : 16;
          this.map.gmapRef.$mapObject.setZoom(zoomLevel);
        });

        if (this.$isMobile) {
          this.updateShowSheet(false);
        }
      }
    },
    resetAlertMapDisplay() {
      if (this.$options.marker) {
        this.$options.marker.setMap(null);
      }

      this.$options.marker = null;
    },
    clearAlertsState() {
      this.resetAlertMapDisplay();
      this.activeAlertId = null;
      this.searchResults = false;
    },
  },
  watch: {
    activeAssetId() {
      this.processing = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
        .getVehicleAlertsHistory(vehicleId)
        .then((data) => {
          this.clearAlertsState();
          this.alerts = data;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
        });
    },
  },
};
</script>