<template>
  <transition name="fade">
    <div id="modal-container" v-show="showModal">
      <transition name="scale">
        <Modal :style="{ width: calculateModalWidth() }" :active="showModal" v-show="showModal">
          <SmoothReflow :active="modalCount > 1">
            <component :is="modalComponent" v-bind="modalComponentProps" @close="closeModal()" />
          </SmoothReflow>
        </Modal>
      </transition>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

#modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($brand-color-1, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<script>
import { mapState } from "vuex";
import { modalService } from "@/services";
import Modal from "@/components/Modal";

export default {
  name: "ModalContainer",
  components: {
    Modal,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState("navigation", ["showModal", "modalCount", "modalWidth", "modalComponent", "modalComponentProps"]),
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    calculateModalWidth() {
      if (this.windowWidth <= 480) {
        return "100%";
      } else if (this.windowWidth - this.modalWidth < 40) {
        return this.windowWidth - 40 + "px";
      } else {
        return this.modalWidth + "px";
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      const vh = document.documentElement.clientHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    closeModal() {
      modalService.closeModal();
    },
  },
};
</script>
