<template>
  <div class="manage-alerts-component screen">
    <ModalHeader
      :config="{
        type: 'back',
        screenName: 'alerts',
        screenType: 'drawer',
        hasVehiclePicker: true,
        title: 'Manage Alerts',
        subTitle: '',
      }"
      @close="beforeClose()"
    />
    <div class="top">
      <div class="heading">MANAGE ALERTS</div>
      <div class="button">
        <button class="btn btn-outline" :disabled="processing" @click="showModal('new-alert-entry')">
          <span v-show="!processing">NEW ALERT</span>
          <Spinner v-show="processing" />
        </button>
      </div>
    </div>
    <Alert />
    <div class="panel">
      <PanelSpinner v-if="loading" />
      <SmoothReflow>
        <div class="item container" v-for="(item, i) in alerts" :key="item.Id">
          <div class="row align-items-center text-center text-md-left">
            <div class="col-md-5 mb-2 mb-md-0">
              <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                <img :src="getAlertIconURL(item.EditorMetadata)" class="alert-icon" width="40" height="40" alt />
                <span class="alert-name">{{ item.Name }}</span>
              </div>
            </div>
            <div class="col-md-3 mb-3 mb-md-0">{{ item.EditorMetadata }}</div>
            <div class="col-md-4 mb-2 mb-md-0 d-flex justify-content-around">
              <Spinner v-if="processing && toggledAlertId === item.Id" />
              <div class="custom-control custom-switch" v-else>
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'alertActive' + i"
                  v-model="item.Active"
                  :disabled="processing"
                  @click="toggleActive(item)"
                />
                <label class="custom-control-label" :for="'alertActive' + i" />
              </div>
              <img src="~@/assets/images/edit.svg" class="button" width="25" height="25" alt @click="edit(item)" />
              <svg width="25" height="25" viewBox="0 0 16 16" class="delete-icon button" fill="currentColor" @click="deleteAlert(item.Id)">
                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
              </svg>
            </div>
          </div>
        </div>
      </SmoothReflow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.manage-alerts-component {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .button {
      width: 110px;

      button {
        width: 100%;
      }
    }
  }

  .panel {
    background: $brand-color-4;
    padding: 20px;

    .item {
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      box-shadow: $drop-shadow-light;

      &:last-of-type {
        margin-bottom: 0;
      }

      .alert {
        &-icon {
          margin-right: 5px;
        }

        &-name {
          position: relative;
          padding-left: 20px;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            height: 30px;
            transform: translateY(-50%);
            border-left: 1px solid #d50000;
          }
        }
      }

      .delete-icon {
        fill: $brand-color-1;
      }

      .button {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { alertService, assetService, modalService } from "@/services";

export default {
  name: "Alerts",
  components: {
    ModalHeader: () => import("@/components/screens/ModalHeader"),
  },
  data() {
    return {
      loading: true,
      processing: false,
      toggledAlertId: null,
      alerts: [],
    };
  },
  computed: {
    ...mapGetters("asset", ["getActiveSafeAndSoundVehicleId"]),
    activeAssetId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
  },
  created() {
    const vehicleId = this.getActiveSafeAndSoundVehicleId();
    const startTime = Date.now();
    assetService
      .getVehicleAlerts(vehicleId)
      .then((data) => {
        const timeElapsed = Date.now() - startTime;
        setTimeout(
          () => {
            this.alerts = data;
            this.loading = false;
          },
          timeElapsed < 500 ? 500 - timeElapsed : 0
        );
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  methods: {
    beforeClose() {
      this.$emit("close");
    },
    showModal(screenName, parameters = {}) {
      modalService.showModal(this, screenName, parameters);
    },
    getAlertIconURL(category) {
      let url = "";
      switch (category) {
        case "Acceleration":
          url = require("@/assets/images/trips/harsh-acceleration.png");
          break;
        case "Braking":
          url = require("@/assets/images/trips/harsh-braking.png");
          break;
        case "Cornering":
          url = require("@/assets/images/trips/harsh-cornering.png");
          break;
        case "Geo-Fencing":
          url = require("@/assets/images/trips/geo-zone.png");
          break;
        case "Impacts":
          url = require("@/assets/images/trips/impacts.png");
          break;
        case "Lane-Change":
          url = require("@/assets/images/trips/lane-changing.png");
          break;
        case "Speeding":
          url = require("@/assets/images/trips/over-speeding.png");
          break;
        case "Time Rule":
          url = require("@/assets/images/trips/time-rule.png");
          break;
      }
      return url;
    },
    edit(alert) {
      this.showModal("new-alert", {
        alert: alert,
      });
    },
    deleteAlert(alertId) {
      modalService.showDeleteDialog("Are you sure you want to delete this alert?", () => {
        this.processing = true;
        assetService
          .deleteVehicleAlert(alertId)
          .then((result) => {
            this.processing = false;
            if (result) {
              this.alerts = this.alerts.filter((item) => item.Id !== alertId);
            } else {
              alertService.showErrorAlert();
            }
          })
          .catch((error) => {
            this.processing = false;
            alertService.showErrorAlert();
          });
      });
    },
    toggleActive(alert) {
      this.processing = true;
      this.toggledAlertId = alert.Id;
      const alertCopy = JSON.parse(JSON.stringify(alert));
      alertCopy.Active = !alertCopy.Active;

      assetService
        .updateVehicleAlert(alertCopy)
        .then((result) => {
          this.processing = false;
          if (result) {
            alert.Active = !alert.Active;
          } else {
            alertService.showErrorAlert();
          }
          this.toggledAlertId = null;
        })
        .catch((error) => {
          this.processing = false;
          this.toggledAlertId = null;
          alertService.showErrorAlert();
        });
    },
  },
  watch: {
    activeAssetId() {
      this.processing = true;
      const vehicleId = this.getActiveSafeAndSoundVehicleId();
      assetService
        .getVehicleAlerts(vehicleId)
        .then((data) => {
          this.alerts = data;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
        });
    },
  },
};
</script>