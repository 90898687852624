<template>
  <main>
    <div class="map-container">
      <LeftDrawer @width="updateLeftDrawerWidth" v-if="!$isMobile" />
      <transition name="fade">
        <div class="loader" v-if="loading">
          <img
            src="~@/assets/images/netstar-loader.gif"
            width="100"
            height="100"
          />
        </div>
      </transition>
      <transition name="fade">
        <div
          id="map_disabled"
          v-if="isMapDisabled"
        />
        <GmapMap
          v-else
          id="map"
          ref="gmap"
          :class="{ shrink: showLeftDrawer }"
          :style="{ width: mapWidth, left: mapLeftPosition }"
          :center="{ lat: -30.5595, lng: 22.9375 }"
          :zoom="7"
          @zoom_changed="onZoomChanged"
          :options="{
            mapTypeId: mapTypeId,
            // maxZoom: 20,
            clickableIcons: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            draggable: !tourActive && mapIsNeeded,
          }"
        >
          <gmap-custom-marker
            :key="index"
            v-for="(m, index) in filterAssets(assets)"
            :marker="{ lat: m.Latitude, lng: m.Longitude }"
            :zIndex="selectedMarkerAssetId == m.SafeAndSoundVehicleId ? 51 : 50"
            v-show="map.showMarkers"
          >
            <AssetIcon
              :key="m.SafeAndSoundVehicleId"
              :registrationNumber="m.RegistrationNumber"
              :vehicleId="m.SafeAndSoundVehicleId"
              :isMarker="true"
              :isAssetPicker="false"
              :hasAccuracyRing="m.hasAccuracyRing"
              :lat="m.Latitude"
              :lng="m.Longitude"
              :totalAssets="filterAssets(assets).length"
              :zoomLevel="currentZoomLevel"
              data-v-step="3"
            />
          </gmap-custom-marker>
        </GmapMap>
      </transition>
    </div>

    <header>
      <div class="shadowWrap">
        <div class="logo">
          <img
            src="~@/assets/images/small-logo.png"
            width="32"
            height="32"
            alt="Netstar"
          />
        </div>
      </div>
      <transition name="fade">
        <GeoZoneEditor v-if="!$isMobile && map.showGeoZoneEditor" />
        <div :class="{ shadowWrap: !show && !$isMobile }" v-else>
          <div class="profile" :class="{ active: show }">
            <div class="welcome">
              <div>Welcome</div>
              <div class="name">{{ getDisplayName }}</div>
            </div>
            <img
              :src="getProfileImageUrl"
              class="inside-menu"
              width="32"
              height="32"
              alt="Avatar"
              v-show="!$isMobile || ($isMobile && show)"
            />
            <a class="menu__link">
              <button
                class="menu-button"
                key="on"
                @click="updateShow(false)"
                v-if="show"
              >
                <svg viewBox="0 0 24 24" class="menu-icon">
                  <title>Close</title>
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    :fill="brandColor1"
                    d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                  />
                </svg>
              </button>
              <template v-else>
                <button class="menu-button" key="off" @click="updateShow(true)">
                  <img
                    :src="getProfileImageUrl"
                    width="42"
                    height="42"
                    alt="Avatar"
                    v-if="$isMobile && getProfileImageUrl"
                  />
                  <svg
                    :fill="brandColor1"
                    viewBox="0 0 100 80"
                    width="20"
                    height="20"
                    v-else
                  >
                    <rect width="100" height="10" />
                    <rect y="30" width="100" height="10" />
                    <rect y="60" width="100" height="10" />
                  </svg>
                </button>
              </template>
            </a>
          </div>
        </div>
      </transition>
    </header>
    <BottomSheet v-if="$isMobile && this.assets.length" />
    <AssetPicker
      id="assetPicker"
      v-show="!map.showGeoZoneEditor"
      data-v-step="0"
      v-else
    />
    <Menu data-v-step="1" />
  </main>
</template>

<style lang="scss">
@import "~@/assets/scss/variables.scss";

.map-type-control {
  margin: 0 15px 15px 0;
  cursor: pointer;
}

@media (max-width: $md) {
  .map-type-control {
    margin-bottom: 90px;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

@include scrollbar;

main {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .mobile & {
    display: flex;
    flex-direction: column;
  }
}

header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 52px;
  z-index: 1;

  .mobile & {
    z-index: 3;
  }

  .shadowWrap {
    position: relative;
    box-shadow: $omni-shadow;
    transition: filter 2s;
  }

  .logo {
    display: flex;
    align-items: center;
    background: #fff;
    width: 75px;
    height: 100%;
    padding: 0 0 0 25px;

    &:after {
      content: " ";
      background: #fff;
      position: absolute;
      width: 20px;
      height: 100%;
      right: -10px;
      top: 0;
      z-index: 0;
      transform: skew(-20deg);
      box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  .profile {
    display: flex;
    align-items: center;
    background: #fff;
    width: 250px;
    height: 100%;
    line-height: 1;
    font-weight: bold;
    font-size: 0.8125rem;
    font-style: normal;
    color: $brand-color-1;
    text-align: right;
    text-transform: uppercase;
    padding-right: 10px;

    &:before {
      content: " ";
      background: #fff;
      position: absolute;
      width: 20px;
      height: 100%;
      left: -10px;
      top: 0;
      z-index: 1;
      transform: skew(20deg);
      box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    }

    &.active {
      box-shadow: $drop-shadow;
    }

    .mobile & {
      background: transparent;
      justify-content: flex-end;
      transition: background 1s;

      &:before {
        display: none;
      }

      .menu-button {
        padding: 10px;
      }

      img {
        margin-right: 0;
        box-shadow: $drop-shadow;

        &.inside-menu {
          box-shadow: none;
        }
      }

      .welcome,
      img.inside-menu {
        opacity: 0;
        transform: translateX(-10px);
        transition: all 1s;
      }

      &.active {
        background: #fff;
        box-shadow: none;

        .welcome,
        img.inside-menu {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .welcome {
      flex: 1;
      width: 150px;
      padding: 0 10px 0 15px;

      .name {
        color: $brand-color-2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    img {
      margin-right: 5px;
      border-radius: 50%;
    }
  }

  .menu-button {
    background-color: transparent;
    color: gray;
    cursor: pointer;
    margin: 0;
    border: 1px solid transparent;

    &:focus {
      outline: 0;
    }

    .menu-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.map-container {
  display: flex;
  height: 100%;

  .mobile & {
    flex: 1;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
  }

  #map {
    width: 100%;
    height: 100%;

    &.shrink {
      transition: left 1s 1s, width 1s 1s;
    }
  }

  #map_disabled {
    background: #fff url("~@/assets/images/google-map.jpg") no-repeat center
      center fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getGoogleMapsAPI, helpers } from "gmap-vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { modalService, publicApiService } from "@/services";
import AssetIcon from "@/components/AssetIcon";
import AssetPicker from "@/components/AssetPicker";
import BottomSheet from "@/components/BottomSheet";
import LeftDrawer from "@/components/LeftDrawer";
import Menu from "@/components/Menu";
import GeoZoneEditor from "@/components/screens/GeoZoneEditor";
import styles from "@/assets/scss/_variables.scss";

const { googleMapsApiInitializer } = helpers;

export default {
  name: "HomePage",
  components: {
    AssetIcon,
    AssetPicker,
    BottomSheet,
    GmapCustomMarker,
    LeftDrawer,
    Menu,
    GeoZoneEditor,
  },
  data() {
    return {
      loading: true,
      mapTypeIndex: 0,
      mapTypeIds: ["roadmap", "terrain", "hybrid"],
      creatingGeoZone: false,
      intervalId: null,
      brandColor1: styles.brandColor1,
      leftDrawerWidth: 0,
      mapWidth: "100%",
      mapLeftPosition: "0px",
      currentZoomLevel: 7,
    };
  },
  computed: {
    ...mapState(["asset", "map"]),
    ...mapState("map", ["selectedMarkerAssetId"]),
    ...mapState("navigation", ["show", "showLeftDrawer", "tourActive"]),
    ...mapGetters("account", ["getDisplayName", "getProfileImageUrl"]),
    google: getGoogleMapsAPI,
    mapTypeId() {
      return this.mapTypeIds[this.mapTypeIndex];
    },
    assets() {
      return this.asset.assets;
    },
    mapIsNeeded() {
      let countOfVehiclesRequiringMap = 0;
      this.assets.forEach((asset) => {
        if (
            !["Nano", "Basic"].includes(asset.ProductName) &&
            !(asset.ProductDisplayName === "StarTag" && asset.DateValidity == 'Outdated') &&
            !asset.AwaitingUnitActivation &&
            !asset.IsInRecovery
        ) {
          countOfVehiclesRequiringMap++;
        }
      });
      return countOfVehiclesRequiringMap > 0;
    },
    isMobile() {
      return /Mobi/.test(navigator.userAgent);
    },
    isMapDisabled() {
      return this.$disableMap || (!this.loading && !this.mapIsNeeded);
    }
  },
  created() {
    publicApiService
      .getSystemNotification()
      .then((message) => {
        if (message) {
          modalService.showSystemNotificationDialog(message);
        }
      });
  },
  mounted() {
    document.body.classList.add("overflow-hidden");

    if (this.$disableMap) {
      this.getVehicles()
        .then((hasVehicles) => {
          this.loading = false;
          this.updateMapLoadedWithVehicles(true);

          if (this.$isMobile) {
            this.updateShowSheet(true);
          }

          if (this.$liveUpdateMap) {
            this.intervalId = setInterval(() => {
              this.updateLocationData();
            }, 30000);
          }
        })
        .catch((error) => {});
    } else {
      this.getVehicles()
        .then((hasVehicles) => {
          if (hasVehicles && this.mapIsNeeded) {
            if (this.map.gmapRef == null) {
              googleMapsApiInitializer(
                {
                  key: process.env.VUE_APP_GOOGLE_API_KEY,
                  libraries: "drawing,geometry",
                },
                false
              );

              try {
                this.$gtag.event("map_load");
              } catch (err) {}
            }

            this.$refs.gmap.$mapPromise.then(() => {
              this.updateGmapRef(this.$refs.gmap);
              this.setMapBounds();

              this.updateMapLoadedWithVehicles(true);

              if (this.$isMobile) {
                this.updateShowSheet(true);
              }

              const mapControlDiv = this.createMapControl();
              this.$refs.gmap.$mapObject.controls[
                google.maps.ControlPosition.RIGHT_BOTTOM
              ].push(mapControlDiv);

              if (this.$liveUpdateMap) {
                this.intervalId = setInterval(() => {
                  this.updateLocationData();
                }, 30000);
              }

              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    document.body.classList.remove("overflow-hidden");
  },
  methods: {
    ...mapActions("asset", ["getVehicles", "updateLocationData"]),
    ...mapActions("map", ["setMapBounds"]),
    ...mapMutations("map", [
      "updateGmapRef",
      "updateShowGeoZoneEditor",
      "updateMapLoadedWithVehicles",
    ]),
    ...mapMutations("navigation", ["updateShow", "updateShowSheet"]),
    updateLeftDrawerWidth(value) {
      this.leftDrawerWidth = value;
    },
    createMapControl() {
      const controlDiv = document.createElement("div");
      const controlUI = document.createElement("img");
      controlUI.src = require("@/assets/images/map-type-control.svg");
      controlUI.width = "40";
      controlUI.height = "40";
      controlUI.title = "Click to change map type";
      controlUI.className = "map-type-control v-step-2";
      if (this.$isMobile) {
        controlUI.style.marginBottom = "15px";
      }
      controlDiv.appendChild(controlUI);

      controlUI.addEventListener("click", () => {
        this.mapTypeIndex++;
        if (this.mapTypeIndex >= this.mapTypeIds.length) {
          this.mapTypeIndex = 0;
        }
        this.$refs.gmap.$mapObject.setMapTypeId(this.mapTypeId);
      });

      return controlDiv;
    },
    canDisplay() {
      return this.$isMobile ? this.show : true;
    },
    filterAssets(assets) {
      return assets.filter((asset) => {
        return asset.show && !asset.shared;
      });
    },
    onZoomChanged() {
      if (this.$refs.gmap && this.$refs.gmap.$mapObject) {
        this.currentZoomLevel = this.$refs.gmap.$mapObject.getZoom();
      }
    },
  },
  watch: {
    showLeftDrawer(value) {
      this.mapWidth = value
        ? "calc(100% - " + this.leftDrawerWidth + "px)"
        : "100%";
      this.mapLeftPosition = value ? this.leftDrawerWidth + "px" : "0px";
    }
  },
};
</script>
