<template>
  <div class="tooltip-component">
    <button class="tooltip-target" :class="{ mobile: $isMobile }">?</button>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.tooltip-component {
  padding: 5px;

  .tooltip-target {
    background: $brand-color-2;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 1px 0 0 0;
    border-radius: 50%;
    border: none;

    &:focus {
      outline: none;
    }

    &.mobile {
      background: #fff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      color: $brand-color-2;
      border: 1px solid $brand-color-2;
    }
  }
}
</style>

<script>
export default {
  name: "Tooltip",
};
</script>